import { Dispatch, SetStateAction } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { CardCheckbox } from '@gbm/starman-next';
import isBoolean from 'lodash/isBoolean';

import { FormRow, FormSection } from '@gbm/onboarding-sdk-ui-components';
import { translations } from '@gbm/onboarding-sdk-utils';
import styles from './proof-of-address.module.scss';

type OptionsProps = {
  prefersIne?: boolean;
  setPrefersIne: Dispatch<SetStateAction<boolean | undefined>>;
};

const {
  upgrade: { proofOfAddress: proofOfAddressMsg },
} = translations;

export default function Options({ prefersIne, setPrefersIne }: OptionsProps) {
  const { t } = useTranslation();

  return (
    <motion.div
      className={styles.options}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <FormSection
        title={t(proofOfAddressMsg.title)}
        description={t(proofOfAddressMsg.subtitle)}
      >
        <FormRow>
          <CardCheckbox
            description={t(proofOfAddressMsg.prefersIneDescription)}
            checked={prefersIne}
            onClick={() => setPrefersIne(true)}
          >
            {t(proofOfAddressMsg.prefersIne)}
          </CardCheckbox>
          <CardCheckbox
            description={t(proofOfAddressMsg.billDescription)}
            checked={isBoolean(prefersIne) && !prefersIne}
            onClick={() => setPrefersIne(false)}
          >
            {t(proofOfAddressMsg.bill)}
          </CardCheckbox>
        </FormRow>
      </FormSection>
    </motion.div>
  );
}
