import { useTranslation } from 'react-i18next';

import {
  DATE_FORMATS,
  MASK_PATTERNS,
  translations,
} from '@gbm/onboarding-sdk-utils';
import {
  FormRow,
  ResumeSection,
  ResumeSectionItem,
} from '@gbm/onboarding-sdk-ui-components';
import {
  useCountriesAndStates,
  useParty,
  useIdentifiers,
} from '../../../hooks';
import { useAppSelector } from '../../../store';
import { selectPhone, selectCurp } from '../../../store/opening-lite';
import { formatIsoDate, mask } from '../../../../utils/helpers';
import { GENDERS } from '../../../../api/opening-lite/types';
import { useOpeningLiteProvider } from '../../../providers/opening-lite';
import styles from './personal-info.module.scss';

type PersonalInfoProps = {
  isLoading?: boolean;
};
const {
  inputs,
  openingLite: { personalInfo, confirmation },
} = translations;

export default function PersonalInfo({ isLoading }: PersonalInfoProps) {
  const { t } = useTranslation();
  const { configuration } = useOpeningLiteProvider();
  const { party } = useParty(configuration);
  const { countries, states } = useCountriesAndStates(configuration);
  const { rfc } = useIdentifiers(configuration);
  const curp = useAppSelector(selectCurp);
  const phone = useAppSelector(selectPhone);
  let phoneValue = '';
  let birthState;
  let birthDate;

  if (!isLoading) {
    birthState = states?.data?.find(
      ({ key }) => key === party.data?.birth_state,
    );
    birthDate = formatIsoDate(party.data?.birth_date, DATE_FORMATS.ddMMyyyy);
  }

  if (phone.data?.phone_number && countries.data.length) {
    const phoneCode = countries.data?.find(
      ({ country_code: code }) => code === phone.data?.country_code,
    )?.phoneCode;
    phoneValue = `+${phoneCode} ${mask(
      phone.data?.phone_number,
      MASK_PATTERNS.phone,
    )}`;
  }

  return (
    <ResumeSection title={t(personalInfo.title)} isLoading={isLoading}>
      <FormRow>
        <div className={styles['firstname-row']}>
          <ResumeSectionItem
            label={t(confirmation.nameLabel)}
            value={party.data?.first_name}
            isLoading={isLoading}
          />
        </div>
        <FormRow className={styles['lastname-row']}>
          <ResumeSectionItem
            label={t(confirmation.firstLastNameLabel)}
            value={party.data?.first_last_name}
            isLoading={isLoading}
          />
          <ResumeSectionItem
            label={t(confirmation.secondLastNameLabel)}
            value={party.data?.second_last_name}
            isLoading={isLoading}
          />
        </FormRow>
      </FormRow>
      <FormRow>
        <ResumeSectionItem
          label={t(confirmation.genderLabel)}
          value={t(
            party.data?.gender === GENDERS.male ? inputs.male : inputs.female,
          )}
          isLoading={isLoading}
        />
        <ResumeSectionItem
          label={t(confirmation.bornEntityLabel)}
          value={birthState?.label as string}
          isLoading={isLoading}
        />
        <ResumeSectionItem
          label={t(confirmation.birthdateLabel)}
          value={birthDate ?? ''}
          isLoading={isLoading}
        />
      </FormRow>
      <FormRow>
        <ResumeSectionItem
          label={t(confirmation.cellphoneLabel)}
          value={phoneValue}
          isLoading={isLoading}
        />
        <ResumeSectionItem
          label={t(confirmation.curpLabel)}
          value={curp.data?.value}
          isLoading={isLoading}
          breakAll
        />
        <ResumeSectionItem
          label={t(confirmation.rfcLabel)}
          value={
            rfc.data?.value
              ? `${rfc.data.value.slice(0, 10)} ${rfc.data.value.slice(-3)}`
              : ''
          }
          isLoading={isLoading}
        />
      </FormRow>
    </ResumeSection>
  );
}
