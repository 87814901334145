import { Values } from '@gbm/onboarding-sdk-utils';

export type PartyCountryType = {
  country_code: string;
  country_type: string;
  is_main_country: boolean;
};

export type BankAccountType = {
  bank_account_type: string;
  bank_account_number: string;
  legacy_contract_id: string;
};

export type IdentifierType = {
  identifier_type_id: string;
  name: string;
  value: string;
};

export const LOREM_QUESTIONNAIRE_TYPE = {
  clientEconomicActivity: 'client_economic_activity',
  pep: 'politically_exposed_person',
  kyc: 'know_your_customer',
};
export type LoremQuestionnaireType = Values<typeof LOREM_QUESTIONNAIRE_TYPE>;

export type LoremQuestionType = {
  question_id: string;
  answer_id?: string;
  answer_response?: string;
};

export type LoremQuestionnaireRequestType = {
  questionnaire_version_id: string;
  questionnaire: {
    version: string;
    questionnaire_type: LoremQuestionnaireType;
    questionnaire_answers: Array<LoremQuestionType>;
  };
};

export const DEEP_LINKS_ACTION = {
  limitIncrease: 'limit_increase',
};
export type DeepLinksActionType = Values<typeof DEEP_LINKS_ACTION>;

export type DeepLinksParamsType = Array<{
  key: string;
  value: string;
}>;

export const DEEP_LINKS_PROVIDER = {
  all: 'google',
  huawei: 'huawei',
} as const;
export type DeepLinksProviderType = Values<typeof DEEP_LINKS_PROVIDER>;

export const DEEP_LINKS_ORIGIN = 'app_evo';
