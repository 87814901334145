import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { useNotificationProvider } from '@gbm/onboarding-sdk-ui-components';
import {
  GENERIC_ERROR,
  TRACKING_NAMES,
  TRACKING_EVENTS,
} from '@gbm/onboarding-sdk-utils';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import {
  UpgradeProviderProps,
  UpgradeStepType,
  MAX_GET_STEP_ATTEMPTS,
  UPGRADE_STEPS,
  STEPS,
  StepType,
  UPGRADE_STATUSES,
  POOL_UPGRADE_STEP_TIME,
} from '../../providers/upgrade/types';
import { waitForTime } from '../../../utils/helpers';
import { useUpgradeApi } from '../../../api/upgrade/use-upgrade-api';
import {
  getUpgradeStep,
  selectUpgradeStep,
  getValidUpgrade,
  selectValidUpgrade,
} from '../../store/upgrade';
import { useAppDispatch, useAppSelector } from '../../store';
import { useParty } from '../useParty';
import { REDUCER_STATUS, StateValueType } from '../../store/types';

type UseUpgradeStepType = {
  checkUpgradeStep: () => void;
  currentStep: UpgradeStepType;
  poolForRequestStep: () => void;
  stopPooling: () => void;
  getUpgradeStep: () => void;
  saveUpgradeStep: (step: UpgradeStepType) => Promise<any>;
  handleUpgradeStep: (
    setStep: Dispatch<SetStateAction<StepType>>,
    step?: UpgradeStepType,
  ) => void;
  validateUpgrade: () => void;
  validUpgrade: StateValueType;
};

export default function useUpgradeStep(
  configuration: UpgradeProviderProps,
): UseUpgradeStepType {
  const { party } = useParty(configuration);
  const upgradeApi = useUpgradeApi(configuration);
  const { showNotification } = useNotificationProvider();
  const [getStepAttempts, setGetStepAttempts] = useState(0);
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );
  const poolingId = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useAppDispatch();
  const upgradeStep = useAppSelector(selectUpgradeStep);
  const currentStep = upgradeStep.data.step;
  const validUpgrade = useAppSelector(selectValidUpgrade);

  useEffect(() => {
    getStepAttempts > 0 && checkUpgradeStep();
  }, [getStepAttempts]);

  useEffect(() => {
    if (upgradeStep.status === REDUCER_STATUS.rejected) {
      !poolingId && setGetStepAttempts(getStepAttempts + 1);
    }
  }, [upgradeStep.status]);

  const checkUpgradeStep = async (pooling = false) => {
    if (getStepAttempts === MAX_GET_STEP_ATTEMPTS && !pooling) {
      // throw notification and return to upgrade screen
      showNotification({
        description: GENERIC_ERROR,
        kind: 'error',
      });
      return;
    }

    if (
      getStepAttempts < MAX_GET_STEP_ATTEMPTS &&
      getStepAttempts >= 1 &&
      !pooling
    ) {
      // wait for retry
      await waitForTime(2000 * getStepAttempts);
    }

    requestUpgradeStep();
  };

  const requestUpgradeStep = () => {
    dispatch(
      getUpgradeStep({ config: configuration, partyId: party.data.party_id }),
    );
  };

  const poolForRequestStep = () => {
    if (poolingId.current) {
      return;
    }

    poolingId.current = setInterval(() => {
      checkUpgradeStep(true);
    }, POOL_UPGRADE_STEP_TIME);
  };

  const stopPooling = () => {
    if (poolingId.current) {
      clearInterval(poolingId.current);
      poolingId.current = null;
    }
  };

  const saveUpgradeStep = async (step: UpgradeStepType) =>
    upgradeApi.saveUpgradeStep(party.data.party_id, step);

  const handleUpgradeStep = (
    setStep: Dispatch<SetStateAction<StepType>>,
    step?: UpgradeStepType,
  ) => {
    const handledStep = step ?? currentStep;

    if (handledStep) {
      switch (handledStep) {
        case UPGRADE_STEPS.gbmReviewDocs:
          mixpanel.track(TRACKING_EVENTS.screenViewed, {
            name: TRACKING_NAMES.upgradeStatus,
            status: UPGRADE_STATUSES.pending,
          });
          setStep(STEPS.pending);
          break;
        case UPGRADE_STEPS.gbmReviewData:
          mixpanel.track(TRACKING_EVENTS.screenViewed, {
            name: TRACKING_NAMES.upgradeStatus,
            status: UPGRADE_STATUSES.pendingManualValidation,
          });

          setStep(STEPS.reviewing);
          break;
        case UPGRADE_STEPS.partyReviewData:
          setStep(STEPS.confirmation);
          break;
        case UPGRADE_STEPS.endedProcess:
          mixpanel.track(TRACKING_EVENTS.screenViewed, {
            name: TRACKING_NAMES.upgradeStatus,
            status: UPGRADE_STATUSES.upgraded,
          });
          setStep(STEPS.success);
          break;
        case UPGRADE_STEPS.economicActivity:
          setStep(STEPS.financialInformation);
          break;
        case UPGRADE_STEPS.taxInformation:
          setStep(STEPS.taxInformation);
          break;
        case UPGRADE_STEPS.nationality:
          setStep(STEPS.nationality);
          break;
        case UPGRADE_STEPS.proofOfAddress:
          setStep(STEPS.proofOfAddress);
          break;
        case UPGRADE_STEPS.ine:
        case UPGRADE_STEPS.selfie:
        case UPGRADE_STEPS.livenessTest:
          setStep(STEPS.ine);
          break;
        case UPGRADE_STEPS.notSet:
        default:
          setStep(STEPS.upgradeScreen);
          break;
      }
    }
  };

  const validateUpgrade = () => {
    dispatch(
      getValidUpgrade({ config: configuration, partyId: party.data.party_id }),
    );
  };

  return {
    checkUpgradeStep,
    currentStep: currentStep as UpgradeStepType,
    poolForRequestStep,
    stopPooling,
    getUpgradeStep: requestUpgradeStep,
    saveUpgradeStep,
    handleUpgradeStep,
    validateUpgrade,
    validUpgrade,
  };
}
