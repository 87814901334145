import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@gbm/starman-next';

import logoPositive from '@gbm/onboarding-sdk-ui-components/assets/images/logos/atm-logo-positive-gbm.svg';
import logoNegative from '@gbm/onboarding-sdk-ui-components/assets/images/logos/atm-logo-negative-gbm.svg';
import { translations } from '@gbm/onboarding-sdk-utils';

import { LogoMarksProps } from './types';
import './LogoMarks.scss';

const CLASS_PREFIX = 'logo-marks';
const {
  base: { logoMarks, smartCash, wealth, trading },
} = translations;

export default function LogoMarks({
  variant,
  lightLogo = logoPositive,
  darkLogo = logoNegative,
}: LogoMarksProps) {
  const { t } = useTranslation();
  const isDefaultLogo = lightLogo === logoPositive && darkLogo === logoNegative;

  return (
    <div className={`${CLASS_PREFIX} ${CLASS_PREFIX}--${variant}`}>
      <img
        src={variant === 'light' ? lightLogo : darkLogo}
        alt="GBM+"
        className={`${CLASS_PREFIX}__logo`}
      />
      {isDefaultLogo ? (
        <div className={`${CLASS_PREFIX}__carousel`}>
          <div className={`${CLASS_PREFIX}__carousel-container`}>
            <div className={`${CLASS_PREFIX}__slide`}>
              <Typography variant="body2">{t(logoMarks)}</Typography>
            </div>
            <div className={`${CLASS_PREFIX}__slide`}>
              <Typography variant="body2">{t(smartCash)}</Typography>
            </div>
            <div className={`${CLASS_PREFIX}__slide`}>
              <Typography variant="body2">{t(wealth)}</Typography>
            </div>
            <div className={`${CLASS_PREFIX}__slide`}>
              <Typography variant="body2">{t(trading)}</Typography>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
