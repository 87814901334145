import { Values } from '@gbm/onboarding-sdk-utils';

import { OpeningType } from '../../app/providers/opening-lite/types';
import { HTTP_STATUS_CODES } from '../types';

export type CurpRequestType = {
  firstName: string;
  lastName: string;
  secondLastName: string;
  gender: string;
  birthState: string;
  birthDate: string;
};

export const GENDERS = {
  male: 'male',
  female: 'female',
};
export type GenderType = Values<typeof GENDERS>;

export type PartyDataType = {
  birth_country: string;
  birth_date: string;
  birth_state: string;
  curp: string;
  email: string;
  first_last_name: string;
  first_name: string;
  gender: GenderType;
  opening_type: OpeningType;
  second_last_name: string;
};

export const PHONE_TYPES = {
  cellphone: 'cellphone',
} as const;
export type PhoneTypeValue = Values<typeof PHONE_TYPES>;

export type PhoneType = {
  id?: string;
  country_code: string;
  extension?: string;
  phone_number: string;
  telephone_type: PhoneTypeValue;
};

export type CountryResponseType = {
  code: string;
  international_dialing_code: string;
  name: string;
};

export type CountryType = {
  key: string;
  value: string;
  label: string;
  phoneCode: string;
  country_code: string;
};

export type StateResponseType = {
  country_code: string;
  id: string;
  name: string;
};

export type StateType = {
  key: string;
  value: string;
  label: string;
};

export const ADDRESS_TYPES = {
  residential_and_fiscal: 'residential_and_fiscal',
  residential: 'residential',
} as const;
export type AddressTypeValue = Values<typeof ADDRESS_TYPES>;

export type AddressType = {
  id?: string;
  address_type: AddressTypeValue;
  inside_number?: string;
  outside_number: string;
  street_name: string;
  zip_location_id: string;
  is_under_change_request_review?: boolean;
};

export type LocationType = {
  id: string;
  suburb: { name: string };
};

export type SuburbType = {
  key: string;
  value: string;
  label: string;
};

export type ZipLocationType = {
  country?: string;
  city?: string;
  state?: string;
  township?: string;
  suburbs?: Array<SuburbType>;
  id?: string;
};

export type GeolocationType = {
  accuracy: number;
  coordenate: string;
  latitude: number;
  longitude: number;
  timestamp: number;
};

export const mockEmptyApiResponse = {
  data: [],
  err: null,
} as const;

export const mockDataFromZipCodeResponse = {
  data: {
    country: 'fake country',
    city: 'fake city',
    state: 'fake state',
    township: 'fake township',
    suburbs: [
      {
        key: 'suburbId',
        value: 'suburbId',
        label: 'fake suburb',
      },
    ],
    id: 'fakeId',
  },
  err: null,
} as const;

export const mockZipLocationDataResponse = {
  data: {
    township: { name: 'fake township' },
    suburb: { name: 'fake suburb' },
    state: { name: 'fake state' },
    country: { name: 'fake country' },
    zip_code: '11111',
  },
  err: null,
} as const;

export const mockPartyAddressResponse = {
  data: {
    address_type: ADDRESS_TYPES.residential,
    zip_location_id: 'fake zipId',
    street_name: 'fake street',
    outside_number: '111',
    township: 'fake township',
    suburb: 'fake suburb',
    state: 'fake state',
    country: 'fake country',
    zipCode: '11111',
  },
  err: null,
} as const;

export const mockGetPartyAddressResponse = {
  data: [mockPartyAddressResponse.data],
  err: null,
} as const;

export const mockHasContractsApiResponse = {
  data: { has_contracts: false },
  err: null,
} as const;

export const mockSaveContractApiResponse = {
  data: { contract_id: 'fakeId' },
  err: null,
  status: HTTP_STATUS_CODES.accepted,
} as const;

export const mockGetDataFromCurp = {
  data: { curp: 'AAABBBBCCCC' },
  err: null,
  status: HTTP_STATUS_CODES.accepted,
};

export const mockSavePartyResponse = {
  data: { contract_id: 'fakeId' },
  err: null,
  status: HTTP_STATUS_CODES.accepted,
} as const;
