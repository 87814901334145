export const PERSONAL_INFO_FIELDS = {
  firstName: 'firstName',
  lastName: 'lastName',
  secondLastName: 'secondLastName',
  hasSecondLastName: 'hasSecondLastName',
  genre: 'genre',
  birthState: 'birthState',
  birthDay: 'birthDay',
  birthMonth: 'birthMonth',
  birthYear: 'birthYear',
  phoneNumber: 'phoneNumber',
  rfc: 'rfc',
  homoclave: 'homoclave',
  hasNoRfc: 'hasNoRfc',
} as const;

export type FormValues = {
  [PERSONAL_INFO_FIELDS.firstName]: string;
  [PERSONAL_INFO_FIELDS.lastName]: string;
  [PERSONAL_INFO_FIELDS.secondLastName]: string;
  [PERSONAL_INFO_FIELDS.hasSecondLastName]: boolean;
  [PERSONAL_INFO_FIELDS.genre]: string;
  [PERSONAL_INFO_FIELDS.birthState]: string;
  [PERSONAL_INFO_FIELDS.birthDay]: string;
  [PERSONAL_INFO_FIELDS.birthMonth]: string;
  [PERSONAL_INFO_FIELDS.birthYear]: string;
  [PERSONAL_INFO_FIELDS.phoneNumber]: string;
  [PERSONAL_INFO_FIELDS.rfc]: string;
  [PERSONAL_INFO_FIELDS.homoclave]: string;
  [PERSONAL_INFO_FIELDS.hasNoRfc]: boolean;
};

export const NAME_MAX_LENGTH = 64;
