import { configureStore, PreloadedState } from '@reduxjs/toolkit';

import { OpeningLiteReducer } from './opening-lite';
import { UpgradeReducer } from './upgrade';

export default function setupStore(preloadedState?: PreloadedState<any>) {
  return configureStore({
    reducer: {
      openingLite: OpeningLiteReducer,
      upgrade: UpgradeReducer,
    },
    preloadedState,
  });
}

export const store = setupStore();
