/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useFormContext } from 'react-hook-form';

import { FormRow } from '@gbm/onboarding-sdk-ui-components';
import {
  filterQuestions,
  findRelatedQuestions,
  formatQuestionnaireAnswers,
  serializeAnswers,
} from '../../../../../utils/helpers';
import { LoremQuestionType } from '../../../../../api/upgrade/types';
import {
  AnswersDataType,
  QUESTION_ANSWER_TYPES,
  QUESTIONNAIRE_NAME,
  DEPOSITS_QUESTION_ID,
  QuestionType,
  QuestionnaireDataType,
} from '../types';
import { SelectQuestion } from '../questions';
import styles from '../financial-information.module.scss';

type KycProps = {
  questionnaire: QuestionnaireDataType;
  answers: AnswersDataType;
  onSerializeAnswers: (answers: Array<LoremQuestionType>) => void;
};

export default function KnowYourCostumer({
  questionnaire,
  answers,
  onSerializeAnswers,
}: KycProps) {
  const { getValues } = useFormContext();
  const [baseQuestions, setBaseQuestions] = useState<Array<QuestionType>>([]);

  useEffect(() => {
    if (!isEmpty(baseQuestions)) {
      const answeredQuestions = baseQuestions.map((question) => ({
        ...question,
        response: getValues(question.id),
      }));
      const serialized = serializeAnswers(answeredQuestions);
      onSerializeAnswers(serialized);
    }
  }, [baseQuestions]);

  useEffect(() => {
    if (!isEmpty(questionnaire?.data)) {
      let filteredQuestions;

      if (!isEmpty(answers.questionnaireAnswers)) {
        filteredQuestions = formatQuestionnaireAnswers(
          answers,
          questionnaire,
          QUESTIONNAIRE_NAME.kyc,
        ).map((question) => ({
          ...question,
          showAsTabs: question.id === DEPOSITS_QUESTION_ID,
        }));
      } else {
        filteredQuestions = filterQuestions(questionnaire.data, [
          DEPOSITS_QUESTION_ID,
        ]);
      }

      setBaseQuestions(filteredQuestions);
    }
  }, [questionnaire.data, answers.questionnaireAnswers]);

  const onChangeQuestion = (key: string, value: string) => {
    if (value) {
      const newBaseQuestions = findRelatedQuestions(
        questionnaire.data,
        baseQuestions,
        key,
        value,
      );
      setBaseQuestions(newBaseQuestions);
    }
  };

  return (
    <>
      {baseQuestions?.map((question) => {
        if (question.answerType !== QUESTION_ANSWER_TYPES.combo) {
          return null;
        }

        return (
          <FormRow key={`question-${question.id}`}>
            <SelectQuestion
              question={question}
              key={question.id}
              onChangeAnswer={(value) => onChangeQuestion(question.id, value)}
            />
            <div className={styles.empty} />
          </FormRow>
        );
      })}
    </>
  );
}
