import { ReactNode } from 'react';
import { Typography } from '@gbm/starman-next';

import styles from './list.module.scss';

export type ListProps = {
  labels: Array<{
    label: ReactNode;
    key: string;
  }>;
};

export default function List({ labels }: ListProps) {
  return (
    <ul className={styles.list}>
      {labels.map(({ label, key }) => (
        <li key={key}>
          <Typography variant="small">{label}</Typography>
        </li>
      ))}
    </ul>
  );
}
