import { LegalDocumentType } from '@gbm/onboarding-sdk-utils';
import { ErrorType } from '../../../api/types';
import {
  PhoneType,
  CountryType,
  StateType,
  AddressType,
  ZipLocationType,
  GeolocationType,
  PartyDataType,
  CurpRequestType,
} from '../../../api/opening-lite/types';
import { REDUCER_STATUS, StateValueType } from '../types';
import {
  OpeningLiteProviderProps,
  OpeningLiteStepType,
  OpeningStatusValueType,
  OpeningType,
} from '../../providers/opening-lite/types';

export const REDUCER_NAME = 'openingLite';

export type OpeningStatusResponseType = {
  opening_status?: OpeningStatusValueType;
  has_any_account_with_position_different_than_zero?: boolean;
  document_verification_attempts?: number;
  is_legacy_user?: boolean;
  is_contract_creation_time_exceeded?: boolean;
  opening_type?: OpeningType;
  current_step?: OpeningLiteStepType;
};

export type OpeningLiteStateErrorsType = {
  openingStatus?: ErrorType;
  countries?: ErrorType;
  states?: ErrorType;
  contract?: ErrorType;
  curp?: ErrorType;
  party?: ErrorType;
  address?: ErrorType;
  telephone?: ErrorType;
  dataFromCurp?: ErrorType;
  dataFromZipCode?: ErrorType;
  challenge?: ErrorType;
  account?: ErrorType;
};

export type OpeningLiteStateType = {
  openingStatus: StateValueType<OpeningStatusResponseType>;
  countries: StateValueType<Array<CountryType>>;
  states: StateValueType<Array<StateType>>;
  contract: StateValueType;
  curp: StateValueType;
  dataFromCurp: StateValueType;
  party: StateValueType;
  address: StateValueType;
  telephone: StateValueType;
  challenge: StateValueType;
  dataFromZipCode: StateValueType<ZipLocationType>;
  account: StateValueType;
  alreadyRegistered: Record<any, any>;
  errors: OpeningLiteStateErrorsType;
};

export const INITIAL_STATE: OpeningLiteStateType = {
  openingStatus: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  countries: {
    status: REDUCER_STATUS.idle,
    data: [],
  },
  states: {
    status: REDUCER_STATUS.idle,
    data: [],
  },
  contract: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  curp: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  dataFromCurp: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  party: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  address: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  telephone: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  dataFromZipCode: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  challenge: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  account: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  alreadyRegistered: {},
  errors: {},
};

export type InfoRequestType<T> = {
  config: OpeningLiteProviderProps;
  partyId?: string;
} & T;

export type SavePartyPhoneRequestType = InfoRequestType<{
  phone: PhoneType;
}>;

export type SavePartyAddressRequestType = InfoRequestType<{
  address: AddressType;
}>;

export type UpdatePartyAddressRequestType = SavePartyAddressRequestType & {
  addressId: string;
};

export type SavePartyErrorType = {
  err: unknown;
  alreadyRegistered: boolean;
};

export type SaveContractRequestType = InfoRequestType<{
  geolocation?: GeolocationType;
  legalDocuments: Array<LegalDocumentType>;
  mfaCode?: string;
  createTradingAccount?: boolean;
}>;

export type GetDataFromCurpRequestType = InfoRequestType<{
  curp: string;
}>;

export type SavePartyRequestType = InfoRequestType<Partial<PartyDataType>>;

export type GetStatesRequestType = InfoRequestType<{
  countryCode: string;
}>;

export type GetDataFromZipCodeRequestType = InfoRequestType<{
  zipCode: string;
}>;

export type InfoCurpRequestType = InfoRequestType<CurpRequestType>;

export type ContractAccountRequestType = InfoRequestType<{
  contractId: string;
}>;
