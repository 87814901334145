/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useFormContext } from 'react-hook-form';

import { FormRow } from '@gbm/onboarding-sdk-ui-components';
import { REGEX_VALIDATORS } from '@gbm/onboarding-sdk-utils';
import {
  filterQuestions,
  findRelatedQuestions,
  formatQuestionnaireAnswers,
  serializeAnswers,
  setQuestionAnswer,
} from '../../../../../utils/helpers';
import { LoremQuestionType } from '../../../../../api/upgrade/types';
import {
  AnswersDataType,
  QUESTION_ANSWER_TYPES,
  QUESTIONNAIRE_NAME,
  QuestionnaireDataType,
  QuestionType,
  PEP_NAME_QUESTION_ID,
} from '../types';
import { SelectQuestion, TextQuestion } from '../questions';
import { pepValidations } from './validations';
import styles from '../financial-information.module.scss';

type EconomicActivityProps = {
  questionnaire: QuestionnaireDataType;
  answers: AnswersDataType;
  onSerializeAnswers: (answers: Array<LoremQuestionType>) => void;
};

export default function PoliticalExposedPerson({
  questionnaire,
  answers,
  onSerializeAnswers,
}: EconomicActivityProps) {
  const { getValues } = useFormContext();
  const [baseQuestions, setBaseQuestions] = useState<Array<QuestionType>>([]);

  useEffect(() => {
    if (!isEmpty(baseQuestions)) {
      const answeredQuestions = baseQuestions.map((question) => ({
        ...question,
        response: getValues(question.id),
      }));
      const serialized = serializeAnswers(answeredQuestions);
      onSerializeAnswers(serialized);
    }
  }, [baseQuestions]);

  useEffect(() => {
    if (!isEmpty(questionnaire.data)) {
      let filteredQuestions;

      if (!isEmpty(answers.questionnaireAnswers)) {
        filteredQuestions = formatQuestionnaireAnswers(
          answers,
          questionnaire,
          QUESTIONNAIRE_NAME.pep,
        );
      } else {
        filteredQuestions = filterQuestions(questionnaire.data);
      }

      setBaseQuestions(filteredQuestions);
    }
  }, [questionnaire.data, answers.questionnaireAnswers]);

  const onChangeQuestion = (key: string, value: string) => {
    if (value) {
      const newBaseQuestions = findRelatedQuestions(
        questionnaire.data,
        baseQuestions,
        key,
        value,
      );
      setBaseQuestions(newBaseQuestions);
    }
  };

  const onChangeInput = (key: string, value: string) => {
    const newQuestions = setQuestionAnswer(baseQuestions, key, value);
    setBaseQuestions(newQuestions);
  };

  return (
    <>
      {baseQuestions?.map((question) => {
        switch (question.answerType) {
          case QUESTION_ANSWER_TYPES.combo:
            return (
              <FormRow key={`question-${question.id}`}>
                <SelectQuestion
                  question={question}
                  key={question.id}
                  onChangeAnswer={(value) =>
                    onChangeQuestion(question.id, value)
                  }
                />
                <div className={styles.empty} />
              </FormRow>
            );
          case QUESTION_ANSWER_TYPES.textBox:
          default:
            return (
              <FormRow key={`question-${question.id}`}>
                <TextQuestion
                  question={{
                    ...question,
                    pattern:
                      question.id === PEP_NAME_QUESTION_ID
                        ? pepValidations.pattern
                        : undefined,
                    onlyAllowedChars:
                      question.id === PEP_NAME_QUESTION_ID
                        ? REGEX_VALIDATORS.pep
                        : undefined,
                  }}
                  key={question.id}
                  onChangeAnswer={(answer) =>
                    onChangeInput(question.id, answer)
                  }
                />
                <div className={styles.empty} />
              </FormRow>
            );
        }
      })}
    </>
  );
}
