import { RegisterOptions } from 'react-hook-form';
import { clabe as clabeValidator } from 'clabe-validator';
import { REGEX_VALIDATORS, translations } from '@gbm/onboarding-sdk-utils';

import { CLABE_LENGTH, TIN_LENGTH } from './types';

const hasFielValidations: RegisterOptions = {
  required: translations.inputs.optionRequired,
};

const fielNumberValidations: RegisterOptions = {
  required: translations.inputs.fielNumber,
  pattern: {
    value: REGEX_VALIDATORS.fiel,
    message: translations.inputs.fielNumber,
  },
};

const clabeValidations: RegisterOptions = {
  required: translations.inputs.clabeRequired,
  pattern: {
    value: REGEX_VALIDATORS.clabe,
    message: translations.inputs.clabeInvalid,
  },
  minLength: {
    value: CLABE_LENGTH,
    message: translations.inputs.clabeLength,
  },
  maxLength: {
    value: CLABE_LENGTH,
    message: translations.inputs.clabeLength,
  },
  validate: {
    clabe: (value) =>
      clabeValidator.validate(value).ok || translations.inputs.clabeInvalid,
  },
};

const tinValidations: RegisterOptions = {
  required: translations.inputs.tinRequired,
  pattern: {
    value: REGEX_VALIDATORS.tin,
    message: translations.inputs.tinValid,
  },
  minLength: {
    value: TIN_LENGTH,
    message: translations.inputs.tinValid,
  },
  maxLength: {
    value: TIN_LENGTH,
    message: translations.inputs.tinValid,
  },
};

export default {
  hasFielValidations,
  fielNumberValidations,
  clabeValidations,
  tinValidations,
} as const;
