import {
  Button,
  ButtonLink,
  ArrowLeft,
  Phone,
  Typography,
  useColors,
} from '@gbm/starman-next';
import { Trans, useTranslation } from 'react-i18next';

import { useBreakpoints } from '@gbm/onboarding-sdk-hooks';
import { translations } from '@gbm/onboarding-sdk-utils';
import { MFA_HELP_PHONE, MfaHelpProps } from './types';
import styles from './MfaCode.module.scss';

const {
  buttons,
  base: { mfaChallenge },
} = translations;

export default function MfaHelp({ onClose }: MfaHelpProps) {
  const { t } = useTranslation();
  const { isSm } = useBreakpoints();
  const colors = useColors();

  return (
    <div className={styles['help-container']}>
      <ButtonLink onClick={onClose}>
        <ArrowLeft color={colors.black} size={styles['icon-size']} />
        <Typography variant={isSm ? 'small' : 'body2'} weight="semibold">
          {t(buttons.goBack)}
        </Typography>
      </ButtonLink>
      <div className={styles['help-title']}>
        <Typography weight="semibold">{t(mfaChallenge.help.title)}</Typography>
      </div>
      <div className={styles['help-info-container']}>
        <div className={styles['help-phone-icon']}>
          <Phone color={colors.black} size={styles['icon-size']} />
        </div>
        <div>
          <div className={styles['help-info']}>
            <Typography weight="semibold">
              {t(mfaChallenge.help.subtitle)}
            </Typography>
          </div>
          <Typography variant="small">
            <Trans
              i18nKey={mfaChallenge.help.description}
              values={{ phone: MFA_HELP_PHONE }}
              components={[<strong></strong>]}
            />
          </Typography>
        </div>
      </div>
      <div className={styles['help-button']}>
        <a href={`tel:${MFA_HELP_PHONE}`}>
          <Button type="button" kind="primary">
            {t(buttons.call)}
          </Button>
        </a>
      </div>
    </div>
  );
}
