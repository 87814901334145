import { useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  useNotificationProvider,
  NOTIFICATION_TIMEOUT,
} from '@gbm/onboarding-sdk-ui-components';
import { TRACKING_EVENTS, TRACKING_NAMES } from '@gbm/onboarding-sdk-utils';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import {
  UpgradeProviderProps,
  UPGRADE_STATUSES,
  UPGRADE_STEPS,
  POOL_UPGRADE_STATUS_TIME,
} from '../../providers/upgrade/types';
import { useAppDispatch, useAppSelector } from '../../store';
import { OPENING_TYPE } from '../../providers/opening-lite/types';
import {
  selectUpgradeStatus,
  getUpgradeStatus,
  selectErrors,
  clearErrors,
} from '../../store/upgrade';
import { openingTypeReceived } from '../../store/opening-lite';
import { useParty } from '../useParty';
import { useUpgradeStep } from '../useUpgradeStep';
import { UpgradeStateType } from '../../store/upgrade/types';
import { REDUCER_STATUS } from '../../store/types';

type UseUpgradeStatusType = Pick<UpgradeStateType, 'upgradeStatus'> & {
  isLoading: boolean;
  isRejected: boolean;
  isFulfilled: boolean;
  fetch: (force?: boolean) => void;
  poolForRequestStatus: () => void;
  stopPooling: () => void;
};

export default function useUpgradeStatus(
  configuration: UpgradeProviderProps,
): UseUpgradeStatusType {
  const { party } = useParty(configuration);
  const dispatch = useAppDispatch();
  const upgradeStatus = useAppSelector(selectUpgradeStatus);
  const errors = useAppSelector(selectErrors);
  const { showNotification } = useNotificationProvider();
  const { saveUpgradeStep } = useUpgradeStep(configuration);
  const isLoading = upgradeStatus.status === REDUCER_STATUS.pending;
  const isRejected = upgradeStatus.status === REDUCER_STATUS.rejected;
  const isResolved = upgradeStatus.status === REDUCER_STATUS.resolved;
  const isFulfilled = isResolved && !isLoading;
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );
  const poolingId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (upgradeStatus.data?.status) {
      mixpanel.track(TRACKING_EVENTS.screenViewed, {
        name: TRACKING_NAMES.accountLimit,
        status: upgradeStatus.data.status,
      });

      switch (upgradeStatus.data.status) {
        case UPGRADE_STATUSES.pending:
          saveUpgradeStep(UPGRADE_STEPS.gbmReviewDocs);
          break;
        case UPGRADE_STATUSES.upgraded:
          saveUpgradeStep(UPGRADE_STEPS.endedProcess);
          dispatch(openingTypeReceived(OPENING_TYPE.black));
          break;
        case UPGRADE_STATUSES.pendingManualValidation:
        case UPGRADE_STATUSES.failed:
          saveUpgradeStep(UPGRADE_STEPS.gbmReviewData);
          break;
        case UPGRADE_STATUSES.notStarted:
        default:
          break;
      }
    }
  }, [upgradeStatus.data]);

  useEffect(() => {
    if (!isEmpty(errors.upgradeStatus)) {
      showNotification({
        description: errors.upgradeStatus?.message,
        kind: 'error',
      });
      setTimeout(() => {
        dispatch(clearErrors());
      }, NOTIFICATION_TIMEOUT);
    }
  }, [errors.upgradeStatus]);

  const fetch = async (force?: boolean) => {
    if (force || (!isResolved && !isLoading)) {
      dispatch(
        getUpgradeStatus({
          partyId: party.data.party_id,
          config: configuration,
        }),
      );
    }
  };

  const poolForRequestStatus = () => {
    if (poolingId.current) {
      return;
    }

    poolingId.current = setInterval(() => {
      fetch(true);
    }, POOL_UPGRADE_STATUS_TIME);
  };

  const stopPooling = () => {
    if (poolingId.current) {
      clearInterval(poolingId.current);
      poolingId.current = null;
    }
  };

  return {
    upgradeStatus,
    isLoading,
    isRejected,
    isFulfilled,
    fetch,
    poolForRequestStatus,
    stopPooling,
  };
}
