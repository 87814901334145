import { Values } from '@gbm/onboarding-sdk-utils';

export type MfaCodeProps = {
  onClose: () => void;
  onSubmit: (mfaCode: string) => void;
  isLoading?: boolean;
  hasError?: boolean;
  isClosing?: boolean;
};

export type MfaHelpProps = {
  onClose: () => void;
};

export const MFA_CODE_FIELDS = {
  firstDigit: 'firstDigit',
  secondDigit: 'secondDigit',
  thirdDigit: 'thirdDigit',
  fourthDigit: 'fourthDigit',
  fifthDigit: 'fifthDigit',
  sixthDigit: 'sixthDigit',
} as const;
export type MfaCodeFieldsType = Values<typeof MFA_CODE_FIELDS>;

export type FormValues = {
  [MFA_CODE_FIELDS.firstDigit]: string;
  [MFA_CODE_FIELDS.secondDigit]: string;
  [MFA_CODE_FIELDS.thirdDigit]: string;
  [MFA_CODE_FIELDS.fourthDigit]: string;
  [MFA_CODE_FIELDS.fifthDigit]: string;
  [MFA_CODE_FIELDS.sixthDigit]: string;
};

export const MFA_HELP_PHONE = '8004264663';
