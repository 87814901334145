import { useCallback, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import { CountryType } from '../../../api/opening-lite/types';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectNationalities, getPartyCountries } from '../../store/upgrade';
import { PartyCountryType } from '../../../api/upgrade/types';
import { UpgradeProviderProps } from '../../providers/upgrade/types';
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_NATIONALITY_LABEL,
} from '../../containers/upgrade/tax-information/types';
import { useParty } from '../useParty';
import { useCountriesAndStates } from '../useCountriesAndStates';
import { usePartyCountriesType } from './types';
import { REDUCER_STATUS } from '../../store/types';
import { COUNTRY_TYPE } from '../../store/upgrade/types';

export default function usePartyCountries(
  config: UpgradeProviderProps,
): usePartyCountriesType {
  const dispatch = useAppDispatch();
  const { party } = useParty(config);
  const partyCountries = useAppSelector(selectNationalities);
  const { countries: catalogCountries } = useCountriesAndStates(config);
  const isLoading = partyCountries.status === REDUCER_STATUS.pending;
  const isFulfilled = !isEmpty(partyCountries.data) && !isLoading;

  const getCountryLabel = useCallback(
    (countriesList: Array<CountryType>, rename = false) => {
      if (isEmpty(catalogCountries.data)) {
        return '';
      }

      const filteredCountries = countriesList?.map(
        ({ country_code: countryCode }) =>
          catalogCountries.data.find(({ value }: any) => countryCode === value),
      );
      const countriesLabels = filteredCountries?.map((country) => {
        if (!country) {
          return '';
        }

        const { label, value } = country;
        return value === DEFAULT_COUNTRY_CODE && rename
          ? DEFAULT_NATIONALITY_LABEL
          : label;
      });

      return countriesLabels?.join(', ');
    },
    [catalogCountries.data],
  );

  const [birthCountries, citizenshipCountries, nationalities, taxCountries] =
    useMemo(() => {
      if (isFulfilled) {
        return [
          getCountryLabel(
            partyCountries.data.filter(
              ({ country_type: countryType }: PartyCountryType) =>
                countryType === COUNTRY_TYPE.birth,
            ),
          ),
          getCountryLabel(
            partyCountries.data.filter(
              ({ country_type: countryType }: PartyCountryType) =>
                countryType === COUNTRY_TYPE.citizenship,
            ),
            true,
          ),
          getCountryLabel(
            partyCountries.data.filter(
              ({ country_type: countryType }: PartyCountryType) =>
                countryType === COUNTRY_TYPE.nationality,
            ),
            true,
          ),
          getCountryLabel(
            partyCountries.data.filter(
              ({ country_type: countryType }: PartyCountryType) =>
                countryType === COUNTRY_TYPE.taxation,
            ),
          ),
        ];
      }

      return ['', '', '', ''];
    }, [partyCountries.status]);

  const fetch = (force: boolean = false) => {
    if ((isEmpty(partyCountries.data) && !isLoading) || force) {
      dispatch(getPartyCountries({ config, partyId: party.data.party_id }));
    }
  };

  return {
    birthCountries,
    citizenshipCountries,
    nationalities,
    taxCountries,
    isLoading,
    fetch,
  };
}
