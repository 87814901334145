import { ValidationRule } from 'react-hook-form';
import { Values } from '@gbm/onboarding-sdk-utils';
import { LoremQuestionnaireType } from '../../../../api/upgrade/types';

export const QUESTION_ANSWER_TYPES = {
  combo: 'combo',
  multipleOption: 'multiple_option',
  textBox: 'text_box',
  calendar: 'calendar',
};
export type QuestionAnswerType = Values<typeof QUESTION_ANSWER_TYPES>;

export const QUESTION_DATA_TYPES = {
  integer: 'integer',
  date: 'date',
};
export type QuestionDataType = Values<typeof QUESTION_DATA_TYPES>;

export const INPUT_CURRENCY_LENGTH = 11;

export type AnswerType = {
  id: string;
  text?: string | { label: string; value: string };
};

export type QuestionType = {
  id: string;
  text: string;
  response?: string | Array<string> | {} | Array<{ value: string }>;
  relatedAnswerId?: string;
  answerType: QuestionAnswerType;
  required: boolean;
  answer?: Array<AnswerType>;
  dataType?: QuestionDataType;
  maxLength?: string | number;
  pattern?: ValidationRule<RegExp>;
  onlyAllowedChars?: RegExp;
  showAsTabs?: boolean;
};

export type QuestionnaireDataType = {
  id: string;
  data: Array<QuestionType>;
  questionnaireId: string;
  version: string;
};

export type AnswersDataType = {
  questionnaireType: LoremQuestionnaireType;
  version: string;
  questionnaireAnswers: Array<{
    questionId: string;
    answerId: string;
    answerResponse: string;
  }>;
};

export const QUESTIONNAIRE_NAME = {
  economicActivity: 'economicActivity',
  pep: 'pep',
  kyc: 'kyc',
};
export type QuestionnaireNameType = Values<typeof QUESTIONNAIRE_NAME>;

export type RelatedBaseQuestions = {
  [key: QuestionnaireNameType]: {
    baseQuestions: Array<QuestionType>;
  };
};

export const DEPOSITS_QUESTION_ID = '93A2F5C1-96EB-4A7F-8AAC-4ED47ED01634';
export const DEPOSITS_QUESTION_DEFAULT_ANSWER =
  'B9CD4840-D9D0-4CFD-ACBE-2AB2CF7ECB0F';
export const PEP_NAME_QUESTION_ID = '2F74407A-9B3C-487E-B542-306F33A5A805';
