import axios from 'axios';

import { ApiOptions, DEFAULT_API_OPTIONS } from './models';
import { HTTP_VERBS, HTTP_STATUS_CODES } from './types';
import { defaultSuccessAdapter, defaultErrorAdapter } from './adapters';

export default function request<T>(
  url: string,
  onRefreshSession?: () => void,
  accessToken?: string,
  options: Partial<ApiOptions> = DEFAULT_API_OPTIONS,
  adapter: Function = defaultSuccessAdapter,
  errAdapter: Function = defaultErrorAdapter,
) {
  const _options = {
    url,
    method: options.method || HTTP_VERBS.get,
    data: options.body || {},
    headers: {
      Accept: 'application/json',
      Authorization: '',
      'Content-Type': 'application/json',
      ...options.headers,
    },
  };

  if (accessToken) {
    _options.headers.Authorization = `Bearer ${accessToken}`;
  }

  const instance = axios.create(_options);

  /* istanbul ignore next */
  instance.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error.response.status === HTTP_STATUS_CODES.unauthorized) {
        onRefreshSession?.();
      }

      return Promise.reject(error);
    },
  );

  return instance
    .request<T>(_options)
    .then((res) => adapter(res))
    .catch((err) => errAdapter(err));
}
