import { useMemo } from 'react';

import { OpeningLiteProviderProps } from '../../../app/providers/opening-lite/types';

import OpeningLite from '../opening-lite';

export default function useOpeningLiteApi(
  configuration: OpeningLiteProviderProps,
) {
  return useMemo(() => new OpeningLite(configuration), [configuration]);
}
