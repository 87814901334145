import { translations } from '@gbm/onboarding-sdk-utils';

const { inputs } = translations;

export const FIELD_NAME = 'mfaCode';
export const CODE_LENGTH = 6;

export const validations = {
  setValueAs: (value: string) => value.replace(/\s|·/g, ''),
  required: inputs.mfaCodeRequired,
  minLength: {
    value: CODE_LENGTH,
    message: inputs.invalidCode,
  },
  maxLength: {
    value: CODE_LENGTH,
    message: inputs.invalidCode,
  },
};
