import { useEffect, useState } from 'react';
import { ArrowCircleRight, Button } from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { motion } from 'framer-motion';

import {
  FormContent,
  FormFooter,
  FormSection,
  useNotificationProvider,
  MfaChallenge,
} from '@gbm/onboarding-sdk-ui-components';
import {
  LITE_IDS,
  LegalDocumentType,
  getErrorMessage,
  translations,
  TRACKING_EVENTS,
  TRACKING_NAMES,
  LEGAL_DOCUMENT_TYPES,
} from '@gbm/onboarding-sdk-utils';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import {
  OpeningLayout,
  PersonalInfo,
  AddressInfo,
} from '../../../components/opening-lite';
import {
  useAddress,
  useParty,
  useLegalDocuments,
  useContract,
  useIdentifiers,
} from '../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  getPartyPhone,
  getPartyCurp,
  getChallenge,
  selectCurp,
  selectPhone,
  selectChallenge,
} from '../../../store/opening-lite';
import { GeolocationType } from '../../../../api/opening-lite/types';
import { useOpeningLiteApi } from '../../../../api/opening-lite/use-opening-lite-api';
import { AcceptanceChecks } from './acceptance-checks';
import {
  OPENING_LITE_STEPS,
  STEPS,
} from '../../../providers/opening-lite/types';
import { REDUCER_STATUS } from '../../../store/types';
import { useOpeningLiteProvider } from '../../../providers/opening-lite';
import styles from './resume.module.scss';

const {
  buttons,
  openingLite: { confirmation },
} = translations;

export default function Resume() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { saveOpeningLiteStep, setStep, setIsEditing, configuration } =
    useOpeningLiteProvider();
  const {
    party,
    isEmpty: isEmptyParty,
    isFulfilled: isFulfilledParty,
  } = useParty(configuration);
  const { fetch: fetchAddress, isEmpty: isEmptyAddress } =
    useAddress(configuration);
  const {
    contract,
    isResolved: isResolvedContract,
    isLoading: isLoadingContract,
    save: saveContract,
  } = useContract(configuration);
  const {
    fetch: getLegalDocs,
    termsAndConditionsDoc,
    executionServiceDoc,
    legalDocs,
  } = useLegalDocuments(configuration);
  const { isEmpty: isEmptyRfc, fetch: fetchRfc } =
    useIdentifiers(configuration);
  const openingLiteApi = useOpeningLiteApi(configuration);
  const { showNotification } = useNotificationProvider();
  const [checksAccepted, setChecksAccepted] = useState(false);
  const [generalExecutionAccepted, setGeneralExecutionAccepted] =
    useState(false);
  const [isLoadingHasContracts, setIsLoadingHasContracts] = useState(false);
  const [showMfaChallenge, setShowMfaChallenge] = useState(false);
  const [geolocation, setGeolocation] = useState<GeolocationType>();
  const phone = useAppSelector(selectPhone);
  const curp = useAppSelector(selectCurp);
  const challenge = useAppSelector(selectChallenge);
  const isLoadingScreen =
    !termsAndConditionsDoc ||
    isEmptyParty ||
    isEmpty(phone.data) ||
    isEmpty(curp.data) ||
    isEmptyAddress;
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getLegalDocs();
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.confirmation,
    });
  }, []);

  useEffect(() => {
    /* istanbul ignore else */
    if (isFulfilledParty) {
      if (isEmpty(phone.data)) {
        dispatch(
          getPartyPhone({
            config: configuration,
            partyId: party.data.party_id,
          }),
        );
      }

      /* istanbul ignore else */
      if (isEmpty(curp.data)) {
        dispatch(
          getPartyCurp({
            config: configuration,
            partyId: party.data.party_id,
          }),
        );
      }

      if (isEmptyRfc) {
        fetchRfc();
      }

      fetchAddress();
      saveOpeningLiteStep(OPENING_LITE_STEPS.confirmation);
    }
  }, [party.status]);

  useEffect(() => {
    /* istanbul ignore else */
    if (isResolvedContract) {
      mixpanel.track(TRACKING_EVENTS.contractCreated, {
        success: true,
      });

      setStep(STEPS.pending);
      configuration.onContractCreationStarted?.();
    }
  }, [contract.status]);

  const handleAddressEdition = () => {
    setIsEditing(true);
    setStep(STEPS.addressInfo);
  };

  const handleSubmit = async () => {
    setIsLoadingHasContracts(true);
    const { data, err } = await openingLiteApi.getHasContracts();

    if (!data) {
      showNotification({
        kind: 'error',
        description: getErrorMessage(err.response),
      });
    } else if (data.has_contracts) {
      dispatch(
        getChallenge({
          config: configuration,
        }),
      );
      setShowMfaChallenge(true);
    } else {
      saveContract({
        geolocation,
        config: configuration,
        legalDocuments: generalExecutionAccepted
          ? legalDocs
          : legalDocs.filter(
              (doc) => doc.type !== LEGAL_DOCUMENT_TYPES.executionService,
            ),
        partyId: party.data.party_id,
        createTradingAccount: generalExecutionAccepted,
      });
    }

    setIsLoadingHasContracts(false);
  };

  const handleMfaSubmit = (mfaCode: string) => {
    saveContract({
      geolocation,
      mfaCode,
      config: configuration,
      legalDocuments: generalExecutionAccepted
        ? legalDocs
        : legalDocs.filter(
            (doc) => doc.type !== LEGAL_DOCUMENT_TYPES.executionService,
          ),
      partyId: party.data.party_id,
      createTradingAccount: generalExecutionAccepted,
    });
  };

  return (
    <OpeningLayout current={3}>
      <motion.div
        className={styles.container}
        id={LITE_IDS.viewConfirmation}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <FormContent>
          <FormSection
            title={t(confirmation.title)}
            description={t(confirmation.subtitle)}
            contentClassName={styles.content}
          >
            <PersonalInfo isLoading={isLoadingScreen} />
            <AddressInfo
              isLoading={isLoadingScreen}
              onEditClick={handleAddressEdition}
            />
            <AcceptanceChecks
              onChecksAccepted={setChecksAccepted}
              onGeneralExecutionAccepted={setGeneralExecutionAccepted}
              onGeolocationAccepted={setGeolocation}
              termsAndConditionsDocument={
                termsAndConditionsDoc as LegalDocumentType
              }
              executionServiceDocument={
                executionServiceDoc as LegalDocumentType
              }
              externalTermsAndConditionsUrl={
                configuration.termsAndConditionsUrl
              }
              appName={configuration.appName}
              isEnabledTradingOpening={configuration.isEnabledTradingOpening}
            />
          </FormSection>
        </FormContent>
        <FormFooter>
          <Button
            disabled={!checksAccepted}
            loading={
              isLoadingContract ||
              challenge.status === REDUCER_STATUS.pending ||
              isLoadingHasContracts
            }
            kind="primary"
            onClick={handleSubmit}
            icon={<ArrowCircleRight size={styles['icon-size']} weight="fill" />}
          >
            {t(buttons.continue)}
          </Button>
        </FormFooter>
      </motion.div>
      <MfaChallenge
        show={showMfaChallenge}
        onClose={/* istanbul ignore next */ () => setShowMfaChallenge(false)}
        onResend={
          /* istanbul ignore next */ () =>
            dispatch(
              getChallenge({
                config: configuration,
              }),
            )
        }
        onSubmit={handleMfaSubmit}
        loading={
          challenge.status === REDUCER_STATUS.pending || isLoadingContract
        }
        mfaPreference={challenge.data.mfaPreference}
        email={configuration.email}
      />
    </OpeningLayout>
  );
}
