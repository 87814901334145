import { useTranslation } from 'react-i18next';

import {
  FormRow,
  ResumeSection,
  ResumeSectionItem,
} from '@gbm/onboarding-sdk-ui-components';
import { translations } from '@gbm/onboarding-sdk-utils';
import { useAddress } from '../../../hooks';
import { useOpeningLiteProvider } from '../../../providers/opening-lite';
import { WITHOUT_OUTSIDE_NUMBER_VALUE } from '../../../containers/opening-lite/address-info/types';
import styles from './address-info.module.scss';

type AddressInfoProps = {
  isLoading?: boolean;
  onEditClick?: () => void;
};
const {
  openingLite: { addressInfo, confirmation },
} = translations;

export default function AddressInfo({
  isLoading,
  onEditClick,
}: AddressInfoProps) {
  const { t } = useTranslation();
  const { configuration } = useOpeningLiteProvider();
  const { address } = useAddress(configuration);

  return (
    <ResumeSection
      title={t(addressInfo.title)}
      onEditClick={
        !address.data?.is_under_change_request_review ? onEditClick : undefined
      }
      isLoading={isLoading}
    >
      <FormRow>
        <div className={styles['initial-row']}>
          <ResumeSectionItem
            label={t(confirmation.streetLabel)}
            value={address.data?.street_name}
            isLoading={isLoading}
          />
        </div>
        <FormRow className={styles.row}>
          <ResumeSectionItem
            label={t(confirmation.outsideNumberLabel)}
            value={
              address.data?.outside_number === WITHOUT_OUTSIDE_NUMBER_VALUE
                ? address.data?.outside_number
                : `#${address.data?.outside_number}`
            }
            isLoading={isLoading}
          />
          <ResumeSectionItem
            label={t(confirmation.insideNumberLabel)}
            value={address.data?.inside_number}
            isLoading={isLoading}
          />
        </FormRow>
      </FormRow>
      <FormRow>
        <ResumeSectionItem
          label={t(confirmation.townshipLabel)}
          value={address.data?.suburb}
          isLoading={isLoading}
        />
        <ResumeSectionItem
          label={t(confirmation.suburbLabel)}
          value={address.data?.township}
          isLoading={isLoading}
        />
        <ResumeSectionItem
          label={t(confirmation.cityLabel)}
          value={address.data?.township}
          isLoading={isLoading}
        />
      </FormRow>
      <FormRow>
        <div className={styles['initial-row']}>
          <ResumeSectionItem
            label={t(confirmation.stateLabel)}
            value={address.data?.state}
            isLoading={isLoading}
          />
        </div>
        <FormRow className={styles.row}>
          <ResumeSectionItem
            label={t(confirmation.zipCodeLabel)}
            value={address.data?.zipCode}
            isLoading={isLoading}
          />
          <ResumeSectionItem
            label={t(confirmation.countryLabel)}
            value={address.data?.country?.name}
            isLoading={isLoading}
          />
        </FormRow>
      </FormRow>
    </ResumeSection>
  );
}
