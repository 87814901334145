import { ReactNode } from 'react';
import { SkeletonLoader, Typography } from '@gbm/starman-next';
import clsx from 'clsx';

import { EMPTY_FIELD_PLACEHOLDER } from '@gbm/onboarding-sdk-utils';
import styles from './resume-section.module.scss';

type ResumeSectionItemProps = {
  label: string;
  isLoading?: boolean;
  value?: ReactNode;
  breakAll?: boolean;
};

export default function ResumeSectionItem({
  label,
  isLoading,
  value,
  breakAll,
}: ResumeSectionItemProps) {
  return (
    <div className={styles.item}>
      {isLoading ? (
        <SkeletonLoader kind="large" reverse />
      ) : (
        <>
          <div
            className={clsx(styles['item-text'], {
              [styles['item-text--break']]: breakAll,
            })}
          >
            <Typography variant="h5" weight="semibold">
              {value || EMPTY_FIELD_PLACEHOLDER}
            </Typography>
          </div>
          <div className={styles['item-label']}>
            <Typography variant="small">{label}</Typography>
          </div>
        </>
      )}
    </div>
  );
}
