import { Values } from '@gbm/onboarding-sdk-utils';

export const CURP_INFO_FIELDS = {
  curp: 'curp',
} as const;

export type FormValues = {
  [CURP_INFO_FIELDS.curp]: string;
};

export const CURP_ERRORS = {
  alreadyRegistered: 'PartyWithSameIdentitiesDataAlreadyHasContract',
  invalid: 'InvalidCurpContactRenapo',
  isForeignBorn: 'IsForeignBorn',
  isNotOfLegalAge: 'IsNotOfLegalAge',
};
export type CurpErrorsType = Values<typeof CURP_ERRORS>;

export const INFORMATIVE_CURP_ERRORS: Array<CurpErrorsType> = [
  CURP_ERRORS.isForeignBorn,
  CURP_ERRORS.isNotOfLegalAge,
];

export const CURP_MAX_LENGTH = 18;

export const FAQS_URL = 'https://about.appgbm.com/faqs/';
export const CHAT_URL =
  'https://wa.me/5215526905840?text=Tengo%20una%20duda%2C%20%C2%BFme%20podr%C3%ADan%20ayudar%3F';
