import { useTranslation } from 'react-i18next';

import {
  FormRow,
  ResumeSection,
  ResumeSectionItem,
} from '@gbm/onboarding-sdk-ui-components';
import { MASK_PATTERNS, translations } from '@gbm/onboarding-sdk-utils';
import { BANK_ACCOUNT_OPENING_TYPES_LEN } from '../../../containers/upgrade/tax-information/types';
import { mask } from '../../../../utils/helpers';
import styles from './tax-information.module.scss';

type TaxInfoProps = {
  isLoading?: boolean;
  onEditClick?: () => void;
  taxCountries: string;
  fiel: string;
  rfc: string;
  clabe: string;
  tin: string;
};
const {
  inputs,
  upgrade: { taxInformation },
} = translations;

export default function TaxInformation({
  isLoading,
  onEditClick,
  taxCountries,
  fiel,
  rfc,
  clabe,
  tin,
}: TaxInfoProps) {
  const { t } = useTranslation();

  return (
    <ResumeSection
      title={t(taxInformation.title)}
      isLoading={isLoading}
      onEditClick={onEditClick}
    >
      <FormRow>
        <ResumeSectionItem
          label={t(taxInformation.taxCountry)}
          value={taxCountries}
          isLoading={isLoading}
        />
        <ResumeSectionItem
          label={t(taxInformation.fiel)}
          value={fiel}
          isLoading={isLoading}
        />
        <ResumeSectionItem
          label={t(taxInformation.rfc)}
          value={rfc}
          isLoading={isLoading}
        />
      </FormRow>
      <FormRow>
        <ResumeSectionItem
          label={t(inputs.clabeBank)}
          value={
            clabe.length === BANK_ACCOUNT_OPENING_TYPES_LEN.clabe
              ? mask(clabe, MASK_PATTERNS.clabe)
              : ''
          }
          isLoading={isLoading}
        />
        <ResumeSectionItem
          label={t(inputs.tinInput)}
          value={tin}
          isLoading={isLoading}
        />
        <div className={styles.empty} />
      </FormRow>
    </ResumeSection>
  );
}
