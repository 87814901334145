import { useState } from 'react';
import {
  ArrowLeft,
  Copy,
  Typography,
  Button,
  useColors,
  ButtonLink,
} from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import {
  InputDigit,
  Modal,
  ModalContent,
  ModalHeader,
} from '@gbm/onboarding-sdk-ui-components';
import { REGEX_VALIDATORS, translations } from '@gbm/onboarding-sdk-utils';
import { useBreakpoints } from '@gbm/onboarding-sdk-hooks';
import { digitValidations } from './validations';
import {
  FormValues,
  MFA_CODE_FIELDS,
  MfaCodeFieldsType,
  MfaCodeProps,
} from './types';
import { useClipboard } from '../../hooks';
import MfaHelp from './MfaHelp';
import styles from './MfaCode.module.scss';

const {
  buttons,
  links,
  base: { mfaChallenge },
} = translations;

export default function MfaCode({
  onClose,
  onSubmit,
  isLoading,
  hasError,
}: MfaCodeProps) {
  const { t } = useTranslation();
  const { isSm } = useBreakpoints();
  const colors = useColors();
  const {
    handleSubmit,
    formState: { isValid },
    register,
    setFocus,
    setValue,
    trigger,
  } = useForm<FormValues>({ mode: 'onChange' });
  const [displayMfaHelp, setDisplayMfaHelp] = useState(false);
  const [allowDisplayError, setAllowDisplayError] = useState(true);
  const { readText } = useClipboard();
  const inputHasError = allowDisplayError && hasError;

  const handlePasteContent = async () => {
    const text = await readText();

    /* istanbul ignore else */
    if (REGEX_VALIDATORS.numbers.test(text)) {
      const digits = text.split('');

      Object.values(MFA_CODE_FIELDS).forEach(
        (field: MfaCodeFieldsType, index: number) =>
          setValue(field, digits[index]),
      );

      trigger();
    }
  };

  const handleSubmitCallback = (data: FormValues) => {
    setAllowDisplayError(true);
    const mfaCode = Object.values(data).join('');
    onSubmit(mfaCode);
  };

  return (
    <Modal show>
      <ModalHeader onClose={onClose} />
      <ModalContent>
        {displayMfaHelp ? (
          <MfaHelp
            onClose={/* istanbul ignore next */ () => setDisplayMfaHelp(false)}
          />
        ) : (
          <form
            className={styles.form}
            onSubmit={handleSubmit(handleSubmitCallback)}
            noValidate
          >
            <ButtonLink onClick={onClose} data-testid="goBackButton">
              <ArrowLeft color={colors.black} size={styles['icon-size']} />
              <Typography variant={isSm ? 'small' : 'body2'} weight="semibold">
                {t(buttons.goBack)}
              </Typography>
            </ButtonLink>
            <div className={styles.title}>
              <Typography weight="semibold">
                {t(mfaChallenge.mfaTitle)}
              </Typography>
            </div>
            <div className={styles.subtitle}>
              <Typography variant="xsmall">
                {t(mfaChallenge.mfaSubtitle)}
              </Typography>
            </div>
            <div className={styles.inputs}>
              <InputDigit
                hasError={inputHasError}
                {...register(MFA_CODE_FIELDS.firstDigit, {
                  ...digitValidations,
                  onChange: () => {
                    setAllowDisplayError(false);
                    setFocus(MFA_CODE_FIELDS.secondDigit);
                  },
                })}
              />
              <InputDigit
                hasError={inputHasError}
                {...register(MFA_CODE_FIELDS.secondDigit, {
                  ...digitValidations,
                  onChange: () => {
                    setAllowDisplayError(false);
                    setFocus(MFA_CODE_FIELDS.thirdDigit);
                  },
                })}
              />
              <InputDigit
                hasError={inputHasError}
                {...register(MFA_CODE_FIELDS.thirdDigit, {
                  ...digitValidations,
                  onChange: () => {
                    setAllowDisplayError(false);
                    setFocus(MFA_CODE_FIELDS.fourthDigit);
                  },
                })}
              />
              <InputDigit
                hasError={inputHasError}
                {...register(MFA_CODE_FIELDS.fourthDigit, {
                  ...digitValidations,
                  onChange: () => {
                    setAllowDisplayError(false);
                    setFocus(MFA_CODE_FIELDS.fifthDigit);
                  },
                })}
              />
              <InputDigit
                hasError={inputHasError}
                {...register(MFA_CODE_FIELDS.fifthDigit, {
                  ...digitValidations,
                  onChange: () => {
                    setAllowDisplayError(false);
                    setFocus(MFA_CODE_FIELDS.sixthDigit);
                  },
                })}
              />
              <InputDigit
                hasError={inputHasError}
                {...register(MFA_CODE_FIELDS.sixthDigit, {
                  ...digitValidations,
                  onChange: () => setAllowDisplayError(false),
                })}
              />
            </div>
            <div className={styles['copy-icon']}>
              <ButtonLink
                onClick={handlePasteContent}
                data-testid="pasteContentButton"
              >
                <Typography
                  variant={isSm ? 'small' : 'body2'}
                  weight="semibold"
                >
                  {t(buttons.paste)}
                </Typography>
                <Copy color={colors.black} size={styles['icon-size']} />
              </ButtonLink>
            </div>
            <div className={styles['help-link']}>
              <ButtonLink
                onClick={() => setDisplayMfaHelp(true)}
                data-testid="needHelpLink"
              >
                <Typography variant="small" weight="semibold">
                  {t(links.doYouNeedHelp)}
                </Typography>
              </ButtonLink>
            </div>
            <div className={styles.button}>
              <Button
                disabled={!isValid}
                loading={isLoading}
                kind="primary"
                testId="submitButton"
              >
                {t(buttons.validateCode)}
              </Button>
            </div>
          </form>
        )}
      </ModalContent>
    </Modal>
  );
}
