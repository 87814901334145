import { useMediaQuery } from 'usehooks-ts';

export default function useBreakpoints() {
  return {
    isSm: Boolean(useMediaQuery('(min-width: 1px) and (max-width: 767.9px)')),
    isMd: Boolean(
      useMediaQuery('(min-width: 768px) and (max-width: 1023.9px)'),
    ),
    isLg: Boolean(
      useMediaQuery('(min-width: 1024px) and (max-width: 1279.9px)'),
    ),
    isXl: Boolean(
      useMediaQuery('(min-width: 1280px) and (max-width: 1535.9px)'),
    ),
    is2Xl: Boolean(useMediaQuery('(min-width: 1536px)')),
  };
}
