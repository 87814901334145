import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

type UseBodyScrollType = {
  disableBodyScroll: () => void;
  enableBodyScroll: () => void;
};
const body = document.querySelector('body');

export default function useBodyScroll(): UseBodyScrollType {
  const disable = () => disableBodyScroll(body as HTMLElement);
  const enable = () => enableBodyScroll(body as HTMLElement);

  return {
    disableBodyScroll: disable,
    enableBodyScroll: enable,
  };
}
