import {
  ArrowCircleRight,
  Button,
  Prohibit,
  Typography,
} from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';

import { translations } from '@gbm/onboarding-sdk-utils';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@gbm/onboarding-sdk-ui-components';
import styles from './initial-screen.module.scss';

export type DocumentTamperedProps = {
  onClose: () => void;
};
const {
  buttons,
  upgrade: { contractStatus },
} = translations;

export default function DocumentTampered({ onClose }: DocumentTamperedProps) {
  const { t } = useTranslation();

  return (
    <Modal show>
      <ModalHeader onClose={onClose} />
      <ModalContent>
        <div className={styles['document-tampered-icon']}>
          <Prohibit
            size={styles['icon-size']}
            color={styles['icon-color--error']}
          />
        </div>
        <div className={styles['document-tampered-title']}>
          <Typography variant="h5">
            {t(contractStatus.documentTamperedTitle)}
          </Typography>
        </div>
        <div className={styles['document-tampered-description']}>
          <Typography variant="small">
            {t(contractStatus.documentTamperedDescription)}
          </Typography>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          size="fluid"
          kind="primary"
          onClick={onClose}
          icon={<ArrowCircleRight size={styles['icon-size']} weight="fill" />}
        >
          {t(buttons.understood)}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
