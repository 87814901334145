/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react';
import { ENVIRONMENTS } from '@gbm/onboarding-sdk-utils';

import { reset } from '../../store/upgrade';
import { useAppDispatch } from '../../store';
import {
  useParty,
  useDocuments,
  useNonInitialEffect,
  useUpgradeStatus,
  useUpgradeStep,
  useRefreshSession,
} from '../../hooks';

import {
  STEPS,
  UpgradeProviderType,
  StepType,
  UpgradeProps,
  UPGRADE_STATUSES,
  DOCUMENT_TYPES,
  UPGRADE_STEPS,
  UpgradeStepType,
} from './types';

const UpgradeContext = createContext<UpgradeProviderType>({
  step: STEPS.unknown,
  setStep: () => null,
  isLoading: false,
  setIsEditing: () => null,
  handleExit: () => null,
  handleSignOut: () => null,
  isEditing: false,
  configuration: {
    email: '',
    clientId: '',
    accessToken: '',
    refreshToken: '',
    appName: '',
    environment: ENVIRONMENTS.development,
    onRefreshSession: () => null,
    onSessionRefreshed: () => null,
    onSignedOut: () => null,
    onExit: () => null,
  },
});
export const useUpgradeProvider = () => useContext(UpgradeContext);

export default function UpgradeProvider({
  children,
  ...config
}: PropsWithChildren<UpgradeProps>) {
  const handleSignOut = () => {
    setIsExiting(true);
    config.onSignedOut();
  };

  const { handleRefreshSession } = useRefreshSession(config, handleSignOut);
  const dispatch = useAppDispatch();
  const newConfig = {
    ...config,
    onRefreshSession: handleRefreshSession,
  };
  const {
    party,
    fetch: fetchParty,
    isFulfilled: isFulfilledParty,
  } = useParty(newConfig);
  const [step, setStep] = useState<StepType>(STEPS.upgradeScreen);
  const [isEditing, setIsEditing] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const { saveUpgradeStep, handleUpgradeStep } = useUpgradeStep(newConfig);
  const { upgradeStatus, fetch: getUpgradeStatus } =
    useUpgradeStatus(newConfig);
  const { rejectedMessage } = useDocuments(upgradeStatus.data?.documents);
  const hasRejectedDocuments =
    upgradeStatus.data?.status === UPGRADE_STATUSES.rejectedDocuments;

  useEffect(() => {
    fetchParty();
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (isFulfilledParty) {
      getUpgradeStatus();
    }
  }, [party.status]);

  useNonInitialEffect(() => {
    switch (upgradeStatus.data?.status) {
      case UPGRADE_STATUSES.documentTampering:
      case UPGRADE_STATUSES.timeoutForAttempts:
        setStep(STEPS.upgradeScreen);
        break;
      case UPGRADE_STATUSES.upgraded:
        setStep(STEPS.success);
        break;
      case UPGRADE_STATUSES.pendingManualValidation:
      case UPGRADE_STATUSES.failed:
        setStep(STEPS.reviewing);
        break;
      default:
        break;
    }
  }, [upgradeStatus.data?.status]);

  const handleRedirect = (toStep: UpgradeStepType) => {
    if (step !== STEPS.upgradeScreen || config.skipInitialScreen) {
      handleUpgradeStep(setStep, toStep);
    }
  };

  useEffect(() => {
    const checkCurrentStep = async () => {
      /* istanbul ignore else */
      if (hasRejectedDocuments) {
        if (rejectedMessage?.type === DOCUMENT_TYPES.proofOfAddress) {
          // If POA fails, redirect to that form
          await saveUpgradeStep(UPGRADE_STEPS.proofOfAddress);
          handleRedirect(UPGRADE_STEPS.proofOfAddress);
        } else if (rejectedMessage.text) {
          // If there is a document error, go to identity screen
          await saveUpgradeStep(UPGRADE_STEPS.ine);
          handleRedirect(UPGRADE_STEPS.ine);
        }
      }
    };

    checkCurrentStep();
  }, [hasRejectedDocuments]);

  const handleExit = () => {
    setIsExiting(true);
    config.onExit?.();
  };

  return (
    <UpgradeContext.Provider
      value={{
        step,
        setStep,
        isLoading: isExiting,
        configuration: newConfig,
        isEditing,
        setIsEditing,
        handleExit,
        handleSignOut,
      }}
    >
      {children}
    </UpgradeContext.Provider>
  );
}
