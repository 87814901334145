import { Values } from '@gbm/onboarding-sdk-utils';

export const HTTP_VERBS = {
  post: 'post',
  put: 'put',
  get: 'get',
  delete: 'delete',
  patch: 'patch',
} as const;
export type HttpVerbsTypes = Values<typeof HTTP_VERBS>;

export const HTTP_STATUS_CODES = {
  ok: 200,
  created: 201,
  accepted: 202,
  noContent: 204,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  unprocessableEntity: 422,
  internalServerError: 500,
  notImplemented: 501,
  badGateway: 502,
  serviceUnavailable: 503,
  gatewayTimeout: 504,
} as const;
export type HttpStatusCodeTypes = Values<typeof HTTP_STATUS_CODES>;

export type ErrorType = {
  eventId?: number | string;
  code?: string;
  message?: string;
  target?: string;
  details?: Record<string, unknown>;
};
