import { ConfigType, Environment, ENVIRONMENTS } from './types';

const environmentConfig: ConfigType = {
  dev: {
    auth: {
      apiUrl: {
        v1: 'https://origin.auth.stg-gbm.com/api/v1',
        v2: 'https://origin.auth.stg-gbm.com/api/v2',
      },
    },
    env: ENVIRONMENTS.development,
    legalDocsBaseUrl: 'https://origin.app.stg-gbm.com/docs',
    legalDocsAuthBaseUrl: 'https://origin.auth.stg-gbm.com/docs',
    academyUrl: 'https://academy.gbm.com',
    blogUrl: 'https://blog.gbm.com',
    helpEmail: 'plus@gbm.com',
    recaptchaKey: '6Le9pHMcAAAAADeoJmQZjhvSwnPCzCNrNFJPAPzJ',
    mixpanelApiKey: 'c95de9af1fa410e88527280b7d0e7ba6',
    segmentApiKey: 'pEBHtI2elRVQJH0iDSoC4HQA8ZcSMsfz',
    onboarding: {
      apiUrl: {
        v1: 'https://api-stg.appgbm.com/gbmcom/v1',
        v2: 'https://api-stg.appgbm.com/gbmcom/v2',
        v3: 'https://api-stg.appgbm.com/gbmcom/v3',
      },
    },
  },
  prd: {
    auth: {
      apiUrl: {
        v1: 'https://auth.gbm.com/api/v1',
        v2: 'https://auth.gbm.com/api/v2',
      },
    },
    env: ENVIRONMENTS.production,
    legalDocsBaseUrl: 'https://app.gbm.com/docs',
    legalDocsAuthBaseUrl: 'https://auth.gbm.com/docs',
    academyUrl: 'https://academy.gbm.com',
    blogUrl: 'https://blog.gbm.com',
    helpEmail: 'plus@gbm.com',
    recaptchaKey: '6Le9pHMcAAAAADeoJmQZjhvSwnPCzCNrNFJPAPzJ',
    mixpanelApiKey: '36f482d6334f5caa027e3a619ad89a7a',
    segmentApiKey: 'HjDQGC9ZoKruCcNeloz9XjigNDlbmkn1',
    onboarding: {
      apiUrl: {
        v1: 'https://api.appgbm.com/gbmcom/v1',
        v2: 'https://api.appgbm.com/gbmcom/v2',
        v3: 'https://api.appgbm.com/gbmcom/v3',
      },
    },
  },
};

export function config(environment: Environment) {
  return environmentConfig[environment];
}
