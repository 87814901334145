import { config, ApiType, LegalDocumentType } from '@gbm/onboarding-sdk-utils';
import { completeErrorAdapter, defaultSuccessAdapter } from '../adapters';
import { HTTP_VERBS, HttpVerbsTypes } from '../types';
import request from '../request';
import { ApiOptions, DEFAULT_API_OPTIONS } from '../models';
import {
  DocumentLoremRequestType,
  DocumentTypesType,
  UpgradeProviderProps,
  UpgradeStepType,
} from '../../app/providers/upgrade/types';
import { USA_COUNTRY_CODE, IDENTIFIERS } from '../../app/store/upgrade/types';
import {
  PartyCountryType,
  IdentifierType,
  BankAccountType,
  LoremQuestionnaireType,
  LoremQuestionnaireRequestType,
  DeepLinksActionType,
  DeepLinksParamsType,
  DeepLinksProviderType,
} from './types';
import {
  financialInfoAnswersAdapter,
  financialInfoQuestionsAdapter,
} from './adapters';

export default class Upgrade {
  private apiUrl: ApiType;

  private accessToken: string;

  private onRefreshSession: () => void;

  private upgradeRequest(
    url: string,
    options: Partial<ApiOptions> = DEFAULT_API_OPTIONS,
    successAdapter: Function = defaultSuccessAdapter,
  ) {
    return request(
      url,
      this.onRefreshSession,
      this.accessToken,
      options,
      successAdapter,
      completeErrorAdapter,
    );
  }

  constructor({
    environment,
    accessToken,
    onRefreshSession,
  }: UpgradeProviderProps) {
    const {
      onboarding: { apiUrl },
    } = config(environment);
    this.apiUrl = apiUrl;
    this.accessToken = accessToken;
    this.onRefreshSession = onRefreshSession;
  }

  async getPartyCountries(partyId: string) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/countries`;
    return this.upgradeRequest(url);
  }

  async updatePartyCountriesBatch(
    partyId: string,
    countries: PartyCountryType[],
    type: string,
  ) {
    const url = `${
      this.apiUrl.v1
    }/parties/${partyId}/countries/batch/${type.toLocaleLowerCase()}`;
    return this.upgradeRequest(url, {
      method: HTTP_VERBS.post,
      body: countries,
    });
  }

  async getBankList() {
    const url = `${this.apiUrl.v1}/catalogs/banks`;
    return this.upgradeRequest(url);
  }

  async getBankAccountStatus(contractId: string, pageSize = 10, page = 1) {
    const url = `${this.apiUrl.v1}/withdrawal-accounts?legacy_contract_id=${contractId}&page_size=${pageSize}&page=${page}`;
    return this.upgradeRequest(url);
  }

  async getWithdrawalDocuments(bankAccountId: string) {
    const url = `${this.apiUrl.v1}/withdrawal-accounts/${bankAccountId}/documents`;
    return this.upgradeRequest(url);
  }

  async getPartyIdentifiers(partyId: string) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/identifiers`;
    return this.upgradeRequest(url);
  }

  async deleteIdentifier(partyId: string, identifier: IdentifierType) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/identifiers/rfc`;
    return this.upgradeRequest(url, {
      method: HTTP_VERBS.delete,
      body: {
        identifier_type_id: identifier.identifier_type_id,
        identifier_type: identifier.name,
        value: identifier.value,
      },
    });
  }

  async savePartyRfc(partyId: string, rfc: string) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/identifiers/rfc`;
    return this.upgradeRequest(url, {
      method: HTTP_VERBS.post,
      body: {
        value: rfc,
      },
    });
  }

  async savePartyTin(partyId: string, tin: string) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/identifiers`;
    return this.upgradeRequest(url, {
      method: HTTP_VERBS.post,
      body: {
        value: tin,
        country_code: USA_COUNTRY_CODE,
        identifier_type: IDENTIFIERS.tin,
      },
    });
  }

  async savePartyFiel(partyId: string, fiel: string) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/identifiers/fiel`;
    return this.upgradeRequest(url, {
      method: HTTP_VERBS.post,
      body: {
        value: fiel,
      },
    });
  }

  async saveBankAccount(bankAccount: BankAccountType) {
    const url = `${this.apiUrl.v1}/withdrawal-accounts`;
    return this.upgradeRequest(url, {
      method: HTTP_VERBS.post,
      body: {
        ...bankAccount,
      },
    });
  }

  async getUpgradeStep(partyId: string) {
    const url = `${this.apiUrl.v2}/parties/${partyId}/upgrade-step`;
    return this.upgradeRequest(url);
  }

  async saveUpgradeStep(partyId: string, step: UpgradeStepType) {
    const url = `${this.apiUrl.v2}/parties/${partyId}/upgrade-step`;
    const body = { step };
    const options = {
      method: HTTP_VERBS.post,
      body,
    };
    return this.upgradeRequest(url, options);
  }

  async getUpgradeStatus(partyId: string) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/upgrade`;
    return this.upgradeRequest(url);
  }

  async getUdisSummary(partyId: string) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/investment-units/summary`;
    return this.upgradeRequest(url);
  }

  async getDocuments(partyId: string) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/documents`;
    return this.upgradeRequest(url);
  }

  async getFinancialInfoQuestionnaire(
    questionnaireId: string,
    version: number,
  ) {
    const url = `${this.apiUrl.v1}/questionnaires/${questionnaireId}/versions/${version}`;
    return this.upgradeRequest(url, {}, financialInfoQuestionsAdapter);
  }

  async getFinancialInfoQuestionnaireAnswers(
    partyId: string,
    questionnaireType: LoremQuestionnaireType,
  ) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/questionnaire-answers?questionnaire_type=${questionnaireType}`;
    return this.upgradeRequest(url, {}, financialInfoAnswersAdapter);
  }

  async saveFinancialInfoAnswers(
    partyId: string,
    answers: LoremQuestionnaireRequestType,
    method: HttpVerbsTypes,
  ) {
    const url = `${this.apiUrl.v2}/parties/${partyId}/questionnaire-answers`;
    const options = {
      method,
      body: answers.questionnaire,
    };
    return this.upgradeRequest(url, options);
  }

  async saveDocument(partyId: string, file: DocumentLoremRequestType) {
    const url = `${this.apiUrl.v2}/parties/${partyId}/documents`;
    const options = {
      method: HTTP_VERBS.post,
      body: file,
    };
    return this.upgradeRequest(url, options);
  }

  async saveDocumentMultipart(
    partyId: string,
    file: Blob,
    documentType: DocumentTypesType,
  ) {
    const url = `${this.apiUrl.v3}/parties/${partyId}/documents`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('document_type', documentType);
    const options = {
      method: HTTP_VERBS.post,
      body: formData,
      headers: { 'content-type': 'multipart/form-data' },
    };
    return this.upgradeRequest(url, options);
  }

  async getBankAccountDecrypted(bankAccountId: string) {
    const url = `${this.apiUrl.v1}/withdrawal-accounts/${bankAccountId}/decrypted`;
    return this.upgradeRequest(url);
  }

  async contractUpgrade(partyId: string) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/upgrade`;
    const options = {
      method: HTTP_VERBS.post,
    };
    return this.upgradeRequest(url, options);
  }

  async verifyDocument(
    partyId: string,
    type: DocumentTypesType,
    prefersIneAsProofOfAddress = false,
  ) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/documents/verify`;
    const options = {
      method: HTTP_VERBS.post,
      body: {
        type,
        use_identification_as_proof_of_address: prefersIneAsProofOfAddress,
      },
    };
    return this.upgradeRequest(url, options);
  }

  async getLegalDocuments() {
    const url = `${this.apiUrl.v2}/legal-documents`;
    return this.upgradeRequest(url);
  }

  async saveLegalDocument(partyId: string, document: LegalDocumentType) {
    const url = `${this.apiUrl.v2}/parties/${partyId}/legal-documents`;
    return this.upgradeRequest(url, {
      method: HTTP_VERBS.post,
      body: document,
    });
  }

  async getDeepLink(
    action: DeepLinksActionType,
    params: DeepLinksParamsType,
    provider: DeepLinksProviderType,
    origin: string,
  ) {
    const url = `${this.apiUrl.v2}/deep-links`;
    const options = {
      method: HTTP_VERBS.post,
      body: {
        action,
        params,
        provider,
        origin,
      },
    };
    return this.upgradeRequest(url, options);
  }

  async getValidUpgrade(partyId: string) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/upgrade-allowed`;

    return this.upgradeRequest(url);
  }
}
