import { Dispatch, PropsWithChildren, SetStateAction } from 'react';

import {
  Environment,
  ENVIRONMENTS,
  Values,
  TranslationsType,
  PlatformType,
} from '@gbm/onboarding-sdk-utils';
import { OpeningLiteStateType } from '../../store/opening-lite/types';
import { GenderType, GeolocationType } from '../../../api/opening-lite/types';
import { REDUCER_STATUS } from '../../store/types';

export const OPENING_STATUS = {
  noContract: 'no_contract',
  inProgress: 'in_progress',
  pld: 'opening_rejected_internal_reasons',
  pendingContractCreation: 'pending_contract_creation',
  pendingToSign: 'pending_to_sign',
  pendingReplication: 'pending_replication',
  active: 'active',
} as const;
export type OpeningStatusValueType = Values<typeof OPENING_STATUS>;

export const OPENING_STATUS_POOLING = [
  OPENING_STATUS.pendingContractCreation,
  OPENING_STATUS.pendingToSign,
  OPENING_STATUS.pendingReplication,
] as const;
export type OpeningStatusPoolingType = (typeof OPENING_STATUS_POOLING)[number];

export const OPENING_TYPE = {
  lite: 'light',
  liteForTracking: 'lite', // For tracking events
  black: 'black',
} as const;
export type OpeningType = Values<typeof OPENING_TYPE>;

export const OPENING_LITE_STEPS = {
  personalInfo: 'personal_info',
  addressInfo: 'address_info',
  confirmation: 'confirmation',
};
export type OpeningLiteStepType = Values<typeof OPENING_LITE_STEPS>;

export const STEPS = {
  unknown: 'unknown',
  welcome: 'welcome',
  curpInfo: 'curpInfo',
  personalInfo: 'personalInfo',
  addressInfo: 'addressInfo',
  confirmation: 'confirmation',
  pending: 'pending',
  sign: 'sign',
  pld: 'pld',
  activating: 'activating',
  alreadyRegistered: 'alreadyRegistered',
  active: 'active',
} as const;
export type StepType = Values<typeof STEPS>;

export const POOL_OPENING_STATUS_TIME = 60000;

export type OpeningLiteType = Pick<OpeningLiteStateType, 'openingStatus'> & {
  step: StepType;
  setStep: Dispatch<SetStateAction<StepType>>;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  isEditing: boolean;
  saveOpeningLiteStep: (step: OpeningLiteStepType) => void;
  configuration: OpeningLiteProviderProps;
  handleSignOut: () => void;
};

export type PersonalInfoType = {
  firstName: string;
  lastName: string;
  secondLastName: string;
  genre: GenderType;
  birthState: string;
  birthDate: string;
  phone: {
    countryCode: string;
    phoneNumber: string;
    type: string;
  };
  curp: string;
};

export type AddressInfoType = {
  addressType: string;
  country: Record<string, unknown>;
  insideNumber: string;
  outsideNumber: string;
  state: string;
  streetName: string;
  suburb: string;
  township: string;
  zipCode: string;
};

export type ContractInfoType = {
  email: string;
  personalInfo: PersonalInfoType;
  addressInfo: AddressInfoType;
  geolocation?: GeolocationType;
  contract?: Record<string, any>;
  account?: Record<string, any>;
};

export type OpeningLiteProviderProps = PropsWithChildren<{
  environment: Environment;
  email: string;
  clientId: string;
  accessToken: string;
  refreshToken: string;
  appName: string;
  onRefreshSession: () => void;
  onSessionRefreshed: (accessToken: string) => void;
  onSignedOut: () => void;
  validations?: {
    allowInvalidCurps?: boolean;
    validateTaxIdentifier?: boolean;
  };
  translations?: TranslationsType;
  termsAndConditionsUrl?: string;
  fetchContractInfo?: boolean;
  isEnabledTrackEvents?: boolean;
  isEnabledTradingOpening?: boolean;
  onPersonalInfoCompleted?: (data: PersonalInfoType) => void;
  onAddressInfoCompleted?: (data: AddressInfoType) => void;
  onContractCreationStarted?: () => void;
  onContractSigned?: () => void;
  onCompleted?: (data: ContractInfoType) => void;
  platform?: PlatformType;
  prepopulatedInfo?: {
    personalInfo?: {
      firstName?: string;
      lastName?: string;
      secondLastName?: string;
      genre?: GenderType;
      birthDay?: string;
      birthMonth?: string;
      birthYear?: string;
      phoneCode?: string;
      phoneNumber?: string;
      curp?: string;
      rfc?: string;
      homoclaveRfc?: string;
    };
    addressInfo?: {
      zipCode?: string;
      streetName?: string;
      insideNumber?: string;
      outsideNumber?: string;
    };
  };
}>;

export type OpeningLiteProps = Omit<
  OpeningLiteProviderProps,
  'onRefreshSession' | 'children'
>;

/* istanbul ignore next */
export const mockUseOpeningLiteProvider: OpeningLiteType = {
  openingStatus: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  step: STEPS.welcome,
  setStep: () => null,
  setIsEditing: () => null,
  isLoading: false,
  isEditing: false,
  saveOpeningLiteStep: () => null,
  handleSignOut: () => null,
  configuration: {
    email: 'fake@fake.com',
    clientId: '',
    accessToken: '',
    refreshToken: '',
    appName: '',
    environment: ENVIRONMENTS.development,
    onCompleted: () => null,
    onRefreshSession: () => null,
    onSessionRefreshed: () => null,
    onSignedOut: () => null,
  },
};
