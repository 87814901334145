import { ReactNode } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  Typography,
  Lock,
  ArrowClockwise,
  MapPin,
  Info,
} from '@gbm/starman-next';

import { i18n, translations } from '@gbm/onboarding-sdk-utils';
import { Modal, ModalContent, ModalHeader, ModalProps } from '../modal';
import styles from './geolocation.module.scss';

export type GeolocationRejectedStepsProps = Omit<ModalProps, 'children'> & {
  onClose: () => void;
};
const {
  base: { geolocation },
} = translations;
const { rejectedSteps } = geolocation;

function Step({
  title,
  description,
}: {
  title: ReactNode;
  description: ReactNode;
}) {
  return (
    <div className={styles['rejected-step']}>
      <span className={styles['rejected-step-label']}>
        <Typography variant="xsmall" weight="semibold">
          {title}
        </Typography>
      </span>
      <Typography variant="small">{description}</Typography>
    </div>
  );
}

export default function GeolocationRejectedSteps({
  onClose,
  ...modalProps
}: GeolocationRejectedStepsProps) {
  const { t } = useTranslation(undefined, { i18n });

  return (
    <Modal {...modalProps}>
      <ModalHeader title={t(geolocation.title)} onClose={onClose} />
      <ModalContent>
        <div className={styles['rejected-title']}>
          <MapPin size={styles['icon-size']} />
          <Typography variant="body2" weight="semibold">
            {t(rejectedSteps.title)}
          </Typography>
        </div>
        <Step
          title="1"
          description={
            <Trans
              i18n={i18n}
              i18nKey={rejectedSteps.instructionsFirst}
              components={[
                <Lock
                  size={styles['icon-size']}
                  className={styles.icon}
                  alt="instruction"
                  weight="fill"
                />,
              ]}
            />
          }
        />
        <Step title="2" description={t(rejectedSteps.instructionsSecond)} />
        <Step
          title="3"
          description={
            <Trans
              i18n={i18n}
              i18nKey={rejectedSteps.instructionsThird}
              components={[
                <ArrowClockwise
                  size={styles['icon-size']}
                  className={styles.icon}
                  alt="instruction"
                  weight="fill"
                />,
              ]}
            />
          }
        />
        <div className={styles['rejected-disclaimer']}>
          <Info
            size={styles['info-icon-size']}
            color={styles['info-icon-color']}
          />
          <Typography variant="small">
            <Trans
              i18n={i18n}
              i18nKey={rejectedSteps.disclaimer}
              components={[<strong></strong>]}
            />
          </Typography>
        </div>
      </ModalContent>
    </Modal>
  );
}
