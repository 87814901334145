import { motion } from 'framer-motion';
import { Typography } from '@gbm/starman-next';

import { useBreakpoints } from '@gbm/onboarding-sdk-hooks';
import { AppLayout } from '../app-layout';
import { Loader } from '../loader';
import styles from './contract-status.module.scss';

export type ContractStatusProps = {
  title: string;
  description: string;
};

export default function ContractStatus({
  title,
  description,
}: ContractStatusProps) {
  const { isSm } = useBreakpoints();

  return (
    <AppLayout>
      <motion.div
        className={styles.container}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <Loader fullScreen={false} kind="accent" />
        <div className={styles.title}>
          <Typography variant={isSm ? 'h4' : 'h2'}>{title}</Typography>
        </div>
        <div className={styles.description}>
          <Typography variant="body2">{description}</Typography>
        </div>
      </motion.div>
    </AppLayout>
  );
}
