import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  useNotificationProvider,
  NOTIFICATION_TIMEOUT,
} from '@gbm/onboarding-sdk-ui-components';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getParty,
  clearErrors,
  selectParty,
  selectErrors,
} from '../../store/opening-lite';
import { OpeningLiteProviderProps } from '../../providers/opening-lite/types';
import { REDUCER_STATUS } from '../../store/types';
import type { UsePartyType } from './types';

export default function useParty(
  config: OpeningLiteProviderProps,
): UsePartyType {
  const dispatch = useAppDispatch();
  const { showNotification } = useNotificationProvider();
  const party = useAppSelector(selectParty);
  const errors = useAppSelector(selectErrors);
  const isLoading = party.status === REDUCER_STATUS.pending;
  const isRejected = party.status === REDUCER_STATUS.rejected;
  const isEmptyParty = isEmpty(party.data);
  const isFulfilled = party.status === REDUCER_STATUS.resolved && !isEmptyParty;

  const fetch = () => {
    if (isEmptyParty && !isLoading) {
      dispatch(getParty({ config }));
    }
  };

  useEffect(() => {
    if (!isEmpty(errors.party)) {
      showNotification({
        description: errors.party?.message,
        kind: 'error',
      });
      setTimeout(() => {
        dispatch(clearErrors());
      }, NOTIFICATION_TIMEOUT);
    }
  }, [errors.party]);

  return {
    party,
    fetch,
    isLoading,
    isRejected,
    isFulfilled,
    isEmpty: isEmptyParty,
  };
}
