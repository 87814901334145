import React, { ReactNode, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DocumentsType, translations } from '@gbm/onboarding-sdk-utils';

import {
  DOCUMENTS_STATUS,
  DOCUMENT_TYPES,
  UpgradeStepType,
  UPGRADE_STEPS,
  DocumentTypesType,
  LOREM_VALIDATION_ERROR_CODES,
  LoremValidationErrorCodeType,
} from '../../providers/upgrade/types';

type UseDocumentsType = {
  loremRejectionReason?: string;
  rejectedMessage: {
    type?: DocumentTypesType;
    text?: string | ReactNode;
  };
  getStepFromDocuments: (currentStep: UpgradeStepType) => UpgradeStepType;
};
const INE_DOCUMENTS: Partial<DocumentTypesType>[] = [
  DOCUMENT_TYPES.officialIdentificationBack,
  DOCUMENT_TYPES.officialIdentificationFront,
];
const IDENTITY_DOCUMENTS: Partial<DocumentTypesType>[] = [
  DOCUMENT_TYPES.livenessTest,
  DOCUMENT_TYPES.selfie,
];
const ERROR_CODES: LoremValidationErrorCodeType[] = [
  LOREM_VALIDATION_ERROR_CODES.ine_not_current,
  LOREM_VALIDATION_ERROR_CODES.ine_reported_lost,
  LOREM_VALIDATION_ERROR_CODES.ine_reported_stolen,
  LOREM_VALIDATION_ERROR_CODES.liveness_failed,
  LOREM_VALIDATION_ERROR_CODES.missing_document_id,
  LOREM_VALIDATION_ERROR_CODES.missing_selfie,
  LOREM_VALIDATION_ERROR_CODES.module_not_supported,
  LOREM_VALIDATION_ERROR_CODES.not_enough_data,
  LOREM_VALIDATION_ERROR_CODES.user_not_found,
  LOREM_VALIDATION_ERROR_CODES.user_not_found_in_ine_db,
  LOREM_VALIDATION_ERROR_CODES.validation_error,
];
const {
  upgrade: { upgrade },
} = translations;

export default function useDocuments(
  documents?: DocumentsType[],
): UseDocumentsType {
  const { t } = useTranslation();

  const loremRejectionReason = useMemo(
    () =>
      documents?.find(
        (document: DocumentsType) =>
          document.document_status === DOCUMENTS_STATUS.rejected &&
          ERROR_CODES.includes(
            document.document_rejection_reason as LoremValidationErrorCodeType,
          ),
      )?.document_rejection_reason,
    [documents],
  );

  const errorMessage = useMemo(() => {
    if (documents?.length) {
      const rejectedDocuments = documents.filter(
        (document: DocumentsType) =>
          document.document_status === DOCUMENTS_STATUS.rejected,
      );

      // There is no rejected documents, so the errorMessage should be an empty object
      if (!rejectedDocuments.length) return {};

      // Check if there is an error with the official identification
      const ineError = rejectedDocuments.find(
        ({ document_type: documentType }: DocumentsType) =>
          INE_DOCUMENTS.includes(documentType as DocumentTypesType),
      );

      if (ineError) {
        return {
          type: DOCUMENT_TYPES.officialIdentificationFront,
          text: ineError.rejection_message || (
            <Trans
              i18nKey={upgrade.ineError}
              components={[<strong></strong>, <br />]}
            />
          ),
        };
      }

      // Check if there is an error with the selfie or the liveness test
      const identityError = rejectedDocuments.find(
        ({ document_type: documentType }: DocumentsType) =>
          IDENTITY_DOCUMENTS.includes(documentType as DocumentTypesType),
      );

      if (identityError) {
        return {
          type: DOCUMENT_TYPES.livenessTest,
          text: (
            <Trans
              i18nKey={upgrade.identityError}
              components={[<strong></strong>, <br />]}
            />
          ),
        };
      }

      // Check if there is an error with the proof of address
      const proofOfAddressError = rejectedDocuments.find(
        ({ document_type: documentType }: DocumentsType) =>
          documentType === DOCUMENT_TYPES.proofOfAddress,
      );

      if (proofOfAddressError) {
        return {
          type: DOCUMENT_TYPES.proofOfAddress,
          text:
            proofOfAddressError.rejection_message ||
            t(upgrade.proofOfAddressError),
        };
      }
    }

    // There is no given documents, so the errorMessage should be an empty object
    return {};
  }, [documents]);

  const getStepFromDocuments = useCallback(
    (currentStep: UpgradeStepType) => {
      if (documents?.length) {
        const validatedDocuments = documents?.filter(
          (document: DocumentsType) =>
            document.document_status !== DOCUMENTS_STATUS.failed &&
            document.document_status !== DOCUMENTS_STATUS.rejected,
        );

        if (!validatedDocuments.length) {
          return currentStep;
        }

        const hasIneFront = validatedDocuments.find(
          (doc: DocumentsType) =>
            doc.document_type === DOCUMENT_TYPES.officialIdentificationFront,
        );
        const hasIneBack = validatedDocuments.find(
          (doc: DocumentsType) =>
            doc.document_type === DOCUMENT_TYPES.officialIdentificationBack,
        );
        const hasSelfie = validatedDocuments.find(
          (doc: DocumentsType) => doc.document_type === DOCUMENT_TYPES.selfie,
        );
        const hasLivenessTest = validatedDocuments.find(
          (doc: DocumentsType) =>
            doc.document_type === DOCUMENT_TYPES.livenessTest,
        );

        if (!hasIneFront || !hasIneBack || !hasSelfie || !hasLivenessTest) {
          return UPGRADE_STEPS.ine;
        }

        const hasProofOfAddress = validatedDocuments.find(
          (doc: DocumentsType) =>
            doc.document_type === DOCUMENT_TYPES.proofOfAddress &&
            doc.document_status === DOCUMENTS_STATUS.validated,
        );

        if (hasProofOfAddress) {
          return UPGRADE_STEPS.partyReviewData;
        }

        return UPGRADE_STEPS.proofOfAddress;
      }

      return currentStep;
    },
    [documents],
  );

  return {
    loremRejectionReason,
    rejectedMessage: errorMessage,
    getStepFromDocuments,
  };
}
