import { useMemo, useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import {
  ArrowCircleRight,
  Button,
  ButtonLink,
  CaretLeft,
  Notification,
  Typography,
} from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';
import isBoolean from 'lodash/isBoolean';

import {
  FormContent,
  FormFooter,
  Modal,
  ModalContent,
  ModalHeader,
} from '@gbm/onboarding-sdk-ui-components';
import {
  UPGRADE_IDS,
  CompressImageCallbackProps,
  translations,
  TRACKING_EVENTS,
  TRACKING_NAMES,
} from '@gbm/onboarding-sdk-utils';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import { UpgradeLayout } from '../../../components/upgrade';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  clearDocuments,
  clearErrors,
  saveProofOfAddress,
  selectErrors,
  selectProofOfAddress,
  verifyProofOfAddress,
} from '../../../store/upgrade';
import { REDUCER_STATUS } from '../../../store/types';
import {
  useDocuments,
  useParty,
  useUpgradeStatus,
  useUpgradeStep,
} from '../../../hooks';
import { useUpgradeProvider } from '../../../providers/upgrade';
import {
  DOCUMENTS_STATUS,
  DOCUMENT_TYPES,
  ERROR_CODES,
  STEPS,
  UPGRADE_STATUSES,
  UPGRADE_STEPS,
} from '../../../providers/upgrade/types';
import Options from './options';
import Upload from './upload';
import styles from './proof-of-address.module.scss';
import { AddressForm } from '../../../components';

const {
  base,
  buttons,
  upgrade: { proofOfAddress: proofOfAddressMsg },
} = translations;
const INCOMPATIBLE_ERROR_FIELDS = {
  [ERROR_CODES.cityInvalid]: proofOfAddressMsg.city,
  [ERROR_CODES.stateInvalid]: proofOfAddressMsg.state,
  [ERROR_CODES.townshipInvalid]: proofOfAddressMsg.township,
  [ERROR_CODES.zipCodeMismatch]: proofOfAddressMsg.zipcode,
};

export default function ProofOfAddress() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { configuration, setStep } = useUpgradeProvider();
  const { party } = useParty(configuration);
  const errors = useAppSelector(selectErrors);
  const proofOfAddress = useAppSelector(selectProofOfAddress);
  const { saveUpgradeStep } = useUpgradeStep(configuration);
  const {
    upgradeStatus,
    fetch: getUpgradeStatus,
    isLoading: isLoadingUpgradeStatus,
    isFulfilled: isFulfilledUpgradeStatus,
  } = useUpgradeStatus(configuration);
  const { rejectedMessage } = useDocuments(upgradeStatus.data?.documents);
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );
  const [showUpload, setShowUpload] = useState(false);
  const [prefersIne, setPrefersIne] = useState<boolean>();
  const [fileData, setFileData] = useState<CompressImageCallbackProps | null>(
    null,
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showAddressEdition, setShowAddressEdition] = useState(false);
  const isContinueButtonDisabled = !showUpload
    ? !isBoolean(prefersIne)
    : !fileData?.file;
  const isContinueButtonLoading =
    isSubmitted && proofOfAddress.status === REDUCER_STATUS.pending;

  useEffect(() => {
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.proofOfAddress,
    });

    return () => hideErrorBanner();
  }, []);

  useEffect(() => {
    // If the user reached the attempts and the status is
    // pending_manual_validation, show that screen
    if (
      isFulfilledUpgradeStatus &&
      upgradeStatus.data?.status === UPGRADE_STATUSES.pendingManualValidation
    ) {
      saveUpgradeStep(UPGRADE_STEPS.gbmReviewData);
      setStep(STEPS.reviewing);
    }
  }, [isFulfilledUpgradeStatus]);

  useEffect(() => {
    if (isSubmitted && proofOfAddress.status === REDUCER_STATUS.resolved) {
      // If the proof of address file is valid, then go to next step
      if (proofOfAddress.data.document_status === DOCUMENTS_STATUS.validated) {
        mixpanel.track(TRACKING_EVENTS.documentValidated, {
          name: DOCUMENT_TYPES.proofOfAddress,
          success: true,
        });
        saveUpgradeStep(UPGRADE_STEPS.partyReviewData);
        setStep(STEPS.confirmation);
        return;
      }

      const isRejected = [
        DOCUMENTS_STATUS.rejected,
        DOCUMENTS_STATUS.failed,
      ].includes(proofOfAddress.data.document_status);

      if (isRejected) {
        // Check the upgrade status and attempts
        getUpgradeStatus(true);
        return;
      }

      // Track document was uploaded
      mixpanel.track(TRACKING_EVENTS.documentUploaded, {
        name: DOCUMENT_TYPES.proofOfAddress,
        succes: true,
      });
      // Call to verify document using the current file as the proof of address
      dispatch(
        verifyProofOfAddress({
          config: configuration,
          partyId: party.data.party_id,
          prefersIne: false,
        }),
      );
    }
  }, [isSubmitted, proofOfAddress.status, proofOfAddress.data]);

  const handleContinueButtonClick = () => {
    if (prefersIne) {
      setIsSubmitted(true);
      // Call to verify document using the official ID as the proof of address
      dispatch(
        verifyProofOfAddress({
          config: configuration,
          partyId: party.data.party_id,
          prefersIne: true,
        }),
      );
      return;
    }

    setShowUpload(true);
  };

  const handleSubmit = () => {
    setIsSubmitted(true);

    // Save the uploaded file as the proof of address
    dispatch(
      saveProofOfAddress({
        file: fileData?.file as Blob,
        width: fileData?.width as number,
        height: fileData?.height as number,
        extension: fileData?.extension as string,
        config: configuration,
        partyId: party.data.party_id,
      }),
    );
  };

  const hideErrorBanner = () => {
    dispatch(clearErrors());
    dispatch(clearDocuments());
  };

  const handleUpdatedAddress = () => {
    setShowAddressEdition(false);
    hideErrorBanner();
  };

  const incompatibleAlertMessage = (buttonText: string) => (
    <div className={styles['address-link']}>
      <Typography variant="small">
        {t(proofOfAddressMsg.incompatible, { errorField: t(buttonText) })}
      </Typography>
      &nbsp;
      <ButtonLink
        kind="accent"
        underline="always"
        onClick={() => setShowAddressEdition(true)}
      >
        <Typography variant="small">{t(proofOfAddressMsg.editLink)}</Typography>
      </ButtonLink>
    </div>
  );

  const errorMessageFromCode = useMemo(() => {
    if (!errors.proofOfAddress?.code) {
      return null;
    }

    switch (errors.proofOfAddress.code) {
      case ERROR_CODES.addressNotFound:
      case ERROR_CODES.invalid:
      case ERROR_CODES.noMatchName:
        return t(proofOfAddressMsg.missingInfo);
      case ERROR_CODES.expired:
        return t(proofOfAddressMsg.expiredInfo);
      case ERROR_CODES.cityInvalid:
      case ERROR_CODES.stateInvalid:
      case ERROR_CODES.townshipInvalid:
      case ERROR_CODES.zipCodeMismatch:
        return incompatibleAlertMessage(
          INCOMPATIBLE_ERROR_FIELDS[errors.proofOfAddress.code],
        );
      default:
        return incompatibleAlertMessage(proofOfAddressMsg.generic);
    }
  }, [errors.proofOfAddress]);

  const notificationMessage =
    errorMessageFromCode ||
    errors.proofOfAddress?.message ||
    rejectedMessage?.text;

  return (
    <UpgradeLayout current={4} kind="secondary">
      <motion.div
        className={styles.container}
        id={UPGRADE_IDS.viewProofOfAddress}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <FormContent>
          <AnimatePresence>
            {notificationMessage ? (
              <motion.div
                className={styles.notification}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Notification
                  title={t(base.error)}
                  kind="error"
                  onClose={hideErrorBanner}
                  description={notificationMessage as string}
                />
              </motion.div>
            ) : null}
          </AnimatePresence>
          {!showUpload ? (
            <Options prefersIne={prefersIne} setPrefersIne={setPrefersIne} />
          ) : (
            <Upload
              fileData={fileData}
              setFileData={setFileData}
              setIsSubmitted={setIsSubmitted}
            />
          )}
        </FormContent>
        <FormFooter>
          {showUpload ? (
            <ButtonLink onClick={() => setShowUpload(false)}>
              <CaretLeft
                color={styles['icon-color']}
                size={styles['icon-size']}
              />
              {t(buttons.previous)}
            </ButtonLink>
          ) : (
            <div />
          )}
          <Button
            id={UPGRADE_IDS.btnProofOfAddress}
            disabled={isContinueButtonDisabled}
            loading={isContinueButtonLoading || isLoadingUpgradeStatus}
            kind="primary"
            onClick={!showUpload ? handleContinueButtonClick : handleSubmit}
            icon={<ArrowCircleRight size={styles['icon-size']} weight="fill" />}
          >
            {t(buttons.continue)}
          </Button>
        </FormFooter>
      </motion.div>
      <Modal show={showAddressEdition} fullScreen>
        <ModalHeader onClose={() => setShowAddressEdition(false)} />
        <ModalContent>
          <AddressForm
            configuration={configuration}
            onHandleContinue={handleUpdatedAddress}
            isEditing
          />
        </ModalContent>
      </Modal>
    </UpgradeLayout>
  );
}
