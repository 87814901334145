import {
  Select,
  SelectOption,
  SelectProps,
  SelectOptionProps,
  XCircle,
} from '@gbm/starman-next';
import clsx from 'clsx';

import styles from './select-item.module.scss';

export type SelectItemProps = {
  className?: string;
  options: Array<SelectOptionProps & { key: string }>;
  selectProps: Pick<SelectProps, 'onChange' | 'disabled' | 'value'>;
  onDelete: () => void;
};

export default function SelectItem({
  className,
  options,
  selectProps,
  onDelete,
}: SelectItemProps) {
  return (
    <div className={clsx(styles.container, className)}>
      <Select widthSize="fluid" {...selectProps}>
        {options.map(({ value, children, key, hidden }) => (
          <SelectOption value={value} key={key} hidden={hidden}>
            {children}
          </SelectOption>
        ))}
      </Select>
      <button
        className={styles.button}
        type="button"
        data-testid="delete-btn"
        onClick={onDelete}
      >
        <XCircle size={styles['icon-size']} weight="fill" />
      </button>
    </div>
  );
}
