import decode, { JwtPayload } from 'jwt-decode';
import {
  getYear,
  subYears,
  addDays,
  parseISO,
  format,
  differenceInYears,
} from 'date-fns';
import heic2any from 'heic2any';

import {
  AGE_RANGE,
  CompressImageCallbackProps,
  DATE_FORMATS,
  GENERIC_ERROR,
  IMAGE_MIN_WIDTH_IN_PIXELS,
  MockableFunction,
} from './types';

type IdTokenPayload = JwtPayload & {
  email?: string;
};

export const getUserEmail = (idToken: string) => {
  try {
    const decoded = idToken ? decode<IdTokenPayload>(idToken) : {};
    return decoded?.email;
  } catch (e) {
    return null;
  }
};

export const getUserId = (accessToken: string) => {
  const decoded = accessToken ? decode<JwtPayload>(accessToken) : {};
  return decoded.sub;
};

export const getAccessToken = () => {
  const params = new URLSearchParams(window.location.search);
  const accessToken =
    params.get('access_token') || localStorage.getItem('accessToken');

  return accessToken;
};

export const generateSecureRandomNumber = (
  min: number,
  max: number,
  withRange: boolean = false,
) => {
  const crypto = window.crypto || window.Crypto;
  const randomBuffer = new Uint32Array(1);
  crypto.getRandomValues(randomBuffer);

  if (withRange) {
    const randomNumber = randomBuffer[0] / (0xffffffff + 1);
    const maxFloor = Math.floor(max);
    const minCeil = Math.ceil(min);
    return Math.floor(randomNumber * (maxFloor - minCeil + 1)) + minCeil;
  }

  return randomBuffer[0];
};

export const getErrorMessage = (error: any) => {
  let message = error?.message;

  if (!message && error?.details?.length) {
    message = error.details[0].message;
  }

  if (!message && error?.data) {
    const { data } = error;
    message = data?.message;

    if (!message && data?.error) {
      message = data.error?.message;
    }

    if (!message && data.error?.details?.length) {
      message = data.error.details[0].message;
    }
  }

  return message || GENERIC_ERROR;
};

export const getBirthDays = () =>
  Array.from({ length: 31 }, (_, i) => (i + 1).toString().padStart(2, '0'));

export const getBirthMonths = () =>
  Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));

export const getBirthYears = () => {
  const currentYear = getYear(new Date());
  const initialYear = getYear(subYears(new Date(), 99));
  const result = [];

  for (let i = initialYear; i <= currentYear; i += 1) {
    result.push(i.toString());
  }

  return result.reverse();
};

export const isoStringToDate = (date: string, addedDays: number = 1) =>
  addDays(parseISO(date), addedDays);

export const splitIsoDate = (date: string, addedDays: number = 1) => {
  const result = isoStringToDate(date, addedDays);
  const day = format(result, DATE_FORMATS.dd);
  const month = format(result, DATE_FORMATS.MM);
  const year = format(result, DATE_FORMATS.yyyy);

  return [day, month, year];
};

export const getAgeFromDate = (date: Date) => {
  try {
    const years = differenceInYears(new Date(), date);
    return years;
  } catch (e) {
    return AGE_RANGE.default;
  }
};

export const isAdult = (date: Date) => {
  const age = getAgeFromDate(date);
  return age >= AGE_RANGE.min;
};

export const isValidAge = (date: Date) => {
  const age = getAgeFromDate(date);
  return age <= AGE_RANGE.max;
};

export const createDate = (
  day: string | number,
  month: string | number,
  year: string | number,
) => new Date(Number(year), Number(month) - 1, Number(day));

export const formatDate = (
  day: string | number,
  month: string | number,
  year: string | number,
  stringFormat: string,
) => format(createDate(day, month, year), stringFormat);

export const makeMockable = <Func extends MockableFunction>(mockedFunc: Func) =>
  mockedFunc as jest.MockedFunction<typeof mockedFunc>;

export const blobToDataURL = (blob: Blob): Promise<string> =>
  new Promise((fulfill, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = (e) => fulfill(e.target?.result as string);
    reader.readAsDataURL(blob);
  });

export const checkHEICImage = async (
  base64string: string,
  file: Blob,
  imageFormat: string,
) => {
  const src = base64string;
  const imageData = Uint8Array.from(
    atob(src.replace(`data:image/${imageFormat};base64,`, '')),
    (c) => c.charCodeAt(0),
  );

  // File is too small for HEIC format
  if (imageData.length < 24) {
    return base64string;
  }

  // The file might be in HEIC format but with jpeg/png extension
  if (
    imageData[20] === 0x68 &&
    imageData[21] === 0x65 &&
    imageData[22] === 0x69 &&
    imageData[23] === 0x63
  ) {
    const convertedFile = await heic2any({
      blob: file,
      toType: `image/${imageFormat}`,
      quality: 1,
    });
    return blobToDataURL(convertedFile as Blob);
  }

  // File is not in HEIC format
  return base64string;
};

const changeHeightWidth = (
  height: number,
  maxHeight: number,
  width: number,
  maxWidth: number,
  minWidth: number = IMAGE_MIN_WIDTH_IN_PIXELS,
) => {
  let newHeight = height;
  let newWidth = width;

  if (width > maxWidth || width < minWidth) {
    newHeight = Math.round((height * maxWidth) / width);
    newWidth = maxWidth;
  }

  if (height > maxHeight) {
    newWidth = Math.round((width * maxHeight) / height);
    newHeight = maxHeight;
  }

  return { newHeight, newWidth };
};

export const resizeAndRotateImage = (
  callback: (data: CompressImageCallbackProps) => void,
  image: HTMLImageElement,
  maxWidth: number,
  maxHeight: number,
  imageFormat: string = 'jpeg',
  quality: number = 100,
  rotation: number = 0,
  minWidth: number = IMAGE_MIN_WIDTH_IN_PIXELS,
) => {
  const qualityDecimal = quality / 100;
  const canvas = document.createElement('canvas');

  let { width } = image;
  let { height } = image;

  const { newHeight, newWidth } = changeHeightWidth(
    height,
    maxHeight,
    width,
    maxWidth,
    minWidth,
  );

  if (rotation && (rotation === 90 || rotation === 270)) {
    canvas.width = newHeight;
    canvas.height = newWidth;
  } else {
    canvas.width = newWidth;
    canvas.height = newHeight;
  }

  width = newWidth;
  height = newHeight;

  const ctx = canvas.getContext('2d');

  if (rotation && ctx) {
    ctx.rotate((rotation * Math.PI) / 180);

    switch (rotation) {
      case 90:
        ctx.translate(0, -canvas.width);
        break;
      case 180:
        ctx.translate(-canvas.width, -canvas.height);
        break;
      case 270:
        ctx.translate(-canvas.height, 0);
        break;
      default:
        ctx.translate(0, 0);
        break;
    }
  }

  ctx?.drawImage(image, 0, 0, width, height);
  canvas.toBlob(
    (file) => file && callback({ file, width, height }),
    `image/${imageFormat}`,
    qualityDecimal,
  );
};

export const compressImage = async (
  file: Blob,
  maxWidth: number,
  maxHeight: number,
  quality: number = 100,
  rotation: number = 0,
  callback: (data: CompressImageCallbackProps) => void = () => null,
  minWidth: number = IMAGE_MIN_WIDTH_IN_PIXELS,
) => {
  const fileAsDataUrl = await blobToDataURL(file);
  const imageFormat = file.type.split('/')[1].toLowerCase();
  const fixedImage = await checkHEICImage(fileAsDataUrl, file, imageFormat);

  const image = new Image();
  image.onload = () => {
    resizeAndRotateImage(
      (result) => {
        callback({ ...result, extension: imageFormat });
      },
      image,
      maxWidth,
      maxHeight,
      imageFormat,
      quality,
      rotation,
      minWidth,
    );
  };
  image.src = fixedImage;
};
