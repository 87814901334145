export {
  default as OpeningLiteReducer,
  OpeningLiteSlice,
  clearDataFromCurp,
  clearDataFromZipCode,
  clearChallenge,
  openingTypeReceived,
  clearErrors,
  reset,
} from './slice';
export * from './actions';
export * from './selectors';
