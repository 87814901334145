import isEmpty from 'lodash/isEmpty';

import {
  savePartyRfc,
  deleteIdentifier,
  selectRfc,
  selectTin,
  selectFiel,
  getPartyIdentifiers,
  selectErrors,
  savePartyTin,
  savePartyFiel,
} from '../../store/upgrade';
import { UpgradeProviderProps } from '../../providers/upgrade/types';
import { useAppDispatch, useAppSelector } from '../../store';
import { IdentifierType } from '../../../api/upgrade/types';
import { useParty } from '../useParty';
import { UpgradeStateType } from '../../store/upgrade/types';
import { REDUCER_STATUS } from '../../store/types';

type UseIdentifiersType = Pick<
  UpgradeStateType,
  'rfc' | 'tin' | 'fiel' | 'errors'
> & {
  isLoading: boolean;
  isRejected: boolean;
  isFulfilled: boolean;
  isEmpty: boolean;
  fetch: (force?: boolean) => void;
  saveRfc: (rfc: string) => void;
  saveTin: (tin: string) => void;
  saveFiel: (fiel: string) => void;
  delete: (identifier: IdentifierType) => void;
};

export default function useIdentifiers(
  configuration: UpgradeProviderProps,
): UseIdentifiersType {
  const dispatch = useAppDispatch();
  const rfc = useAppSelector(selectRfc);
  const tin = useAppSelector(selectTin);
  const fiel = useAppSelector(selectFiel);
  const errors = useAppSelector(selectErrors);
  const isLoading = rfc.status === REDUCER_STATUS.pending;
  const isRejected = rfc.status === REDUCER_STATUS.rejected;
  const isFulfilled = rfc.status === REDUCER_STATUS.resolved;
  const isEmptyIdentifier =
    isEmpty(rfc.data) && isEmpty(fiel.data) && isEmpty(tin.data);
  const { party } = useParty(configuration);

  const fetch = (force: boolean = false) => {
    if ((isEmptyIdentifier && !isLoading) || force) {
      dispatch(
        getPartyIdentifiers({
          partyId: party.data.party_id,
          config: configuration,
        }),
      );
    }
  };

  const saveRfc = (newRfc: string) => {
    dispatch(
      savePartyRfc({
        partyId: party.data.party_id,
        rfc: newRfc,
        config: configuration,
      }),
    );
  };

  const saveTin = (newTin: string) => {
    dispatch(
      savePartyTin({
        partyId: party.data.party_id,
        tin: newTin,
        config: configuration,
      }),
    );
  };

  const saveFiel = (newFiel: string) => {
    dispatch(
      savePartyFiel({
        partyId: party.data.party_id,
        fiel: newFiel,
        config: configuration,
      }),
    );
  };

  const deleteId = (identifier: IdentifierType) => {
    dispatch(
      deleteIdentifier({
        partyId: party.data.party_id,
        identifier,
        config: configuration,
      }),
    );
  };

  return {
    rfc,
    tin,
    fiel,
    errors,
    isLoading,
    isRejected,
    isFulfilled,
    isEmpty: isEmptyIdentifier,
    fetch,
    saveRfc,
    saveTin,
    saveFiel,
    delete: deleteId,
  };
}
