import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useTranslation } from 'react-i18next';
import { Button, Typography, LoaderCountdown } from '@gbm/starman-next';

import { i18n, translations } from '@gbm/onboarding-sdk-utils';
import { Modal, ModalContent, ModalFooter } from '../modal';
import {
  TIME_TO_LOGOUT,
  TIME_TO_SHOW_MODAL,
  USER_ACTIONS,
  InactivityProviderProps,
  TIME_TO_LOGOUT_SECS,
} from './types';
import styles from './inactivity-provider.module.scss';

const {
  buttons,
  base: {
    inactivity: { modal },
  },
} = translations;

export default function InactivityProvider({
  onLogout,
}: InactivityProviderProps) {
  const { t } = useTranslation(undefined, { i18n });
  const [showModal, setShowModal] = useState(false);
  const { activate } = useIdleTimer({
    onPrompt: () => {
      setShowModal(true);
    },
    onIdle: () => {
      onLogout();
    },
    onActive: () => {
      setShowModal(false);
    },
    timeout: TIME_TO_SHOW_MODAL,
    promptBeforeIdle: TIME_TO_LOGOUT,
    events: [...USER_ACTIONS],
  });

  const handleStillHere = () => {
    setShowModal(false);
    activate();
  };

  return (
    <Modal show={showModal}>
      <ModalContent>
        <div className={styles.title}>
          <Typography variant="h5">{t(modal.title)}</Typography>
        </div>
        <div className={styles.loader}>
          <LoaderCountdown countStart={TIME_TO_LOGOUT_SECS} />
        </div>
        <div className={styles.question}>
          <Typography variant="body2">{t(modal.question)}</Typography>
        </div>
      </ModalContent>
      <ModalFooter>
        <div className={styles.actions}>
          <Button
            size="fluid"
            id="closeSession"
            kind="secondary"
            onClick={onLogout}
          >
            {t(buttons.logout)}
          </Button>
          <Button
            size="fluid"
            id="preserveSession"
            kind="primary"
            onClick={handleStillHere}
          >
            {t(buttons.keepSession)}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
