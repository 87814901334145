import { components, OptionProps, GroupBase } from 'react-select';
import { Typography } from '@gbm/starman-next';

import { SelectMultipleOption } from './types';
import styles from './select-multiple.module.scss';

export default function SelectMultipleItem({
  children,
  ...props
}: OptionProps<SelectMultipleOption, true, GroupBase<SelectMultipleOption>>) {
  return (
    <components.Option className={styles.option} {...props}>
      <Typography variant="body2">{children}</Typography>
    </components.Option>
  );
}
