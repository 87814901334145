import { config, ApiType, DATE_FORMATS } from '@gbm/onboarding-sdk-utils';
import { formatDate, parseDate, removeSpaces } from '../../utils/helpers';
import { completeErrorAdapter, defaultSuccessAdapter } from '../adapters';
import { HTTP_VERBS } from '../types';
import request from '../request';
import { ApiOptions, DEFAULT_API_OPTIONS } from '../models';
import {
  OpeningLiteProviderProps,
  OpeningLiteStepType,
} from '../../app/providers/opening-lite/types';
import {
  countriesAdapter,
  statesAdapter,
  partyAdapter,
  phoneAdapter,
  curpAdapter,
  zipCodeAdapter,
} from './adapters';
import {
  AddressType,
  CurpRequestType,
  GeolocationType,
  PartyDataType,
  PhoneType,
} from './types';

export default class OpeningLite {
  private apiUrl: ApiType;

  private accessToken: string;

  private onRefreshSession: () => void;

  private openingLiteRequest(
    url: string,
    options: Partial<ApiOptions> = DEFAULT_API_OPTIONS,
    successAdapter: Function = defaultSuccessAdapter,
  ) {
    return request(
      url,
      this.onRefreshSession,
      this.accessToken,
      options,
      successAdapter,
      completeErrorAdapter,
    );
  }

  constructor({
    environment,
    accessToken,
    onRefreshSession,
  }: OpeningLiteProviderProps) {
    const {
      onboarding: { apiUrl },
    } = config(environment);
    this.apiUrl = apiUrl;
    this.accessToken = accessToken;
    this.onRefreshSession = onRefreshSession;
  }

  async getOpeningStatus() {
    const url = `${this.apiUrl.v3}/opening-status`;
    return this.openingLiteRequest(url);
  }

  async getCountries() {
    const url = `${this.apiUrl.v1}/catalogs/countries`;
    return this.openingLiteRequest(url, {}, countriesAdapter);
  }

  async getStates(countryCode: string) {
    const url = `${this.apiUrl.v1}/catalogs/countries/${countryCode}/states`;
    return this.openingLiteRequest(url, {}, statesAdapter);
  }

  async getDataFromCurp(curp: string) {
    const url = `${this.apiUrl.v1}/curp/${curp}`;
    return this.openingLiteRequest(url);
  }

  async getCurpFromData(data: CurpRequestType) {
    const {
      firstName,
      lastName,
      secondLastName,
      gender,
      birthState,
      birthDate,
    } = data;
    const genderValue = gender.toLowerCase();
    const params = `?first_name=${firstName}&first_last_name=${lastName}&second_last_name=${secondLastName}&gender=${genderValue}&birth_state=${birthState}&birth_date=${birthDate}`;
    const url = `${this.apiUrl.v1}/curp${params}`;
    return this.openingLiteRequest(url, {}, curpAdapter);
  }

  async getPartyCurp(partyId: string) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/identifiers/curp`;
    return this.openingLiteRequest(url, {}, curpAdapter);
  }

  async saveParty(data: Partial<PartyDataType>, email: string) {
    const url = `${this.apiUrl.v1}/parties`;
    const cleanBirthDate = removeSpaces(data.birth_date as string);
    const birthDateValue = formatDate(
      parseDate(cleanBirthDate, DATE_FORMATS.ddMMyyyy),
      DATE_FORMATS.yyyyMMdd,
    );
    const body = { ...data, email, birth_date: birthDateValue };
    return this.openingLiteRequest(url, {
      body,
      method: HTTP_VERBS.post,
    });
  }

  async getParty() {
    const url = `${this.apiUrl.v1}/parties`;
    return this.openingLiteRequest(url, {}, partyAdapter);
  }

  async savePartyPhone(partyId: string, phone: PhoneType) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/telephones`;
    return this.openingLiteRequest(url, {
      method: HTTP_VERBS.post,
      body: {
        ...phone,
        phone_number: removeSpaces(phone.phone_number),
      },
    });
  }

  async getPartyPhone(partyId: string) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/telephones`;
    return this.openingLiteRequest(url, {}, phoneAdapter);
  }

  async updatePartyPhone(partyId: string, phone: PhoneType) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/telephones/${phone.id}`;
    return this.openingLiteRequest(url, {
      method: HTTP_VERBS.put,
      body: {
        ...phone,
        phone_number: removeSpaces(phone.phone_number),
      },
    });
  }

  async saveOpeningLiteStep(partyId: string, step: OpeningLiteStepType) {
    const url = `${this.apiUrl.v2}/parties/${partyId}/opening-step`;
    return this.openingLiteRequest(url, {
      method: HTTP_VERBS.post,
      body: { step },
    });
  }

  async savePartyAddress(partyId: string, address: AddressType) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/addresses`;
    return this.openingLiteRequest(url, {
      method: HTTP_VERBS.post,
      body: address,
    });
  }

  async getPartyAddress(partyId: string) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/addresses`;
    return this.openingLiteRequest(url);
  }

  async updatePartyAddress(
    partyId: string,
    addressId: string,
    address: AddressType,
  ) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/addresses/${addressId}`;
    return this.openingLiteRequest(url, {
      method: HTTP_VERBS.put,
      body: address,
    });
  }

  async getZipLocationData(zipLocationId: string) {
    const url = `${this.apiUrl.v1}/catalogs/zip-locations/${zipLocationId}`;
    return this.openingLiteRequest(url);
  }

  async getDataFromZipCode(zipCode: string) {
    const url = `${this.apiUrl.v1}/catalogs/countries/MEX/zip-locations/${zipCode}`;
    return this.openingLiteRequest(url, {}, zipCodeAdapter);
  }

  async saveContract(
    partyId: string,
    geolocation?: GeolocationType,
    mfaCode?: string,
    createTradingAccount?: boolean,
  ) {
    const url = `${this.apiUrl.v3}/parties/${partyId}/contracts`;
    let headers = {};

    if (geolocation) {
      headers = {
        ...headers,
        'Device-Latitude': geolocation.latitude,
        'Device-Longitude': geolocation.longitude,
      };
    }

    if (mfaCode) {
      headers = {
        ...headers,
        'X-MFA-CODE': mfaCode,
      };
    }

    return this.openingLiteRequest(url, {
      headers,
      body: {
        create_trading_account: createTradingAccount,
      },
      method: HTTP_VERBS.post,
    });
  }

  async getContracts() {
    const url = `${this.apiUrl.v1}/contracts`;
    return this.openingLiteRequest(url);
  }

  async getHasContracts() {
    const url = `${this.apiUrl.v1}/contracts/summary`;
    return this.openingLiteRequest(url);
  }

  async getSignContractLink(partyId: string, contractId: string) {
    const url = `${this.apiUrl.v1}/parties/${partyId}/contracts/${contractId}/signature-links`;
    return this.openingLiteRequest(url);
  }

  async getContractAccount(contractId: string) {
    const url = `${this.apiUrl.v2}/contracts/${contractId}/accounts`;
    return this.openingLiteRequest(url);
  }
}
