import { ReactNode, useState } from 'react';
import { Notification } from '@gbm/starman-next';
import { useTranslation, Trans } from 'react-i18next';

import { translations } from '@gbm/onboarding-sdk-utils';
import styles from './initial-screen.module.scss';

type NotificationsProps = {
  isPending?: boolean;
  isRejected?: boolean;
  rejectedDocumentsMessage?: ReactNode;
  hasDocumentTampered?: boolean;
  hasReachedAttempts?: boolean;
  reachedAttemptsTime?: string;
};

const {
  upgrade: { upgrade },
} = translations;

export default function Notifications({
  isPending,
  isRejected,
  rejectedDocumentsMessage,
  hasDocumentTampered,
  hasReachedAttempts,
  reachedAttemptsTime = '',
}: NotificationsProps) {
  const { t } = useTranslation();
  const [showHasDocumentTamperedAlert] = useState(hasDocumentTampered);
  const [showHasReachedAttemptsAlert] = useState(hasReachedAttempts);
  const hasDocumentTamperedAlert =
    hasDocumentTampered && showHasDocumentTamperedAlert;
  const hasReachedAttemptsAlert =
    hasReachedAttempts && showHasReachedAttemptsAlert;
  const [hasAlert, setHasAlert] = useState(
    isPending ||
      isRejected ||
      rejectedDocumentsMessage ||
      hasDocumentTamperedAlert ||
      hasReachedAttemptsAlert,
  );

  if (!hasAlert) {
    return null;
  }

  return (
    <div className={styles.notification}>
      {isPending || isRejected ? (
        <Notification
          onClose={() => setHasAlert(false)}
          widthSize="fluid"
          kind={isPending ? 'info' : 'warning'}
          title={t(upgrade.monthlyTitle)}
          description={
            isPending ? (
              t(upgrade.pendingAlert)
            ) : (
              <Trans
                i18nKey={upgrade.rejectedAlert}
                components={[
                  <strong className={styles['notification--white']}></strong>,
                ]}
              />
            )
          }
        />
      ) : null}
      {!isPending && !isRejected && !!rejectedDocumentsMessage ? (
        <Notification
          onClose={() => setHasAlert(false)}
          widthSize="fluid"
          kind="info"
          title={t(upgrade.monthlyTitle)}
          description={rejectedDocumentsMessage}
        />
      ) : null}
      {hasDocumentTamperedAlert ? (
        <Notification
          onClose={() => setHasAlert(false)}
          widthSize="fluid"
          kind="error"
          title={t(upgrade.documentTamperedTitle)}
          description={t(upgrade.documentTamperedDescription)}
        />
      ) : null}
      {hasReachedAttemptsAlert ? (
        <Notification
          onClose={() => setHasAlert(false)}
          widthSize="fluid"
          kind="warning"
          title={t(upgrade.reachedAttemptsTitle)}
          description={
            <Trans
              i18nKey={upgrade.reachedAttemptsDescription}
              values={{ time: reachedAttemptsTime }}
              components={[
                <strong className={styles['notification--white']}></strong>,
              ]}
            />
          }
        />
      ) : null}
    </div>
  );
}
