import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrowCircleRight,
  Button,
  CheckCircle,
  Typography,
} from '@gbm/starman-next';

import {
  UPGRADE_IDS,
  translations,
  TRACKING_EVENTS,
  TRACKING_NAMES,
} from '@gbm/onboarding-sdk-utils';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@gbm/onboarding-sdk-ui-components';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import { useUpgradeProvider } from '../../../providers/upgrade';
import { UPGRADE_STATUSES } from '../../../providers/upgrade/types';
import styles from './contract-upgrade.module.scss';

const {
  buttons,
  upgrade: { contractStatus },
} = translations;

export default function Success() {
  const { t } = useTranslation();
  const { handleExit, configuration } = useUpgradeProvider();
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  useEffect(() => {
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.upgradeStatus,
      status: UPGRADE_STATUSES.upgraded,
    });
  }, []);

  return (
    <div id={UPGRADE_IDS.viewSuccess}>
      <Modal show>
        <ModalHeader onClose={handleExit} />
        <ModalContent>
          <div className={styles['success-title']}>
            <Typography variant="h5">
              {t(contractStatus.successUpgrade)}
            </Typography>
          </div>
          <div className={styles['success-icon']}>
            <CheckCircle
              size={styles['icon-size']}
              color={styles['icon-color--success']}
            />
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            size="fluid"
            id={UPGRADE_IDS.btnSuccess}
            kind="primary"
            onClick={configuration.onCompleted ?? handleExit}
            icon={<ArrowCircleRight size={styles['icon-size']} weight="fill" />}
          >
            {t(buttons.understood)}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
