import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InputFile as InputFileStarman,
  InputFileStatus,
  InputFileStatusType,
} from '@gbm/starman-next';
import isEmpty from 'lodash/isEmpty';

import {
  CompressImageCallbackProps,
  IMAGES,
  IMAGES_PDF,
  IMAGE_MAX_HEIGHT_IN_PIXELS,
  IMAGE_MAX_WIDTH_IN_PIXELS,
  IMAGE_MIN_WIDTH_IN_PIXELS,
  IMAGE_QUALITY_COMPRESSION,
  MAX_FILE_SIZE,
  compressImage,
  i18n,
  translations,
} from '@gbm/onboarding-sdk-utils';

export type InputFileProps = {
  currentDocument: Blob;
  onUploadDocument: (data: CompressImageCallbackProps) => void;
  onChangeDocument: () => void;
  errorMessage?: ReactNode;
};

const { inputs, links } = translations;

export default function InputFile({
  currentDocument,
  onUploadDocument,
  onChangeDocument,
  errorMessage,
}: InputFileProps) {
  const { t } = useTranslation(undefined, { i18n });
  const [status, setStatus] = useState<InputFileStatusType>(
    InputFileStatus.default,
  );
  const message = useMemo(() => {
    switch (status) {
      case InputFileStatus.processing:
        return t(inputs.uploadingCaption);
      case InputFileStatus.success:
        return t(inputs.uploadSuccess);
      case InputFileStatus.error:
        return errorMessage;
      case InputFileStatus.default:
      default:
        return t(inputs.docInputText);
    }
  }, [status]);

  useEffect(() => {
    if (!currentDocument) {
      handleChangeDocument();
    }
  }, [currentDocument]);

  const handleChangeDocument = () => {
    setStatus(InputFileStatus.default);
    onChangeDocument();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || isEmpty(e.target.files)) {
      return;
    }

    const file = e.target.files[0];

    if (file.size > MAX_FILE_SIZE) {
      setStatus(InputFileStatus.error);
      return;
    }

    setStatus(InputFileStatus.processing);

    if (IMAGES.includes(file.type)) {
      compressImage(
        file,
        IMAGE_MAX_WIDTH_IN_PIXELS,
        IMAGE_MAX_HEIGHT_IN_PIXELS,
        IMAGE_QUALITY_COMPRESSION,
        0,
        (result) => {
          setStatus(InputFileStatus.success);
          onUploadDocument(result);
        },
        IMAGE_MIN_WIDTH_IN_PIXELS,
      );
    } else if (IMAGES_PDF.includes(file.type)) {
      setStatus(InputFileStatus.success);
      onUploadDocument({ file, extension: 'pdf' });
    }
  };

  return (
    <InputFileStarman
      accept={IMAGES_PDF}
      linkContent={t(
        status === InputFileStatus.error ? links.retry : links.change,
      )}
      onLinkClick={handleChangeDocument}
      onChange={handleInputChange}
      status={status}
    >
      {message}
    </InputFileStarman>
  );
}
