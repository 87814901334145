import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  useNotificationProvider,
  NOTIFICATION_TIMEOUT,
} from '@gbm/onboarding-sdk-ui-components';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getContractAccount,
  selectAccount,
  selectErrors,
  clearErrors,
} from '../../store/opening-lite';
import { OpeningLiteProviderProps } from '../../providers/opening-lite/types';
import { useContract } from '../useContract';
import { REDUCER_STATUS } from '../../store/types';
import { OpeningLiteStateType } from '../../store/opening-lite/types';

type UseContractAccountType = Pick<OpeningLiteStateType, 'account'> & {
  isLoading: boolean;
  isRejected: boolean;
  isFulfilled: boolean;
  isResolved: boolean;
  isEmpty: boolean;
  fetch: () => void;
};

export default function useContractAccounts(
  config: OpeningLiteProviderProps,
): UseContractAccountType {
  const dispatch = useAppDispatch();
  const { contract } = useContract(config);
  const { showNotification } = useNotificationProvider();
  const account = useAppSelector(selectAccount);
  const errors = useAppSelector(selectErrors);
  const isLoading = account.status === REDUCER_STATUS.pending;
  const isRejected = account.status === REDUCER_STATUS.rejected;
  const isResolved = account.status === REDUCER_STATUS.resolved;
  const isEmptyAccount = isEmpty(account.data);
  const isFulfilled =
    account.status === REDUCER_STATUS.resolved && !isEmptyAccount;

  const fetch = () => {
    if (isEmptyAccount && !isLoading) {
      dispatch(
        getContractAccount({ config, contractId: contract.data.contract_id }),
      );
    }
  };

  useEffect(() => {
    if (!isEmpty(errors.account)) {
      showNotification({
        description: errors.account?.message,
        kind: 'error',
      });
      setTimeout(() => {
        dispatch(clearErrors());
      }, NOTIFICATION_TIMEOUT);
    }
  }, [errors.account]);

  return {
    account,
    fetch,
    isLoading,
    isRejected,
    isResolved,
    isFulfilled,
    isEmpty: isEmptyAccount,
  };
}
