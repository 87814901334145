import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, Typography, ButtonLink } from '@gbm/starman-next';

import { Fade } from '@gbm/onboarding-sdk-ui-components';
import {
  AUTH_IDS,
  config,
  ENVIRONMENTS,
  translations,
} from '@gbm/onboarding-sdk-utils';
import { useBreakpoints } from '@gbm/onboarding-sdk-hooks';
import iconMail from '@gbm/onboarding-sdk-ui-components/assets/images/icons/atm-icn-mail.svg';
import { Layout } from '../../../components/auth';
import { useAuthProvider } from '../../../providers/auth';
import styles from './sign-up-confirmation.module.scss';

const {
  auth: { signUpConfirmation },
  links,
  buttons,
} = translations;
const { recaptchaKey } = config(ENVIRONMENTS.production);

export default function SignUpConfirmation() {
  const { t } = useTranslation();
  const {
    handleResendCode: onResendCode,
    handleSignOut,
    configuration,
  } = useAuthProvider();
  const { isSm } = useBreakpoints();
  const [resend, setResend] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleResendCode = async () => {
    setIsLoading(true);
    await onResendCode(recaptchaValue as string);
    setResend(false);
    setRecaptchaValue(null);
    setIsLoading(false);
  };

  return (
    <Fade>
      <Layout
        variant="center"
        onClose={handleSignOut}
        images={configuration.images}
      >
        <div className={styles.container} id={AUTH_IDS.viewConfirmation}>
          <div className={styles.content}>
            <img
              src={iconMail}
              alt={t(signUpConfirmation.title)}
              className={styles.icon}
            />
            <Typography variant={isSm ? 'h5' : 'h2'} weight="semibold">
              {t(signUpConfirmation.title)}
            </Typography>
            <Typography variant={isSm ? 'body2' : 'body1'}>
              {t(signUpConfirmation.sent)}
            </Typography>
            {resend ? (
              <div className={styles.recaptcha}>
                <ReCAPTCHA
                  onChange={(token) => setRecaptchaValue(token)}
                  onExpired={() => setRecaptchaValue(null)}
                  sitekey={recaptchaKey}
                  size="normal"
                  className={styles['recaptcha-item']}
                />
                <Button
                  testId="resend-button"
                  disabled={!recaptchaValue}
                  loading={isLoading}
                  kind="primary"
                  onClick={handleResendCode}
                >
                  {t(buttons.resend)}
                </Button>
              </div>
            ) : (
              <div className={styles.link}>
                <ButtonLink underline="always" onClick={() => setResend(true)}>
                  <Typography variant="small" weight="semibold">
                    {t(links.resendEmail)}
                  </Typography>
                </ButtonLink>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </Fade>
  );
}
