import { useEffect, useState } from 'react';
import {
  ArrowCircleRight,
  Button,
  ButtonLink,
  Checkbox,
  Typography,
} from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import {
  ENVIRONMENTS,
  GENERIC_ERROR,
  LEGAL_DOCUMENT_TYPES,
  PRIVACY_POLICY_DOCUMENT_NAME,
  TRACKING_EVENTS,
  TRACKING_NAMES,
  config,
  getErrorMessage,
  translations,
} from '@gbm/onboarding-sdk-utils';
import {
  ExternalLink,
  Markdown,
  MfaChallenge,
  Modal,
  ModalContent,
  ModalHeader,
  useNotificationProvider,
} from '@gbm/onboarding-sdk-ui-components';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';

import { useUpgradeProvider } from '../../../providers/upgrade';
import { getMarkdownDataFromLegalDoc } from '../../../../utils/helpers';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getChallenge, selectChallenge } from '../../../store/opening-lite';
import { REDUCER_STATUS } from '../../../store/types';
import { HTTP_STATUS_CODES } from '../../../../api/types';
import { useAuthApi } from '../../../../api/auth/use-auth-api';
import { useLegalDocuments } from '../../../hooks';
import styles from './initial-screen.module.scss';

type StartProps = {
  alreadyStarted: boolean;
  loading: boolean;
  onSubmit: () => void;
};

const {
  buttons,
  upgrade: { upgrade },
  links: { privacyPolicy },
} = translations;
const { legalDocsBaseUrl } = config(ENVIRONMENTS.production);

export default function Start({
  alreadyStarted,
  loading,
  onSubmit,
}: StartProps) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { configuration } = useUpgradeProvider();
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );
  const { showNotification } = useNotificationProvider();
  const challenge = useAppSelector(selectChallenge);
  const authApi = useAuthApi(configuration.environment);
  const { signDocument } = useLegalDocuments(configuration);
  const [isIdentityCheckAccepted, setIsIdentityCheckAccepted] =
    useState(alreadyStarted);
  const [isPrivacyCheckAccepted, setIsPrivacyCheckAccepted] =
    useState(alreadyStarted);
  const [displayLegalDocument, setDisplayLegalDocument] = useState(false);
  const [legalDocument, setLegalDocument] = useState<string>();
  const [isFetchingChallenge, setIsFetchingChallenge] = useState(false);
  const [showMfaChallenge, setShowMfaChallenge] = useState(false);
  const [isValidatingChallenge, setIsValidatingChallenge] = useState(false);

  useEffect(() => {
    if (!isEmpty(challenge.data) && isFetchingChallenge) {
      setIsFetchingChallenge(false);
      setShowMfaChallenge(true);
    }
  }, [challenge.data, isFetchingChallenge]);

  const handleRequestMfaChallenge = () => {
    setIsFetchingChallenge(true);
    dispatch(
      getChallenge({
        config: configuration,
      }),
    );
  };

  const handleDisplayLegalDoc = async () => {
    setDisplayLegalDocument(true);
    /* istanbul ignore else */
    if (!legalDocument) {
      const data = await getMarkdownDataFromLegalDoc(
        legalDocsBaseUrl,
        PRIVACY_POLICY_DOCUMENT_NAME,
      );

      if (data) {
        setLegalDocument(data);
      } else {
        setDisplayLegalDocument(false);
        showNotification({
          kind: 'error',
          description: GENERIC_ERROR,
        });
      }
    }
  };

  const handleContinue = async (mfaCode: string) => {
    setIsValidatingChallenge(true);
    const { status, err } = await authApi.validateChallenge(
      configuration.accessToken,
      mfaCode,
    );

    if (status !== HTTP_STATUS_CODES.ok) {
      mixpanel.track(TRACKING_EVENTS.screenViewed, {
        name: TRACKING_NAMES.upgradeMfa,
        success: false,
        error: getErrorMessage(err.response),
      });
      showNotification({
        description: getErrorMessage(err.response),
        kind: 'error',
      });
      setIsValidatingChallenge(false);
      return;
    }

    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.upgradeMfa,
      success: true,
    });

    const docSigned = await signDocument(
      LEGAL_DOCUMENT_TYPES.personalIdDataVerificationUpgrade,
    );

    if (!docSigned) {
      showNotification({
        kind: 'error',
        description: GENERIC_ERROR,
      });
      setIsValidatingChallenge(false);
      return;
    }

    onSubmit();
  };

  return (
    <div className={styles.start}>
      <div className={styles['start-title']}>
        <Typography variant="h4">{t(upgrade.start)}</Typography>
      </div>
      <div className={styles['start-checks']}>
        <Checkbox
          id="identityCheck"
          data-testid="identityCheck"
          checked={isIdentityCheckAccepted}
          onChange={
            !alreadyStarted
              ? () => setIsIdentityCheckAccepted(!isIdentityCheckAccepted)
              : undefined
          }
        >
          <Typography variant="body2">{t(upgrade.identityCheck)}</Typography>
        </Checkbox>
        <Checkbox
          id="privacyCheck"
          data-testid="privacyCheck"
          checked={isPrivacyCheckAccepted}
          onChange={
            !alreadyStarted
              ? () => setIsPrivacyCheckAccepted(!isPrivacyCheckAccepted)
              : undefined
          }
        >
          <div className={styles['start-privacy-check']}>
            <Typography variant="body2">{t(upgrade.privacyCheck)}</Typography>
            &nbsp;
            {configuration.urls?.privacyPolicy ? (
              <ExternalLink href={configuration?.urls?.privacyPolicy}>
                <div className={styles['start-link']}>
                  <Typography variant="body2">
                    {t(upgrade.privacyCheck2)}
                  </Typography>
                </div>
              </ExternalLink>
            ) : (
              <ButtonLink underline="always" onClick={handleDisplayLegalDoc}>
                <div className={styles['start-link']}>
                  <Typography variant="body2">
                    {t(upgrade.privacyCheck2)}
                  </Typography>
                </div>
              </ButtonLink>
            )}
            &nbsp;
            <Typography variant="body2">{t(upgrade.privacyCheck3)}</Typography>
          </div>
        </Checkbox>
      </div>
      <div className={styles['start-button']}>
        <Button
          size="fluid"
          disabled={!isPrivacyCheckAccepted || !isIdentityCheckAccepted}
          loading={loading || isFetchingChallenge}
          testId="upgrade-button"
          kind="primary--inverted"
          onClick={alreadyStarted ? onSubmit : handleRequestMfaChallenge}
          icon={<ArrowCircleRight size={styles['icon-size']} weight="fill" />}
        >
          {t(alreadyStarted ? buttons.continue : buttons.upgrade)}
        </Button>
      </div>
      <MfaChallenge
        show={showMfaChallenge}
        onClose={() => setShowMfaChallenge(false)}
        onResend={handleRequestMfaChallenge}
        onSubmit={handleContinue}
        loading={
          challenge.status === REDUCER_STATUS.pending ||
          loading ||
          isValidatingChallenge
        }
        mfaPreference={challenge.data.mfaPreference}
        email={configuration.email}
      />
      <Modal show={displayLegalDocument} fullScreen>
        <ModalHeader onClose={() => setDisplayLegalDocument(false)} />
        <ModalContent loading={!legalDocument}>
          <Typography variant="body1">{t(privacyPolicy)}</Typography>
          <Markdown>{legalDocument as string}</Markdown>
        </ModalContent>
      </Modal>
    </div>
  );
}
