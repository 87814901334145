import { ReactNode } from 'react';
import { motion } from 'framer-motion';
import { Typography, WarningCircle, Warning } from '@gbm/starman-next';
import clsx from 'clsx';

import { useBreakpoints } from '@gbm/onboarding-sdk-hooks';
import { Environment } from '@gbm/onboarding-sdk-utils';
import { AppLayout } from '../app-layout';
import { Chat } from '../chat';
import logo from '../../assets/images/logos/GBM-logo.svg';
import logoBlack from '../../assets/images/logos/GBM-logo-black.svg';
import styles from './full-alert.module.scss';

export type FullAlertProps = {
  title: ReactNode;
  description?: ReactNode;
  actions?: ReactNode;
  actionsEnd?: boolean;
  withChat?: boolean;
  environment: Environment;
  kind?: 'warn' | 'error';
};

export default function FullAlert({
  title,
  description,
  actions,
  withChat = true,
  actionsEnd = true,
  environment,
  kind = 'warn',
}: FullAlertProps) {
  const { isSm } = useBreakpoints();
  const Icon = kind === 'error' ? WarningCircle : Warning;

  return (
    <AppLayout>
      <motion.div
        className={styles.container}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <aside className={styles.aside}>
          <div className={styles['aside-content']}>
            <img src={logo} alt="GBM" className={styles.logo} />
          </div>
        </aside>
        <div className={styles.content}>
          <div className={styles['content-logo']}>
            <img src={logoBlack} alt="GBM" className={styles.logo} />
          </div>
          <div className={styles.title}>
            <div className={styles.text}>
              <Typography variant={isSm ? 'h3' : 'h2'}>{title}</Typography>
            </div>
            <div
              className={clsx(styles.icon, {
                [styles['icon--warn']]: kind === 'warn',
                [styles['icon--error']]: kind === 'error',
              })}
            >
              <Icon
                size={styles['icon-size']}
                weight="fill"
                color={
                  kind === 'error'
                    ? styles['icon-color-error']
                    : styles['icon-color-warn']
                }
              />
            </div>
          </div>
          {description ? (
            <div className={styles.description}>
              <Typography variant={isSm ? 'body2' : 'body1'}>
                {description}
              </Typography>
            </div>
          ) : null}
          {actions ? (
            <div
              className={clsx(styles.actions, {
                [styles['actions--end']]: actionsEnd,
              })}
            >
              {actions}
            </div>
          ) : null}
          {withChat && (
            <div className={styles.chat}>
              <Chat environment={environment} />
            </div>
          )}
        </div>
      </motion.div>
    </AppLayout>
  );
}
