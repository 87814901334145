import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { motion } from 'framer-motion';
import { Button, ButtonLink, Typography } from '@gbm/starman-next';

import {
  List,
  FormRow,
  FormSection,
  InputFile,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@gbm/onboarding-sdk-ui-components';
import {
  CompressImageCallbackProps,
  translations,
} from '@gbm/onboarding-sdk-utils';
import { useAppSelector } from '../../../store';
import { selectErrors } from '../../../store/upgrade';
import styles from './proof-of-address.module.scss';

type UploadProps = {
  fileData: CompressImageCallbackProps | null;
  setFileData: Dispatch<SetStateAction<CompressImageCallbackProps | null>>;
  setIsSubmitted: Dispatch<SetStateAction<boolean>>;
};

const {
  buttons,
  upgrade: { proofOfAddress: proofOfAddressMsg },
} = translations;

export default function Upload({
  fileData,
  setFileData,
  setIsSubmitted,
}: UploadProps) {
  const { t } = useTranslation();
  const errors = useAppSelector(selectErrors);
  const [displayAcceptedBills, setDisplayAcceptedBills] = useState(false);
  const validProofOfAddress = [
    { key: 'lightBill', label: t(proofOfAddressMsg.lightBill) },
    { key: 'tvBill', label: t(proofOfAddressMsg.tvBill) },
    { key: 'gasBill', label: t(proofOfAddressMsg.gasBill) },
    { key: 'waterBill', label: t(proofOfAddressMsg.waterBill) },
    { key: 'phoneBill', label: t(proofOfAddressMsg.phoneBill) },
  ];

  useEffect(() => {
    if (!isEmpty(errors.proofOfAddress)) {
      handleChangeDocButton();
    }
  }, [errors.proofOfAddress]);

  const handleChangeDocButton = () => {
    setFileData(null);
    setIsSubmitted(false);
  };

  return (
    <motion.div
      className={styles.options}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <FormSection
        title={t(proofOfAddressMsg.bill)}
        description={t(proofOfAddressMsg.billDescriptionTwo)}
      >
        <FormRow>
          <InputFile
            currentDocument={fileData?.file as Blob}
            onUploadDocument={setFileData}
            errorMessage={t(proofOfAddressMsg.errorMessage)}
            onChangeDocument={handleChangeDocButton}
          />
          <div className={styles.empty} />
        </FormRow>
        <FormRow className={styles.link}>
          <ButtonLink
            underline="always"
            onClick={() => setDisplayAcceptedBills(true)}
          >
            <Typography variant="small" weight="semibold">
              {t(proofOfAddressMsg.whatBillsAreAccepted)}
            </Typography>
          </ButtonLink>
        </FormRow>
      </FormSection>
      <Modal show={displayAcceptedBills}>
        <ModalHeader
          className={styles['modal-header']}
          onClose={() => setDisplayAcceptedBills(false)}
        />
        <ModalContent>
          <div className={styles['modal-title']}>
            <Typography variant="h5">
              {t(proofOfAddressMsg.whatBillsAreAccepted)}
            </Typography>
          </div>
          <div className={styles['modal-list']}>
            <List labels={validProofOfAddress} />
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            kind="primary"
            size="fluid"
            onClick={() => setDisplayAcceptedBills(false)}
          >
            {t(buttons.ready)}
          </Button>
        </ModalFooter>
      </Modal>
    </motion.div>
  );
}
