import { PropsWithChildren } from 'react';

import bg01 from '@gbm/onboarding-sdk-ui-components/assets/images/atm-bg-img-01.jpg';
import bg02 from '@gbm/onboarding-sdk-ui-components/assets/images/atm-bg-img-02.jpg';
import bg03 from '@gbm/onboarding-sdk-ui-components/assets/images/atm-bg-img-03.jpg';
import bg04 from '@gbm/onboarding-sdk-ui-components/assets/images/atm-bg-img-04.jpg';
import { AuthImages } from '../../../providers/auth/types';

export type LayoutVariant = 'left' | 'center';

export type LayoutProps = PropsWithChildren<{
  variant?: LayoutVariant;
  onClose?: () => void;
  images?: AuthImages;
}>;

export const DEFAULT_BACKGROUND_IMAGES: Array<string> = [
  bg01,
  bg02,
  bg03,
  bg04,
];
