import { useEffect, useState } from 'react';

import { getUserEmail } from '@gbm/onboarding-sdk-utils';

type SessionType = {
  email: string | null;
  clientId: string | null;
  appName: string | null;
  accessToken: string | null;
  idToken: string | null;
  refreshToken: string | null;
  redirectUrl: string | null;
  ivRedirectUrl: string | null;
  backRedirectUrl: string | null;
  skipInitialScreen: boolean;
};
type UseSessionType = {
  session?: SessionType;
  hasSession: boolean;
  refreshSession: (accessToken: string) => void;
  clearSession: () => void;
};

export default function useSession(): UseSessionType {
  const [session, setSession] = useState<SessionType>();
  const hasSession =
    !!session?.clientId &&
    !!session?.appName &&
    !!session?.accessToken &&
    !!session?.idToken &&
    !!session?.email;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const clientId =
      params.get('client_id') || localStorage.getItem('clientId');
    const appName = params.get('app_name') || localStorage.getItem('appName');
    const accessToken =
      params.get('access_token') || localStorage.getItem('accessToken');
    const idToken = params.get('id_token') || localStorage.getItem('idToken');
    const refreshToken =
      params.get('refresh_token') || localStorage.getItem('refreshToken');
    const redirectUrl =
      params.get('redirect_url') || localStorage.getItem('redirectUrl');
    const ivRedirectUrl =
      params.get('iv_redirect_url') || localStorage.getItem('ivRedirectUrl');
    const backRedirectUrl =
      params.get('back_redirect_url') ||
      localStorage.getItem('backRedirectUrl');
    const skipInitialScreen =
      params.get('skip_initial_screen') ||
      localStorage.getItem('skipInitialScreen');
    const signed = params.get('signed') ? '?signed=true' : '';
    const mainUrl = window.location.href.split('?')[0];

    window.history.replaceState({}, '', `${mainUrl}${signed}`);

    if (!clientId) {
      console.warn('Missing query param: client_id');
      return;
    }

    if (!appName) {
      console.warn('Missing query param: app_name');
      return;
    }

    if (!accessToken) {
      console.warn('Missing query param: access_token');
      return;
    }

    if (!idToken) {
      console.warn('Missing query param: id_token');
      return;
    }

    const email = getUserEmail(idToken as string);

    if (!email) {
      console.warn('Invalid query param: id_token');
      return;
    }

    localStorage.setItem('clientId', clientId as string);
    localStorage.setItem('appName', appName as string);
    localStorage.setItem('accessToken', accessToken as string);
    localStorage.setItem('idToken', idToken as string);
    refreshToken && localStorage.setItem('refreshToken', refreshToken);
    email && localStorage.setItem('email', email);
    redirectUrl && localStorage.setItem('redirectUrl', redirectUrl);
    ivRedirectUrl && localStorage.setItem('ivRedirectUrl', ivRedirectUrl);
    backRedirectUrl && localStorage.setItem('backRedirectUrl', backRedirectUrl);
    skipInitialScreen &&
      localStorage.setItem('skipInitialScreen', skipInitialScreen);

    setSession({
      clientId,
      appName,
      accessToken,
      idToken,
      refreshToken,
      email,
      redirectUrl,
      ivRedirectUrl,
      backRedirectUrl,
      skipInitialScreen: Boolean(skipInitialScreen),
    });
  }, []);

  const clearSession = () => {
    localStorage.clear();
    setSession(undefined);
  };

  const refreshSession = (accessToken: string) => {
    if (session) {
      setSession({ ...session, accessToken });
    }
  };

  return {
    session,
    hasSession,
    refreshSession,
    clearSession,
  };
}
