import { PropsWithChildren } from 'react';

import styles from './app-layout.module.scss';

export default function AppLayout({ children }: PropsWithChildren<{}>) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
    </div>
  );
}
