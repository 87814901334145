import { HttpVerbsTypes, HTTP_VERBS } from './types';

export interface ApiOptions {
  method: HttpVerbsTypes;
  body?: Record<string, any>;
  headers: Record<string, any>;
}

export const DEFAULT_API_OPTIONS = {
  method: HTTP_VERBS.get,
  body: {},
  headers: {},
} as const;
