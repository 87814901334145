import { useMemo } from 'react';
import clsx from 'clsx';

import { CloseButton } from '@gbm/onboarding-sdk-ui-components';
import { generateSecureRandomNumber } from '@gbm/onboarding-sdk-utils';
import logoPositive from '@gbm/onboarding-sdk-ui-components/assets/images/logos/atm-logo-positive-gbm.svg';
import logoNegative from '@gbm/onboarding-sdk-ui-components/assets/images/logos/atm-logo-negative-gbm.svg';
import { LogoMarks } from '../../LogoMarks';
import { LayoutProps, DEFAULT_BACKGROUND_IMAGES } from './types';
import styles from './layout.module.scss';

export default function Layout({
  children,
  variant = 'left',
  onClose,
  images,
}: LayoutProps) {
  const currentBackground = useMemo(() => {
    const backgroundImages =
      images?.backgroundImages || DEFAULT_BACKGROUND_IMAGES;
    return backgroundImages[
      generateSecureRandomNumber(0, backgroundImages.length - 1, true)
    ];
  }, [images?.backgroundImages]);

  if (variant === 'center') {
    return (
      <>
        <div className={styles.mark}>
          <LogoMarks
            variant="dark"
            darkLogo={images?.darkLogo || logoNegative}
          />
        </div>
        <div className={styles.close}>
          <CloseButton kind="primary" onClick={onClose} testId="close-button" />
        </div>
        <div className={clsx(styles.container, styles['container--center'])}>
          <div className={styles.content}>{children}</div>
        </div>
      </>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
      <div className={styles.image}>
        <div
          className={styles['image--animated']}
          style={{
            background: `url(${currentBackground}) center center no-repeat`,
          }}
        />
        <div className={styles.mark}>
          <LogoMarks
            variant="light"
            lightLogo={images?.lightLogo || logoPositive}
          />
        </div>
      </div>
    </div>
  );
}
