import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { InputDate } from '@gbm/starman-next';

import { translations } from '@gbm/onboarding-sdk-utils';
import validations from './validations';
import { QuestionType } from '../../types';

type CalendarQuestionProps = {
  question: QuestionType;
  onChangeAnswer: (answer: string) => void;
};
const { inputs } = translations;

export default function CalendarQuestion({
  question,
  onChangeAnswer,
}: CalendarQuestionProps) {
  const { t } = useTranslation();
  const {
    control,
    watch,
    setValue,
    formState: { errors, isValidating },
  } = useFormContext();
  const answer = watch(question.id);

  useEffect(() => {
    if (answer && isEmpty(errors[question.id]) && !isValidating) {
      onChangeAnswer(answer);
    }
  }, [answer, errors, isValidating]);

  useEffect(() => {
    if (!isEmpty(question.response)) {
      setValue(question.id, question.response);
    }

    return () => {
      setValue(question.id, '');
    };
  }, []);

  return (
    <Controller
      control={control}
      name={question.id}
      rules={validations}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <InputDate
          id={question.id}
          label={question.text}
          placeholder={t(inputs.birthDatePlaceholder)}
          widthSize="fluid"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          status={error?.message ? 'error' : undefined}
          hintText={error?.message ? t(error?.message) : ''}
        />
      )}
    />
  );
}
