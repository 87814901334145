import { useTranslation } from 'react-i18next';

import {
  FormRow,
  ResumeSection,
  ResumeSectionItem,
} from '@gbm/onboarding-sdk-ui-components';
import { translations } from '@gbm/onboarding-sdk-utils';

type NationalityProps = {
  isLoading?: boolean;
  onEditClick?: () => void;
  birthCountries: string;
  citizenshipCountries: string;
  nationalityCountries: string;
};
const {
  inputs,
  upgrade: { nationality },
} = translations;

export default function Nationality({
  isLoading,
  onEditClick,
  birthCountries,
  citizenshipCountries,
  nationalityCountries,
}: NationalityProps) {
  const { t } = useTranslation();

  return (
    <ResumeSection
      title={t(nationality.title)}
      isLoading={isLoading}
      onEditClick={onEditClick}
    >
      <FormRow>
        <ResumeSectionItem
          label={t(inputs.birthCountry)}
          value={birthCountries}
          isLoading={isLoading}
        />
        <ResumeSectionItem
          label={t(inputs.citizenship)}
          value={citizenshipCountries}
          isLoading={isLoading}
        />
        <ResumeSectionItem
          label={t(inputs.nationality)}
          value={nationalityCountries}
          isLoading={isLoading}
        />
      </FormRow>
    </ResumeSection>
  );
}
