import { addDays, parseISO, format, Locale, parse, isBefore } from 'date-fns';
import decode, { JwtPayload } from 'jwt-decode';
import esLocale from 'date-fns/locale/es';
import axios from 'axios';
import { generar, GENERO, EstadoType } from 'curp-ts';
import has from 'lodash/has';
import flattenDeep from 'lodash/flattenDeep';
import difference from 'lodash/difference';
import parseInt from 'lodash/parseInt';
import isArray from 'lodash/isArray';
import { validate as uuidValidate } from 'uuid';
import heic2any from 'heic2any';
import isEmpty from 'lodash/isEmpty';
import { formatNumber } from '@gbm/utils';

import {
  getErrorMessage,
  GENERIC_ERROR,
  EMPTY_PARAM,
  DateFormatType,
  DATE_FORMATS,
  REGEX_VALIDATORS,
  CODES_STATES,
  CodeStatesKey,
  MockableFunction,
  IMAGE_MIN_WIDTH_IN_PIXELS,
  CompressImageCallbackProps,
} from '@gbm/onboarding-sdk-utils';
import { StateValueType } from '../app/store/types';
import {
  CurpRequestType,
  GENDERS,
  StateType,
  GeolocationType,
} from '../api/opening-lite/types';
import {
  LoremQuestionnaireRequestType,
  LoremQuestionnaireType,
  LoremQuestionType,
} from '../api/upgrade/types';
import { CountryName, countryListType } from '../app/store/upgrade/types';
import { ErrorType } from '../api/types';
import {
  AnswersDataType,
  AnswerType,
  QUESTION_ANSWER_TYPES,
  QUESTION_DATA_TYPES,
  QuestionDataType,
  QUESTIONNAIRE_NAME,
  QuestionnaireDataType,
  QuestionnaireNameType,
  QuestionType,
  RelatedBaseQuestions,
} from '../app/components/upgrade/financial-information/types';

export const makeError = (error: any): ErrorType => ({
  eventId: EMPTY_PARAM,
  code: EMPTY_PARAM,
  message: getErrorMessage(error),
  target: EMPTY_PARAM,
  details: {},
});

export const hasUpperCase = (value: string) =>
  REGEX_VALIDATORS.upperCase.test(value);

export const hasLowerCase = (value: string) =>
  REGEX_VALIDATORS.lowerCase.test(value);

export const hasNumber = (value: string) => REGEX_VALIDATORS.digit.test(value);

export const hasSpecialSymbol = (value: string) =>
  REGEX_VALIDATORS.specialChar.test(value);

export const isValidPassword = (password: string) =>
  REGEX_VALIDATORS.passwordPolicy.test(password);

export const hasRepeatedElements = (value: string) =>
  REGEX_VALIDATORS.repeatedElements.test(value);

export const hasSequentialElements = (value: string) =>
  REGEX_VALIDATORS.sequentialElements.test(value);

export const hasGbmWord = (value: string) => REGEX_VALIDATORS.gbm.test(value);

export const containsEmail = (value: string, email: string) => {
  if (value.length < 3) {
    return false;
  }

  const [user] = email.split('@');
  const splitted = user.split('+');
  let values: string[] = [];

  splitted.forEach((word) => {
    values = [...values, ...word.split('.')];
  });

  return !values
    .filter((word) => word.length > 2)
    .every((word) => !value.includes(word));
};

export const normalizeText = (value: string = '') =>
  value
    .normalize('NFD')
    .replace(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]"¡¿·/ºª';´¨]/g, '')
    .replace(/^\s+/g, '')
    .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, '$1$2')
    .normalize()
    .toUpperCase();

export const normalizeName = (value = '') =>
  value
    .normalize('NFD')
    .replace(/[!$%^&*()_+|~=`{}[:;<>?,@#\]"¡¿·ºª']/g, '')
    .replace(/^\s+/g, '')
    .normalize()
    .toUpperCase();

export const isoStringToDate = (date: string, addedDays: number = 1) =>
  addDays(parseISO(date), addedDays);

export const parseDate = (
  date: string,
  stringFormat: string,
  locale: Locale = esLocale,
) => parse(date, stringFormat, new Date(), { locale });

export const formatIsoDate = (
  date: string,
  stringFormat: DateFormatType,
  addedDays: number = 1,
  locale: Locale = esLocale,
) => format(isoStringToDate(date, addedDays), stringFormat, { locale });

export const formatDate = (
  date: Date,
  stringFormat: DateFormatType,
  locale: Locale = esLocale,
) => format(date, stringFormat, { locale });

export const removeSpaces = (value: string) => value.replace(/\s/g, '');

export const isBeforeDate = (value: string, dateToCompare: Date) => {
  try {
    const valueFormatted = removeSpaces(value);
    const date = parseDate(valueFormatted, DATE_FORMATS.ddMMyyyy);
    return isBefore(date, dateToCompare);
  } catch (e) {
    return false;
  }
};

export const getMarkdownDataFromLegalDoc = async (
  baseUrl: string,
  documentTitle: string,
  uri?: string,
) => {
  try {
    const { data } = await axios.get(uri || `${baseUrl}/${documentTitle}.md`);

    if (data) {
      return data;
    }

    return null;
  } catch (e) {
    return null;
  }
};

export const mask = (value: string | number, pattern: string) => {
  const stringValue = value.toString();
  let index = 0;
  // eslint-disable-next-line no-plusplus
  return pattern.replace(/#/g, () => stringValue[index++]);
};

export const generateCurp = (
  states: StateValueType<Array<StateType>>,
  data: CurpRequestType,
) => {
  const state = states.data.find((s) => s.key === data.birthState);
  const stateCode = state && CODES_STATES[state.label as CodeStatesKey];
  const info = {
    nombre: data.firstName,
    apellidoPaterno: data.lastName,
    apellidoMaterno: data.secondLastName,
    genero: data.gender === GENDERS.male ? GENERO.MASCULINO : GENERO.FEMENINO,
    fechaNacimiento: data.birthDate,
    estado: stateCode as EstadoType,
  };
  let curp = '';

  try {
    curp = generar(info);
  } catch (e) {
    curp = '';
  }

  return curp;
};

export const makeMockable = <Func extends MockableFunction>(mockedFunc: Func) =>
  mockedFunc as jest.MockedFunction<typeof mockedFunc>;

export const getUserId = (token: string) => {
  const decoded = token ? (decode(token) as JwtPayload) : {};
  return decoded.sub;
};

export const generateRfc = (curp: string) => curp?.substring(0, 10) ?? '';

export const formatCountriesToSave = (
  countriesList: countryListType[],
  type: CountryName,
) =>
  countriesList.map((country) => ({
    country_code: country.value,
    country_type: type,
    is_main_country: false,
  }));

export const validateDateFormat = (date: string) =>
  REGEX_VALIDATORS.dateFormat.test(date.replace(/\s/g, ''));

export const formatQuestionnaireAnswers = (
  answers: AnswersDataType,
  questionnaire: QuestionnaireDataType,
  key: QuestionnaireNameType = QUESTIONNAIRE_NAME.economicActivity,
) => {
  const relatedbaseQuestions: RelatedBaseQuestions = {};
  const baseQuestion: Array<QuestionType> = [];

  answers?.questionnaireAnswers &&
    answers.questionnaireAnswers.forEach((questionAnswer) => {
      const questionFound = questionnaire.data?.find(
        (question) =>
          question.id.toUpperCase() === questionAnswer.questionId.toUpperCase(),
      );

      if (!questionFound) {
        return;
      }

      let response;
      let parsedInteger;
      const isInteger = questionFound.dataType === QUESTION_DATA_TYPES.integer;

      switch (questionFound.answerType) {
        case QUESTION_ANSWER_TYPES.combo:
          response = questionFound.answer
            ?.find(
              (option) =>
                option.id === questionAnswer.answerId.toUpperCase() ||
                option.id === questionAnswer.answerId,
            )
            ?.id.toUpperCase();
          break;
        case QUESTION_ANSWER_TYPES.textBox:
          parsedInteger = 0;

          if (isInteger && questionAnswer.answerResponse) {
            parsedInteger = parseInt(
              questionAnswer.answerResponse?.replace(
                REGEX_VALIDATORS.replaceLetters,
                '',
              ),
            );
          }

          response = isInteger
            ? parsedInteger.toString()
            : questionAnswer.answerResponse;
          break;
        case QUESTION_ANSWER_TYPES.multipleOption:
          {
            const answerFromQuestionnaire = questionFound.answer?.find(
              (option) => option.id === questionAnswer.answerId,
            );
            response = {
              label: answerFromQuestionnaire && answerFromQuestionnaire.text,
              value:
                answerFromQuestionnaire &&
                answerFromQuestionnaire.id.toUpperCase(),
            };
          }
          break;
        case QUESTION_ANSWER_TYPES.calendar:
        default:
          response = questionAnswer.answerResponse
            ?.replace(/\s+/g, '')
            .split('-')
            .reverse()
            .join(' / ');
          break;
      }

      const newBaseQuestion: QuestionType = {
        id: questionAnswer.questionId.toUpperCase(),
        response: response || questionAnswer.answerId,
        answerType: questionFound.answerType,
        required: questionFound.required,
        text: questionFound.text,
        relatedAnswerId: questionFound.relatedAnswerId,
        answer: questionFound.answer,
        ...(questionFound.maxLength && {
          maxLength: questionFound.maxLength,
        }),
        ...(questionFound.dataType && {
          dataType: questionFound.dataType,
        }),
      };

      if (questionFound?.answerType === QUESTION_ANSWER_TYPES.multipleOption) {
        const baseQuestionsIndex = baseQuestion.find(
          (baseQuestionAnswer) =>
            baseQuestionAnswer.id === questionAnswer.questionId.toUpperCase(),
        );
        if (baseQuestionsIndex) {
          if (response && typeof response === 'object' && response.value) {
            // @ts-ignore
            baseQuestion[baseQuestion.length - 1].response?.push(response);
          }
        } else {
          newBaseQuestion.response = [response];
          baseQuestion.push(newBaseQuestion);
        }
      } else if (questionFound.answerType === QUESTION_ANSWER_TYPES.calendar) {
        if (validateDateFormat(newBaseQuestion.response as string)) {
          baseQuestion.push(newBaseQuestion);
        } else {
          newBaseQuestion.response = '';
          baseQuestion.push(newBaseQuestion);
        }
      } else {
        if (typeof newBaseQuestion.response === 'string') {
          newBaseQuestion.response.toUpperCase();
        }
        baseQuestion.push(newBaseQuestion);
      }
    });

  // @ts-ignore
  relatedbaseQuestions[key] = {
    baseQuestions: baseQuestion,
  };

  return baseQuestion;
};

export const filterQuestions = (
  questions: Array<QuestionType>,
  showAsTabsIds?: Array<string>,
) =>
  questions
    .filter(
      (question) =>
        !has(question, 'related_answer_id') &&
        !has(question, 'questionnaire_type'),
    )
    .map((question) => ({
      ...question,
      showAsTabs: showAsTabsIds?.length
        ? showAsTabsIds.includes(question.id)
        : false,
    }));

export const findRelatedQuestions = (
  questions: Array<QuestionType>,
  baseQuestions: Array<QuestionType>,
  key: string,
  value: string,
) => {
  let newBaseQuestions = [...baseQuestions];
  const index = newBaseQuestions.findIndex((question) => question.id === key);

  // Find new questions based on answer
  const newQuestions = questions.filter(
    (question) => question.relatedAnswerId === value,
  );

  // Track previous response of that question
  const previousResponse = newBaseQuestions[index].response;
  let questionsToRemove = newBaseQuestions.filter((question) => {
    if (question.relatedAnswerId && previousResponse) {
      return question.relatedAnswerId === previousResponse;
    }
    return false;
  });

  // Find related questions to all levels
  questionsToRemove.forEach(({ response }) => {
    /* istanbul ignore else */
    if (response) {
      const relatedQuestions = newBaseQuestions.filter(
        (q) => q.relatedAnswerId === response,
      );
      questionsToRemove = [...questionsToRemove, ...relatedQuestions];
    }
  });

  // Find related questions from other related question.
  newBaseQuestions.forEach((question) => {
    if (question.relatedAnswerId) {
      const relatedQuestions = newBaseQuestions.filter(
        (q) => q.relatedAnswerId === question.id,
      );
      questionsToRemove = [...questionsToRemove, ...relatedQuestions];
    }
  });

  // Flat array
  questionsToRemove = flattenDeep(questionsToRemove);

  // Update question answer
  newBaseQuestions = setQuestionAnswer(newBaseQuestions, key, value);

  // Delete questions based on previous response
  newBaseQuestions = difference(newBaseQuestions, questionsToRemove);
  return [...newBaseQuestions, ...newQuestions];
};

export const setQuestionAnswer = (
  questions: Array<QuestionType>,
  key: string,
  value: string,
) =>
  questions.map((question) => {
    if (question.id === key) {
      return {
        ...question,
        response: value || '',
      };
    }

    return question;
  });

export const formatAnswerInputByDataType = (
  answer: string,
  type?: QuestionDataType,
): string => {
  let response = '';

  if (answer) {
    switch (type) {
      case QUESTION_DATA_TYPES.date:
        // eslint-disable-next-line no-case-declarations
        const cleanedValue = removeSpaces(answer);

        if (validateDateFormat(cleanedValue)) {
          response = formatDate(
            parseDate(cleanedValue, DATE_FORMATS.ddMMyyyy),
            DATE_FORMATS.yyyyMMddWithDash,
          );
        } else {
          response = answer;
        }
        break;
      case QUESTION_DATA_TYPES.integer:
        response = parseInt(answer?.replace(/\$|,/g, '')).toString();
        break;
      default:
        response = answer;
        break;
    }
  }

  return response;
};

export const formatAnswerByDataType = (
  type: QuestionDataType,
  answer: string,
) => {
  let response = '';

  switch (type) {
    case QUESTION_DATA_TYPES.date:
      response = removeSpaces(answer);
      break;
    case QUESTION_DATA_TYPES.integer:
      response = formatNumber(answer, 'currency');
      break;
    default:
      response = answer;
      break;
  }

  return response;
};

export const serializeAnswers = (
  questions: Array<QuestionType>,
): Array<LoremQuestionType> => {
  const questionnaireAnswers: Array<LoremQuestionType> = [];

  questions.forEach((question) => {
    if (isArray(question.response)) {
      question.response.forEach((response) => {
        const value = typeof response === 'object' ? response.value : '';
        questionnaireAnswers.push({
          question_id: question.id,
          answer_id: value,
          answer_response: undefined,
        });
      });
    } else {
      const response = question.response as string;
      questionnaireAnswers.push({
        question_id: question.id,
        answer_id: uuidValidate(response || '') ? response : undefined,
        answer_response: !uuidValidate(response || '')
          ? formatAnswerInputByDataType(response, question.dataType)
          : undefined,
      });
    }
  });

  return questionnaireAnswers;
};

export const formatQuestionnaireToSave = (
  id: string,
  version: string,
  type: LoremQuestionnaireType,
  answers: Array<LoremQuestionType>,
): LoremQuestionnaireRequestType => ({
  questionnaire_version_id: id,
  questionnaire: {
    version,
    questionnaire_type: type,
    questionnaire_answers: answers,
  },
});

export function chunkArray<T>(items: Array<T>, size: number): Array<Array<T>> {
  const chunks = [];
  const newItems = [...items];

  for (let i = 0; i < newItems.length; i += size) {
    const chunkPart = newItems.slice(i, i + size);
    chunks.push(chunkPart);
  }

  return chunks;
}

export const getQuestionsWithAnswers = (
  questionnaire: QuestionnaireDataType,
  answers: AnswersDataType,
): Array<QuestionType> => {
  if (isEmpty(answers) || isEmpty(questionnaire)) {
    return [];
  }

  // Map a question with their answers
  const formattedQuestions = formatQuestionnaireAnswers(answers, questionnaire);

  return formattedQuestions.map(
    ({ id, response, answer, dataType, text, answerType, required }) => {
      let formattedResponse: Array<AnswerType> = [];

      if (typeof response === 'string') {
        if (uuidValidate((response as string) || '')) {
          // Search the question response in the question answers array
          formattedResponse =
            answer?.filter(
              ({ id: answerId }) =>
                answerId.toUpperCase() === response.toUpperCase(),
            ) || [];
        } else {
          // Format text response
          const label = formatAnswerByDataType(
            dataType as QuestionDataType,
            response as string,
          );
          formattedResponse = [...formattedResponse, { id, text: label }];
        }
      } else if (isArray(response)) {
        // If the answer is an array of responses, just add the texts
        response.forEach((label) => {
          formattedResponse = [
            ...formattedResponse,
            { id, text: label as string },
          ];
        });
      }

      return {
        id,
        text,
        answerType,
        required,
        response: formattedResponse,
      };
    },
  );
};

export const waitForTime = (milliseconds: number) =>
  // eslint-disable-next-line no-promise-executor-return
  new Promise((resolve) => setTimeout(resolve, milliseconds));

export const blobToDataURL = (blob: Blob): Promise<string> =>
  new Promise((fulfill, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = (e) => fulfill(e.target?.result as string);
    reader.readAsDataURL(blob);
  });

export const checkHEICImage = async (
  base64string: string,
  file: Blob,
  imageFormat: string,
) => {
  const src = base64string;
  const imageData = Uint8Array.from(
    atob(src.replace(`data:image/${imageFormat};base64,`, '')),
    (c) => c.charCodeAt(0),
  );

  // File is too small for HEIC format
  if (imageData.length < 24) {
    return base64string;
  }

  // The file might be in HEIC format but with jpeg/png extension
  if (
    imageData[20] === 0x68 &&
    imageData[21] === 0x65 &&
    imageData[22] === 0x69 &&
    imageData[23] === 0x63
  ) {
    const convertedFile = await heic2any({
      blob: file,
      toType: `image/${imageFormat}`,
      quality: 1,
    });
    return blobToDataURL(convertedFile as Blob);
  }

  // File is not in HEIC format
  return base64string;
};

const changeHeightWidth = (
  height: number,
  maxHeight: number,
  width: number,
  maxWidth: number,
  minWidth: number = IMAGE_MIN_WIDTH_IN_PIXELS,
) => {
  let newHeight = height;
  let newWidth = width;

  if (width > maxWidth || width < minWidth) {
    newHeight = Math.round((height * maxWidth) / width);
    newWidth = maxWidth;
  }

  if (height > maxHeight) {
    newWidth = Math.round((width * maxHeight) / height);
    newHeight = maxHeight;
  }

  return { newHeight, newWidth };
};

export const resizeAndRotateImage = (
  callback: (data: CompressImageCallbackProps) => void,
  image: HTMLImageElement,
  maxWidth: number,
  maxHeight: number,
  imageFormat: string = 'jpeg',
  quality: number = 100,
  rotation: number = 0,
  minWidth: number = IMAGE_MIN_WIDTH_IN_PIXELS,
) => {
  const qualityDecimal = quality / 100;
  const canvas = document.createElement('canvas');

  let { width } = image;
  let { height } = image;

  const { newHeight, newWidth } = changeHeightWidth(
    height,
    maxHeight,
    width,
    maxWidth,
    minWidth,
  );

  if (rotation && (rotation === 90 || rotation === 270)) {
    canvas.width = newHeight;
    canvas.height = newWidth;
  } else {
    canvas.width = newWidth;
    canvas.height = newHeight;
  }

  width = newWidth;
  height = newHeight;

  const ctx = canvas.getContext('2d');

  if (rotation && ctx) {
    ctx.rotate((rotation * Math.PI) / 180);

    switch (rotation) {
      case 90:
        ctx.translate(0, -canvas.width);
        break;
      case 180:
        ctx.translate(-canvas.width, -canvas.height);
        break;
      case 270:
        ctx.translate(-canvas.height, 0);
        break;
      default:
        ctx.translate(0, 0);
        break;
    }
  }

  ctx?.drawImage(image, 0, 0, width, height);
  canvas.toBlob(
    (file) => file && callback({ file, width, height }),
    `image/${imageFormat}`,
    qualityDecimal,
  );
};

export const compressImage = async (
  file: Blob,
  maxWidth: number,
  maxHeight: number,
  quality: number = 100,
  rotation: number = 0,
  callback: (data: CompressImageCallbackProps) => void = () => null,
  minWidth: number = IMAGE_MIN_WIDTH_IN_PIXELS,
) => {
  const fileAsDataUrl = await blobToDataURL(file);
  const imageFormat = file.type.split('/')[1].toLowerCase();
  const fixedImage = await checkHEICImage(fileAsDataUrl, file, imageFormat);

  const image = new Image();
  image.onload = () => {
    resizeAndRotateImage(
      (result) => {
        callback({ ...result, extension: imageFormat });
      },
      image,
      maxWidth,
      maxHeight,
      imageFormat,
      quality,
      rotation,
      minWidth,
    );
  };
  image.src = fixedImage;
};

export const getBase64 = (file: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const encoded =
        typeof reader.result === 'string'
          ? reader.result?.replace(/^data:(.*;base64,)?/, '')
          : '';
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });

/**
 * Replace spaces with the "+" sign from the emails
 * read by the URLSearchParams class
 * @param email
 */
export const resolveUrlEmailParam = (email: string) =>
  email.replace(/\s/g, '+');

export const getGeolocation = async (
  customOptions?: Partial<PositionOptions>,
): Promise<GeolocationType> =>
  new Promise((resolve, reject) => {
    const options = {
      enableHighAccuracy: true,
      timeout: 10000,
      ...customOptions,
    };
    const { geolocation } = navigator;

    if (geolocation) {
      geolocation.getCurrentPosition(
        (position) =>
          resolve({
            accuracy: position.coords.accuracy,
            coordenate: `${position.coords.latitude},${position.coords.longitude}`,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            timestamp: position.timestamp,
          }),
        (err) => reject(err.message),
        options,
      );
    } else {
      reject(GENERIC_ERROR);
    }
  });
