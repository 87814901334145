import { useTranslation } from 'react-i18next';

import { FormLayout, FormLayoutProps } from '@gbm/onboarding-sdk-ui-components';
import { translations } from '@gbm/onboarding-sdk-utils';
import { useBreakpoints } from '@gbm/onboarding-sdk-hooks';
import { useUpgradeProvider } from '../../../providers/upgrade';

export type UpgradeLayoutProps = Omit<FormLayoutProps, 'steps' | 'onClose'> & {
  onClose?: () => void;
};

const { upgrade } = translations;

export default function UpgradeLayout({
  children,
  onClose,
  current,
  kind,
}: UpgradeLayoutProps) {
  const { t } = useTranslation();
  const { isSm, isMd, isLg } = useBreakpoints();
  const { handleExit } = useUpgradeProvider();
  const steps = [
    {
      key: 'step-nationality',
      title: t(upgrade.steps.nationality),
    },
    {
      key: 'step-tax-info',
      title: t(upgrade.steps.taxInformation),
    },
    {
      key: 'step-financial-info',
      title: t(upgrade.steps.financialInformation),
    },
    {
      key: 'step-identity',
      title: t(upgrade.steps.identity),
    },
    {
      key: 'step-confirmation',
      title: t(upgrade.steps.confirmation),
    },
  ];

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    } else {
      handleExit();
    }
  };

  return (
    <FormLayout
      steps={steps}
      current={current}
      kind={kind}
      onClose={handleOnClose}
      fullStepper
      displayTitles={!isSm && !isMd && !isLg}
      stepTitlePosition="bottom"
      stepWidthSize={isSm || isMd ? 'small' : 'default'}
    >
      {children}
    </FormLayout>
  );
}
