import MarkdownJsx from 'markdown-to-jsx';
import { Typography } from '@gbm/starman-next';

import styles from './markdown.module.scss';

export type MarkdownProps = { children: string };

const h2 = (props: any) => <Typography variant="h2" {...props} />;

export default function Markdown({ children }: MarkdownProps) {
  return (
    <div className={styles.container}>
      <MarkdownJsx options={{ overrides: { h2 } }}>{children}</MarkdownJsx>
    </div>
  );
}
