import { differenceInYears } from 'date-fns';
import sortBy from 'lodash/sortBy';

import {
  CountryResponseType,
  CountryType,
  LocationType,
  PHONE_TYPES,
  PhoneType,
  StateResponseType,
  StateType,
  ZipLocationType,
} from './types';

export const partyAdapter = (response: Record<string, any>) => {
  const age =
    response.data.birth_date &&
    differenceInYears(new Date(), new Date(response.data.birth_date));

  return {
    ...response,
    data: {
      ...response.data,
      age,
    },
  };
};

export const countriesAdapter = (response: Record<string, any>) => {
  const adapter: Array<CountryType> = [];
  response.data.forEach((country: CountryResponseType) => {
    adapter.push({
      key: country.code,
      value: country.code,
      label: country.name,
      phoneCode: country.international_dialing_code,
      country_code: country.code,
    });
  });
  return { ...response, data: sortBy(adapter, ['label']) };
};

export const statesAdapter = (response: Record<string, any>) => {
  const adapter: Array<StateType> = [];
  response.data.forEach((state: StateResponseType) => {
    adapter.push({
      key: state.id,
      value: state.id,
      label: state.name,
    });
  });
  return { ...response, data: adapter };
};

export const phoneAdapter = (response: Record<string, any>) => {
  let data = response.data?.find(
    (phone: PhoneType) => phone.telephone_type === PHONE_TYPES.cellphone,
  );

  if (!data && response.data?.length) {
    const [phone] = response.data;
    data = phone;
  }

  if (!data) data = {};

  return { ...response, data };
};

export const curpAdapter = (response: Record<string, any>) => {
  const curp = response.data?.curp || response.data?.value;
  return { ...response, data: { value: curp } };
};

export const zipCodeAdapter = (response: Record<string, any>) => {
  const emptyLocation = {
    country: '',
    state: '',
    city: '',
    township: '',
    suburbs: [],
  };
  const baseZipLocation = response.data.length
    ? response.data[0]
    : emptyLocation;
  const adapter: ZipLocationType = {
    country: baseZipLocation.country.name,
    city: baseZipLocation.city?.name,
    state: baseZipLocation.state.name,
    township: baseZipLocation.township.name,
    suburbs: [],
    id: baseZipLocation.id,
  };
  response.data.forEach((location: LocationType) => {
    const suburb = {
      key: location.id,
      value: location.id,
      label: location.suburb.name,
    };
    adapter.suburbs?.push(suburb);
  });
  adapter.suburbs = sortBy(adapter.suburbs, ['label']);
  return { ...response, data: adapter };
};
