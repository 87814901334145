/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useFormContext } from 'react-hook-form';

import { FormRow } from '@gbm/onboarding-sdk-ui-components';
import { LoremQuestionType } from '../../../../../api/upgrade/types';
import {
  formatQuestionnaireAnswers,
  filterQuestions,
  findRelatedQuestions,
  setQuestionAnswer,
  serializeAnswers,
} from '../../../../../utils/helpers';
import {
  TextQuestion,
  SelectQuestion,
  SelectMultipleQuestion,
  CalendarQuestion,
} from '../questions';
import {
  QUESTION_ANSWER_TYPES,
  QuestionType,
  QuestionnaireDataType,
  AnswersDataType,
  QUESTIONNAIRE_NAME,
} from '../types';
import styles from '../financial-information.module.scss';

type EconomicActivityProps = {
  questionnaire: QuestionnaireDataType;
  answers: AnswersDataType;
  onSerializeAnswers: (answers: Array<LoremQuestionType>) => void;
};

export default function EconomicActivity({
  questionnaire,
  answers,
  onSerializeAnswers,
}: EconomicActivityProps) {
  const { getValues } = useFormContext();
  const [baseQuestions, setBaseQuestions] = useState<Array<QuestionType>>([]);

  useEffect(() => {
    if (!isEmpty(baseQuestions)) {
      const answeredQuestions = baseQuestions.map((question) => ({
        ...question,
        response: getValues(question.id),
      }));
      const serialized = serializeAnswers(answeredQuestions);
      onSerializeAnswers(serialized);
    }
  }, [baseQuestions]);

  useEffect(() => {
    if (!isEmpty(questionnaire.data)) {
      let filteredQuestions;

      if (!isEmpty(answers.questionnaireAnswers)) {
        filteredQuestions = formatQuestionnaireAnswers(
          answers,
          questionnaire,
          QUESTIONNAIRE_NAME.economicActivity,
        );
      } else {
        filteredQuestions = filterQuestions(questionnaire.data);
      }

      setBaseQuestions(filteredQuestions);
    }
  }, [questionnaire.data, answers.questionnaireAnswers]);

  const onChangeQuestion = (key: string, value: string, checkValue = true) => {
    if ((checkValue && value) || !checkValue) {
      const newBaseQuestions = findRelatedQuestions(
        questionnaire.data,
        baseQuestions,
        key,
        value,
      );
      setBaseQuestions(newBaseQuestions);
    }
  };

  const onChangeInput = (key: string, value: string) => {
    const newQuestions = setQuestionAnswer(baseQuestions, key, value);
    setBaseQuestions(newQuestions);
  };

  return (
    <>
      {baseQuestions?.map((question) => {
        switch (question.answerType) {
          case QUESTION_ANSWER_TYPES.combo:
            return (
              <FormRow key={`question-${question.id}`}>
                <SelectQuestion
                  question={question}
                  key={question.id}
                  onChangeAnswer={(value) =>
                    onChangeQuestion(question.id, value)
                  }
                />
                <div className={styles.empty} />
              </FormRow>
            );
          case QUESTION_ANSWER_TYPES.multipleOption:
            return (
              <FormRow key={`question-${question.id}`}>
                <SelectMultipleQuestion
                  question={question}
                  key={question.id}
                  onChangeAnswer={(value) =>
                    onChangeQuestion(question.id, value)
                  }
                />
                <div className={styles.empty} />
              </FormRow>
            );
          case QUESTION_ANSWER_TYPES.calendar:
            return (
              <FormRow key={`question-${question.id}`}>
                <CalendarQuestion
                  question={question}
                  key={question.id}
                  onChangeAnswer={(answer) =>
                    onChangeInput(question.id, answer)
                  }
                />
                <div className={styles.empty} />
              </FormRow>
            );
          case QUESTION_ANSWER_TYPES.textBox:
          default:
            return (
              <FormRow key={`question-${question.id}`}>
                <TextQuestion
                  question={question}
                  key={question.id}
                  onChangeAnswer={(answer) =>
                    onChangeInput(question.id, answer)
                  }
                />
                <div className={styles.empty} />
              </FormRow>
            );
        }
      })}
    </>
  );
}
