import { PropsWithChildren } from 'react';
import clsx from 'clsx';

import { Loader } from '../loader';
import styles from './modal.module.scss';

export type ModalContentProps = PropsWithChildren<{
  loading?: boolean;
  contentClassName?: string;
}>;

export default function ModalContent({
  children,
  loading,
  contentClassName,
}: ModalContentProps) {
  return (
    <div
      className={clsx(styles.content, contentClassName, {
        [styles['content--loading']]: loading,
      })}
    >
      {loading ? <Loader fullScreen={false} kind="light" /> : children}
    </div>
  );
}
