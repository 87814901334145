import { useCallback, useEffect, useState } from 'react';
import {
  ArrowCircleRight,
  Button,
  ButtonLink,
  CaretLeft,
  SkeletonLoader,
} from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { motion } from 'framer-motion';

import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import {
  FormContent,
  FormFooter,
  FormRow,
  FormSection,
} from '@gbm/onboarding-sdk-ui-components';
import {
  TRACKING_EVENTS,
  TRACKING_NAMES,
  UPGRADE_IDS,
  translations,
} from '@gbm/onboarding-sdk-utils';
import { UpgradeLayout } from '../../../components/upgrade';
import { useUpgradeProvider } from '../../../providers/upgrade';
import { UPGRADE_STEPS, STEPS } from '../../../providers/upgrade/types';
import {
  EconomicActivity,
  PoliticalExposedPerson,
  KnowYourCostumer,
} from '../../../components/upgrade/financial-information';
import {
  AnswersDataType,
  QuestionnaireDataType,
} from '../../../components/upgrade/financial-information/types';
import {
  useEaQuestionnaire,
  useParty,
  usePepQuestionnaire,
  useKycQuestionnaire,
  useUpgradeStep,
} from '../../../hooks';
import { LoremQuestionType } from '../../../../api/upgrade/types';
import styles from './financial-information.module.scss';

const {
  buttons,
  upgrade: { financialInformation },
} = translations;

export default function FinancialInformation() {
  const { t } = useTranslation();
  const { setStep, isEditing, setIsEditing, configuration } =
    useUpgradeProvider();
  const formMethods = useForm({ mode: 'onChange' });
  const {
    handleSubmit,
    formState: { isValid, isSubmitting, isSubmitted },
  } = formMethods;
  const { party, isFulfilled: isFulfilledParty } = useParty(configuration);
  const {
    questionnaire: eaQuestionnaire,
    answers: eaAnswers,
    fetchQuestionnaire: fetchEaQuestionnaire,
    fetchAnswers: fetchEaAnswers,
    saveAnswers: saveEaAnswers,
    isLoading: isLoadingEaQuestionnaire,
    isFulfilledAnswers: isFulfilledEaAnswers,
    isRejected: isRejectedEa,
  } = useEaQuestionnaire(configuration);
  const {
    questionnaire: pepQuestionnaire,
    answers: pepAnswers,
    fetchQuestionnaire: fetchPepQuestionnaire,
    fetchAnswers: fetchPepAnswers,
    saveAnswers: savePepAnswers,
    isLoading: isLoadingPepQuestionnaire,
    isFulfilledAnswers: isFulfilledPepAnswers,
    isRejected: isRejectedPep,
  } = usePepQuestionnaire(configuration);
  const {
    questionnaire: kycQuestionnaire,
    answers: kycAnswers,
    fetchQuestionnaire: fetchKycQuestionnaire,
    fetchAnswers: fetchKycAnswers,
    saveAnswers: saveKycAnswers,
    isLoading: isLoadingKycQuestionnaire,
    isFulfilledAnswers: isFulfilledKycAnswers,
    isRejected: isRejectedKyc,
  } = useKycQuestionnaire(configuration);
  const [eaAnswersSerialized, setEaAnswersSerialized] = useState<
    Array<LoremQuestionType>
  >([]);
  const [pepAnswersSerialized, setPepAnswersSerialized] = useState<
    Array<LoremQuestionType>
  >([]);
  const [kycAnswersSerialized, setKycAnswersSerialized] = useState<
    Array<LoremQuestionType>
  >([]);
  const [savingOrUpdating, setSavingOrUpdating] = useState(false);
  const { saveUpgradeStep: saveStep } = useUpgradeStep(configuration);
  const isLoading =
    isLoadingEaQuestionnaire ||
    isLoadingPepQuestionnaire ||
    isLoadingKycQuestionnaire;
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 50);
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.economicActivity,
      success: true,
    });
  }, []);

  useEffect(() => {
    /* istanbul ignore else */
    if (isFulfilledParty) {
      fetchEaQuestionnaire();
      fetchEaAnswers();
      fetchPepQuestionnaire();
      fetchPepAnswers();
      fetchKycQuestionnaire();
      fetchKycAnswers();
    }
  }, [party.status]);

  useEffect(() => {
    const questionnairesHasBeenSaved =
      isFulfilledEaAnswers && isFulfilledPepAnswers && isFulfilledKycAnswers;
    const questionnairesHasErrored =
      isRejectedEa || isRejectedPep || isRejectedKyc;

    if (questionnairesHasBeenSaved && isSubmitted) {
      mixpanel.track(TRACKING_EVENTS.screenFilled, {
        name: TRACKING_NAMES.economicActivity,
        success: true,
      });
      if (!isEditing) {
        saveStep(UPGRADE_STEPS.ine);
        setStep(STEPS.ine);
      } else {
        handleCloseEdition();
      }
    }

    if (questionnairesHasErrored && savingOrUpdating) {
      setSavingOrUpdating(false);
    }
  }, [
    eaAnswers.status,
    pepAnswers.status,
    kycAnswers.status,
    savingOrUpdating,
    isSubmitted,
  ]);

  const handleCloseEdition = () => {
    setIsEditing(false);
    setStep(STEPS.confirmation);
  };

  const handleSubmitCallback = useCallback(() => {
    if (
      eaAnswersSerialized.length &&
      pepAnswersSerialized.length &&
      kycAnswersSerialized.length
    ) {
      setSavingOrUpdating(true);
      saveEaAnswers(eaAnswersSerialized);
      savePepAnswers(pepAnswersSerialized);
      saveKycAnswers(kycAnswersSerialized);
    }
  }, [eaAnswersSerialized, pepAnswersSerialized, kycAnswersSerialized]);

  return (
    <UpgradeLayout current={3}>
      <motion.div
        className={styles.container}
        id={UPGRADE_IDS.viewFinancialInfo}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <FormProvider {...formMethods}>
          <FormContent>
            <FormSection
              title={t(financialInformation.title)}
              description={t(financialInformation.subtitle)}
            >
              {isLoading && !isSubmitting && !isSubmitted ? (
                <>
                  <FormRow>
                    <SkeletonLoader kind="large" reverse />
                    <div className={styles.empty} />
                  </FormRow>
                  <FormRow>
                    <SkeletonLoader kind="large" reverse />
                    <div className={styles.empty} />
                  </FormRow>
                  <FormRow>
                    <SkeletonLoader kind="large" reverse />
                    <div className={styles.empty} />
                  </FormRow>
                  <FormRow>
                    <SkeletonLoader kind="large" reverse />
                    <div className={styles.empty} />
                  </FormRow>
                  <FormRow>
                    <SkeletonLoader kind="large" reverse />
                    <div className={styles.empty} />
                  </FormRow>
                </>
              ) : (
                <>
                  <EconomicActivity
                    questionnaire={
                      eaQuestionnaire.data as QuestionnaireDataType
                    }
                    answers={eaAnswers.data as AnswersDataType}
                    onSerializeAnswers={setEaAnswersSerialized}
                  />
                  <PoliticalExposedPerson
                    questionnaire={
                      pepQuestionnaire.data as QuestionnaireDataType
                    }
                    answers={pepAnswers.data as AnswersDataType}
                    onSerializeAnswers={setPepAnswersSerialized}
                  />
                  <KnowYourCostumer
                    questionnaire={
                      kycQuestionnaire.data as QuestionnaireDataType
                    }
                    answers={kycAnswers.data as AnswersDataType}
                    onSerializeAnswers={setKycAnswersSerialized}
                  />
                </>
              )}
            </FormSection>
          </FormContent>
          <FormFooter>
            {!isEditing ? (
              <ButtonLink
                data-testid="back-button"
                onClick={() => setStep(STEPS.taxInformation)}
              >
                <CaretLeft
                  color={styles['icon-color']}
                  size={styles['icon-size']}
                />
                {t(buttons.previous)}
              </ButtonLink>
            ) : (
              <ButtonLink onClick={handleCloseEdition} underline="always">
                {t(buttons.cancel)}
              </ButtonLink>
            )}
            <Button
              id={UPGRADE_IDS.btnFinancialInfo}
              disabled={!isValid}
              loading={savingOrUpdating}
              kind="primary"
              onClick={handleSubmit(handleSubmitCallback)}
              icon={
                <ArrowCircleRight size={styles['icon-size']} weight="fill" />
              }
            >
              {t(buttons[isEditing ? 'save' : 'continue'])}
            </Button>
          </FormFooter>
        </FormProvider>
      </motion.div>
    </UpgradeLayout>
  );
}
