import { useClipboard as useClipboardStarman } from '@gbm/starman-next';

export default function useClipboard() {
  const readText = async () =>
    navigator.clipboard
      .readText()
      .then((text: string) => text)
      .catch(() => '');

  return {
    ...useClipboardStarman(),
    readText,
  };
}
