import { Typography } from '@gbm/starman-next';
import { components, GroupBase, PlaceholderProps } from 'react-select';
import clsx from 'clsx';

import { SelectMultipleOption } from './types';
import styles from './select-multiple.module.scss';

export default function SelectMultiplePlaceholder({
  children,
  ...props
}: PlaceholderProps<
  SelectMultipleOption,
  true,
  GroupBase<SelectMultipleOption>
>) {
  return (
    <components.Placeholder {...props}>
      <div
        className={clsx(styles.placeholder, {
          [styles['placeholder--opened']]: props.selectProps.menuIsOpen,
        })}
      >
        <Typography variant="body2" weight="semibold">
          {children}
        </Typography>
      </div>
    </components.Placeholder>
  );
}
