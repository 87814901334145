import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  makeTrackingErrorProps,
  Mixpanel,
  TRACKING_EVENTS,
  TRACKING_NAMES,
} from '@gbm/onboarding-sdk-utils';
import { Auth, Upgrade, OpeningLite } from '../../../api';
import { ADDRESS_TYPES, AddressType } from '../../../api/opening-lite/types';
import { HTTP_STATUS_CODES } from '../../../api/types';
import { makeError } from '../../../utils/helpers';
import { OPENING_TYPE } from '../../providers/opening-lite/types';
import { CURP_ERRORS } from '../../containers/opening-lite/curp-info/types';
import {
  REDUCER_NAME,
  InfoRequestType,
  SavePartyPhoneRequestType,
  SavePartyAddressRequestType,
  UpdatePartyAddressRequestType,
  SaveContractRequestType,
  GetDataFromCurpRequestType,
  SavePartyRequestType,
  GetStatesRequestType,
  GetDataFromZipCodeRequestType,
  InfoCurpRequestType,
  ContractAccountRequestType,
} from './types';

export const getOpeningStatus = createAsyncThunk(
  `${REDUCER_NAME}/getOpeningStatus`,
  async ({ config }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const openingLiteApi = new OpeningLite(config);
      const { data, err } = await openingLiteApi.getOpeningStatus();

      if (data) {
        return data;
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getDataFromCurp = createAsyncThunk(
  `${REDUCER_NAME}/getDataFromCurp`,
  async ({ config, curp }: GetDataFromCurpRequestType, { rejectWithValue }) => {
    try {
      const openingLiteApi = new OpeningLite(config);
      const { data, err } = await openingLiteApi.getDataFromCurp(curp);

      if (data) {
        return { ...data, curp };
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getCurpFromData = createAsyncThunk(
  `${REDUCER_NAME}/getCurp`,
  async ({ config, ...rest }: InfoCurpRequestType, { rejectWithValue }) => {
    try {
      const openingLiteApi = new OpeningLite(config);
      const mixpanel = new Mixpanel(
        config.environment,
        config.isEnabledTrackEvents,
      );
      const { data, err, headers } = await openingLiteApi.getCurpFromData(rest);

      if (data) {
        return data;
      }

      const trackingErrorProps = makeTrackingErrorProps(err, headers);
      mixpanel.track(TRACKING_EVENTS.documentValidated, {
        name: TRACKING_NAMES.curp,
        ...trackingErrorProps,
      });
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const saveParty = createAsyncThunk(
  `${REDUCER_NAME}/saveParty`,
  async ({ config, ...rest }: SavePartyRequestType, { rejectWithValue }) => {
    try {
      const party = { ...rest, opening_type: OPENING_TYPE.lite };
      const openingLiteApi = new OpeningLite(config);

      const { data, err } = await openingLiteApi.saveParty(party, config.email);

      if (data) {
        return data;
      }

      const alreadyRegistered = err?.code === CURP_ERRORS.alreadyRegistered;
      return rejectWithValue({ err, alreadyRegistered });
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getParty = createAsyncThunk(
  `${REDUCER_NAME}/getParty`,
  async ({ config }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const openingLiteApi = new OpeningLite(config);
      const { data, err } = await openingLiteApi.getParty();

      if (data) {
        return data;
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getPartyCurp = createAsyncThunk(
  `${REDUCER_NAME}/getPartyCurp`,
  async ({ config, partyId }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const openingLiteApi = new OpeningLite(config);
      const mixpanel = new Mixpanel(
        config.environment,
        config.isEnabledTrackEvents,
      );
      const { data, err, headers } = await openingLiteApi.getPartyCurp(
        partyId as string,
      );

      if (data) {
        return data;
      }

      const trackingErrorProps = makeTrackingErrorProps(err, headers);
      mixpanel.track(TRACKING_EVENTS.documentValidated, {
        name: TRACKING_NAMES.curp,
        ...trackingErrorProps,
      });
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const savePartyPhone = createAsyncThunk(
  `${REDUCER_NAME}/savePartyPhone`,
  async (
    { partyId, phone, config }: SavePartyPhoneRequestType,
    { rejectWithValue },
  ) => {
    try {
      const openingLiteApi = new OpeningLite(config);
      const mixpanel = new Mixpanel(
        config.environment,
        config.isEnabledTrackEvents,
      );
      const { data, err, headers } = await openingLiteApi.savePartyPhone(
        partyId as string,
        phone,
      );

      if (data) {
        return data;
      }

      const trackingErrorProps = makeTrackingErrorProps(err, headers);
      mixpanel.track(TRACKING_EVENTS.screenFilled, {
        name: TRACKING_NAMES.personalInfo,
        ...trackingErrorProps,
      });
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getPartyPhone = createAsyncThunk(
  `${REDUCER_NAME}/getPartyPhone`,
  async ({ config, partyId }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const openingLiteApi = new OpeningLite(config);
      const { data, err } = await openingLiteApi.getPartyPhone(
        partyId as string,
      );

      if (data) {
        return data;
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const updatePartyPhone = createAsyncThunk(
  `${REDUCER_NAME}/updatePartyPhone`,
  async (
    { partyId, phone, config }: SavePartyPhoneRequestType,
    { rejectWithValue },
  ) => {
    try {
      const openingLiteApi = new OpeningLite(config);
      const mixpanel = new Mixpanel(
        config.environment,
        config.isEnabledTrackEvents,
      );
      const { data, err, headers } = await openingLiteApi.updatePartyPhone(
        partyId as string,
        phone,
      );

      if (data) {
        return data;
      }

      const trackingErrorProps = makeTrackingErrorProps(err, headers);
      mixpanel.track(TRACKING_EVENTS.screenFilled, {
        name: TRACKING_NAMES.personalInfo,
        ...trackingErrorProps,
      });
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getCountries = createAsyncThunk(
  `${REDUCER_NAME}/getCountries`,
  async ({ config }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const openingLiteApi = new OpeningLite(config);
      const { data, err } = await openingLiteApi.getCountries();

      if (data) {
        return data;
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getStates = createAsyncThunk(
  `${REDUCER_NAME}/getStates`,
  async (
    { config, countryCode }: GetStatesRequestType,
    { rejectWithValue },
  ) => {
    try {
      const openingLiteApi = new OpeningLite(config);
      const { data, err } = await openingLiteApi.getStates(countryCode);

      if (data) {
        return data;
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const savePartyAddress = createAsyncThunk(
  `${REDUCER_NAME}/savePartyAddress`,
  async (
    { partyId, address, config }: SavePartyAddressRequestType,
    { rejectWithValue },
  ) => {
    try {
      const openingLiteApi = new OpeningLite(config);
      const { data, err } = await openingLiteApi.savePartyAddress(
        partyId as string,
        address,
      );

      if (data) {
        const { data: zipLocationData, err: zipLocationErr } =
          await openingLiteApi.getZipLocationData(data.zip_location_id);

        if (zipLocationData) {
          const {
            township,
            suburb,
            state,
            country,
            zip_code: zipCode,
          } = zipLocationData;

          return {
            ...data,
            zipCode,
            country,
            township: township.name,
            suburb: suburb.name,
            state: state.name,
          };
        }

        return rejectWithValue(zipLocationErr);
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getPartyAddress = createAsyncThunk(
  `${REDUCER_NAME}/getPartyAddress`,
  async (
    { config, partyId }: InfoRequestType<{}>,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const openingLiteApi = new OpeningLite(config);
      const { data, err } = await openingLiteApi.getPartyAddress(
        partyId as string,
      );

      if (data) {
        const partyAddress = data.find(
          (address: AddressType) => address.address_type in ADDRESS_TYPES,
        );

        if (!partyAddress) {
          return {};
        }

        const { data: zipLocationData, err: zipLocationErr } =
          await openingLiteApi.getZipLocationData(partyAddress.zip_location_id);

        if (zipLocationData) {
          const {
            township,
            suburb,
            state,
            country,
            zip_code: zipCode,
          } = zipLocationData;

          dispatch(
            getDataFromZipCode({
              config,
              zipCode,
            }),
          );

          return {
            ...partyAddress,
            zipCode,
            country,
            township: township.name,
            suburb: suburb.name,
            state: state.name,
          };
        }

        return rejectWithValue(zipLocationErr);
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getDataFromZipCode = createAsyncThunk(
  `${REDUCER_NAME}/getDataFromZipCode`,
  async (
    { config, zipCode }: GetDataFromZipCodeRequestType,
    { rejectWithValue },
  ) => {
    try {
      const openingLiteApi = new OpeningLite(config);
      const { data, err } = await openingLiteApi.getDataFromZipCode(zipCode);

      if (data) {
        return data;
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const updatePartyAddress = createAsyncThunk(
  `${REDUCER_NAME}/updatePartyAddress`,
  async (
    { partyId, address, addressId, config }: UpdatePartyAddressRequestType,
    { rejectWithValue },
  ) => {
    try {
      const openingLiteApi = new OpeningLite(config);
      const { data, err } = await openingLiteApi.updatePartyAddress(
        partyId as string,
        addressId,
        address,
      );

      if (data) {
        const { data: zipLocationData, err: zipLocationErr } =
          await openingLiteApi.getZipLocationData(data.zip_location_id);

        if (zipLocationData) {
          const {
            township,
            suburb,
            state,
            country,
            zip_code: zipCode,
          } = zipLocationData;

          return {
            ...data,
            zipCode,
            country,
            township: township.name,
            suburb: suburb.name,
            state: state.name,
          };
        }

        return rejectWithValue(zipLocationErr);
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const saveContract = createAsyncThunk(
  `${REDUCER_NAME}/saveContract`,
  async (
    {
      legalDocuments,
      partyId,
      geolocation,
      mfaCode,
      createTradingAccount,
      config,
    }: SaveContractRequestType,
    { rejectWithValue },
  ) => {
    try {
      const openingLiteApi = new OpeningLite(config);
      const upgradeApi = new Upgrade(config);
      const mixpanel = new Mixpanel(
        config.environment,
        config.isEnabledTrackEvents,
      );
      const legalDocumentsRequests = legalDocuments.map((doc) =>
        upgradeApi.saveLegalDocument(partyId as string, doc),
      );
      const legalDocumentsResponses = await Promise.all(legalDocumentsRequests);
      const legalDocumentError = legalDocumentsResponses.find(({ err }) => err);

      if (legalDocumentError) {
        const trackingErrorProps = makeTrackingErrorProps(
          legalDocumentError.err,
          legalDocumentError.headers,
        );
        mixpanel.track(TRACKING_EVENTS.screenViewed, trackingErrorProps);

        return rejectWithValue(legalDocumentError);
      }

      const { data, err, status, headers } = await openingLiteApi.saveContract(
        partyId as string,
        geolocation,
        mfaCode,
        createTradingAccount,
      );

      if (status === HTTP_STATUS_CODES.accepted) {
        return data;
      }

      const trackingErrorProps = makeTrackingErrorProps(err, headers);
      mixpanel.track(TRACKING_EVENTS.contractCreated, trackingErrorProps);

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getContract = createAsyncThunk(
  `${REDUCER_NAME}/getContract`,
  async ({ config }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const openingLiteApi = new OpeningLite(config);
      const { data, err } = await openingLiteApi.getContracts();

      if (data && data.length) {
        return data[0];
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getContractAccount = createAsyncThunk(
  `${REDUCER_NAME}/getContractAccount`,
  async (
    { contractId, config }: ContractAccountRequestType,
    { rejectWithValue },
  ) => {
    try {
      const openingLiteApi = new OpeningLite(config);
      const { data, err } = await openingLiteApi.getContractAccount(contractId);

      if (data && data.length) {
        return data[0];
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getChallenge = createAsyncThunk(
  `${REDUCER_NAME}/getChallenge`,
  async ({ config }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const authApi = new Auth(config.environment);
      const { data, err } = await authApi.getChallenge(config.accessToken);

      if (data) {
        return data;
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);
