import { useEffect } from 'react';
import { Input, InputNumber } from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { translations } from '@gbm/onboarding-sdk-utils';
import {
  QUESTION_ANSWER_TYPES,
  QUESTION_DATA_TYPES,
  INPUT_CURRENCY_LENGTH,
  QuestionType,
} from '../../types';

type TextQuestionProps = {
  question: QuestionType;
  onChangeAnswer: (answer: string) => void;
};
const { inputs } = translations;

export default function TextQuestion({
  question,
  onChangeAnswer,
}: TextQuestionProps) {
  const { t } = useTranslation();
  const {
    control,
    watch,
    setValue,
    formState: { errors, isValidating },
  } = useFormContext();
  const answer = watch(question.id);
  const isSalaryQuestion =
    question.answerType === QUESTION_ANSWER_TYPES.textBox &&
    question.dataType === QUESTION_DATA_TYPES.integer;

  useEffect(() => {
    if (answer && isEmpty(errors[question.id]) && !isValidating) {
      onChangeAnswer(answer);
    }
  }, [answer, errors, isValidating]);

  useEffect(() => {
    if (!isEmpty(question.response)) {
      setValue(question.id, question.response);
    }

    return () => {
      setValue(question.id, '');
    };
  }, []);

  return (
    <Controller
      control={control}
      name={question.id}
      rules={{
        required: inputs.genericRequiredField,
        pattern: question.pattern,
        maxLength:
          (question.maxLength as number) ||
          INPUT_CURRENCY_LENGTH ||
          t(inputs.invalidField),
        minLength: 1,
        validate: {
          minValue: (value) => {
            if (!isSalaryQuestion) return true;
            return (
              Number(value.replace(/\$|,/g, '')) > 0 || t(inputs.invalidField)
            );
          },
        },
      }}
      render={({ field: { onChange, value, onBlur }, fieldState: { error } }) =>
        isSalaryQuestion ? (
          <InputNumber
            widthSize="fluid"
            id={question.id}
            label={question.text}
            value={value}
            onChange={onChange}
            onValueChange={() => null}
            onBlur={onBlur}
            prefix="$"
            placeholder="$"
            status={error?.message ? 'error' : undefined}
            hintText={error?.message ? t(error?.message) : ''}
            inputMode="numeric"
          />
        ) : (
          <Input
            id={question.id}
            name={question.id}
            label={question.text}
            widthSize="fluid"
            type="text"
            placeholder=""
            value={value}
            onBlur={onBlur}
            onChange={(event) => {
              if (
                question.onlyAllowedChars &&
                !question.onlyAllowedChars.test(event.target.value)
              ) {
                return;
              }
              onChange(event);
            }}
            status={error?.message ? 'error' : undefined}
            hintText={error?.message ? t(error?.message) : ''}
          />
        )
      }
    />
  );
}
