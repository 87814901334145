import { MFA_PREFERENCE, Values } from '@gbm/onboarding-sdk-utils';

export const API_CODES = {
  success: 'Success',
  invalidUserForSignUp: 'InvalidUserForSignUp',
  userNotConfirmedException: 'UserNotConfirmedException',
  mustSendVerificationCode: 'MustSendVerificationCode',
  challengeRequired: 'ChallengeRequired',
  lockoutPolicyException: 'LockoutPolicyException',
} as const;

export type ApiCodesType = Values<typeof API_CODES>;

export const mockGetChallengeResponse = {
  data: { mfaPreference: MFA_PREFERENCE.totp },
  err: null,
} as const;
