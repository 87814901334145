/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { ErrorType } from '../../../api/types';
import { REDUCER_STATUS } from '../types';
import { DOCUMENTS_STATUS } from '../../providers/upgrade/types';

import {
  getBankList,
  updateCountriesBatch,
  getBankAccountStatus,
  getPartyCountries,
  savePartyRfc,
  savePartyTin,
  savePartyFiel,
  saveBankAccount,
  getUpgradeStep,
  getUpgradeStatus,
  getUdisSummary,
  getDocuments,
  getEaQuestionnaire,
  getEaAnswers,
  saveEaAnswers,
  getPepQuestionnaire,
  getPepAnswers,
  savePepAnswers,
  getKycQuestionnaire,
  getKycAnswers,
  saveKycAnswers,
  verifyProofOfAddress,
  saveProofOfAddress,
  getBankAccountDecrypted,
  getDeepLink,
  getValidUpgrade,
} from './actions';
import { INITIAL_STATE, REDUCER_NAME } from './types';

export const UpgradeSlice = createSlice({
  initialState: { ...INITIAL_STATE },
  name: REDUCER_NAME,
  reducers: {
    getPartyRfc: (draft, { payload }) => {
      draft.rfc.status = REDUCER_STATUS.resolved;
      draft.rfc.data = payload;
    },
    getPartyFiel: (draft, { payload }) => {
      draft.fiel.status = REDUCER_STATUS.resolved;
      draft.fiel.data = payload;
    },
    getPartyTin: (draft, { payload }) => {
      draft.tin.status = REDUCER_STATUS.resolved;
      draft.tin.data = payload;
    },
    clearDocuments: (draft) => {
      draft.documents = { ...INITIAL_STATE.documents };
    },
    clearErrors: (draft) => {
      draft.errors = { ...INITIAL_STATE.errors };
    },
    reset: () => ({ ...INITIAL_STATE }),
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(updateCountriesBatch.pending, getPartyCountries.pending),
        (draft) => {
          draft.nationalities.status = REDUCER_STATUS.pending;
        },
      )
      .addMatcher(
        isAnyOf(updateCountriesBatch.fulfilled, getPartyCountries.fulfilled),
        (draft, { payload }) => {
          draft.nationalities.status = REDUCER_STATUS.resolved;
          draft.nationalities.data = payload;
        },
      )
      .addMatcher(
        isAnyOf(updateCountriesBatch.rejected, getPartyCountries.rejected),
        (draft, { payload }) => {
          draft.nationalities.status = REDUCER_STATUS.rejected;
          draft.errors.nationalities = payload as ErrorType;
        },
      )

      .addMatcher(isAnyOf(getBankList.pending), (draft) => {
        draft.banks.status = REDUCER_STATUS.pending;
      })
      .addMatcher(isAnyOf(getBankList.fulfilled), (draft, { payload }) => {
        draft.banks.status = REDUCER_STATUS.resolved;
        draft.banks.data = payload;
      })
      .addMatcher(isAnyOf(getBankList.rejected), (draft, { payload }) => {
        draft.banks.status = REDUCER_STATUS.rejected;
        draft.errors.banks = payload as ErrorType;
      })

      .addMatcher(isAnyOf(getBankAccountStatus.pending), (draft) => {
        draft.bankAccounts.status = REDUCER_STATUS.pending;
      })
      .addMatcher(
        isAnyOf(getBankAccountStatus.fulfilled),
        (draft, { payload }) => {
          draft.bankAccounts.status = REDUCER_STATUS.resolved;
          draft.bankAccounts.data = payload;
        },
      )
      .addMatcher(
        isAnyOf(getBankAccountStatus.rejected),
        (draft, { payload }) => {
          draft.bankAccounts.status = REDUCER_STATUS.rejected;
          draft.errors.bankAccounts = payload as ErrorType;
        },
      )

      .addMatcher(isAnyOf(savePartyRfc.pending), (draft) => {
        draft.rfc.status = REDUCER_STATUS.pending;
      })
      .addMatcher(isAnyOf(savePartyRfc.fulfilled), (draft, { payload }) => {
        draft.rfc.status = REDUCER_STATUS.resolved;
        draft.rfc.data = payload;
      })
      .addMatcher(isAnyOf(savePartyRfc.rejected), (draft, { payload }) => {
        draft.rfc.status = REDUCER_STATUS.rejected;
        draft.errors.rfc = payload as ErrorType;
      })

      .addMatcher(isAnyOf(savePartyTin.pending), (draft) => {
        draft.tin.status = REDUCER_STATUS.pending;
      })
      .addMatcher(isAnyOf(savePartyTin.fulfilled), (draft, { payload }) => {
        draft.tin.status = REDUCER_STATUS.resolved;
        draft.tin.data = payload;
      })
      .addMatcher(isAnyOf(savePartyTin.rejected), (draft, { payload }) => {
        draft.tin.status = REDUCER_STATUS.rejected;
        draft.errors.tin = payload as ErrorType;
      })

      .addMatcher(isAnyOf(savePartyFiel.pending), (draft) => {
        draft.fiel.status = REDUCER_STATUS.pending;
      })
      .addMatcher(isAnyOf(savePartyFiel.fulfilled), (draft, { payload }) => {
        draft.fiel.status = REDUCER_STATUS.resolved;
        draft.fiel.data = payload;
      })
      .addMatcher(isAnyOf(savePartyFiel.rejected), (draft, { payload }) => {
        draft.fiel.status = REDUCER_STATUS.rejected;
        draft.errors.fiel = payload as ErrorType;
      })

      .addMatcher(isAnyOf(saveBankAccount.pending), (draft) => {
        draft.addBankAccount.status = REDUCER_STATUS.pending;
      })
      .addMatcher(isAnyOf(saveBankAccount.fulfilled), (draft, { payload }) => {
        draft.addBankAccount.status = REDUCER_STATUS.resolved;
        draft.addBankAccount.data = payload;
      })
      .addMatcher(isAnyOf(saveBankAccount.rejected), (draft, { payload }) => {
        draft.addBankAccount.status = REDUCER_STATUS.rejected;
        draft.errors.addBankAccount = payload as ErrorType;
      })

      .addMatcher(isAnyOf(getUpgradeStep.pending), (draft) => {
        draft.upgradeStep.status = REDUCER_STATUS.pending;
      })
      .addMatcher(isAnyOf(getUpgradeStep.fulfilled), (draft, { payload }) => {
        draft.upgradeStep.status = REDUCER_STATUS.resolved;
        draft.upgradeStep.data = payload;
      })
      .addMatcher(isAnyOf(getUpgradeStep.rejected), (draft, { payload }) => {
        draft.upgradeStep.status = REDUCER_STATUS.rejected;
        draft.errors.upgradeStep = payload as ErrorType;
      })

      .addMatcher(isAnyOf(getUpgradeStatus.pending), (draft) => {
        draft.upgradeStatus.status = REDUCER_STATUS.pending;
      })
      .addMatcher(isAnyOf(getUpgradeStatus.fulfilled), (draft, { payload }) => {
        draft.upgradeStatus.status = REDUCER_STATUS.resolved;
        draft.upgradeStatus.data = payload;
      })
      .addMatcher(isAnyOf(getUpgradeStatus.rejected), (draft, { payload }) => {
        draft.upgradeStatus.status = REDUCER_STATUS.rejected;
        draft.errors.upgradeStatus = payload as ErrorType;
      })

      .addMatcher(isAnyOf(getUdisSummary.pending), (draft) => {
        draft.udisSummary.status = REDUCER_STATUS.pending;
      })
      .addMatcher(isAnyOf(getUdisSummary.fulfilled), (draft, { payload }) => {
        draft.udisSummary.status = REDUCER_STATUS.resolved;
        draft.udisSummary.data = payload;
      })
      .addMatcher(isAnyOf(getUdisSummary.rejected), (draft, { payload }) => {
        draft.udisSummary.status = REDUCER_STATUS.rejected;
        draft.errors.udisSummary = payload as ErrorType;
      })

      .addMatcher(isAnyOf(getDocuments.pending), (draft) => {
        draft.documents.status = REDUCER_STATUS.pending;
      })
      .addMatcher(isAnyOf(getDocuments.fulfilled), (draft, { payload }) => {
        draft.documents.status = REDUCER_STATUS.resolved;
        draft.documents.data = payload;
      })
      .addMatcher(isAnyOf(getDocuments.rejected), (draft, { payload }) => {
        draft.documents.status = REDUCER_STATUS.rejected;
        draft.errors.documents = payload as ErrorType;
      })

      .addMatcher(isAnyOf(getEaQuestionnaire.pending), (draft) => {
        draft.eaQuestionnaire.status = REDUCER_STATUS.pending;
      })
      .addMatcher(
        isAnyOf(getEaQuestionnaire.fulfilled),
        (draft, { payload }) => {
          draft.eaQuestionnaire.status = REDUCER_STATUS.resolved;
          draft.eaQuestionnaire.data = payload;
        },
      )
      .addMatcher(
        isAnyOf(getEaQuestionnaire.rejected),
        (draft, { payload }) => {
          draft.eaQuestionnaire.status = REDUCER_STATUS.rejected;
          draft.errors.eaQuestionnaire = payload as ErrorType;
        },
      )

      .addMatcher(
        isAnyOf(getEaAnswers.pending, saveEaAnswers.pending),
        (draft) => {
          draft.eaAnswers.status = REDUCER_STATUS.pending;
        },
      )
      .addMatcher(
        isAnyOf(getEaAnswers.fulfilled, saveEaAnswers.fulfilled),
        (draft, { payload }) => {
          draft.eaAnswers.status = REDUCER_STATUS.resolved;
          draft.eaAnswers.data = payload;
        },
      )
      .addMatcher(
        isAnyOf(getEaAnswers.rejected, saveEaAnswers.rejected),
        (draft, { payload }) => {
          draft.eaAnswers.status = REDUCER_STATUS.rejected;
          draft.errors.eaAnswers = payload as ErrorType;
        },
      )

      .addMatcher(isAnyOf(getPepQuestionnaire.pending), (draft) => {
        draft.pepQuestionnaire.status = REDUCER_STATUS.pending;
      })
      .addMatcher(
        isAnyOf(getPepQuestionnaire.fulfilled),
        (draft, { payload }) => {
          draft.pepQuestionnaire.status = REDUCER_STATUS.resolved;
          draft.pepQuestionnaire.data = payload;
        },
      )
      .addMatcher(
        isAnyOf(getPepQuestionnaire.rejected),
        (draft, { payload }) => {
          draft.pepQuestionnaire.status = REDUCER_STATUS.rejected;
          draft.errors.pepQuestionnaire = payload as ErrorType;
        },
      )

      .addMatcher(
        isAnyOf(getPepAnswers.pending, savePepAnswers.pending),
        (draft) => {
          draft.pepAnswers.status = REDUCER_STATUS.pending;
        },
      )
      .addMatcher(
        isAnyOf(getPepAnswers.fulfilled, savePepAnswers.fulfilled),
        (draft, { payload }) => {
          draft.pepAnswers.status = REDUCER_STATUS.resolved;
          draft.pepAnswers.data = payload;
        },
      )
      .addMatcher(
        isAnyOf(getPepAnswers.rejected, savePepAnswers.rejected),
        (draft, { payload }) => {
          draft.pepAnswers.status = REDUCER_STATUS.rejected;
          draft.errors.pepAnswers = payload as ErrorType;
        },
      )

      .addMatcher(isAnyOf(getKycQuestionnaire.pending), (draft) => {
        draft.kycQuestionnaire.status = REDUCER_STATUS.pending;
      })
      .addMatcher(
        isAnyOf(getKycQuestionnaire.fulfilled),
        (draft, { payload }) => {
          draft.kycQuestionnaire.status = REDUCER_STATUS.resolved;
          draft.kycQuestionnaire.data = payload;
        },
      )
      .addMatcher(
        isAnyOf(getKycQuestionnaire.rejected),
        (draft, { payload }) => {
          draft.kycQuestionnaire.status = REDUCER_STATUS.rejected;
          draft.errors.kycQuestionnaire = payload as ErrorType;
        },
      )

      .addMatcher(
        isAnyOf(getKycAnswers.pending, saveKycAnswers.pending),
        (draft) => {
          draft.kycAnswers.status = REDUCER_STATUS.pending;
        },
      )
      .addMatcher(
        isAnyOf(getKycAnswers.fulfilled, saveKycAnswers.fulfilled),
        (draft, { payload }) => {
          draft.kycAnswers.status = REDUCER_STATUS.resolved;
          draft.kycAnswers.data = payload;
        },
      )
      .addMatcher(
        isAnyOf(getKycAnswers.rejected, saveKycAnswers.rejected),
        (draft, { payload }) => {
          draft.kycAnswers.status = REDUCER_STATUS.rejected;
          draft.errors.kycAnswers = payload as ErrorType;
        },
      )

      .addMatcher(
        isAnyOf(saveProofOfAddress.pending, verifyProofOfAddress.pending),
        (draft) => {
          draft.proofOfAddress.status = REDUCER_STATUS.pending;
        },
      )
      .addMatcher(
        isAnyOf(saveProofOfAddress.fulfilled),
        (draft, { payload }) => {
          draft.proofOfAddress.status = REDUCER_STATUS.resolved;
          draft.proofOfAddress.data = payload;
        },
      )
      .addMatcher(
        isAnyOf(verifyProofOfAddress.fulfilled),
        (draft, { payload }) => {
          draft.proofOfAddress.status = REDUCER_STATUS.resolved;
          draft.proofOfAddress.data.document_status = payload;
        },
      )
      .addMatcher(
        isAnyOf(saveProofOfAddress.rejected),
        (draft, { payload }) => {
          draft.proofOfAddress.status = REDUCER_STATUS.rejected;
          draft.errors.proofOfAddress = payload as ErrorType;
        },
      )
      .addMatcher(
        isAnyOf(verifyProofOfAddress.rejected),
        (draft, { payload }) => {
          draft.proofOfAddress.status = REDUCER_STATUS.resolved;
          draft.proofOfAddress.data.document_status = DOCUMENTS_STATUS.rejected;
          draft.errors.proofOfAddress = payload as ErrorType;
        },
      )

      .addMatcher(isAnyOf(getBankAccountDecrypted.pending), (draft) => {
        draft.bankAccountDecrypted.status = REDUCER_STATUS.pending;
      })
      .addMatcher(
        isAnyOf(getBankAccountDecrypted.fulfilled),
        (draft, { payload }) => {
          draft.bankAccountDecrypted.status = REDUCER_STATUS.resolved;
          draft.bankAccountDecrypted.data = payload;
        },
      )
      .addMatcher(
        isAnyOf(getBankAccountDecrypted.rejected),
        (draft, { payload }) => {
          draft.bankAccountDecrypted.status = REDUCER_STATUS.rejected;
          draft.errors.bankAccountDecrypted = payload as ErrorType;
        },
      )

      .addMatcher(isAnyOf(getDeepLink.pending), (draft) => {
        draft.deepLinks.status = REDUCER_STATUS.pending;
      })
      .addMatcher(isAnyOf(getDeepLink.fulfilled), (draft, { payload }) => {
        draft.deepLinks.status = REDUCER_STATUS.resolved;
        draft.deepLinks.data[payload.provider] = payload.link;
      })
      .addMatcher(isAnyOf(getDeepLink.rejected), (draft, { payload }) => {
        draft.deepLinks.status = REDUCER_STATUS.rejected;
        draft.errors.deepLinks = payload as ErrorType;
      })

      .addMatcher(isAnyOf(getValidUpgrade.pending), (draft) => {
        draft.validUpgrade.status = REDUCER_STATUS.pending;
      })
      .addMatcher(isAnyOf(getValidUpgrade.fulfilled), (draft, { payload }) => {
        draft.validUpgrade.status = REDUCER_STATUS.resolved;
        draft.validUpgrade.data = payload;
      })
      .addMatcher(isAnyOf(getValidUpgrade.rejected), (draft, { payload }) => {
        draft.validUpgrade.status = REDUCER_STATUS.rejected;
        draft.errors.validUpgrade = payload as ErrorType;
      });
  },
});

export const {
  getPartyRfc,
  getPartyFiel,
  getPartyTin,
  clearErrors,
  clearDocuments,
  reset,
} = UpgradeSlice.actions;

export default UpgradeSlice.reducer;
