import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography } from '@gbm/starman-next';

import arrowIcn from '@gbm/onboarding-sdk-ui-components/assets/images/icons/atm-icn-arrow-open.svg';
import { translations } from '@gbm/onboarding-sdk-utils';
import styles from './password-policies.module.scss';

const {
  auth: {
    passwordPolicies: {
      instructions,
      no,
      mustInclude,
      name,
      nameExample,
      repeated,
      repeatedExample,
      sequences,
      sequencesExample,
      user,
      userExample,
      showPolicies,
    },
  },
} = translations;

export default function PasswordPolicies() {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <div className={styles.container}>
      <div
        role="presentation"
        className={styles.label}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Typography variant="small">
          {t(instructions)}
          <strong>{t(no)}</strong>
          {t(mustInclude)}
        </Typography>
        <img
          src={arrowIcn}
          alt={t(showPolicies)}
          className={clsx(styles.icon, {
            [styles['icon--show']]: !isCollapsed,
            [styles['icon--hide']]: isCollapsed,
          })}
        />
      </div>
      <div
        className={clsx(styles.content, {
          [styles['content--show']]: !isCollapsed,
          [styles['content--hide']]: isCollapsed,
        })}
      >
        <ul>
          <li>
            <Typography variant="xsmall">
              {t(name)}
              <strong>{t(nameExample)}</strong>
            </Typography>
          </li>
          <li>
            <Typography variant="xsmall">
              {t(repeated)}
              <strong>{t(repeatedExample)}</strong>
            </Typography>
          </li>
          <li>
            <Typography variant="xsmall">
              {t(sequences)}
              <strong>{t(sequencesExample)}</strong>
            </Typography>
          </li>
          <li>
            <Typography variant="xsmall">
              {t(user)}
              <strong>{t(userExample)}</strong>
            </Typography>
          </li>
        </ul>
      </div>
    </div>
  );
}
