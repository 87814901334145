import { Values } from '../types';

export const TRACKING_EVENTS = {
  screenViewed: 'screen_viewed',
  screenFilled: 'screen_filled',
  fieldFilled: 'field_filled',
  fieldEnabled: 'field_enabled',
  signUpClicked: 'sign_up_clicked',
  signUpCompleted: 'sign_up_completed',
  contractStarted: 'contract_started',
  documentValidated: 'document_validated',
  linkClicked: 'link_clicked',
  contractCreated: 'contract_created',
  contractSigned: 'contract_signed',
  documentUploaded: 'document_uploaded',
  checkChecked: 'check_checked',
};

export const TRACKING_NAMES = {
  personalInfo: 'personal_info',
  taxId: 'tax_id',
  taxKey: 'tax_key',
  notTaxId: 'not_tax_id',
  address: 'address',
  confirmation: 'confirmation',
  sign: 'sign',
  verifyingInformation: 'verifying_information',
  pldError: 'pld_error',
  contractExpectedTimeError: 'contract_expected_time_error',
  accountLimit: 'account_limit',
  upgradeMfa: 'upgrade_mfa',
  nationality: 'nationality',
  fiscalData: 'fiscal_data',
  economicActivity: 'economic_activity',
  confirmationUpgrade: 'confirmation_upgrade',
  upgradeStatus: 'upgrade_status',
  playVideoUpgrade: 'play_video_upgrade',
  signUp: 'sign_up',
  email: 'email',
  password: 'password',
  curp: 'curp',
  curpUnknown: 'curp_unknown',
  proofOfAddress: 'proof_of_address',
  personalInfoCorrect: 'personal_info_correct',
  noOutsideNumber: 'no_outside_number',
  executionService: 'execution_service',
  location: 'location',
  ownResources: 'ownResources',
  termsAndConditions: 'terms_and_conditions',
};

export const SEGMENT_TYPES = {
  anonymousId: 'anonymousId',
  identify: 'identify',
  track: 'track',
} as const;
export type SegmentType = Values<typeof SEGMENT_TYPES>;

export const TRACKING_DEFAULT_VALUES = {
  emptyParam: 'N/A',
};

export const TRACKING_CALLBACK = {
  success: 'Success',
};

export const TRACKING_BIOMETRIC = {
  none: 'None',
};

export const UPGRADE_TYPE = 'upgrade';

export type TrackingCode = {
  code?: string;
  event_id?: string;
  target?: string;
  message?: string;
};

type TrackingError = {
  error?: TrackingCode;
};

type TrackingData = {
  data?: TrackingError;
};

export type TrackingResponse = {
  response?: TrackingData;
};
