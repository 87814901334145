import { MenuProps, GroupBase } from 'react-select';

import { SelectMultipleOption } from './types';
import styles from './select-multiple.module.scss';

export default function SelectMultipleMenu({
  children,
  ...props
}: MenuProps<SelectMultipleOption, true, GroupBase<SelectMultipleOption>>) {
  return (
    <div className={styles.content} {...props}>
      {children}
    </div>
  );
}
