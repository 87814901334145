import { useEffect, useState } from 'react';

import {
  TranslationsType,
  TranslationTypeKey,
  TranslationJsonTypeKey,
  i18n,
} from '@gbm/onboarding-sdk-utils';

type UseLanguageProps = {
  isLoading: boolean;
};

export default function useLanguage(
  translations?: TranslationsType,
): UseLanguageProps {
  const [isLoading, setIsLoading] = useState(false);

  const createTranslations = async () => {
    if (translations) {
      setIsLoading(true);
      Object.keys(translations).forEach((lang) => {
        const langKey = lang as TranslationTypeKey;
        Object.keys(translations[langKey]).forEach((ns) => {
          i18n.addResourceBundle(
            lang,
            ns,
            translations[langKey][ns as TranslationJsonTypeKey],
            true,
            true,
          );
        });
      });

      await i18n.reloadResources();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    createTranslations();
  }, [translations]);

  return { isLoading };
}
