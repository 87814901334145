import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import { SelectMultiple } from '@gbm/onboarding-sdk-ui-components';
import { translations } from '@gbm/onboarding-sdk-utils';
import { QuestionType } from '../../types';
import styles from './select-multiple.module.scss';

type SelectMultipleQuestionProps = {
  question: QuestionType;
  onChangeAnswer: (value: string) => void;
};
const { inputs } = translations;

export default function SelectMultipleQuestion({
  question,
  onChangeAnswer,
}: SelectMultipleQuestionProps) {
  const { t } = useTranslation();
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const answer = watch(question.id);
  const options =
    question.answer?.map((option) => ({
      value: option.id,
      label: option.text as string,
      isDisabled: false,
    })) || [];

  useEffect(() => {
    if (isEmpty(errors[question.id])) {
      onChangeAnswer(answer);
    }
  }, [answer]);

  useEffect(() => {
    if (!isEmpty(question.response)) {
      setValue(question.id, question.response);
    }

    return () => {
      setValue(question.id, '');
    };
  }, []);

  return (
    <Controller
      control={control}
      name={question.id}
      rules={{ required: t(inputs.optionRequired) }}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { error },
      }) => (
        <div className={styles.container}>
          <SelectMultiple
            id={question.id}
            value={value}
            defaultValue={value}
            onChange={onChange}
            onBlur={onBlur}
            options={options}
            label={question.text}
            placeholder={t(inputs.selectOption)}
            hasError={!!error?.message}
            errorMessage={error?.message ? t(error?.message) : ''}
          />
        </div>
      )}
    />
  );
}
