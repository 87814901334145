import { RegisterOptions } from 'react-hook-form';

import {
  REGEX_VALIDATORS,
  PHONE_LENGTH,
  translations,
  RFC_LENGTH,
  HOMOCLAVE_LENGTH,
} from '@gbm/onboarding-sdk-utils';
import { normalizeName, removeSpaces } from '../../../../utils/helpers';
import { NAME_MAX_LENGTH } from './types';

const firstNameValidations: RegisterOptions = {
  required: translations.inputs.firstNameRequired,
  maxLength: {
    value: NAME_MAX_LENGTH,
    message: translations.inputs.maxLengthInvalid,
  },
  setValueAs: normalizeName,
};

const genreValidations: RegisterOptions = {
  required: translations.inputs.optionRequired,
};

const phoneNumberValidations: RegisterOptions = {
  required: translations.inputs.phoneNumberRequired,
  validate: {
    length: (value) =>
      removeSpaces(value).length === PHONE_LENGTH ||
      translations.inputs.phoneNumberLength,
    isValid: (value) =>
      REGEX_VALIDATORS.numbers.test(removeSpaces(value)) ||
      translations.inputs.phoneNumberLength,
  },
};

export const rfcValidations: RegisterOptions = {
  required: translations.inputs.rfcRequired,
  pattern: {
    value: REGEX_VALIDATORS.rfcWithoutHomoclave,
    message: translations.inputs.rfcValid,
  },
  minLength: {
    value: RFC_LENGTH,
    message: translations.inputs.rfcLength,
  },
  maxLength: {
    value: RFC_LENGTH,
    message: translations.inputs.rfcLength,
  },
  setValueAs: (value: string) => value.toUpperCase(),
};

export const homoclaveValidations: RegisterOptions = {
  required: translations.inputs.homoclaveRequired,
  pattern: {
    value: REGEX_VALIDATORS.homoclave,
    message: translations.inputs.homoclaveValid,
  },
  minLength: {
    value: HOMOCLAVE_LENGTH,
    message: translations.inputs.homoclaveValid,
  },
  maxLength: {
    value: HOMOCLAVE_LENGTH,
    message: translations.inputs.homoclaveValid,
  },
  setValueAs: (value: string) => value.toUpperCase(),
};

export default {
  firstName: firstNameValidations,
  lastName: {
    ...firstNameValidations,
    required: translations.inputs.lastNameRequired,
  },
  secondLastName: {
    ...firstNameValidations,
    required: translations.inputs.secondLastNameRequired,
  },
  genre: genreValidations,
  birthState: {
    required: translations.inputs.birthStateRequired,
  },
  phoneNumber: phoneNumberValidations,
  homoclaveValidations,
  rfcValidations,
} as const;
