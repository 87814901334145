import {
  AllHTMLAttributes,
  ChangeEvent,
  FocusEvent,
  ForwardedRef,
  forwardRef,
} from 'react';
import clsx from 'clsx';

import styles from './InputDigit.module.scss';

export type InputDigitProps = Omit<
  AllHTMLAttributes<HTMLInputElement>,
  'type' | 'value'
> & {
  hasError?: boolean;
};

function InputDigit(
  { onFocus, onChange, hasError, ...props }: InputDigitProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.slice(e.target.value.length - 1);
    onChange?.(e);
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.select();
    onFocus?.(e);
  };

  return (
    <input
      type="number"
      className={clsx(styles['input-digit'], {
        [styles['input-digit--error']]: hasError,
      })}
      ref={ref}
      pattern="[0-9]"
      max={9}
      min={0}
      step={1}
      onChange={handleChange}
      onFocus={handleFocus}
      autoComplete="off"
      {...props}
    />
  );
}

export default forwardRef<HTMLInputElement, InputDigitProps>(InputDigit);
