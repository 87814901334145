import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import {
  Button,
  Checkbox,
  Input,
  InputPhone,
  Typography,
  Select,
  SelectOption,
  SkeletonLoader,
  RadioButton,
  ArrowCircleRight,
} from '@gbm/starman-next';
import isEmpty from 'lodash/isEmpty';
import { motion } from 'framer-motion';

import {
  FormContent,
  FormFooter,
  FormRow,
  FormSection,
  NOTIFICATION_TIMEOUT,
  useNotificationProvider,
} from '@gbm/onboarding-sdk-ui-components';
import {
  LITE_IDS,
  DEFAULT_COUNTRY,
  DEFAULT_COUNTRY_PHONE_CODE,
  DATE_FORMATS,
  translations,
  getBirthDays,
  getBirthMonths,
  getBirthYears,
  splitIsoDate,
  formatDate,
  createDate,
  isAdult,
  isValidAge,
  TRACKING_EVENTS,
  TRACKING_NAMES,
} from '@gbm/onboarding-sdk-utils';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import { PHONE_TYPES } from '../../../../api/opening-lite/types';
import { IdentifierType } from '../../../../api/upgrade/types';
import { generateCurp, generateRfc } from '../../../../utils/helpers';
import { useAppDispatch, useAppSelector } from '../../../store';
import { OpeningLayout } from '../../../components/opening-lite';
import {
  useParty,
  useCountriesAndStates,
  useIdentifiers,
} from '../../../hooks';
import {
  saveParty,
  updatePartyPhone,
  savePartyPhone,
  getCurpFromData,
  getPartyPhone,
  getPartyCurp,
  clearErrors,
  selectDataFromCurp,
  selectPhone,
  selectCurp,
  selectErrors,
} from '../../../store/opening-lite';
import { clearErrors as clearUpgradeErrors } from '../../../store/upgrade';
import { REDUCER_STATUS } from '../../../store/types';
import { FormValues, PERSONAL_INFO_FIELDS } from './types';
import formValidations from './validations';
import {
  OPENING_LITE_STEPS,
  OPENING_STATUS,
  OPENING_TYPE,
  STEPS,
} from '../../../providers/opening-lite/types';
import { useOpeningLiteProvider } from '../../../providers/opening-lite';
import { InputRfc } from '../../../components';
import styles from './personal-info.module.scss';

const {
  inputs,
  buttons,
  openingLite: { personalInfo, curp: curpMessages, rfc: rfcMessages },
} = translations;
const birthDays = getBirthDays();
const birthMonths = getBirthMonths();
const birthYears = getBirthYears();

export default function PersonalInfo() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { openingStatus, setStep, saveOpeningLiteStep, configuration } =
    useOpeningLiteProvider();
  const prepopulatedInfo = configuration.prepopulatedInfo?.personalInfo;
  const formMethods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      [PERSONAL_INFO_FIELDS.firstName]: prepopulatedInfo?.firstName,
      [PERSONAL_INFO_FIELDS.lastName]: prepopulatedInfo?.lastName,
      [PERSONAL_INFO_FIELDS.secondLastName]: prepopulatedInfo?.secondLastName,
      [PERSONAL_INFO_FIELDS.birthDay]: prepopulatedInfo?.birthDay,
      [PERSONAL_INFO_FIELDS.birthMonth]: prepopulatedInfo?.birthMonth,
      [PERSONAL_INFO_FIELDS.birthYear]: prepopulatedInfo?.birthYear,
      [PERSONAL_INFO_FIELDS.phoneNumber]: prepopulatedInfo?.phoneNumber,
      [PERSONAL_INFO_FIELDS.rfc]: prepopulatedInfo?.rfc,
      [PERSONAL_INFO_FIELDS.homoclave]: prepopulatedInfo?.homoclaveRfc,
      [PERSONAL_INFO_FIELDS.genre]: prepopulatedInfo?.genre,
    },
  });
  const {
    control,
    register,
    resetField,
    watch,
    trigger: validate,
    handleSubmit,
    setValue,
    clearErrors: clearFormErrors,
    formState: { errors: formErrors, dirtyFields, isValid: isValidForm },
  } = formMethods;
  const { showNotification } = useNotificationProvider();
  const {
    [PERSONAL_INFO_FIELDS.firstName]: firstNameValue,
    [PERSONAL_INFO_FIELDS.lastName]: lastNameValue,
    [PERSONAL_INFO_FIELDS.secondLastName]: secondLastNameValue,
    [PERSONAL_INFO_FIELDS.genre]: genreValue,
    [PERSONAL_INFO_FIELDS.birthState]: birthStateValue,
    [PERSONAL_INFO_FIELDS.birthDay]: birthDayValue,
    [PERSONAL_INFO_FIELDS.birthMonth]: birthMonthValue,
    [PERSONAL_INFO_FIELDS.birthYear]: birthYearValue,
    [PERSONAL_INFO_FIELDS.phoneNumber]: phoneNumberValue,
    [PERSONAL_INFO_FIELDS.homoclave]: homoclaveValue,
    [PERSONAL_INFO_FIELDS.rfc]: rfcValue,
    [PERSONAL_INFO_FIELDS.hasNoRfc]: hasNoRfc,
  } = watch();
  const dataFromCurp = useAppSelector(selectDataFromCurp);
  const curpFromStore = useAppSelector(selectCurp);
  const telephone = useAppSelector(selectPhone);
  const errors = useAppSelector(selectErrors);
  const { countries, states, phoneCodes } =
    useCountriesAndStates(configuration);
  const {
    party,
    isLoading: isLoadingParty,
    isEmpty: isEmptyParty,
    isFulfilled: isFulfilledParty,
    isRejected: isRejectedParty,
  } = useParty(configuration);
  const {
    rfc,
    errors: identifierErrors,
    isFulfilled: isFulfilledRfc,
    isEmpty: isEmptyRfc,
    fetch: fetchRfc,
    saveRfc,
    delete: deleteId,
  } = useIdentifiers(configuration);
  const [verifiedData, setVerifiedData] = useState({
    isChecked: false,
    isTouched: false,
  });
  const [hasSecondLastName, setHasSecondLastName] = useState(true);
  const [submittedParty, setSubmittedParty] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [curp, setCurp] = useState('');
  const [phoneNumberCode, setPhoneNumberCode] = useState(
    prepopulatedInfo?.phoneCode ?? DEFAULT_COUNTRY_PHONE_CODE,
  );
  const [isLoadingData, setIsLoadingData] = useState(
    openingStatus.data.opening_status === OPENING_STATUS.inProgress,
  );
  const loadingForm =
    isLoadingParty ||
    telephone.status === REDUCER_STATUS.pending ||
    rfc.status === REDUCER_STATUS.pending;
  const dataComesFromCurp = dataFromCurp.status === REDUCER_STATUS.resolved;
  const isFieldDisabled = verifiedData.isChecked || !isEmptyParty;
  const isFieldReadOnly = isFieldDisabled || dataComesFromCurp;
  const allowInvalidCurps = configuration.validations?.allowInvalidCurps;
  const validateTaxIdentifier =
    configuration.validations?.validateTaxIdentifier;
  const [submittedRfc, setSubmittedRfc] = useState(false);
  const [birthDateError, setBirthDateError] = useState<string>();
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.personalInfo,
    });
  }, []);

  useEffect(() => {
    /* istanbul ignore else */
    if (dataComesFromCurp) {
      const {
        first_name: firstName,
        first_last_name: lastName,
        second_last_name: secondLastName,
        birth_date: birthDateValue,
        birth_state: birthStateId,
        gender,
      } = dataFromCurp.data;
      const [birthDay, birthMonth, birthYear] = splitIsoDate(birthDateValue);

      setValue(PERSONAL_INFO_FIELDS.firstName, firstName);
      setValue(PERSONAL_INFO_FIELDS.lastName, lastName);
      setValue(PERSONAL_INFO_FIELDS.birthState, birthStateId);
      setValue(PERSONAL_INFO_FIELDS.birthDay, birthDay);
      setValue(PERSONAL_INFO_FIELDS.birthMonth, birthMonth);
      setValue(PERSONAL_INFO_FIELDS.birthYear, birthYear);
      setValue(PERSONAL_INFO_FIELDS.genre, gender);
      secondLastName
        ? setValue(PERSONAL_INFO_FIELDS.secondLastName, secondLastName)
        : setHasSecondLastName(false);
    }
  }, [dataFromCurp.status]);

  useEffect(() => {
    /* istanbul ignore else */
    if (isFulfilledParty) {
      if (submittedParty && !submitted) {
        saveOpeningLiteStep(OPENING_LITE_STEPS.personalInfo);
        setSubmitted(true);
        saveOrUpdatePhone();
      }

      if (isEmpty(telephone.data) && !submittedParty) {
        dispatch(
          getPartyPhone({
            config: configuration,
            partyId: party.data.party_id,
          }),
        );
      }

      /* istanbul ignore else */
      if (isEmptyRfc && !isFulfilledRfc) {
        fetchRfc();
      }
    }
  }, [party.status]);

  useEffect(() => {
    if (telephone.status === REDUCER_STATUS.resolved) {
      if (!isEmpty(telephone.data) && submitted) {
        handleSaveRfc();
      } else {
        setPartyData();
      }
    }
  }, [telephone.status, submitted]);

  useEffect(() => {
    if (curpFromStore.status === REDUCER_STATUS.rejected) {
      mixpanel.track(TRACKING_EVENTS.documentValidated, {
        name: TRACKING_NAMES.curp,
        success: true,
      });
      showErrors();
    }

    if (curpFromStore.status === REDUCER_STATUS.resolved) {
      setValue(
        PERSONAL_INFO_FIELDS.rfc,
        generateRfc(curpFromStore.data.value),
        {
          shouldValidate: true,
        },
      );
      mixpanel.track(TRACKING_EVENTS.documentValidated, {
        name: TRACKING_NAMES.curp,
        success: true,
      });
      setIsLoadingData(false);
    }
  }, [curpFromStore.status, curp]);

  useEffect(() => {
    if (
      (isRejectedParty && submittedParty) ||
      (telephone.status === REDUCER_STATUS.rejected && submitted)
    ) {
      showErrors();
    }
  }, [party.status, telephone.status, submittedParty, submitted, errors]);

  useEffect(() => {
    if (rfc.status === REDUCER_STATUS.resolved) {
      if (!submittedRfc && !isEmpty(rfc.data)) {
        setValue(PERSONAL_INFO_FIELDS.rfc, rfc.data.value.substring(0, 10), {
          shouldValidate: true,
        });
        setValue(
          PERSONAL_INFO_FIELDS.homoclave,
          rfc.data.value.substring(10, 13),
          {
            shouldValidate: true,
          },
        );
      }
      if (submittedRfc) {
        mixpanel.track(TRACKING_EVENTS.fieldEnabled, {
          name: TRACKING_NAMES.taxId,
          success: true,
        });
        handleContinue();
      }
    }

    if (rfc.status === REDUCER_STATUS.rejected) {
      mixpanel.track(TRACKING_EVENTS.fieldEnabled, {
        name: TRACKING_NAMES.taxId,
        success: false,
      });
      if (validateTaxIdentifier) {
        showErrors();
      } else {
        handleContinue();
      }
    }
  }, [rfc.status]);

  useEffect(() => {
    const validBirthDate = validateBirthDate();

    if (
      firstNameValue &&
      lastNameValue &&
      genreValue &&
      birthStateValue &&
      validBirthDate
    ) {
      if (!allowInvalidCurps) {
        if (!dataComesFromCurp) {
          const birthDate = formatDate(
            birthDayValue,
            birthMonthValue,
            birthYearValue,
            DATE_FORMATS.yyyyMMdd,
          );
          dispatch(
            getCurpFromData({
              birthDate,
              firstName: firstNameValue,
              lastName: lastNameValue,
              secondLastName: secondLastNameValue,
              gender: genreValue,
              birthState: birthStateValue,
              config: configuration,
            }),
          );
        } else {
          setCurp(dataFromCurp.data.curp);
        }
      } else if (!dataComesFromCurp) {
        handleGenerateCurp();
      } else {
        setCurp(dataFromCurp.data.curp);
        setValue(
          PERSONAL_INFO_FIELDS.rfc,
          generateRfc(dataFromCurp.data.curp),
          {
            shouldValidate: true,
          },
        );
      }
    }
  }, [
    firstNameValue,
    lastNameValue,
    secondLastNameValue,
    genreValue,
    birthStateValue,
    birthDayValue,
    birthMonthValue,
    birthYearValue,
  ]);

  const validateBirthDate = () => {
    setBirthDateError(undefined);

    if (!birthDayValue || !birthMonthValue || !birthYearValue) {
      return false;
    }

    const birthDate = createDate(
      birthDayValue,
      birthMonthValue,
      birthYearValue,
    );

    if (!isAdult(birthDate)) {
      setBirthDateError(t(translations.inputs.birthDateIsNotAdult));
      return false;
    }

    if (!isValidAge(birthDate)) {
      setBirthDateError(t(translations.inputs.birthDateMaxAge));
      return false;
    }

    return true;
  };

  const handleContinue = () => {
    mixpanel.track(TRACKING_EVENTS.screenFilled, {
      name: TRACKING_NAMES.personalInfo,
      success: true,
    });

    setStep(STEPS.addressInfo);
    const birthState = states.data.find(
      (state) => state.value === party.data.birth_state,
    )?.label as string;
    configuration.onPersonalInfoCompleted?.({
      birthState,
      firstName: party.data.first_name,
      lastName: party.data.first_last_name,
      secondLastName: party.data.second_last_name,
      birthDate: party.data.birth_date,
      genre: party.data.gender,
      curp: curpFromStore.data.value || curp,
      phone: {
        countryCode: telephone.data.country_code,
        phoneNumber: telephone.data.phone_number,
        type: telephone.data.telephone_type,
      },
    });
  };

  const showErrors = () => {
    let hasError = false;
    if (!isEmpty(errors.curp)) {
      showNotification({
        description: errors.curp?.message,
        kind: 'error',
      });
      hasError = true;
    }

    if (!isEmpty(errors.party)) {
      showNotification({
        description: errors.party?.message,
        kind: 'error',
      });
      hasError = true;
    }

    if (!isEmpty(errors.telephone)) {
      showNotification({
        description: errors.telephone?.message,
        kind: 'error',
      });
      hasError = true;
    }

    if (!isEmpty(identifierErrors.rfc)) {
      showNotification({
        description: identifierErrors.rfc?.message,
        kind: 'error',
      });

      setTimeout(() => {
        dispatch(clearUpgradeErrors());
      }, NOTIFICATION_TIMEOUT);
    }

    if (hasError) {
      setTimeout(() => {
        dispatch(clearErrors());
      }, NOTIFICATION_TIMEOUT);
    }
  };

  const saveOrUpdatePhone = () => {
    const phoneCountryCode = countries.data.find(
      (country) => country.phoneCode === phoneNumberCode,
    );
    /* istanbul ignore else */
    if (phoneCountryCode) {
      if (
        !isEmpty(telephone.data) &&
        telephone.data?.telephone_type === PHONE_TYPES.cellphone
      ) {
        dispatch(
          updatePartyPhone({
            config: configuration,
            partyId: party.data.party_id,
            phone: {
              country_code: phoneCountryCode.key,
              extension: undefined,
              id: telephone.data.id,
              phone_number: phoneNumberValue,
              telephone_type: PHONE_TYPES.cellphone,
            },
          }),
        );
      } else {
        dispatch(
          savePartyPhone({
            config: configuration,
            partyId: party.data.party_id,
            phone: {
              country_code: phoneCountryCode.key,
              extension: undefined,
              phone_number: phoneNumberValue,
              telephone_type: PHONE_TYPES.cellphone,
            },
          }),
        );
      }
    }
  };

  const handleHasSecondLastNameCheckbox = () => {
    clearFormErrors(PERSONAL_INFO_FIELDS.secondLastName);
    resetField(PERSONAL_INFO_FIELDS.secondLastName, { defaultValue: '' });
    setHasSecondLastName(!hasSecondLastName);
  };

  const handleVerifiedDataClick = async () => {
    mixpanel.track(TRACKING_EVENTS.checkChecked, {
      name: TRACKING_NAMES.personalInfoCorrect,
    });
    setVerifiedData({ ...verifiedData, isTouched: true });
    await handleSubmit(handleSubmitCallback)();
  };

  const handleSubmitCallback = () => {
    /* istanbul ignore else */
    if (phoneNumberCode) {
      setVerifiedData({
        ...verifiedData,
        isChecked: false,
        isTouched: true,
      });

      if (!verifiedData.isChecked && isEmptyParty) {
        const isValidRfc = !hasNoRfc ? !!homoclaveValue && !!rfcValue : true;
        const isValidCurp = !isEmpty(curpFromStore.data) || !!curp;

        setVerifiedData({
          ...verifiedData,
          isChecked:
            isValidCurp && isValidRfc && isValidForm && !birthDateError,
        });
      } else {
        setVerifiedData({
          ...verifiedData,
          isChecked: !verifiedData.isChecked,
        });
      }
    }
  };

  const handleGenerateCurp = () => {
    setCurp('');
    const birthDate = formatDate(
      birthDayValue,
      birthMonthValue,
      birthYearValue,
      DATE_FORMATS.ddMMyyyyWithDash,
    );
    const generatedCurp = generateCurp(states, {
      birthDate,
      firstName: firstNameValue,
      lastName: lastNameValue,
      secondLastName: secondLastNameValue,
      gender: genreValue,
      birthState: birthStateValue,
    });
    setCurp(generatedCurp);
    setValue(PERSONAL_INFO_FIELDS.rfc, generateRfc(generatedCurp), {
      shouldValidate: true,
    });
  };

  const handleContinueButtonClick = async () => {
    const validPhone = await validate(PERSONAL_INFO_FIELDS.phoneNumber);
    /* istanbul ignore else */
    if (validPhone) {
      if (isEmptyParty && !!phoneNumberValue) {
        setSubmittedParty(true);
        const partyBirthDate = formatDate(
          birthDayValue,
          birthMonthValue,
          birthYearValue,
          DATE_FORMATS.ddMMyyyy,
        );
        const partyFields = {
          first_name: firstNameValue,
          first_last_name: lastNameValue,
          second_last_name: secondLastNameValue,
          gender: genreValue,
          birth_date: partyBirthDate,
          birth_state: birthStateValue,
          birth_country: DEFAULT_COUNTRY.key,
          curp: curpFromStore.data.value || curp,
        };
        dispatch(
          saveParty({
            ...partyFields,
            config: configuration,
          }),
        );
      } else if (!!phoneNumberValue && dirtyFields?.phoneNumber) {
        saveOrUpdatePhone();
        setSubmitted(true);
      } else {
        handleSaveRfc();
      }
    }
  };

  const setPartyData = () => {
    let phoneNumber = '';

    if (!isEmpty(telephone.data)) {
      const {
        phone_number: phoneNumberFromStore,
        country_code: telephoneCode,
      } = telephone.data;
      const phoneCountryCode = countries.data.find(
        (country) => country.key === telephoneCode,
      );
      phoneNumber = phoneNumberFromStore;
      phoneCountryCode && setPhoneNumberCode(phoneCountryCode.phoneCode);
    }

    const {
      gender,
      party_id: partyId,
      first_name: firstName,
      first_last_name: lastName,
      second_last_name: secondLastName,
      birth_state: birthStateId,
      birth_date: birthDateValue,
    } = party.data;
    const [birthDay, birthMonth, birthYear] = splitIsoDate(birthDateValue);

    setValue(PERSONAL_INFO_FIELDS.firstName, firstName);
    setValue(PERSONAL_INFO_FIELDS.lastName, lastName);
    setValue(PERSONAL_INFO_FIELDS.phoneNumber, phoneNumber);
    setValue(PERSONAL_INFO_FIELDS.birthState, birthStateId);
    setValue(PERSONAL_INFO_FIELDS.birthDay, birthDay);
    setValue(PERSONAL_INFO_FIELDS.birthMonth, birthMonth);
    setValue(PERSONAL_INFO_FIELDS.birthYear, birthYear);
    setValue(PERSONAL_INFO_FIELDS.genre, gender);
    secondLastName
      ? setValue(PERSONAL_INFO_FIELDS.secondLastName, secondLastName)
      : setHasSecondLastName(false);

    if (isEmpty(curpFromStore.data)) {
      dispatch(
        getPartyCurp({
          partyId,
          config: configuration,
        }),
      );
    }
  };

  const handleSaveRfc = () => {
    const rfcWithHomoclave = `${rfcValue}${homoclaveValue}`;
    if (
      !hasNoRfc &&
      !!rfcWithHomoclave &&
      (dirtyFields?.[PERSONAL_INFO_FIELDS.rfc] ||
        dirtyFields?.[PERSONAL_INFO_FIELDS.homoclave])
    ) {
      saveRfc(rfcWithHomoclave);
      setSubmittedRfc(true);
    } else if (hasNoRfc && !isEmpty(rfc.data)) {
      deleteId(rfc.data as IdentifierType);
      setSubmittedRfc(true);
    } else {
      handleContinue();
    }
  };

  const handleHasNoRfcCheckbox = () => {
    setVerifiedData({
      ...verifiedData,
      isChecked: false,
    });
  };

  return (
    <OpeningLayout current={1}>
      <motion.div
        className={styles.container}
        id={LITE_IDS.viewPersonalInfo}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <FormProvider {...formMethods}>
          <FormContent>
            <FormSection
              title={t(personalInfo.title)}
              description={t(personalInfo.subtitle)}
            >
              <FormRow>
                {isLoadingData ? (
                  <SkeletonLoader kind="large" reverse />
                ) : (
                  <Input
                    widthSize="fluid"
                    label={t(inputs.names)}
                    id={PERSONAL_INFO_FIELDS.firstName}
                    type="text"
                    placeholder=""
                    inputMode="text"
                    readOnly={isFieldReadOnly}
                    status={formErrors?.firstName ? 'error' : undefined}
                    className={styles.upper}
                    hintText={
                      formErrors?.firstName
                        ? t(formErrors.firstName.message as string)
                        : undefined
                    }
                    {...register(
                      PERSONAL_INFO_FIELDS.firstName,
                      formValidations.firstName,
                    )}
                  />
                )}
                {isLoadingData ? (
                  <SkeletonLoader kind="large" reverse />
                ) : (
                  <Input
                    widthSize="fluid"
                    label={t(inputs.lastName)}
                    id={PERSONAL_INFO_FIELDS.lastName}
                    type="text"
                    placeholder=""
                    inputMode="text"
                    readOnly={isFieldReadOnly}
                    status={formErrors?.lastName ? 'error' : undefined}
                    className={styles.upper}
                    hintText={
                      formErrors?.lastName
                        ? t(formErrors.lastName.message as string)
                        : undefined
                    }
                    {...register(
                      PERSONAL_INFO_FIELDS.lastName,
                      formValidations.lastName,
                    )}
                  />
                )}
              </FormRow>
              <FormRow>
                {isLoadingData ? (
                  <SkeletonLoader kind="large" reverse />
                ) : (
                  <div className={styles['input-second-last-name']}>
                    <Input
                      widthSize="fluid"
                      label={t(inputs.secondLastName)}
                      id={PERSONAL_INFO_FIELDS.secondLastName}
                      type="text"
                      placeholder=""
                      inputMode="text"
                      disabled={!hasSecondLastName}
                      readOnly={isFieldReadOnly}
                      status={formErrors?.secondLastName ? 'error' : undefined}
                      className={styles.upper}
                      hintText={
                        formErrors?.secondLastName
                          ? t(formErrors.secondLastName.message as string)
                          : undefined
                      }
                      {...register(PERSONAL_INFO_FIELDS.secondLastName, {
                        ...formValidations.secondLastName,
                        required: hasSecondLastName
                          ? inputs.secondLastNameRequired
                          : false,
                      })}
                    />
                    <div className={styles['checkbox-container']}>
                      <Checkbox
                        id="hasNoSecondLastName"
                        checked={
                          !hasSecondLastName ||
                          (!dataFromCurp.data.second_last_name &&
                            dataComesFromCurp)
                        }
                        disabled={isFieldReadOnly}
                        {...register(PERSONAL_INFO_FIELDS.hasSecondLastName, {
                          onChange: handleHasSecondLastNameCheckbox,
                        })}
                      >
                        <Typography variant="small">
                          {t(inputs.hasNoSecondLastName)}
                        </Typography>
                      </Checkbox>
                    </div>
                  </div>
                )}
                {isLoadingData ? (
                  <SkeletonLoader kind="large" reverse />
                ) : (
                  <div className={styles['genre-container']}>
                    <Typography variant="small">{t(inputs.genre)}</Typography>
                    <Controller
                      control={control}
                      name={PERSONAL_INFO_FIELDS.genre}
                      rules={formValidations.genre}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <div className={styles['genre-container-radios']}>
                            <RadioButton
                              disabled={isFieldReadOnly}
                              value="female"
                              checked={value === 'female'}
                              onChange={onChange}
                              widthSize="fluid"
                            >
                              {t(inputs.female)}
                            </RadioButton>
                            <RadioButton
                              disabled={isFieldReadOnly}
                              value="male"
                              checked={value === 'male'}
                              onChange={onChange}
                              widthSize="fluid"
                            >
                              {t(inputs.male)}
                            </RadioButton>
                          </div>
                          {error?.message ? (
                            <div className={styles['genre-container-error']}>
                              <Typography variant="xsmall">
                                {t(error.message)}
                              </Typography>
                            </div>
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                )}
              </FormRow>
              <FormRow>
                {isLoadingData ? (
                  <SkeletonLoader kind="large" reverse />
                ) : (
                  <Controller
                    control={control}
                    name={PERSONAL_INFO_FIELDS.birthState}
                    rules={formValidations.birthState}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Select
                        widthSize="fluid"
                        hintText={
                          error?.message ? t(error?.message) : undefined
                        }
                        status={formErrors?.birthState ? 'error' : undefined}
                        label={t(inputs.birthState)}
                        onChange={onChange}
                        value={value}
                        disabled={isEmpty(states.data) || isFieldReadOnly}
                      >
                        <SelectOption value="" hidden>
                          {t(inputs.birthStatePlaceholder)}
                        </SelectOption>
                        {states.data.map(
                          ({ label, value: optionValue, key }) => (
                            <SelectOption value={optionValue} key={key}>
                              {label}
                            </SelectOption>
                          ),
                        )}
                      </Select>
                    )}
                  />
                )}
                {isLoadingData ? (
                  <SkeletonLoader kind="large" reverse />
                ) : (
                  <div className={styles['birthdate-container']}>
                    <Typography variant="small">
                      {t(inputs.birthDate)}
                    </Typography>
                    <FormRow className={styles['birthdate-row']}>
                      <div className={styles['birthdate-selects']}>
                        <Controller
                          control={control}
                          name={PERSONAL_INFO_FIELDS.birthDay}
                          rules={{
                            required: true,
                          }}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <Select
                              widthSize="fluid"
                              hintText={
                                error?.message ? t(error?.message) : undefined
                              }
                              status={
                                formErrors?.birthDay ? 'error' : undefined
                              }
                              onChange={onChange}
                              value={value}
                              disabled={isFieldReadOnly}
                            >
                              <SelectOption value="" hidden>
                                {t(inputs.birthDayPlaceholder)}
                              </SelectOption>
                              {birthDays.map((day) => (
                                <SelectOption
                                  value={day}
                                  key={`birthDay-${day}`}
                                >
                                  {day}
                                </SelectOption>
                              ))}
                            </Select>
                          )}
                        />
                        <Controller
                          control={control}
                          name={PERSONAL_INFO_FIELDS.birthMonth}
                          rules={{
                            required: true,
                          }}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <Select
                              widthSize="fluid"
                              hintText={
                                error?.message ? t(error?.message) : undefined
                              }
                              status={
                                formErrors?.birthMonth ? 'error' : undefined
                              }
                              onChange={onChange}
                              value={value}
                              disabled={isFieldReadOnly}
                            >
                              <SelectOption value="" hidden>
                                {t(inputs.birthMonthPlaceholder)}
                              </SelectOption>
                              {birthMonths.map((month) => (
                                <SelectOption
                                  value={month}
                                  key={`birthMonth-${month}`}
                                >
                                  {month}
                                </SelectOption>
                              ))}
                            </Select>
                          )}
                        />
                      </div>
                      <div className={styles['birthdate-year']}>
                        <Controller
                          control={control}
                          name={PERSONAL_INFO_FIELDS.birthYear}
                          rules={{
                            required: true,
                          }}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <Select
                              widthSize="fluid"
                              hintText={
                                error?.message ? t(error?.message) : undefined
                              }
                              status={
                                formErrors?.birthYear ? 'error' : undefined
                              }
                              onChange={onChange}
                              value={value}
                              disabled={isFieldReadOnly}
                            >
                              <SelectOption value="" hidden>
                                {t(inputs.birthYearPlaceholder)}
                              </SelectOption>
                              {birthYears.map((year) => (
                                <SelectOption
                                  value={year}
                                  key={`birthYear-${year}`}
                                >
                                  {year}
                                </SelectOption>
                              ))}
                            </Select>
                          )}
                        />
                      </div>
                    </FormRow>
                    {birthDateError ? (
                      <div className={styles['birthdate-error']}>
                        <Typography variant="xsmall">
                          {birthDateError}
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                )}
              </FormRow>
              <FormRow>
                {isLoadingData ? (
                  <SkeletonLoader kind="large" reverse />
                ) : (
                  <Input
                    widthSize="fluid"
                    type="text"
                    label={t(curpMessages.title)}
                    inputMode="text"
                    className={styles.upper}
                    value={curpFromStore.data.value || curp}
                    readOnly
                  />
                )}
                {isLoadingData ? (
                  <SkeletonLoader kind="large" reverse />
                ) : (
                  <Controller
                    control={control}
                    name={PERSONAL_INFO_FIELDS.phoneNumber}
                    rules={formValidations.phoneNumber}
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { error },
                    }) => (
                      <InputPhone
                        widthSize="fluid"
                        id={PERSONAL_INFO_FIELDS.phoneNumber}
                        name={PERSONAL_INFO_FIELDS.phoneNumber}
                        label={t(inputs.phoneNumber)}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        status={error?.message ? 'error' : undefined}
                        hintText={
                          error?.message ? t(error?.message) : undefined
                        }
                        selectProps={{
                          onChange: (countryCode) =>
                            setPhoneNumberCode(countryCode),
                          value: phoneNumberCode,
                        }}
                        selectOptions={phoneCodes.map(({ phoneCode }) => ({
                          value: phoneCode,
                          children: `+ ${phoneCode}`,
                        }))}
                      />
                    )}
                  />
                )}
              </FormRow>
            </FormSection>
            <FormSection
              title={t(rfcMessages.title)}
              description={t(rfcMessages.subtitle)}
            >
              <FormRow>
                <InputRfc
                  appName={configuration.appName}
                  environment={configuration.environment}
                  hasNoRfcId={PERSONAL_INFO_FIELDS.hasNoRfc}
                  homoclaveId={PERSONAL_INFO_FIELDS.homoclave}
                  id={PERSONAL_INFO_FIELDS.rfc}
                  openingType={OPENING_TYPE.liteForTracking}
                  isEnabledTrackEvents={configuration.isEnabledTrackEvents}
                  loading={isLoadingData}
                  onHasNoRfcClick={handleHasNoRfcCheckbox}
                />
              </FormRow>
            </FormSection>
          </FormContent>
          <FormFooter>
            {isLoadingData ? (
              <div className={styles['checkbox-loader']}>
                <SkeletonLoader kind="large" reverse />
              </div>
            ) : (
              <Checkbox
                id="personalDataVerified"
                name="personalDataVerified"
                checked={verifiedData.isChecked}
                onClick={handleVerifiedDataClick}
              >
                <Typography variant="small">
                  {t(inputs.dataVerified)}
                </Typography>
              </Checkbox>
            )}
            <Button
              disabled={!verifiedData.isChecked}
              loading={loadingForm}
              kind="primary"
              onClick={handleContinueButtonClick}
              id={LITE_IDS.personalInfo}
              icon={
                <ArrowCircleRight size={styles['icon-size']} weight="fill" />
              }
            >
              {t(buttons.continue)}
            </Button>
          </FormFooter>
        </FormProvider>
      </motion.div>
    </OpeningLayout>
  );
}
