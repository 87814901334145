/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import getPkce from 'oauth-pkce';

import {
  makeTrackingErrorProps,
  ENVIRONMENTS,
  GENERIC_ERROR,
  getErrorMessage,
  translations,
  TRACKING_EVENTS,
} from '@gbm/onboarding-sdk-utils';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import { useNotificationProvider } from '@gbm/onboarding-sdk-ui-components';
import { Auth, OpeningLite } from '../../../api';
import { GeolocationType } from '../../../api/opening-lite/types';
import { resolveUrlEmailParam } from '../../../utils/helpers';
import { API_CODES } from '../../../api/auth/types';
import {
  AuthContextValueType,
  StepType,
  STEPS,
  AuthProviderProps,
  ALLOWED_CLIENT_IDS_TO_FETCH_CONTRACTS,
  PKCE_LENGTH,
  PkceType,
  ACTION_PARAMS,
  MfaCodeModalDataType,
} from './types';
import { MfaCode } from '../../components';

/* istanbul ignore next */
const AuthContext = createContext<AuthContextValueType>({
  email: '',
  step: STEPS.signIn,
  setStep: () => null,
  handleSignIn: () => null,
  handleSignUp: () => null,
  handleSignOut: () => null,
  handleResendCode: () => null,
  handlePasswordRecovery: () => null,
  configuration: {
    environment: ENVIRONMENTS.development,
    clientId: '',
    appName: '',
  },
  isLoading: false,
});
export const useAuthProvider = () => useContext(AuthContext);

export default function AuthProvider({
  children,
  ...config
}: AuthProviderProps) {
  const { t } = useTranslation();
  const { showNotification } = useNotificationProvider();
  const [step, setStep] = useState<StepType>(STEPS.signIn);
  const [userEmail, setUserEmail] = useState('');
  const [pkce, setPkce] = useState<PkceType>({ verifier: '', challenge: '' });
  const authApi = useMemo(
    () => new Auth(config.environment),
    [config.environment],
  );
  const mixpanel = useMixpanel(config.environment, config.isEnabledTrackEvents);
  const [isLoading, setIsLoading] = useState(true);
  const [displayMfaCode, setDisplayMfaCode] = useState(false);
  const [mfaCodeHasError, setMfaCodeHasError] = useState(false);
  const [isClosingMfaModal, setIsClosingMfaModal] = useState(false);
  const [isLoadingMfaVerification, setIsLoadingMfaVerification] =
    useState(false);
  const [mfaCodeData, setMfaCodeData] = useState<MfaCodeModalDataType>();

  useEffect(() => {
    getPkce(PKCE_LENGTH, (error, { verifier, challenge }) => {
      setPkce({ verifier, challenge });
    });
    handleUrlParams();
  }, []);

  const handleUrlParams = () => {
    const params = new URLSearchParams(window.location.search);
    const action = params.get('action');
    const clientId = params.get('client_id');
    const code = params.get('code');
    const email = params.get('user');

    if (action === ACTION_PARAMS.confirmation) {
      const resolvedEmail = resolveUrlEmailParam(email as string);
      handleConfirmation(clientId as string, resolvedEmail, code as string);
      window.history.replaceState({}, '', window.location.href.split('?')[0]);
    } else {
      setIsLoading(false);
    }
  };

  const handleConfirmation = async (
    clientId: string,
    email: string,
    code: string,
  ) => {
    const { data, err } = await authApi.signUpConfirmation(
      clientId,
      email,
      code,
    );
    setIsLoading(false);

    if (!data && err) {
      showNotification({
        description: getErrorMessage(err.response),
        kind: 'error',
      });
      return;
    }

    const { id, message = GENERIC_ERROR } = data;

    if (id === API_CODES.success) {
      showNotification({
        title: t(translations.auth.signUpConfirmation.confirmedTitle),
        description: t(translations.auth.signUpConfirmation.confirmed),
        kind: 'success',
      });
    } else {
      showNotification({
        description: message,
        kind: 'error',
      });
    }
  };

  const getContractsData = async (accessToken: string, email: string) => {
    if (
      ALLOWED_CLIENT_IDS_TO_FETCH_CONTRACTS.includes(config.clientId) &&
      config.fetchContractInfo
    ) {
      /* istanbul ignore next */
      const openingLiteApi = new OpeningLite({
        accessToken,
        email,
        environment: config.environment,
        clientId: config.clientId,
        appName: config.appName,
        refreshToken: '',
        onRefreshSession: handleSignOut,
        onSessionRefreshed: () => null,
        onSignedOut: config?.onSignedOut || (() => null),
      });

      const { data } = await openingLiteApi.getContracts();
      let contractsData = {};
      if (data?.length && data[0].contract_id) {
        const { data: account } = await openingLiteApi.getContractAccount(
          data[0].contract_id,
        );
        contractsData = {
          contract: data[0],
          account: (account?.length && account[0]) || {},
        };
      }

      return contractsData;
    }

    return {
      contract: {},
      account: {},
    };
  };

  const handleSuccessfulSignIn = async (
    authorizationCode: string,
    email: string,
  ) => {
    const tokenResponse = await authApi.sessionToken(
      config.clientId,
      authorizationCode,
      pkce.verifier,
    );
    const { err: tokenError } = tokenResponse;

    if (tokenResponse.data.id !== API_CODES.success && tokenError) {
      showNotification({
        description: getErrorMessage(tokenError.response),
        kind: 'error',
      });
      return;
    }

    const {
      data: { accessToken, expiresIn, identityToken, refreshToken, tokenType },
    } = tokenResponse;
    const sessionResponse = await authApi.postSession(
      identityToken,
      accessToken,
    );
    const { err: sessionError } = sessionResponse;

    if (sessionError) {
      showNotification({
        description: getErrorMessage(sessionError.response),
        kind: 'error',
      });
      return;
    }

    const newUser = {
      accessToken,
      expiresIn,
      identityToken,
      refreshToken,
      tokenType,
      email,
    };

    const contracts = await getContractsData(accessToken, email);
    setUserEmail(email);
    setStep(STEPS.signedIn);
    config.onSignedIn?.({ ...newUser, ...contracts });
  };

  const handleSignIn = async (
    email: string,
    password: string,
    geolocation: GeolocationType,
  ) => {
    const { data, err } = await authApi.signIn(
      config.clientId,
      email,
      password,
      pkce.challenge,
      geolocation,
    );

    if (!data && err) {
      showNotification({
        description: getErrorMessage(err.response),
        kind: 'error',
      });
      return;
    }

    const { id, message, challengeInfo, authorizationCode } = data;

    if (id === API_CODES.success) {
      await handleSuccessfulSignIn(authorizationCode, email);
    } else if (id === API_CODES.userNotConfirmedException) {
      setUserEmail(email);
      setStep(STEPS.confirmation);
    } else if (id === API_CODES.challengeRequired) {
      setMfaCodeData({
        challengeInfo,
        email,
        password,
        geolocation,
      });
      setDisplayMfaCode(true);
    } else {
      showNotification({
        description: message,
        kind: 'error',
      });
    }
  };

  const handleSignUp = async (
    email: string,
    password: string,
    recaptchaValue: string,
  ) => {
    const { data, err, headers } = await authApi.signUp(
      config.clientId,
      email,
      password,
      recaptchaValue,
    );

    if (!data && err) {
      const trackingErrorProps = makeTrackingErrorProps(err, headers);
      mixpanel.track(TRACKING_EVENTS.signUpCompleted, trackingErrorProps);
      showNotification({
        description: getErrorMessage(err.response),
        kind: 'error',
      });
      return;
    }

    const { id, message } = data;

    switch (id) {
      case API_CODES.success:
      case API_CODES.userNotConfirmedException:
      case API_CODES.mustSendVerificationCode:
        mixpanel.track(TRACKING_EVENTS.signUpCompleted, {
          success: true,
        });
        setUserEmail(email);
        setStep(STEPS.confirmation);
        config.onSignedUp?.(email);
        break;
      case API_CODES.invalidUserForSignUp:
      default:
        showNotification({
          description: message,
          kind: 'error',
        });
        break;
    }
  };

  const handleResendCode = async (recaptchaValue: string) => {
    const { data, err } = await authApi.resendSignUpCode(
      config.clientId,
      userEmail,
      recaptchaValue,
    );

    if (!data && err) {
      showNotification({
        description: getErrorMessage(err.response),
        kind: 'error',
      });
      return;
    }

    const { id, message } = data;

    if (id === API_CODES.success) {
      showNotification({
        title: t(translations.base.info),
        description: t(translations.auth.signUpConfirmation.resendCode),
        kind: 'info',
      });
    } else {
      showNotification({
        description: message,
        kind: 'error',
      });
    }
  };

  const handlePasswordRecovery = async (email: string) => {
    const { data, err } = await authApi.passwordRecovery(
      config.clientId,
      email,
    );

    if (!data && err) {
      showNotification({
        description: getErrorMessage(err.response),
        kind: 'error',
      });
      return;
    }

    const { id, message } = data;

    if (id === API_CODES.success) {
      setUserEmail(email);
      setStep(STEPS.passwordRecoverySent);
    } else if (id === API_CODES.userNotConfirmedException) {
      setUserEmail(email);
      setStep(STEPS.confirmation);
    } else {
      showNotification({
        description: message,
        kind: 'error',
      });
    }
  };

  const handleSignOut = () => {
    setUserEmail('');
    setStep(STEPS.signIn);
    config.onSignedOut?.();
  };

  const handleCloseMfaCode = () => {
    setDisplayMfaCode(false);
    setMfaCodeData(undefined);
    setIsClosingMfaModal(false);
  };

  const handleValidateMfaCode = async (mfaCode: string) => {
    setMfaCodeHasError(false);
    setIsLoadingMfaVerification(true);
    const { challengeInfo, email, geolocation } =
      mfaCodeData as MfaCodeModalDataType;

    const { data, err } = await authApi.signInMfa(
      config.clientId,
      email,
      pkce.challenge,
      mfaCode,
      challengeInfo,
      geolocation,
    );

    if (err) {
      showNotification({
        description: getErrorMessage(err.response),
        kind: 'error',
      });
      setIsLoadingMfaVerification(false);
      setMfaCodeHasError(true);

      if (data.id === API_CODES.lockoutPolicyException) {
        setIsClosingMfaModal(true);
        setTimeout(handleCloseMfaCode, 400);
      }

      return;
    }

    await handleSuccessfulSignIn(data.authorizationCode, email);
  };

  return (
    <AuthContext.Provider
      value={{
        step,
        email: userEmail,
        setStep,
        handleSignIn,
        handleSignUp,
        handleSignOut,
        handleResendCode,
        handlePasswordRecovery,
        configuration: config,
        isLoading,
      }}
    >
      {children}
      {displayMfaCode && (
        <MfaCode
          onClose={handleCloseMfaCode}
          isLoading={isLoadingMfaVerification}
          onSubmit={handleValidateMfaCode}
          hasError={mfaCodeHasError}
          isClosing={isClosingMfaModal}
        />
      )}
    </AuthContext.Provider>
  );
}
