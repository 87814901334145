// 15 minutes
export const TIME_TO_SHOW_MODAL = 900000;
// 5 minutes
export const TIME_TO_LOGOUT = 300000;
export const TIME_TO_LOGOUT_SECS = TIME_TO_LOGOUT / 1000;

export const USER_ACTIONS = [
  'keypress',
  'scroll',
  'click',
  'dblclick',
  'mousedown',
  'touchstart',
] as const;

export type InactivityProviderProps = {
  onLogout: () => void;
};
