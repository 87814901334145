import { RegisterOptions } from 'react-hook-form';

import { REGEX_VALIDATORS, translations } from '@gbm/onboarding-sdk-utils';

const setValueAs = (value: string) => value.toLowerCase();

const emailValidations: RegisterOptions = {
  setValueAs,
  required: translations.auth.emailRequired,
  maxLength: { value: 256, message: translations.auth.emailInvalid },
  pattern: {
    value: REGEX_VALIDATORS.email,
    message: translations.auth.emailInvalid,
  },
};

const emailConfirmationValidations: RegisterOptions = {
  setValueAs,
  required: translations.auth.emailConfirmationRequired,
};

const checkboxesValidations: RegisterOptions = {
  required: true,
};

export default {
  email: emailValidations,
  emailConfirmation: emailConfirmationValidations,
  privacyPolicy: checkboxesValidations,
  openingAcceptance: checkboxesValidations,
} as const;
