import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Select,
  SelectOption,
  RadioButtonSet,
  RadioButton,
  Typography,
} from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import { useBreakpoints } from '@gbm/onboarding-sdk-hooks';
import { translations } from '@gbm/onboarding-sdk-utils';
import { DEPOSITS_QUESTION_ID, QuestionType } from '../../types';
import styles from './select.module.scss';

type SelectQuestionProps = {
  question: QuestionType;
  onChangeAnswer: (value: string) => void;
};
const { inputs } = translations;

export default function SelectQuestion({
  question,
  onChangeAnswer,
}: SelectQuestionProps) {
  const { t } = useTranslation();
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const answer = watch(question.id);
  const { isSm } = useBreakpoints();
  const isRadioQuestion = question.id === DEPOSITS_QUESTION_ID && !isSm;

  useEffect(() => {
    if (isEmpty(errors[question.id])) {
      onChangeAnswer(answer);
    }
  }, [answer]);

  useEffect(() => {
    if (!isEmpty(question.response)) {
      setValue(question.id, question.response);
    }

    return () => {
      setValue(question.id, '');
    };
  }, []);

  return (
    <Controller
      control={control}
      name={question.id}
      rules={{ required: t(inputs.optionRequired) }}
      render={({ field: { onChange, value }, fieldState: { error } }) =>
        isRadioQuestion && question.answer?.length ? (
          <div className={styles.radios}>
            <Typography variant="small">{question.text}</Typography>
            <RadioButtonSet>
              {question.answer?.map(({ text, id }) => (
                <RadioButton
                  key={`option-${question.id}-${id}`}
                  value={id}
                  checked={value === id}
                  onChange={onChange}
                  name={question.id}
                >
                  {text as string}
                </RadioButton>
              ))}
            </RadioButtonSet>
            {error?.message ? (
              <div className={styles['radios-error']}>
                <Typography variant="xsmall">{t(error.message)}</Typography>
              </div>
            ) : null}
          </div>
        ) : (
          <Select
            widthSize="fluid"
            errorFeedback={error?.message ? t(error?.message) : undefined}
            onChange={onChange}
            value={value}
            label={question.text}
          >
            <SelectOption value="" hidden>
              {t(inputs.selectOption)}
            </SelectOption>
            {question.answer?.map(({ text, id }) => (
              <SelectOption value={id} key={id}>
                {text as string}
              </SelectOption>
            ))}
          </Select>
        )
      }
    />
  );
}
