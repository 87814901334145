import { useState, useEffect, useMemo } from 'react';
import {
  Typography,
  Input,
  Button,
  CaretLeft,
  ToggleLabel,
  ButtonLink,
  Checkbox,
  RadioButton,
  ArrowCircleRight,
  SkeletonLoader,
} from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import isEqual from 'lodash/isEqual';
import { motion } from 'framer-motion';

import {
  useNotificationProvider,
  NOTIFICATION_TIMEOUT,
  FormContent,
  FormSection,
  FormRow,
  FormFooter,
} from '@gbm/onboarding-sdk-ui-components';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import {
  formError,
  UPGRADE_IDS,
  translations,
  TRACKING_EVENTS,
  TRACKING_NAMES,
} from '@gbm/onboarding-sdk-utils';
import { UpgradeLayout } from '../../../components/upgrade';
import { generateRfc } from '../../../../utils/helpers';
import {
  useContract,
  useParty,
  useIdentifiers,
  useUpgradeStep,
} from '../../../hooks';
import { useUpgradeProvider } from '../../../providers/upgrade';
import { useAppSelector, useAppDispatch } from '../../../store';
import {
  selectBanks,
  selectBankAccounts,
  selectNationalities,
  getBankList,
  getBankAccountStatus,
  getPartyCountries,
  selectAddBankAccount,
  updateCountriesBatch,
  saveBankAccount,
  selectErrors,
  clearErrors,
} from '../../../store/upgrade';
import { selectCurp, getPartyCurp } from '../../../store/opening-lite';
import {
  PartyCountryType,
  IdentifierType,
} from '../../../../api/upgrade/types';
import { STEPS, UPGRADE_STEPS } from '../../../providers/upgrade/types';
import { OPENING_TYPE } from '../../../providers/opening-lite/types';
import {
  FormValues,
  TAX_INFO_FIELDS,
  CLABE_LENGTH,
  TIN_LENGTH,
  INITIAL_TAX_COUNTRIES,
  COUNTRY_TAXATION_TYPE,
  BANK_ACCOUNT_OPENING_TYPES_LEN,
  BANK_ACCOUNT_OPENING_TYPES,
  DEFAULT_COUNTRY_CODE,
  HAS_FIEL_OPTIONS,
} from './types';
import formValidations from './validations';
import { REDUCER_STATUS } from '../../../store/types';
import {
  BanksType,
  COUNTRY_TYPE,
  CountryName,
  USA_COUNTRY_CODE,
  VALID_BANK_ACCOUNT_STATUSES,
} from '../../../store/upgrade/types';
import { InputRfc } from '../../../components';
import styles from './tax-information.module.scss';

const {
  inputs,
  buttons,
  upgrade: { taxInformation },
} = translations;
const taxation1 = 'taxation-1';

export default function TaxInformation() {
  const { configuration, setStep, isEditing, setIsEditing } =
    useUpgradeProvider();
  const { showNotification } = useNotificationProvider();
  const formMethods = useForm<FormValues>({ mode: 'onChange' });
  const {
    control,
    register,
    setValue,
    watch,
    formState: { errors: formErrors, dirtyFields },
    clearErrors: clearFormErrors,
    handleSubmit,
  } = formMethods;
  const {
    contract,
    isResolved: isResolvedContract,
    isLoading: isLoadingContract,
    fetch: fetchContract,
  } = useContract(configuration);
  const { party, isFulfilled: isFulfilledParty } = useParty(configuration);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const banks = useAppSelector(selectBanks);
  const bankAccounts = useAppSelector(selectBankAccounts);
  const {
    rfc,
    tin,
    fiel,
    isLoading: isLoadingIds,
    isFulfilled: isFulfilledIds,
    isEmpty: isEmptyIds,
    fetch: fetchIds,
    saveRfc,
    saveFiel,
    saveTin,
    delete: deleteId,
  } = useIdentifiers(configuration);
  const curp = useAppSelector(selectCurp);
  const addBankAccount = useAppSelector(selectAddBankAccount);
  const { saveUpgradeStep } = useUpgradeStep(configuration);
  const [hasRegisteredRfc, setHasRegisteredRfc] = useState(false);
  const [isUsaResident, setIsUsaResident] = useState(false);
  const [taxCountries, setTaxCountries] = useState(INITIAL_TAX_COUNTRIES);
  const countries = useAppSelector(selectNationalities);
  const [hasUsaTaxCountry, setHasUsaTaxCountry] = useState(false);
  const [bank, setBank] = useState({
    id: '',
    name: '',
    reference: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const errors = useAppSelector(selectErrors);
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );
  const {
    [TAX_INFO_FIELDS.homoclave]: homoclaveValue,
    [TAX_INFO_FIELDS.rfc]: rfcValue,
    [TAX_INFO_FIELDS.tinInput]: tinValue,
    [TAX_INFO_FIELDS.fielNumber]: fielValue,
    [TAX_INFO_FIELDS.clabeBank]: clabeValue,
    [TAX_INFO_FIELDS.hasNoRfc]: hasNoRfc,
    [TAX_INFO_FIELDS.rfcVerified]: rfcVerified,
    [TAX_INFO_FIELDS.hasFiel]: hasFiel,
  } = watch();

  const wasUsaResident = useMemo(() => {
    if (countries.status !== REDUCER_STATUS.resolved) return false;
    const residences = countries.data.filter(
      (country: PartyCountryType) =>
        country.country_type === COUNTRY_TYPE.residence &&
        country.country_code === USA_COUNTRY_CODE,
    );
    return residences.length > 0;
  }, [countries.data]);

  const hadUsaTaxCountry = useMemo(() => {
    if (countries.status !== REDUCER_STATUS.resolved) return false;
    const residences = countries.data.filter(
      (country: PartyCountryType) =>
        country.country_type === COUNTRY_TYPE.taxation &&
        country.country_code === USA_COUNTRY_CODE,
    );
    return residences.length > 0;
  }, [countries.status]);

  const hasValidBankAccount = useMemo(() => {
    if (!configuration.isEnabledBankAccountSection) {
      // If bank account section is not enabled, we assume
      // the user has a valid bank account, so we do not
      // request a new one
      return true;
    }

    if (!isEmpty(bankAccounts.data)) {
      const validAccounts = bankAccounts.data.items.filter(
        /* istanbul ignore next */
        ({ verification_status: status }: { verification_status: string }) =>
          VALID_BANK_ACCOUNT_STATUSES.includes(status),
      ).length;

      return validAccounts > 0;
    }

    return false;
  }, [bankAccounts]);

  const handleAddCountry = () => {
    const key = `taxation-${taxCountries.length}`;
    const newTaxCountries = [
      ...taxCountries,
      {
        key,
        value: '',
        country_code: '',
        is_main_country: false,
        country_type: COUNTRY_TAXATION_TYPE,
        label: '',
        id: taxCountries.length,
      },
    ];
    setTaxCountries(newTaxCountries);
  };

  const handleRemoveCountry = (key: string) => {
    const newTaxCountries = [...taxCountries]
      .filter((country) => country.key !== key)
      .map((country, index) => {
        if (index) {
          return {
            ...country,
            id: index,
            key: `taxation-${index}`,
          };
        }

        return country;
      });
    setTaxCountries(newTaxCountries);
  };

  const handleTaxCountryCheck = (key: string) => {
    const taxCountry = taxCountries.some(
      (country) => country.key === taxation1,
    );
    const isChecked = Boolean(taxCountry);
    setHasUsaTaxCountry(!isChecked);
    if (!isChecked) {
      handleAddCountry();
    } else {
      handleRemoveCountry(key);
      setValue(TAX_INFO_FIELDS.tinInput, '');
      clearFormErrors(TAX_INFO_FIELDS.tinInput);
    }
  };

  useEffect(() => {
    if (hasFiel === HAS_FIEL_OPTIONS.noOption) {
      setValue(TAX_INFO_FIELDS.fielNumber, '');
      clearFormErrors(TAX_INFO_FIELDS.fielNumber);
    }
  }, [hasFiel]);

  const isValidForm = useMemo(() => {
    const isValidRfc =
      !hasNoRfc && !hasRegisteredRfc ? !!homoclaveValue && rfcVerified : true;
    const isValidTin = hasUsaTaxCountry ? !!tinValue : true;
    const isValidFiel =
      hasFiel === HAS_FIEL_OPTIONS.yesOption ? !!fielValue : !!hasFiel;
    const isValidClabe = !hasValidBankAccount ? !!bank?.name : true;
    return isValidRfc && isValidTin && isValidFiel && isValidClabe;
  }, [
    hasNoRfc,
    homoclaveValue,
    rfcVerified,
    hasUsaTaxCountry,
    tinValue,
    hasFiel,
    fielValue,
    hasValidBankAccount,
    bank,
  ]);

  const isButtonLoading = useMemo(
    () =>
      isLoadingIds ||
      countries.status === REDUCER_STATUS.pending ||
      addBankAccount.status === REDUCER_STATUS.pending,
    [rfc, tin, fiel, countries, addBankAccount],
  );

  const isFormLoading = useMemo(
    () =>
      !isSubmitted &&
      !(
        banks.status === REDUCER_STATUS.resolved &&
        bankAccounts.status === REDUCER_STATUS.resolved &&
        curp.status === REDUCER_STATUS.resolved &&
        !isEmpty(contract)
      ),
    [banks, bankAccounts, contract, curp],
  );

  const handleSaveCountries = (
    countryType: CountryName,
    hadUsaInfo: boolean,
    hasUsaInfo: boolean,
  ) => {
    const { party_id: partyId } = party.data;
    let newCountries = [
      ...countries.data.filter(
        (country: PartyCountryType) => country.country_type === countryType,
      ),
    ];
    const hasMexInfo = newCountries.some(
      ({ country_code: countryCode }) => countryCode === DEFAULT_COUNTRY_CODE,
    );

    if (!hasMexInfo) {
      newCountries = [
        ...newCountries,
        {
          country_code: DEFAULT_COUNTRY_CODE,
          country_type: countryType,
          is_main_country: true,
        },
      ];
    }

    if (!hadUsaInfo && hasUsaInfo) {
      newCountries = [
        ...newCountries,
        {
          country_code: USA_COUNTRY_CODE,
          country_type: countryType,
          is_main_country: false,
        },
      ];
    }

    if (hadUsaInfo && !hasUsaInfo) {
      remove(
        newCountries,
        ({ country_code: countryCode }) => countryCode === USA_COUNTRY_CODE,
      );
    }

    // @ts-ignore
    if (!isEqual(countries.data[countryType], newCountries)) {
      dispatch(
        updateCountriesBatch({
          partyId,
          countries: {
            [countryType]: newCountries,
          },
          config: configuration,
          isFromNationality: false,
        }),
      );
    }
  };

  const handleSaveResidenceCountry = () => {
    handleSaveCountries(COUNTRY_TYPE.residence, wasUsaResident, isUsaResident);
  };

  const handleSaveTaxationCountry = () => {
    handleSaveCountries(
      COUNTRY_TYPE.taxation,
      hadUsaTaxCountry,
      hasUsaTaxCountry,
    );
  };

  const handleSaveIdentifiers = () => {
    const rfcWithHomoclave = `${rfcValue}${homoclaveValue}`;
    if (
      !hasRegisteredRfc &&
      !hasNoRfc &&
      !!rfcWithHomoclave &&
      (dirtyFields?.[TAX_INFO_FIELDS.rfc] ||
        dirtyFields?.[TAX_INFO_FIELDS.homoclave])
    ) {
      saveRfc(rfcWithHomoclave);
    } else if (hasNoRfc && !isEmpty(rfc.data)) {
      /* istanbul ignore next */
      deleteId(rfc.data as IdentifierType);
    }

    if (!!tinValue && dirtyFields?.[TAX_INFO_FIELDS.tinInput]) {
      saveTin(tinValue);
    } else if (!tinValue && !isEmpty(tin.data)) {
      deleteId(tin.data as IdentifierType);
    }

    if (!!fielValue && dirtyFields?.[TAX_INFO_FIELDS.fielNumber]) {
      saveFiel(fielValue);
    } else if (!fielValue && !isEmpty(fiel.data)) {
      deleteId(fiel.data as IdentifierType);
    }

    if (!!clabeValue && dirtyFields?.[TAX_INFO_FIELDS.clabeBank]) {
      const bankAccount = {
        bank_account_type: BANK_ACCOUNT_OPENING_TYPES.clabe.toLowerCase(),
        bank_account_number: clabeValue,
        legacy_contract_id: contract.data.legacy_contract_id,
      };
      dispatch(saveBankAccount({ bankAccount, config: configuration }));
    }
  };

  const handleSubmitCallback = () => {
    /* istanbul ignore else */
    if (isValidForm) {
      handleSaveResidenceCountry();
      handleSaveTaxationCountry();
      handleSaveIdentifiers();
      setIsSubmitted(true);
    }
  };

  const hasErrors = useMemo(
    () =>
      !isEmpty(errors.nationalities) ||
      !isEmpty(errors.rfc) ||
      !isEmpty(errors.fiel) ||
      !isEmpty(errors.tin) ||
      !isEmpty(errors.addBankAccount),
    [errors],
  );

  useEffect(() => {
    let hasError = false;
    if (!isEmpty(errors.nationalities)) {
      showNotification({
        description: errors.nationalities?.message,
        kind: 'error',
      });
      hasError = true;
    }

    if (!isEmpty(errors.rfc)) {
      showNotification({
        description: errors.rfc?.message,
        kind: 'error',
      });
      hasError = true;
    }

    if (!isEmpty(errors.fiel)) {
      showNotification({
        description: errors.fiel?.message,
        kind: 'error',
      });
      hasError = true;
    }

    if (!isEmpty(errors.tin)) {
      showNotification({
        description: errors.tin?.message,
        kind: 'error',
      });
      hasError = true;
    }

    if (!isEmpty(errors.addBankAccount)) {
      showNotification({
        description: errors.addBankAccount?.message,
        kind: 'error',
      });
      hasError = true;
    }

    if (hasError) {
      setIsSubmitted(false);
      setTimeout(
        /* istanbul ignore next */ () => {
          dispatch(clearErrors());
        },
        NOTIFICATION_TIMEOUT,
      );
    }
  }, [hasErrors]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.fiscalData,
      success: true,
    });

    /* istanbul ignore else */
    if (!isResolvedContract && !isLoadingContract) {
      fetchContract();
    }
    /* istanbul ignore else */
    if (banks.status === REDUCER_STATUS.idle) {
      dispatch(getBankList({ config: configuration }));
    }
  }, []);

  useEffect(() => {
    /* istanbul ignore else */
    if (!isButtonLoading && isSubmitted && !hasErrors) {
      mixpanel.track(TRACKING_EVENTS.screenFilled, {
        name: TRACKING_NAMES.fiscalData,
        success: true,
      });
      if (!isEditing) {
        saveUpgradeStep(UPGRADE_STEPS.economicActivity);
        setStep(STEPS.financialInformation);
      } else {
        /* istanbul ignore next */
        handleCloseEdition();
      }
    }
  }, [isButtonLoading, isSubmitted, hasErrors]);

  useEffect(() => {
    /* istanbul ignore else */
    if (isFulfilledParty) {
      dispatch(
        getPartyCountries({
          partyId: party.data.party_id,
          config: configuration,
        }),
      );
      /* istanbul ignore else */
      if (isEmptyIds && !isFulfilledIds) {
        fetchIds();
      }
    }
  }, [isFulfilledParty]);

  useEffect(() => {
    /* istanbul ignore else */
    if (isResolvedContract && !isEmpty(contract.data)) {
      dispatch(
        getBankAccountStatus({
          config: configuration,
          contractId: contract.data.legacy_contract_id,
        }),
      );
    }
  }, [isResolvedContract]);

  useEffect(() => {
    setIsUsaResident(wasUsaResident);
  }, [wasUsaResident]);

  useEffect(() => {
    setHasUsaTaxCountry(hadUsaTaxCountry);
  }, [hadUsaTaxCountry]);

  useEffect(() => {
    if (clabeValue?.length === BANK_ACCOUNT_OPENING_TYPES_LEN.clabe) {
      const reference = clabeValue.substring(0, 3);
      const bankData = banks.data.find(
        (bankItem: BanksType) => bankItem.reference_number === reference,
      );
      setBank({
        id: bankData ? bankData.bank_id : '',
        name: bankData ? bankData.trading_name : '',
        reference: bankData ? bankData.reference_number : '',
      });
    }

    if (!clabeValue) {
      setBank({
        id: '',
        name: '',
        reference: '',
      });
    }
  }, [clabeValue]);

  useEffect(() => {
    if (isFulfilledIds) {
      /* istanbul ignore else */
      if (!isEmpty(rfc.data)) {
        setValue(TAX_INFO_FIELDS.rfc, rfc.data.value.substring(0, 10), {
          shouldValidate: true,
        });
        setValue(TAX_INFO_FIELDS.homoclave, rfc.data.value.substring(10, 13), {
          shouldValidate: true,
        });
        setValue(TAX_INFO_FIELDS.rfcVerified, true, {
          shouldValidate: true,
        });
        setHasRegisteredRfc(true);
      }

      if (isEmpty(curp.data)) {
        dispatch(
          getPartyCurp({
            config: configuration,
            partyId: party.data.party_id,
          }),
        );
      }

      if (!isEmpty(tin.data)) {
        setValue(TAX_INFO_FIELDS.tinInput, tin.data.value, {
          shouldValidate: !!tin.data.value,
        });
        handleTaxCountryCheck('');
      }

      if (!isEmpty(fiel.data)) {
        setValue(TAX_INFO_FIELDS.fielNumber, fiel.data.value, {
          shouldValidate: !!fiel.data.value,
        });
        setValue(TAX_INFO_FIELDS.hasFiel, HAS_FIEL_OPTIONS.yesOption);
      }
    }
  }, [isFulfilledIds]);

  useEffect(() => {
    /* istanbul ignore else */
    if (curp.status === REDUCER_STATUS.resolved && isEmpty(rfc.data)) {
      setValue(TAX_INFO_FIELDS.rfc, generateRfc(curp.data.value), {
        shouldValidate: true,
      });
    }
  }, [isFulfilledIds, curp.status]);

  useEffect(() => {
    /* istanbul ignore else */
    if (
      dirtyFields?.[TAX_INFO_FIELDS.rfc] ||
      dirtyFields?.[TAX_INFO_FIELDS.homoclave]
    ) {
      setValue(TAX_INFO_FIELDS.rfcVerified, false, {
        shouldValidate: true,
      });
    }
  }, [rfcValue, homoclaveValue]);

  useEffect(() => {
    /* istanbul ignore else */
    if (
      addBankAccount.status === REDUCER_STATUS.resolved &&
      !isEmpty(addBankAccount.data)
    ) {
      dispatch(
        getBankAccountStatus({
          config: configuration,
          contractId: contract.data.legacy_contract_id,
        }),
      );
    }
  }, [addBankAccount]);

  const handleCloseEdition = () => {
    /* istanbul ignore next */
    setIsEditing(false);
    setStep(STEPS.confirmation);
  };

  return (
    <UpgradeLayout current={2}>
      <motion.div
        className={styles.container}
        id={UPGRADE_IDS.viewTaxInfo}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <FormProvider {...formMethods}>
          <FormContent>
            <FormSection
              title={t(taxInformation.title)}
              description={t(taxInformation.subtitle)}
              headerContent={
                <div className={styles.toggle}>
                  {isFormLoading ? (
                    <SkeletonLoader kind="large" reverse />
                  ) : (
                    <ToggleLabel
                      id="usaResident"
                      name="usaResident"
                      checked={isUsaResident}
                      onClick={() => setIsUsaResident(!isUsaResident)}
                      kind="primary"
                      alignment="right"
                      spaced={false}
                    >
                      <Typography variant="small">
                        {t(taxInformation.usaResident)}
                      </Typography>
                    </ToggleLabel>
                  )}
                </div>
              }
            >
              <FormRow>
                <div className={styles['tax-country']}>
                  <Typography variant="body2" weight="semibold">
                    {t(taxInformation.taxCountry)}
                  </Typography>
                  <div className={styles['tax-country-checks']}>
                    {isFormLoading ? (
                      <SkeletonLoader kind="large" reverse />
                    ) : (
                      <>
                        <Checkbox checked id="countryMex">
                          {t(taxInformation.mexCountry)}
                        </Checkbox>
                        <Checkbox
                          id="countryUsa"
                          checked={Boolean(
                            taxCountries.some(
                              (country) => country.key === taxation1,
                            ),
                          )}
                          onChange={() => handleTaxCountryCheck(taxation1)}
                        >
                          {t(taxInformation.usaCountry)}
                        </Checkbox>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.empty} />
              </FormRow>
              {!hasRegisteredRfc && (
                <>
                  <FormRow className={styles.rfc}>
                    <InputRfc
                      id={TAX_INFO_FIELDS.rfc}
                      hasNoRfcId={TAX_INFO_FIELDS.hasNoRfc}
                      homoclaveId={TAX_INFO_FIELDS.homoclave}
                      loading={isFormLoading}
                      openingType={OPENING_TYPE.black}
                      environment={configuration.environment}
                      isEnabledTrackEvents={configuration.isEnabledTrackEvents}
                      appName={configuration.appName}
                    />
                  </FormRow>
                  {!hasNoRfc ? (
                    <FormRow className={styles['rfc-check']}>
                      {isFormLoading ? (
                        <SkeletonLoader kind="large" reverse />
                      ) : (
                        <ToggleLabel
                          kind="primary"
                          alignment="right"
                          spaced={false}
                          {...register(TAX_INFO_FIELDS.rfcVerified)}
                        >
                          <Typography variant="small">
                            {t(inputs.rfcVerified)}
                          </Typography>
                        </ToggleLabel>
                      )}
                      <div className={styles.empty} />
                    </FormRow>
                  ) : null}
                </>
              )}
              {hasUsaTaxCountry && (
                <FormRow>
                  {isFormLoading ? (
                    <SkeletonLoader kind="large" reverse />
                  ) : (
                    <Input
                      widthSize="fluid"
                      id={TAX_INFO_FIELDS.tinInput}
                      label={t(inputs.tinInput)}
                      maxLength={TIN_LENGTH}
                      counterEnabled
                      value={tinValue}
                      status={formErrors?.tinInput ? formError : undefined}
                      inputMode="numeric"
                      hintText={
                        /* istanbul ignore next */ formErrors?.tinInput
                          ? t(formErrors.tinInput.message as string)
                          : undefined
                      }
                      {...register(TAX_INFO_FIELDS.tinInput, {
                        ...formValidations.tinValidations,
                        required: hasUsaTaxCountry,
                      })}
                    />
                  )}
                  <div className={styles.empty} />
                  <div className={styles.empty} />
                </FormRow>
              )}
              <FormRow className={styles.fiel}>
                <div className={styles['fiel-container']}>
                  <Typography variant="small">{t(inputs.fiel)}</Typography>
                  {isFormLoading ? (
                    <SkeletonLoader kind="large" reverse />
                  ) : (
                    <Controller
                      control={control}
                      name={TAX_INFO_FIELDS.hasFiel}
                      rules={formValidations.hasFielValidations}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <div className={styles['fiel-options']}>
                            <RadioButton
                              value={HAS_FIEL_OPTIONS.yesOption}
                              checked={value === HAS_FIEL_OPTIONS.yesOption}
                              onChange={onChange}
                              widthSize="fluid"
                            >
                              {t(inputs.optionYes)}
                            </RadioButton>
                            <RadioButton
                              value={HAS_FIEL_OPTIONS.noOption}
                              checked={value === HAS_FIEL_OPTIONS.noOption}
                              onChange={onChange}
                              widthSize="fluid"
                            >
                              {t(inputs.optionNo)}
                            </RadioButton>
                          </div>
                          {error?.message ? (
                            <div>
                              <Typography variant="xsmall">
                                {t(error.message)}
                              </Typography>
                            </div>
                          ) : null}
                        </>
                      )}
                    />
                  )}
                </div>
                {hasFiel === HAS_FIEL_OPTIONS.yesOption && !isFormLoading ? (
                  <Input
                    widthSize="fluid"
                    id={TAX_INFO_FIELDS.fielNumber}
                    label={t(inputs.fielNumber)}
                    value={fielValue}
                    status={formErrors?.fielNumber ? formError : undefined}
                    inputMode="numeric"
                    hintText={
                      /* istanbul ignore next */ formErrors?.fielNumber
                        ? t(formErrors.fielNumber.message as string)
                        : undefined
                    }
                    {...register(TAX_INFO_FIELDS.fielNumber, {
                      ...formValidations.fielNumberValidations,
                      required: hasFiel === HAS_FIEL_OPTIONS.yesOption,
                    })}
                  />
                ) : (
                  <div className={styles.empty} />
                )}
              </FormRow>
            </FormSection>
            {!hasValidBankAccount && !isFormLoading ? (
              <FormSection
                title={t(taxInformation.accountTitle)}
                description={t(taxInformation.accountSubtitle)}
              >
                <FormRow>
                  <Input
                    widthSize="fluid"
                    id={TAX_INFO_FIELDS.clabeBank}
                    label={t(inputs.clabeBank)}
                    maxLength={CLABE_LENGTH}
                    counterEnabled
                    value={clabeValue}
                    status={formErrors?.clabeBank ? 'error' : undefined}
                    inputMode="numeric"
                    hintText={
                      /* istanbul ignore next */ formErrors?.clabeBank
                        ? t(formErrors.clabeBank.message as string)
                        : undefined
                    }
                    {...register(
                      TAX_INFO_FIELDS.clabeBank,
                      formValidations.clabeValidations,
                    )}
                  />
                  <Input
                    widthSize="fluid"
                    id={TAX_INFO_FIELDS.bank}
                    label={t(inputs.bank)}
                    value={bank.name}
                    disabled
                  />
                </FormRow>
              </FormSection>
            ) : null}
          </FormContent>
          <FormFooter>
            {!isEditing ? (
              <ButtonLink onClick={() => setStep(STEPS.nationality)}>
                <CaretLeft
                  color={styles['icon-color']}
                  size={styles['icon-size']}
                />
                {t(buttons.previous)}
              </ButtonLink>
            ) : (
              <ButtonLink onClick={handleCloseEdition} underline="always">
                {t(buttons.cancel)}
              </ButtonLink>
            )}
            <Button
              id={UPGRADE_IDS.btnTaxInfo}
              testId="submitButton"
              disabled={!isEmpty(formErrors) || !isValidForm}
              loading={isButtonLoading}
              kind="primary"
              onClick={handleSubmit(handleSubmitCallback)}
              icon={
                <ArrowCircleRight size={styles['icon-size']} weight="fill" />
              }
            >
              {t(buttons[isEditing ? 'save' : 'continue'])}
            </Button>
          </FormFooter>
        </FormProvider>
      </motion.div>
    </UpgradeLayout>
  );
}
