import { useEffect } from 'react';
import { Typography, Button, ArrowCircleRight } from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import { AppLayout } from '@gbm/onboarding-sdk-ui-components';
import { useBreakpoints, useMixpanel } from '@gbm/onboarding-sdk-hooks';
import {
  LITE_IDS,
  translations,
  TRACKING_EVENTS,
} from '@gbm/onboarding-sdk-utils';
import gbmLogo from '@gbm/onboarding-sdk-ui-components/assets/images/logos/GBM-logo.svg';
import { useOpeningLiteProvider } from '../../../providers/opening-lite';
import { STEPS } from '../../../providers/opening-lite/types';
import styles from './welcome.module.scss';

const {
  openingLite: { welcome },
  buttons,
} = translations;

export default function Welcome() {
  const { t } = useTranslation();
  const { isSm } = useBreakpoints();
  const { setStep, configuration, openingStatus } = useOpeningLiteProvider();
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  useEffect(() => {
    mixpanel.track(TRACKING_EVENTS.contractStarted, {
      success: true,
      status: openingStatus.status,
    });
  }, []);

  return (
    <AppLayout>
      <motion.div
        className={styles.container}
        id={LITE_IDS.viewWelcome}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <div className={styles.content}>
          <div className={styles.above}>
            <img src={gbmLogo} alt="GBM" className={styles.logo} />
          </div>
          <div className={styles.below}>
            <div className={styles.text}>
              <span className={styles.title}>{t(welcome.title)}</span>
              <Typography variant={isSm ? 'body2' : 'h5'}>
                {t(welcome.description)}
              </Typography>
            </div>
            <Button
              size={isSm ? 'fluid' : 'default'}
              id={LITE_IDS.welcome}
              onClick={() => setStep(STEPS.curpInfo)}
              kind="primary--inverted"
              icon={
                <ArrowCircleRight size={styles['icon-size']} weight="fill" />
              }
            >
              {t(buttons.start)}
            </Button>
          </div>
        </div>
      </motion.div>
    </AppLayout>
  );
}
