import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InputPassword as InputPasswordStarman,
  Typography,
} from '@gbm/starman-next';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import {
  translations,
  TRACKING_EVENTS,
  TRACKING_NAMES,
} from '@gbm/onboarding-sdk-utils';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import {
  FormValues,
  SIGN_UP_FIELDS,
} from '../../../containers/auth/sign-up/types';
import { useAuthProvider } from '../../../providers/auth';

import {
  containsEmail,
  hasGbmWord,
  hasRepeatedElements,
  hasSequentialElements,
  isValidPassword,
} from '../../../../utils/helpers';
import formValidations from './validations';
import styles from './input-password.module.scss';

type InputPasswordProps = {
  id: string;
  email: string;
};
const COMPLETED_CLASS = styles['container--completed'];
const ERROR_CLASS = styles['container--error'];
const { auth, inputs } = translations;

export default function InputPassword({ id, email }: InputPasswordProps) {
  const { t } = useTranslation();
  const {
    register,
    watch,
    formState: { errors: formErrors, dirtyFields, touchedFields },
  } = useFormContext<FormValues>();
  const { configuration } = useAuthProvider();
  const passwordValue = watch(SIGN_UP_FIELDS.password);
  const hasValue = passwordValue?.length > 0;
  const hasError = SIGN_UP_FIELDS.password in formErrors;
  const [
    hasNoUpperCase,
    hasNoLowerCase,
    hasNoSpecialSymbol,
    hasNoNumber,
    hasInvalidLength,
  ] = useMemo(() => {
    const errors = formErrors?.password?.types || {};
    return [
      'hasUpperCase' in errors,
      'hasLowerCase' in errors,
      'hasSpecialSymbol' in errors,
      'hasNumber' in errors,
      'minLength' in errors,
    ];
  }, [formErrors?.password?.types]);
  const showRequirements =
    SIGN_UP_FIELDS.password in dirtyFields ||
    SIGN_UP_FIELDS.password in touchedFields;
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  useEffect(() => {
    if (passwordValue && !hasError) {
      mixpanel.track(TRACKING_EVENTS.fieldFilled, {
        name: TRACKING_NAMES.password,
      });
    }
  }, [passwordValue, hasError]);

  return (
    <div className={styles.container}>
      <InputPasswordStarman
        id={id}
        widthSize="fluid"
        label={t(inputs.password)}
        placeholder=""
        status={formErrors?.password ? 'error' : undefined}
        hintText={
          formErrors?.password
            ? t(formErrors.password.message as string)
            : undefined
        }
        {...register(SIGN_UP_FIELDS.password, {
          ...formValidations.password,
          validate: {
            ...formValidations.password.validate,
            policies: (value: string) =>
              (isValidPassword(value) &&
                !hasRepeatedElements(value.toUpperCase()) &&
                !hasSequentialElements(value.toUpperCase()) &&
                !hasGbmWord(value.toUpperCase()) &&
                !containsEmail(value.toUpperCase(), email.toUpperCase())) ||
              'invalidPassword',
          },
        })}
        data-testid="inputPassword"
      />
      {showRequirements ? (
        <div
          className={styles.requirements}
          data-testid="password-requirements"
        >
          <ul>
            <li
              className={clsx({
                [COMPLETED_CLASS]: hasValue && !hasNoUpperCase,
                [ERROR_CLASS]: hasNoUpperCase,
              })}
            >
              <Typography variant="xsmall">
                {t(auth.passwordRequirements.uppercase)}
              </Typography>
            </li>
            <li
              className={clsx({
                [COMPLETED_CLASS]: hasValue && !hasNoLowerCase,
                [ERROR_CLASS]: hasNoLowerCase,
              })}
            >
              <Typography variant="xsmall">
                {t(auth.passwordRequirements.lowercase)}
              </Typography>
            </li>
            <li
              className={clsx({
                [COMPLETED_CLASS]: hasValue && !hasNoSpecialSymbol,
                [ERROR_CLASS]: hasNoSpecialSymbol,
              })}
            >
              <Typography variant="xsmall">
                {t(auth.passwordRequirements.specialSymbol)}
              </Typography>
            </li>
            <li
              className={clsx({
                [COMPLETED_CLASS]: hasValue && !hasNoNumber,
                [ERROR_CLASS]: hasNoNumber,
              })}
            >
              <Typography variant="xsmall">
                {t(auth.passwordRequirements.number)}
              </Typography>
            </li>
            <li
              className={clsx({
                [COMPLETED_CLASS]: hasValue && !hasInvalidLength,
                [ERROR_CLASS]: hasInvalidLength,
              })}
            >
              <Typography variant="xsmall">
                {t(auth.passwordRequirements.length)}
              </Typography>
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
}
