import { Typography, ButtonLink } from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';

import {
  Fade,
  useNotificationProvider,
} from '@gbm/onboarding-sdk-ui-components';
import { config, ENVIRONMENTS, translations } from '@gbm/onboarding-sdk-utils';
import { useBreakpoints } from '@gbm/onboarding-sdk-hooks';
import iconMail from '@gbm/onboarding-sdk-ui-components/assets/images/icons/atm-icn-mail.svg';
import { Layout } from '../../../components/auth';
import { STEPS } from '../../../providers/auth/types';
import { useAuthProvider } from '../../../providers/auth';
import styles from './password-recovery-sent.module.scss';

const {
  links,
  base,
  auth: { passwordRecoverySent },
} = translations;
const { helpEmail } = config(ENVIRONMENTS.production);

export default function PasswordRecoverySent() {
  const { t } = useTranslation();
  const { showNotification } = useNotificationProvider();
  const { isSm } = useBreakpoints();
  const { handlePasswordRecovery, handleSignOut, email, step, configuration } =
    useAuthProvider();

  const handleResend = async () => {
    await handlePasswordRecovery(email);
    if (step === STEPS.passwordRecoverySent) {
      showNotification({
        title: t(base.info),
        description: t(passwordRecoverySent.resendPasswordRecovery),
        kind: 'info',
      });
    }
  };

  return (
    <Fade>
      <Layout
        variant="center"
        onClose={handleSignOut}
        images={configuration.images}
      >
        <div className={styles.container}>
          <div className={styles.content}>
            <img
              src={iconMail}
              alt={t(passwordRecoverySent.title)}
              className={styles.image}
            />
            <div className={styles.title}>
              <Typography variant={isSm ? 'h5' : 'h2'} weight="semibold">
                {t(passwordRecoverySent.title)}
              </Typography>
            </div>
            <div className={styles.subtitle}>
              <Typography variant={isSm ? 'small' : 'body2'}>
                {t(passwordRecoverySent.subtitle)}
              </Typography>
            </div>
            <div className={styles.sent}>
              <Typography variant={isSm ? 'small' : 'body2'}>
                {t(passwordRecoverySent.sent)}
                <br />
                <span className={styles.help}>{helpEmail}</span>
              </Typography>
            </div>
            <div className={styles.link}>
              <Typography variant={isSm ? 'small' : 'body2'}>
                {t(passwordRecoverySent.didNotReceiveEmail)}
                &nbsp;
              </Typography>
              <ButtonLink underline="always" onClick={handleResend}>
                <Typography
                  variant={isSm ? 'small' : 'body2'}
                  weight="semibold"
                >
                  {t(links.resend)}
                </Typography>
              </ButtonLink>
            </div>
          </div>
        </div>
      </Layout>
    </Fade>
  );
}
