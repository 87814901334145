import { RegisterOptions } from 'react-hook-form';
import { REGEX_VALIDATORS } from '@gbm/onboarding-sdk-utils';

export const digitValidations: RegisterOptions = {
  required: true,
  maxLength: 1,
  minLength: 1,
  min: 0,
  max: 9,
  pattern: REGEX_VALIDATORS.digit,
} as const;
