import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  generateSecureRandomNumber,
  translations,
} from '@gbm/onboarding-sdk-utils';
import {
  FormRow,
  ResumeSection,
  ResumeSectionItem,
} from '@gbm/onboarding-sdk-ui-components';
import { chunkArray } from '../../../../../utils/helpers';
import { AnswerType, QuestionType } from '../types';
import styles from '../financial-information.module.scss';

type FinancialInfoResumeProps = {
  isLoading?: boolean;
  onEditClick?: () => void;
  eaResponses: Array<QuestionType>;
  pepResponses: Array<QuestionType>;
  kycResponses: Array<QuestionType>;
};
type FinancialInfoSectionProps = {
  label: string;
  response: Array<AnswerType>;
  isLoading?: boolean;
};
const {
  upgrade: { financialInformation },
} = translations;

const FinancialInfoSection = ({
  label,
  response,
  isLoading,
}: FinancialInfoSectionProps) => (
  <ResumeSectionItem
    label={label}
    value={response.map(
      ({ text, id: responseId = generateSecureRandomNumber(0, 10) }) => (
        <div key={responseId}>
          {typeof text === 'string' && text.toUpperCase()}
          {typeof text === 'object' && text.label.toUpperCase()}
        </div>
      ),
    )}
    isLoading={isLoading}
  />
);

export default function FinancialInformationResume({
  isLoading,
  onEditClick,
  eaResponses,
  pepResponses,
  kycResponses,
}: FinancialInfoResumeProps) {
  const { t } = useTranslation();
  const allResponses = [...eaResponses, ...pepResponses, ...kycResponses];
  const allResponsesChunk = useMemo(
    () => chunkArray(allResponses, 3),
    [allResponses],
  );

  return (
    <ResumeSection
      title={t(financialInformation.title)}
      isLoading={isLoading}
      onEditClick={onEditClick}
    >
      {allResponsesChunk.map((responses) => (
        <FormRow>
          {responses.map((question) => (
            <FinancialInfoSection
              isLoading={isLoading}
              key={question.id}
              label={question.text}
              response={question.response as Array<AnswerType>}
            />
          ))}
          {Array.from({ length: 3 - responses.length }).map(() => (
            <div className={styles.empty} />
          ))}
        </FormRow>
      ))}
    </ResumeSection>
  );
}
