import { ErrorType } from './types';

export const defaultSuccessAdapter = (response: Record<string, unknown>) => ({
  data: response.data,
  status: response.status,
  headers: response.headers,
});

export const defaultErrorAdapter = (err: Record<string, any>) => {
  const data = err.response ? err.response.data : null;
  const headers = err.response ? err.response.headers : null;
  const status = err.response ? err.response.status : null;

  return { data, err, status, headers };
};

export const completeErrorAdapter = (err: Record<string, any>) => {
  const headers = err.response ? err.response.headers : null;
  const status = err.response ? err.response.status : null;
  const error: ErrorType = { ...err.response?.data?.error };

  return { err: error, status, headers };
};
