import { getErrorMessage } from '@gbm/onboarding-sdk-utils';
import { useNotificationProvider } from '@gbm/onboarding-sdk-ui-components';
import { API_CODES } from '../../../api/auth/types';
import { useAuthApi } from '../../../api/auth/use-auth-api';
import { OpeningLiteProps } from '../../providers/opening-lite/types';
import { UpgradeProps } from '../../providers/upgrade/types';

export default function useRefreshSession(
  config: OpeningLiteProps | UpgradeProps,
  onSignOut: () => void,
) {
  const authApi = useAuthApi(config.environment);
  const { showNotification } = useNotificationProvider();

  const handleRefreshSession = async () => {
    const { data, err } = await authApi.refreshSession(
      config.clientId,
      config.refreshToken,
    );

    if (!data && err) {
      showNotification({
        description: getErrorMessage(err.response),
        kind: 'error',
      });
      onSignOut();
      return;
    }

    const { id } = data;

    if (id === API_CODES.success) {
      config.onSessionRefreshed(data.accessToken);
    } else {
      onSignOut();
    }
  };

  return { handleRefreshSession };
}
