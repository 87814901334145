import { X, ButtonFab, ButtonFabProps } from '@gbm/starman-next';

import styles from './close-button.module.scss';

type CloseButtonProps = Omit<ButtonFabProps, 'children'>;

export default function CloseButton(props: CloseButtonProps) {
  return (
    <div className={styles.container}>
      <ButtonFab {...props}>
        <X />
      </ButtonFab>
    </div>
  );
}
