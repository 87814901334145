/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  PropsWithChildren,
  useRef,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import { ENVIRONMENTS } from '@gbm/onboarding-sdk-utils';

import { useAppDispatch, useAppSelector } from '../../store';
import { useParty } from '../../hooks/useParty';
import { useContract } from '../../hooks/useContract';
import { useAddress } from '../../hooks/useAddress';
import { useCountriesAndStates } from '../../hooks/useCountriesAndStates';
import { useGeolocation } from '../../hooks/useGeolocation';
import { useContractAccounts } from '../../hooks/useContractAccounts';
import { useRefreshSession } from '../../hooks/useRefreshSession';
import {
  getOpeningStatus,
  selectOpeningStatus,
  selectAlreadyRegistered,
  reset,
  selectCurp,
  selectPhone,
  getPartyPhone,
  getPartyCurp,
} from '../../store/opening-lite';
import { ALLOWED_CLIENT_IDS_TO_FETCH_CONTRACTS } from '../auth/types';
import { useOpeningLiteApi } from '../../../api/opening-lite/use-opening-lite-api';

import {
  OpeningLiteType,
  StepType,
  STEPS,
  POOL_OPENING_STATUS_TIME,
  OPENING_STATUS,
  OPENING_LITE_STEPS,
  OpeningLiteStepType,
  OPENING_STATUS_POOLING,
  OpeningStatusPoolingType,
  OpeningLiteProps,
} from './types';
import { REDUCER_STATUS } from '../../store/types';

/* istanbul ignore next */
const OpeningLiteContext = createContext<OpeningLiteType>({
  openingStatus: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  step: STEPS.welcome,
  setStep: () => null,
  setIsEditing: () => null,
  isLoading: false,
  isEditing: false,
  saveOpeningLiteStep: () => null,
  handleSignOut: () => null,
  configuration: {
    email: '',
    clientId: '',
    accessToken: '',
    refreshToken: '',
    appName: '',
    environment: ENVIRONMENTS.development,
    onCompleted: () => null,
    onRefreshSession: () => null,
    onSessionRefreshed: () => null,
    onSignedOut: () => null,
  },
});
export const useOpeningLiteProvider = () => useContext(OpeningLiteContext);

export default function OpeningLiteProvider({
  children,
  ...config
}: PropsWithChildren<OpeningLiteProps>) {
  const handleSignOut = () => {
    setIsLoggingOff(true);
    config?.onSignedOut();
  };

  const { handleRefreshSession } = useRefreshSession(config, handleSignOut);
  const dispatch = useAppDispatch();
  const newConfig = {
    ...config,
    onRefreshSession: handleRefreshSession,
  };
  const [step, setStep] = useState<StepType>(STEPS.unknown);
  const [isEditing, setIsEditing] = useState(false);
  const {
    fetch: fetchParty,
    party,
    isFulfilled: isFulfilledParty,
  } = useParty(newConfig);
  const {
    fetch: fetchContract,
    contract,
    isLoading: isLoadingContract,
    isFulfilled: isFulfilledContract,
  } = useContract(newConfig);
  const [isLoggingOff, setIsLoggingOff] = useState(false);
  const openingStatus = useAppSelector(selectOpeningStatus);
  const alreadyRegistered = useAppSelector(selectAlreadyRegistered);
  const isLoading = openingStatus.status === REDUCER_STATUS.pending;
  const openingLiteApi = useOpeningLiteApi(newConfig);
  const isOpeningLoading =
    (isLoading || isLoadingContract) &&
    !OPENING_STATUS_POOLING.includes(
      openingStatus.data?.opening_status as OpeningStatusPoolingType,
    );
  const { states } = useCountriesAndStates(newConfig);
  const {
    address,
    fetch: fetchAddress,
    isFulfilled: isFulfilledAddress,
  } = useAddress(newConfig);
  const {
    account,
    fetch: fetchAccount,
    isFulfilled: isFulfilledAccount,
  } = useContractAccounts(newConfig);
  const curpFromStore = useAppSelector(selectCurp);
  const telephone = useAppSelector(selectPhone);
  const { data: geolocationData, getGeolocation } = useGeolocation();
  const params = new URLSearchParams(window.location.search);
  const [isContractSigned] = useState(params.has('signed'));
  const isAllowedToFetchContracts =
    ALLOWED_CLIENT_IDS_TO_FETCH_CONTRACTS.includes(newConfig.clientId) &&
    newConfig.fetchContractInfo;
  const poolingId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!isEmpty(alreadyRegistered) && alreadyRegistered?.message) {
      setStep(STEPS.alreadyRegistered);
    }
  }, [alreadyRegistered]);

  useEffect(() => {
    if (isEmpty(openingStatus.data) && !isLoading) {
      dispatch(getOpeningStatus({ config: newConfig }));
    }

    return () => {
      stopPoolingOpeningStatus();
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (step === STEPS.pending) {
      poolForOpeningStatus();
    }
  }, [step]);

  useEffect(() => {
    let currentOpeningStatus = openingStatus.data?.opening_status;

    if (
      currentOpeningStatus === OPENING_STATUS.pendingToSign &&
      isContractSigned
    ) {
      currentOpeningStatus = OPENING_STATUS.pendingReplication;
    }

    switch (currentOpeningStatus) {
      case OPENING_STATUS.noContract:
        setStep(STEPS.welcome);
        break;
      case OPENING_STATUS.inProgress:
        fetchParty();
        handleProgressStep();
        break;
      case OPENING_STATUS.pendingContractCreation:
        poolForOpeningStatus();
        setStep(STEPS.pending);
        break;
      case OPENING_STATUS.pendingToSign:
        poolForOpeningStatus();
        setStep(STEPS.sign);
        break;
      case OPENING_STATUS.pendingReplication:
        poolForOpeningStatus();
        setStep(STEPS.activating);
        config.onContractSigned?.();
        break;
      case OPENING_STATUS.pld:
        stopPoolingOpeningStatus();
        setStep(STEPS.pld);
        break;
      case OPENING_STATUS.active:
        stopPoolingOpeningStatus();
        isAllowedToFetchContracts && fetchContract();
        fetchParty();
        getGeolocation();
        setStep(STEPS.active);
        break;
      default:
        break;
    }
  }, [openingStatus.data?.opening_status]);

  useEffect(() => {
    if (isFulfilledParty) {
      if (step === STEPS.active) {
        fetchAddress();

        if (isEmpty(telephone.data)) {
          dispatch(
            getPartyPhone({
              config: newConfig,
              partyId: party.data.party_id,
            }),
          );
        }

        if (isEmpty(curpFromStore.data)) {
          dispatch(
            getPartyCurp({
              config: newConfig,
              partyId: party.data.party_id,
            }),
          );
        }
      }
    }
  }, [isFulfilledParty, step]);

  useEffect(() => {
    if (isFulfilledContract) {
      isAllowedToFetchContracts && fetchAccount();
    }
  }, [isFulfilledContract]);

  useEffect(() => {
    const callbackContract = async () => {
      const hasContractInfo = isAllowedToFetchContracts
        ? isFulfilledContract && isFulfilledAccount
        : true;

      if (
        step === STEPS.active &&
        isFulfilledParty &&
        isFulfilledAddress &&
        hasContractInfo &&
        geolocationData &&
        !isEmpty(telephone.data) &&
        !isEmpty(curpFromStore.data)
      ) {
        const birthState = states.data.find(
          (state: any) => state.value === party.data.birth_state,
        )?.label as string;
        config.onCompleted?.({
          email: config.email,
          personalInfo: {
            birthState,
            firstName: party.data.first_name,
            lastName: party.data.first_last_name,
            secondLastName: party.data.second_last_name,
            birthDate: party.data.birth_date,
            genre: party.data.gender,
            curp: curpFromStore.data.value,
            phone: {
              countryCode: telephone.data.country_code,
              phoneNumber: telephone.data.phone_number,
              type: telephone.data.telephone_type,
            },
          },
          addressInfo: {
            addressType: address.data.address_type,
            country: address.data.country,
            insideNumber: address.data.inside_number,
            outsideNumber: address.data.outside_number,
            state: address.data.state,
            streetName: address.data.street_name,
            suburb: address.data.suburb,
            township: address.data.township,
            zipCode: address.data.zipCode,
          },
          geolocation: geolocationData,
          contract: contract.data,
          account: account.data,
        });
      }
    };

    callbackContract();
  }, [
    isAllowedToFetchContracts,
    isFulfilledContract,
    step,
    isFulfilledParty,
    isFulfilledAddress,
    isFulfilledAccount,
    geolocationData,
    telephone.data,
    curpFromStore.data,
  ]);

  const handleProgressStep = () => {
    switch (openingStatus.data?.current_step) {
      case OPENING_LITE_STEPS.addressInfo:
        setStep(STEPS.addressInfo);
        break;
      case OPENING_LITE_STEPS.confirmation:
        setStep(STEPS.confirmation);
        break;
      case OPENING_LITE_STEPS.personalInfo:
      default:
        setStep(STEPS.personalInfo);
        break;
    }
  };

  const poolForOpeningStatus = () => {
    if (poolingId.current) {
      return;
    }

    poolingId.current = setInterval(() => {
      dispatch(getOpeningStatus({ config: newConfig }));
    }, POOL_OPENING_STATUS_TIME);
  };

  const stopPoolingOpeningStatus = () => {
    if (poolingId.current) {
      clearInterval(poolingId.current);
      poolingId.current = null;
    }
  };

  const saveOpeningLiteStep = async (openingLiteStep: OpeningLiteStepType) => {
    await openingLiteApi.saveOpeningLiteStep(
      party.data.party_id,
      openingLiteStep,
    );
  };

  return (
    <OpeningLiteContext.Provider
      value={{
        openingStatus,
        step,
        setStep,
        isLoading: isOpeningLoading || isLoggingOff,
        isEditing,
        setIsEditing,
        saveOpeningLiteStep,
        configuration: newConfig,
        handleSignOut,
      }}
    >
      {children}
    </OpeningLiteContext.Provider>
  );
}
