import { CaretUp } from '@gbm/starman-next';
import { components, GroupBase, DropdownIndicatorProps } from 'react-select';
import clsx from 'clsx';

import { SelectMultipleOption } from './types';
import styles from './select-multiple.module.scss';

export default function SelectMultipleIndicator(
  props: DropdownIndicatorProps<
    SelectMultipleOption,
    true,
    GroupBase<SelectMultipleOption>
  >,
) {
  return (
    <components.DropdownIndicator {...props}>
      <CaretUp
        size={24}
        color={styles['icon-color']}
        className={clsx(styles.icon, {
          [styles['icon--rotated']]: !props.selectProps.menuIsOpen,
        })}
      />
    </components.DropdownIndicator>
  );
}
