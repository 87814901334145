import { useState, useEffect } from 'react';
import {
  Button,
  ButtonLink,
  Select,
  SelectOption,
  Input,
  SkeletonLoader,
  CaretLeft,
  Checkbox,
  ArrowCircleRight,
} from '@gbm/starman-next';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { motion } from 'framer-motion';

import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import {
  useNotificationProvider,
  NOTIFICATION_TIMEOUT,
  FormContent,
  FormSection,
  FormRow,
  FormFooter,
} from '@gbm/onboarding-sdk-ui-components';
import {
  DEFAULT_COUNTRY,
  EMPTY_FIELD_PLACEHOLDER,
  ZIP_CODE_LENGTH,
  LITE_IDS,
  translations,
  TRACKING_EVENTS,
  TRACKING_NAMES,
} from '@gbm/onboarding-sdk-utils';

import { useAppSelector, useAppDispatch } from '../../store';
import { useAddress, useParty } from '../../hooks';
import { OpeningLiteProviderProps } from '../../providers/opening-lite/types';
import { UpgradeProviderProps } from '../../providers/upgrade/types';
import {
  getDataFromZipCode,
  clearDataFromZipCode,
  selectDataFromZipCode,
  selectErrors,
  clearErrors,
} from '../../store/opening-lite';
import { SuburbType, ADDRESS_TYPES } from '../../../api/opening-lite/types';
import formValidations from '../../containers/opening-lite/address-info/validations';
import {
  ADDRESS_INFO_FIELDS,
  FormValues,
  WITHOUT_OUTSIDE_NUMBER_VALUE,
} from '../../containers/opening-lite/address-info/types';
import { REDUCER_STATUS, StateValueType } from '../../store/types';
import styles from './address-form.module.scss';

export type AddressFormProps = {
  configuration: OpeningLiteProviderProps | UpgradeProviderProps;
  isEditing: boolean;
  onHandleContinue: (address: StateValueType<Record<string, any>>) => void;
  onFulfilledParty?: () => void;
  onGoBack?: () => void;
  onCloseEdition?: () => void;
};

const {
  inputs,
  buttons,
  openingLite: { addressInfo },
} = translations;

export default function AddressForm({
  configuration,
  isEditing,
  onFulfilledParty,
  onHandleContinue,
  onGoBack,
  onCloseEdition,
}: AddressFormProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    clearErrors: clearFormErrors,
    formState: { errors: formErrors, isValid: areValidInputs, dirtyFields },
  } = useForm<FormValues>({ mode: 'onChange' });
  const {
    address,
    fetch: fetchAddress,
    save: saveAddress,
    update: updateAddress,
    isLoading: isLoadingAddress,
    isFulfilled: isFulfilledAddress,
  } = useAddress(configuration);
  const {
    party,
    isFulfilled: isFulfilledParty,
    isLoading: isLoadingParty,
  } = useParty(configuration);
  const { showNotification } = useNotificationProvider();
  const [submitted, setSubmitted] = useState(false);
  const dataFromZipCode = useAppSelector(selectDataFromZipCode);
  const errors = useAppSelector(selectErrors);
  const [state, setState] = useState({
    id: '',
    value: EMPTY_FIELD_PLACEHOLDER,
  });
  const [city, setCity] = useState(EMPTY_FIELD_PLACEHOLDER);
  const [township, setTownship] = useState(EMPTY_FIELD_PLACEHOLDER);
  const [suburbs, setSuburbs] = useState<Array<SuburbType>>();
  const [hasOutsideNumber, setHasOutsideNumber] = useState(true);
  const zipCodeValue = watch(ADDRESS_INFO_FIELDS.zipCode);
  const isValidForm =
    areValidInputs &&
    state.value !== EMPTY_FIELD_PLACEHOLDER &&
    city !== EMPTY_FIELD_PLACEHOLDER &&
    township !== EMPTY_FIELD_PLACEHOLDER;
  const isLoadingDataFromZipCode =
    dataFromZipCode.status === REDUCER_STATUS.pending;
  const isLoadingForm = isLoadingParty || isLoadingAddress;
  const isLoadingData = isLoadingForm && !submitted;
  const prepopulatedInfo = (configuration as OpeningLiteProviderProps)
    .prepopulatedInfo?.addressInfo;
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  useEffect(() => {
    /* istanbul ignore else */
    if (isFulfilledParty) {
      onFulfilledParty && onFulfilledParty();
      fetchAddress();
    }
  }, [party.status]);

  useEffect(() => {
    clearFormErrors(ADDRESS_INFO_FIELDS.outsideNumber);
    setValue(ADDRESS_INFO_FIELDS.outsideNumber, '', { shouldValidate: true });
  }, [hasOutsideNumber]);

  useEffect(() => {
    clearFormErrors(ADDRESS_INFO_FIELDS.suburb);
    if (
      isEmpty(formErrors.zipCode) &&
      zipCodeValue?.replace('_', '')?.length === ZIP_CODE_LENGTH
    ) {
      dispatch(clearDataFromZipCode());
      dispatch(
        getDataFromZipCode({
          config: configuration,
          zipCode: zipCodeValue,
        }),
      );
    }
  }, [zipCodeValue, formErrors.zipCode]);

  useEffect(() => {
    if (isFulfilledAddress) {
      if (submitted) {
        mixpanel.track(TRACKING_EVENTS.screenFilled, {
          name: TRACKING_NAMES.address,
          success: true,
        });
        onHandleContinue(address);
      } else {
        setValue(ADDRESS_INFO_FIELDS.zipCode, address.data.zipCode, {
          shouldValidate: true,
        });
      }
    } else {
      clearFormErrors();

      /* istanbul ignore else */
      if (!submitted && prepopulatedInfo?.zipCode) {
        setValue(ADDRESS_INFO_FIELDS.zipCode, prepopulatedInfo.zipCode, {
          shouldValidate: true,
        });
      }
    }
  }, [address.status, submitted]);

  useEffect(() => {
    if (!isEmpty(dataFromZipCode.data)) {
      setFormValues();
    } else {
      setCity(EMPTY_FIELD_PLACEHOLDER);
      setState({
        id: '',
        value: EMPTY_FIELD_PLACEHOLDER,
      });
      setSuburbs([]);
      setTownship(EMPTY_FIELD_PLACEHOLDER);
      setValue(ADDRESS_INFO_FIELDS.suburb, '');
      setValue(ADDRESS_INFO_FIELDS.streetName, '');
      setValue(ADDRESS_INFO_FIELDS.outsideNumber, '');
      setValue(ADDRESS_INFO_FIELDS.insideNumber, '');
    }
  }, [dataFromZipCode.status]);

  useEffect(() => {
    /* istanbul ignore else */
    if (!isEmpty(errors.dataFromZipCode)) {
      showNotification({
        description: errors.dataFromZipCode?.message,
        kind: 'error',
      });
      setTimeout(
        /* istanbul ignore next */ () => {
          dispatch(clearErrors());
        },
        NOTIFICATION_TIMEOUT,
      );
    }
  }, [errors.dataFromZipCode]);

  const setFormValues = () => {
    const addressLoadedFromStore = address.data.zipCode === zipCodeValue;
    const defaultStreetName = prepopulatedInfo?.streetName ?? '';
    const defaultInsideNumber = prepopulatedInfo?.insideNumber ?? '';
    const defaultOutsideNumber = prepopulatedInfo?.outsideNumber ?? '';
    const {
      city: cityLabel,
      id: stateId,
      state: stateLabel,
      suburbs: suburbsList,
      township: townshipLabel,
    } = dataFromZipCode.data;
    setCity(cityLabel as string);
    setState({
      id: stateId as string,
      value: stateLabel as string,
    });
    setSuburbs(suburbsList);
    setTownship(townshipLabel as string);
    setValue(
      ADDRESS_INFO_FIELDS.suburb,
      addressLoadedFromStore
        ? address.data.zip_location_id
        : suburbsList?.[0]?.value,
      { shouldValidate: addressLoadedFromStore },
    );
    setValue(
      ADDRESS_INFO_FIELDS.streetName,
      addressLoadedFromStore ? address.data.street_name : defaultStreetName,
      { shouldValidate: addressLoadedFromStore },
    );
    setValue(
      ADDRESS_INFO_FIELDS.insideNumber,
      addressLoadedFromStore ? address.data.inside_number : defaultInsideNumber,
      { shouldValidate: addressLoadedFromStore },
    );

    if (
      addressLoadedFromStore &&
      address.data.outside_number === WITHOUT_OUTSIDE_NUMBER_VALUE
    ) {
      setHasOutsideNumber(false);
    } else {
      setValue(
        ADDRESS_INFO_FIELDS.outsideNumber,
        addressLoadedFromStore
          ? address.data.outside_number
          : defaultOutsideNumber,
        { shouldValidate: addressLoadedFromStore },
      );
    }
  };

  const handleSubmitCallback = () => {
    /* istanbul ignore next */
    if (!isValidForm) {
      return;
    }

    const addressData = {
      address_type: ADDRESS_TYPES.residential,
      inside_number: getValues(ADDRESS_INFO_FIELDS.insideNumber),
      outside_number: hasOutsideNumber
        ? getValues(ADDRESS_INFO_FIELDS.outsideNumber)
        : WITHOUT_OUTSIDE_NUMBER_VALUE,
      street_name: getValues(ADDRESS_INFO_FIELDS.streetName),
      zip_location_id: getValues(ADDRESS_INFO_FIELDS.suburb),
    };

    /* istanbul ignore else */
    if (isEmpty(address.data)) {
      saveAddress(addressData);
    } else if (
      !isEmpty(dirtyFields) ||
      getValues(ADDRESS_INFO_FIELDS.suburb) !== address.data.zip_location_id ||
      addressData.outside_number !== address.data.outside_number
    ) {
      updateAddress({
        ...addressData,
        id: address.data.id,
      });
    }

    setSubmitted(true);
  };

  const handleNoOutsideNumber = () => {
    mixpanel.track(TRACKING_EVENTS.checkChecked, {
      name: TRACKING_NAMES.noOutsideNumber,
    });
    setHasOutsideNumber(!hasOutsideNumber);
  };

  return (
    <motion.div
      className={styles.container}
      id={LITE_IDS.viewAddressInfo}
      data-testid="address-form"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <FormContent>
        <FormSection
          title={t(addressInfo.title)}
          description={t(addressInfo.subtitle)}
        >
          <FormRow>
            {isLoadingData ? (
              <SkeletonLoader kind="large" reverse />
            ) : (
              <Select
                label={t(inputs.birthCountry)}
                value={DEFAULT_COUNTRY.value}
                onChange={/* istanbul ignore next */ () => null}
                widthSize="fluid"
                disabled
              >
                <SelectOption
                  value={DEFAULT_COUNTRY.value}
                  key={DEFAULT_COUNTRY.key}
                >
                  {DEFAULT_COUNTRY.label}
                </SelectOption>
              </Select>
            )}
            {isLoadingData ? (
              <SkeletonLoader kind="large" reverse />
            ) : (
              <Input
                widthSize="fluid"
                id={ADDRESS_INFO_FIELDS.zipCode}
                label={t(inputs.zipCode)}
                type="text"
                inputMode="numeric"
                maxLength={ZIP_CODE_LENGTH}
                status={formErrors?.zipCode ? 'error' : undefined}
                className={styles.upper}
                hintText={
                  formErrors?.zipCode
                    ? t(formErrors.zipCode.message as string)
                    : undefined
                }
                {...register(
                  ADDRESS_INFO_FIELDS.zipCode,
                  formValidations.zipCode,
                )}
              />
            )}
          </FormRow>
          <FormRow>
            {isLoadingDataFromZipCode || isLoadingData ? (
              <SkeletonLoader kind="large" reverse />
            ) : (
              <Input
                id="state"
                widthSize="fluid"
                label={t(inputs.state)}
                type="text"
                placeholder=""
                inputMode="text"
                readOnly
                value={state.value}
              />
            )}
            {isLoadingDataFromZipCode || isLoadingData ? (
              <SkeletonLoader kind="large" reverse />
            ) : (
              <Input
                widthSize="fluid"
                label={t(inputs.city)}
                type="text"
                placeholder=""
                inputMode="text"
                readOnly
                value={city}
              />
            )}
          </FormRow>
          <FormRow>
            {isLoadingDataFromZipCode || isLoadingData ? (
              <SkeletonLoader kind="large" reverse />
            ) : (
              <Controller
                control={control}
                name={ADDRESS_INFO_FIELDS.suburb}
                rules={formValidations.suburb}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Select
                    onChange={onChange}
                    value={value}
                    label={t(inputs.suburb)}
                    widthSize="fluid"
                    hintText={
                      /* istanbul ignore next */
                      error?.message ? t(error?.message) : undefined
                    }
                    disabled={
                      isLoadingDataFromZipCode ||
                      (suburbs && suburbs?.length < 2)
                    }
                  >
                    <SelectOption value="" hidden>
                      {EMPTY_FIELD_PLACEHOLDER}
                    </SelectOption>
                    {suburbs?.map(({ label, value: optionValue, key }) => (
                      <SelectOption value={optionValue} key={key}>
                        {label}
                      </SelectOption>
                    ))}
                  </Select>
                )}
              />
            )}
            {isLoadingDataFromZipCode || isLoadingData ? (
              <SkeletonLoader kind="large" reverse />
            ) : (
              <Input
                widthSize="fluid"
                label={t(inputs.township)}
                type="text"
                placeholder=""
                inputMode="text"
                readOnly
                value={township}
              />
            )}
          </FormRow>
          <FormRow>
            {isLoadingData ? (
              <SkeletonLoader kind="large" reverse />
            ) : (
              <Input
                widthSize="fluid"
                id="streetName"
                label={t(inputs.streetName)}
                type="text"
                placeholder=""
                inputMode="text"
                status={
                  /* istanbul ignore next */ formErrors?.streetName
                    ? 'error'
                    : undefined
                }
                className={styles.upper}
                hintText={
                  /* istanbul ignore next */ formErrors?.streetName
                    ? t(formErrors.streetName.message as string)
                    : undefined
                }
                {...register(
                  ADDRESS_INFO_FIELDS.streetName,
                  formValidations.streetName,
                )}
              />
            )}
            <FormRow className={styles.numbers}>
              {isLoadingData ? (
                <SkeletonLoader kind="large" reverse />
              ) : (
                <div className={styles['outside-number']}>
                  <Input
                    id="outsideNumber"
                    widthSize="fluid"
                    label={t(inputs.outsideNumber)}
                    type="text"
                    placeholder=""
                    inputMode="text"
                    disabled={!hasOutsideNumber}
                    status={
                      /* istanbul ignore next */ formErrors?.outsideNumber
                        ? 'error'
                        : undefined
                    }
                    className={styles.upper}
                    hintText={
                      /* istanbul ignore next */ formErrors?.outsideNumber
                        ? t(formErrors.outsideNumber.message as string)
                        : undefined
                    }
                    {...register(ADDRESS_INFO_FIELDS.outsideNumber, {
                      ...formValidations.outsideNumber,
                      required: hasOutsideNumber
                        ? inputs.outsideNumberRequired
                        : false,
                    })}
                  />
                  <Checkbox
                    id="hasNoOutsideNumber"
                    className="mt-1"
                    checked={!hasOutsideNumber}
                    {...register(ADDRESS_INFO_FIELDS.hasOutsideNumber, {
                      onChange: handleNoOutsideNumber,
                    })}
                  >
                    {t(inputs.hasNoOutsideNumber)}
                  </Checkbox>
                </div>
              )}
              {isLoadingData ? (
                <SkeletonLoader kind="large" reverse />
              ) : (
                <Input
                  id="insideNumber"
                  widthSize="fluid"
                  label={t(inputs.insideNumber)}
                  type="text"
                  placeholder=""
                  inputMode="text"
                  status={
                    /* istanbul ignore next */ formErrors?.insideNumber
                      ? 'error'
                      : undefined
                  }
                  className={styles.upper}
                  hintText={
                    /* istanbul ignore next */ formErrors?.insideNumber
                      ? t(formErrors.insideNumber.message as string)
                      : undefined
                  }
                  {...register(
                    ADDRESS_INFO_FIELDS.insideNumber,
                    formValidations.insideNumber,
                  )}
                />
              )}
            </FormRow>
          </FormRow>
        </FormSection>
      </FormContent>
      <FormFooter className={styles.footer}>
        {!isEditing && onGoBack ? (
          <div className={styles['footer-link']}>
            <ButtonLink onClick={onGoBack}>
              <CaretLeft
                color={styles['icon-color']}
                size={styles['icon-size']}
              />
              {t(buttons.previous)}
            </ButtonLink>
          </div>
        ) : null}
        {isEditing && onCloseEdition ? (
          <div className={styles['footer-link']}>
            <ButtonLink onClick={onCloseEdition} underline="always">
              {t(buttons.cancel)}
            </ButtonLink>
          </div>
        ) : null}
        <Button
          testId="submitButton"
          disabled={!isValidForm}
          loading={isLoadingForm}
          kind="primary"
          onClick={handleSubmit(handleSubmitCallback)}
          id={LITE_IDS.addressInfo}
          icon={<ArrowCircleRight size={styles['icon-size']} weight="fill" />}
        >
          {t(isEditing ? buttons.save : buttons.continue)}
        </Button>
      </FormFooter>
    </motion.div>
  );
}
