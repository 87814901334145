import { useEffect, useState } from 'react';
import { AppStoreLogo, Button, Typography } from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { motion } from 'framer-motion';

import {
  useNotificationProvider,
  NOTIFICATION_TIMEOUT,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  QrCode,
  FormContent,
  IconText,
} from '@gbm/onboarding-sdk-ui-components';
import gbmLogo from '@gbm/onboarding-sdk-ui-components/assets/images/GBM.svg';
import huaweiIcon from '@gbm/onboarding-sdk-ui-components/assets/images/icons/Huawei-black.svg';
import { UPGRADE_IDS, translations } from '@gbm/onboarding-sdk-utils';
import { useBreakpoints } from '@gbm/onboarding-sdk-hooks';
import {
  DEEP_LINKS_ACTION,
  DEEP_LINKS_ORIGIN,
  DEEP_LINKS_PROVIDER,
  DeepLinksProviderType,
} from '../../../../api/upgrade/types';
import { UpgradeLayout } from '../../../components/upgrade';
import { useAppDispatch, useAppSelector } from '../../../store';
import { REDUCER_STATUS } from '../../../store/types';
import {
  selectDeepLinks,
  selectDeepLinkByProvider,
  selectErrors,
  clearErrors,
  getDeepLink,
} from '../../../store/upgrade';
import { useUpgradeProvider } from '../../../providers/upgrade';
import { useUpgradeStatus } from '../../../hooks';
import styles from './identity-validation.module.scss';

const {
  buttons,
  upgrade: { identityValidation, upgrade },
} = translations;

export default function IdentityValidation() {
  const { isSm } = useBreakpoints();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const deepLinks = useAppSelector(selectDeepLinks);
  const errors = useAppSelector(selectErrors);
  const { showNotification } = useNotificationProvider();
  const [device, setDevice] = useState<DeepLinksProviderType>();
  const [buttonWasClicked, setButtonWasClicked] = useState(false);
  const [showQrModal, setShowQrModal] = useState(false);
  const { configuration } = useUpgradeProvider();
  const isPending = deepLinks.status === REDUCER_STATUS.pending;
  const linkAllDevices = useAppSelector(
    selectDeepLinkByProvider(DEEP_LINKS_PROVIDER.all),
  );
  const linkHuawei = useAppSelector(
    selectDeepLinkByProvider(DEEP_LINKS_PROVIDER.huawei),
  );
  const { poolForRequestStatus, stopPooling } = useUpgradeStatus(configuration);

  useEffect(() => {
    configuration.onStartIdentityValidation?.();
    poolForRequestStatus();
    return () => {
      stopPooling();
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(errors.deepLinks)) {
      showNotification({
        description: errors.deepLinks?.message,
        kind: 'error',
      });
      setTimeout(() => {
        dispatch(clearErrors());
      }, NOTIFICATION_TIMEOUT);
    }
  }, [errors.deepLinks]);

  useEffect(() => {
    if (device && linkIsReady(device) && buttonWasClicked) {
      setShowQrModal(true);
    }
  }, [linkAllDevices, linkHuawei, device, buttonWasClicked]);

  const linkIsReady = (selectedDevice: DeepLinksProviderType) =>
    selectedDevice === DEEP_LINKS_PROVIDER.all
      ? !!linkAllDevices
      : !!linkHuawei;

  const getQRLink = (selectedDevice: DeepLinksProviderType) => {
    setDevice(selectedDevice);

    if (linkIsReady(selectedDevice) && buttonWasClicked) {
      setShowQrModal(true);
      return;
    }

    setButtonWasClicked(true);

    const params = [
      {
        key: 'access_token',
        value: configuration.accessToken,
      },
      {
        key: 'token_type',
        value: 'Bearer',
      },
      {
        key: 'email',
        value: configuration.email,
      },
      {
        key: 'app_name',
        value: configuration.appName,
      },
    ];
    dispatch(
      getDeepLink({
        params,
        config: configuration,
        action: DEEP_LINKS_ACTION.limitIncrease,
        provider: selectedDevice,
        origin: DEEP_LINKS_ORIGIN,
      }),
    );
  };

  return (
    <UpgradeLayout current={4}>
      <motion.div
        className={styles.container}
        id={UPGRADE_IDS.viewIdentity}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <FormContent>
          <Typography variant={isSm ? 'h4' : 'h3'} weight="semibold">
            {t(identityValidation.title)}
          </Typography>
          <div className={styles.description}>
            <Typography variant="body2">
              {t(identityValidation.description)}
            </Typography>
          </div>
          <div className={styles.buttons}>
            <Button
              kind="primary"
              loading={isPending && device === DEEP_LINKS_PROVIDER.all}
              onClick={() => getQRLink(DEEP_LINKS_PROVIDER.all)}
              size="fluid"
              icon={
                <AppStoreLogo
                  color={styles['icon-color']}
                  size={styles['icon-size']}
                />
              }
              iconAlignment="left"
            >
              iOS / Android
            </Button>
            <Button
              kind="secondary"
              loading={isPending && device === DEEP_LINKS_PROVIDER.huawei}
              onClick={() => getQRLink(DEEP_LINKS_PROVIDER.huawei)}
              size="fluid"
            >
              <IconText
                text="Huawei"
                iconPosition="left"
                icon={huaweiIcon}
                className={styles.huawei}
              />
            </Button>
          </div>
        </FormContent>
      </motion.div>
      <Modal show={showQrModal}>
        <ModalHeader
          className={styles.header}
          onClose={() => setShowQrModal(false)}
        />
        <ModalContent>
          <div className={styles.title}>
            <Typography variant="h5">{t(upgrade.increaseYourLimit)}</Typography>
          </div>
          <div className={styles.subtitle}>
            <Typography variant="small">{t(upgrade.scanCode)}</Typography>
          </div>
          <div className={styles.frame}>
            <div className={styles.code}>
              <QrCode
                size={128}
                value={
                  device === DEEP_LINKS_PROVIDER.all
                    ? linkAllDevices
                    : linkHuawei
                }
                withImage
                imageSrc={gbmLogo}
                imageWidth={40}
                imageHeight={12}
                imageAlt={t(upgrade.increaseYourLimit)}
              />
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            kind="primary"
            size="fluid"
            onClick={() => setShowQrModal(false)}
          >
            {t(buttons.ready)}
          </Button>
        </ModalFooter>
      </Modal>
    </UpgradeLayout>
  );
}
