import { useEffect } from 'react';
import { Button } from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';

import { FullAlert } from '@gbm/onboarding-sdk-ui-components';
import {
  TRACKING_EVENTS,
  TRACKING_NAMES,
  translations,
} from '@gbm/onboarding-sdk-utils';
import { useBreakpoints, useMixpanel } from '@gbm/onboarding-sdk-hooks';
import { useOpeningLiteProvider } from '../../../providers/opening-lite';

type DelayedProps = {
  onClick: () => void;
};
const {
  buttons,
  openingLite: { contractStatus },
} = translations;

export default function Delayed({ onClick }: DelayedProps) {
  const { t } = useTranslation();
  const { isSm } = useBreakpoints();
  const { configuration } = useOpeningLiteProvider();
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  useEffect(() => {
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.contractExpectedTimeError,
    });
  }, []);

  return (
    <FullAlert
      actions={
        <Button
          size={isSm ? 'fluid' : 'default'}
          kind="primary"
          onClick={onClick}
        >
          {t(buttons.understood)}
        </Button>
      }
      description={t(contractStatus.delayed)}
      title={t(contractStatus.somethingHappened)}
      environment={configuration.environment}
    />
  );
}
