import { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Typography, Checkbox, Tooltip, ButtonLink } from '@gbm/starman-next';

import {
  ExternalLink,
  GeolocationEnableSteps,
  GeolocationRejectedSteps,
  Markdown,
  Modal,
  ModalContent,
  ModalHeader,
  useNotificationProvider,
  Loader,
} from '@gbm/onboarding-sdk-ui-components';
import {
  config,
  ENVIRONMENTS,
  GENERIC_ERROR,
  GEOLOCATION_PERMISSIONS_STATUSES,
  LEGAL_DOCUMENT_TYPES,
  LegalDocumentType,
  TRACKING_EVENTS,
  TRACKING_NAMES,
  translations,
} from '@gbm/onboarding-sdk-utils';
import { useBreakpoints, useMixpanel } from '@gbm/onboarding-sdk-hooks';

import { useOpeningLiteProvider } from '../../../../providers/opening-lite';
import { getMarkdownDataFromLegalDoc } from '../../../../../utils/helpers';
import { useGeolocation } from '../../../../hooks';
import { GeolocationType } from '../../../../../api/opening-lite/types';
import styles from './acceptance-checks.module.scss';

type AcceptanceChecksProps = {
  termsAndConditionsDocument: LegalDocumentType;
  executionServiceDocument: LegalDocumentType;
  onChecksAccepted: (accepted: boolean) => void;
  onGeneralExecutionAccepted: (accepted: boolean) => void;
  onGeolocationAccepted: (data: GeolocationType) => void;
  externalTermsAndConditionsUrl?: string;
  appName?: string;
  isEnabledTradingOpening?: boolean;
};
const {
  inputs,
  links,
  base: { termsAndConditions, executionService },
} = translations;
const { legalDocsBaseUrl } = config(ENVIRONMENTS.production);

export default function AcceptanceChecks({
  termsAndConditionsDocument,
  executionServiceDocument,
  onChecksAccepted,
  onGeolocationAccepted,
  externalTermsAndConditionsUrl,
  appName,
  isEnabledTradingOpening,
  onGeneralExecutionAccepted,
}: AcceptanceChecksProps) {
  const { t } = useTranslation();
  const { isSm, isMd } = useBreakpoints();
  const { showNotification } = useNotificationProvider();
  const { configuration } = useOpeningLiteProvider();
  const {
    data: geolocationData,
    permission,
    checkPermission,
    getGeolocation,
  } = useGeolocation();
  const [displayGeolocationEnableSteps, setDisplayGeolocationEnableSteps] =
    useState(false);
  const [displayGeolocationRejectedSteps, setDisplayGeolocationRejectedSteps] =
    useState(false);
  const [generalExecution, setGeneralExecution] = useState(false);
  const [ownResources, setOwnResources] = useState(false);
  const [termsConditions, setTermsConditions] = useState(false);
  const [generalExecutionService, setGeneralExecutionService] = useState(false);
  const [externalTermsConditions, setExternalTermsConditions] = useState(false);
  const [geolocationCheck, setGeolocationCheck] = useState(false);
  const [geolocationCheckboxDisabled, setGeolocationCheckboxDisabled] =
    useState(false);
  const [legalDocument, setLegalDocument] = useState<LegalDocumentType>();
  const [displayTermsAndConditions, setDisplayTermsAndConditions] =
    useState(false);
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  useEffect(() => {
    checkPermission();
  }, []);

  useEffect(() => {
    if (geolocationData) {
      onGeolocationAccepted(geolocationData);
      setGeolocationCheck(!geolocationCheck);
      setDisplayGeolocationEnableSteps(false);
    } else if (geolocationCheck) {
      setDisplayGeolocationRejectedSteps(true);
    }
    setGeolocationCheckboxDisabled(false);
  }, [geolocationData]);

  useEffect(() => {
    const isAcceptedExternalTerms =
      externalTermsAndConditionsUrl && appName ? externalTermsConditions : true;
    onChecksAccepted(
      generalExecution &&
        ownResources &&
        termsConditions &&
        geolocationCheck &&
        isAcceptedExternalTerms,
    );
  }, [
    generalExecution,
    ownResources,
    termsConditions,
    externalTermsConditions,
    geolocationCheck,
  ]);

  useEffect(() => {
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.confirmation,
    });
    onGeneralExecutionAccepted(generalExecutionService);
  }, [generalExecutionService]);

  const checkGeolocationPermissions = async () => {
    mixpanel.track(TRACKING_EVENTS.checkChecked, {
      name: TRACKING_NAMES.location,
    });
    switch (permission) {
      case GEOLOCATION_PERMISSIONS_STATUSES.prompt:
        setDisplayGeolocationEnableSteps(true);
        handleRequestGeolocation();
        break;
      case GEOLOCATION_PERMISSIONS_STATUSES.granted:
        handleRequestGeolocation();
        break;
      case GEOLOCATION_PERMISSIONS_STATUSES.denied:
        setDisplayGeolocationRejectedSteps(true);
        break;
      default:
        break;
    }
  };

  const handleRequestGeolocation = () => {
    if (!geolocationCheck) {
      setGeolocationCheckboxDisabled(true);
      getGeolocation();
    } else {
      setGeolocationCheck(!geolocationCheck);
    }
  };

  const handleDisplayLegalDoc = async (document: LegalDocumentType) => {
    setDisplayTermsAndConditions(true);
    /* istanbul ignore else */
    if (!legalDocument || legalDocument.type !== document.type) {
      setIsLoadingDocument(true);
      const data = await getMarkdownDataFromLegalDoc(
        legalDocsBaseUrl,
        document.file_name as string,
        document.uri.replace('.pdf', '.md'),
      );

      if (data) {
        setIsLoadingDocument(false);
        setLegalDocument({
          ...document,
          data,
          type: document.type,
        });
      } else {
        setDisplayTermsAndConditions(false);
        showNotification({
          kind: 'error',
          description: GENERIC_ERROR,
        });
      }
    }
  };

  return (
    <>
      <GeolocationEnableSteps
        show={displayGeolocationEnableSteps}
        onClick={() => setDisplayGeolocationEnableSteps(false)}
      />
      <GeolocationRejectedSteps
        show={displayGeolocationRejectedSteps}
        onClose={() => setDisplayGeolocationRejectedSteps(false)}
      />
      <Modal show={displayTermsAndConditions} fullScreen>
        <ModalHeader onClose={() => setDisplayTermsAndConditions(false)} />
        <ModalContent loading={!legalDocument?.data}>
          {!isLoadingDocument && (
            <Typography variant="h1">
              {legalDocument?.type === LEGAL_DOCUMENT_TYPES.termsAndConditions
                ? t(termsAndConditions)
                : t(executionService)}
            </Typography>
          )}
          <div className={styles['legal-document-content']}>
            {isLoadingDocument ? (
              <Loader kind="dark" />
            ) : (
              <Markdown>{legalDocument?.data as string}</Markdown>
            )}
          </div>
        </ModalContent>
      </Modal>
      <div className={styles.container}>
        <Checkbox
          id="generalExecution"
          checked={generalExecution}
          onChange={() => setGeneralExecution(!generalExecution)}
        >
          <Typography variant="small">
            {t(inputs.generalExecutionCheckbox)}
          </Typography>
        </Checkbox>
        <Checkbox
          id="termsConditions"
          data-testid="termsConditions"
          checked={termsConditions}
          onChange={() => {
            if (!termsConditions)
              mixpanel.track(TRACKING_EVENTS.checkChecked, {
                name: TRACKING_NAMES.termsAndConditions,
              });
            setTermsConditions(!termsConditions);
          }}
        >
          <div className={styles.checkbox}>
            <Typography variant="small">{t(inputs.acceptThe)}</Typography>
            &nbsp;
            <ButtonLink
              underline="always"
              onClick={() => handleDisplayLegalDoc(termsAndConditionsDocument)}
            >
              <Typography variant="small" weight="semibold">
                {t(inputs.terms)}
              </Typography>
            </ButtonLink>
            &nbsp;
            <Typography variant="small">{t(inputs.gbmTerms)}</Typography>
          </div>
        </Checkbox>
        {externalTermsAndConditionsUrl && appName ? (
          <Checkbox
            id="externalTermsConditions"
            checked={externalTermsConditions}
            onChange={() =>
              setExternalTermsConditions(!externalTermsConditions)
            }
          >
            <div className={styles.checkbox}>
              <Typography variant="small">{t(inputs.acceptThe)}</Typography>
              &nbsp;
              <ExternalLink
                target="_blank"
                href={externalTermsAndConditionsUrl}
              >
                <Typography variant="small" weight="semibold">
                  {t(inputs.terms)}
                </Typography>
              </ExternalLink>
              &nbsp;
              <Typography variant="small">
                {t(inputs.appTerms, { appName })}
              </Typography>
            </div>
          </Checkbox>
        ) : null}
        <Checkbox
          id="ownResources"
          checked={ownResources}
          onChange={() => {
            if (!ownResources) {
              mixpanel.track(TRACKING_EVENTS.checkChecked, {
                name: TRACKING_NAMES.ownResources,
              });
            }
            setOwnResources(!ownResources);
          }}
        >
          <Typography variant="small">
            {t(inputs.ownResourcesCheckbox)}
          </Typography>
        </Checkbox>
        <Checkbox
          id="geolocationCheck"
          data-testid="geolocationCheck"
          checked={geolocationCheck}
          disabled={geolocationCheckboxDisabled}
          onChange={checkGeolocationPermissions}
        >
          <div className={styles.checkbox}>
            <Typography variant="small">
              {t(inputs.acceptGeolocation)}
            </Typography>
            &nbsp;
            <Tooltip
              size={isSm || isMd ? 'small' : 'default'}
              contentChildren={[
                { content: t(inputs.acceptGeolocationDescription) },
              ]}
              position="bottom"
            >
              <ButtonLink type="button" underline="always" kind="accent">
                <Typography variant="small" weight="semibold">
                  {t(links.knowMore)}
                </Typography>
              </ButtonLink>
            </Tooltip>
          </div>
        </Checkbox>
        {isEnabledTradingOpening && (
          <Checkbox
            id="generalExecutionService"
            data-testid="generalExecutionService"
            checked={generalExecutionService}
            onChange={() => {
              if (!generalExecutionService) {
                mixpanel.track(TRACKING_EVENTS.checkChecked, {
                  name: TRACKING_NAMES.executionService,
                });
              }
              setGeneralExecutionService(!generalExecutionService);
            }}
            description={
              <div className={styles.checkbox}>
                <Typography variant="small">
                  <Trans
                    i18nKey={inputs.generalExecutionServiceDescription}
                    components={[<strong></strong>]}
                  />
                </Typography>
                &nbsp;
                <ButtonLink
                  underline="always"
                  onClick={() =>
                    handleDisplayLegalDoc(executionServiceDocument)
                  }
                >
                  <Typography variant="small" weight="semibold">
                    {t(inputs.generalExecutionServiceDocument)}
                  </Typography>
                </ButtonLink>
              </div>
            }
          >
            <div className={styles.checkbox}>
              <Trans
                i18nKey={inputs.generalExecutionService}
                components={[<strong></strong>]}
              />
            </div>
          </Checkbox>
        )}
      </div>
    </>
  );
}
