import { useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  useNotificationProvider,
  NOTIFICATION_TIMEOUT,
} from '@gbm/onboarding-sdk-ui-components';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  selectKycQuestionnaire,
  selectKycAnswers,
  selectErrors,
  getKycQuestionnaire,
  clearErrors,
  getKycAnswers,
  saveKycAnswers,
} from '../../store/upgrade';
import { UpgradeProviderProps } from '../../providers/upgrade/types';
import { LoremQuestionType } from '../../../api/upgrade/types';
import { HTTP_VERBS, HttpVerbsTypes } from '../../../api/types';
import { getQuestionsWithAnswers } from '../../../utils/helpers';
import {
  AnswersDataType,
  QuestionnaireDataType,
} from '../../components/upgrade/financial-information/types';
import { useParty } from '../useParty';
import { REDUCER_STATUS } from '../../store/types';

export default function useKycQuestionnaire(config: UpgradeProviderProps) {
  const dispatch = useAppDispatch();
  const { showNotification } = useNotificationProvider();
  const { party } = useParty(config);
  const questionnaire = useAppSelector(selectKycQuestionnaire);
  const answers = useAppSelector(selectKycAnswers);
  const errors = useAppSelector(selectErrors);
  const isLoadingQuestionnaire =
    questionnaire.status === REDUCER_STATUS.pending;
  const isLoadingAnswers = answers.status === REDUCER_STATUS.pending;
  const isRejected =
    questionnaire.status === REDUCER_STATUS.rejected ||
    answers.status === REDUCER_STATUS.rejected;
  const isEmptyQuestionnaire = isEmpty(questionnaire.data);
  const isEmptyAnswers = isEmpty(answers.data);
  const isFulfilledQuestionnaire =
    questionnaire.status === REDUCER_STATUS.resolved && !isEmptyQuestionnaire;
  const isFulfilledAnswers =
    answers.status === REDUCER_STATUS.resolved && !isEmptyAnswers;

  const fetchQuestionnaire = (force: boolean = false) => {
    if ((isEmptyQuestionnaire && !isLoadingQuestionnaire) || force) {
      dispatch(getKycQuestionnaire({ config }));
    }
  };

  const fetchAnswers = (force: boolean = false) => {
    if ((isEmptyAnswers && !isLoadingAnswers) || force) {
      dispatch(getKycAnswers({ config, partyId: party.data.party_id }));
    }
  };

  const saveAnswers = (questions: Array<LoremQuestionType>) => {
    let method: HttpVerbsTypes = HTTP_VERBS.post;

    if (
      'questionnaireAnswers' in answers.data &&
      'version' in answers.data &&
      !isEmpty(answers.data.questionnaireAnswers) &&
      'version' in questionnaire.data &&
      questionnaire.data.version === answers.data.version
    ) {
      method = HTTP_VERBS.put;
    }

    dispatch(
      saveKycAnswers({
        config,
        questions,
        method,
        partyId: party.data.party_id,
      }),
    );
  };

  const questionsWithAnswers = useMemo(
    () =>
      getQuestionsWithAnswers(
        questionnaire.data as QuestionnaireDataType,
        answers.data as AnswersDataType,
      ),
    [questionnaire.data, answers.data],
  );

  useEffect(() => {
    let hasError = false;

    if (!isEmpty(errors.kycQuestionnaire)) {
      showNotification({
        description: errors.kycQuestionnaire?.message,
        kind: 'error',
      });
      hasError = true;
    }

    if (!isEmpty(errors.kycAnswers)) {
      showNotification({
        description: errors.kycAnswers?.message,
        kind: 'error',
      });
      hasError = true;
    }

    if (hasError) {
      setTimeout(() => {
        dispatch(clearErrors());
      }, NOTIFICATION_TIMEOUT);
    }
  }, [errors.kycQuestionnaire, errors.kycAnswers]);

  return {
    questionnaire,
    answers,
    questionsWithAnswers,
    fetchQuestionnaire,
    fetchAnswers,
    saveAnswers,
    isLoading: isLoadingQuestionnaire || isLoadingAnswers,
    isRejected,
    isFulfilledQuestionnaire,
    isFulfilledAnswers,
    isEmptyQuestionnaire,
    isEmptyAnswers,
  };
}
