import { ReactNode } from 'react';
import { NotificationProps, NotificationKinds } from '@gbm/starman-next';

export type NotificationProviderProps = {
  children: ReactNode;
};

export type NotificationContextType = {
  showNotification: (props: Partial<NotificationProps>) => void;
  hideNotification: () => void;
};

export const NOTIFICATION_TIMEOUT = 6000;
export const DEFAULT_NOTIFICATION_PROPS: NotificationProps = {
  title: 'Error',
  description: '',
  onClose: () => null,
  kind: NotificationKinds.error,
} as const;

/* istanbul ignore next */
export const mockNotificationProviderValue = {
  showNotification: () => null,
  hideNotification: () => null,
} as const;
