import { useState } from 'react';
import { Button } from '@gbm/starman-next';
import { useTranslation, Trans } from 'react-i18next';

import { translations } from '@gbm/onboarding-sdk-utils';
import { FullAlert } from '@gbm/onboarding-sdk-ui-components';

import Help from '../../opening-lite/curp-info/help';
import { useUpgradeProvider } from '../../../providers/upgrade';

export type InvalidUpgradeProps = {
  onClose: () => void;
};
const {
  buttons,
  upgrade: {
    upgrade: { invalidDescription, invalidTitle },
  },
} = translations;

export default function InvalidUpgrade({ onClose }: InvalidUpgradeProps) {
  const { t } = useTranslation();
  const { configuration } = useUpgradeProvider();
  const [showHelpModal, setShowHelpModal] = useState(false);

  if (showHelpModal) return <Help onBack={onClose} />;

  return (
    <FullAlert
      kind="error"
      description={<Trans i18nKey={invalidDescription} components={[<br />]} />}
      title={t(invalidTitle)}
      environment={configuration.environment}
      actionsEnd={false}
      withChat={false}
      actions={
        <Button
          size="default"
          kind="secondary"
          iconAlignment="left"
          onClick={() => setShowHelpModal(true)}
        >
          {t(buttons.contactGbm)}
        </Button>
      }
    />
  );
}
