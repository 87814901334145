import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  useNotificationProvider,
  NOTIFICATION_TIMEOUT,
} from '@gbm/onboarding-sdk-ui-components';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getContract,
  saveContract,
  clearErrors,
  selectContract,
  selectErrors,
} from '../../store/opening-lite';
import { OpeningLiteProviderProps } from '../../providers/opening-lite/types';
import { UseContractType } from './types';
import { REDUCER_STATUS } from '../../store/types';
import { SaveContractRequestType } from '../../store/opening-lite/types';

export default function useContract(
  config: OpeningLiteProviderProps,
): UseContractType {
  const dispatch = useAppDispatch();
  const { showNotification } = useNotificationProvider();
  const contract = useAppSelector(selectContract);
  const errors = useAppSelector(selectErrors);
  const isLoading = contract.status === REDUCER_STATUS.pending;
  const isRejected = contract.status === REDUCER_STATUS.rejected;
  const isResolved = contract.status === REDUCER_STATUS.resolved;
  const isEmptyContract = isEmpty(contract.data);
  const isFulfilled =
    contract.status === REDUCER_STATUS.resolved && !isEmptyContract;

  const fetch = () => {
    if (isEmptyContract && !isLoading) {
      dispatch(getContract({ config }));
    }
  };

  const save = (data: SaveContractRequestType) => {
    dispatch(saveContract(data));
  };

  useEffect(() => {
    if (!isEmpty(errors.contract)) {
      showNotification({
        description: errors.contract?.message,
        kind: 'error',
      });
      setTimeout(() => {
        dispatch(clearErrors());
      }, NOTIFICATION_TIMEOUT);
    }
  }, [errors.contract]);

  return {
    contract,
    fetch,
    save,
    isLoading,
    isRejected,
    isResolved,
    isFulfilled,
    isEmpty: isEmptyContract,
  };
}
