import { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';

import {
  Loader,
  NotificationProvider,
} from '@gbm/onboarding-sdk-ui-components';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import { i18n } from '@gbm/onboarding-sdk-utils';
import { AuthProvider, useAuthProvider } from '../../providers/auth';
import { AuthProps, STEPS } from '../../providers/auth/types';
import { useLanguage } from '../../hooks';
import { SignIn } from './sign-in';
import { SignUp } from './sign-up';
import { SignUpConfirmation } from './sign-up-confirmation';
import { PasswordRecovery } from './password-recovery';
import { PasswordRecoverySent } from './password-recovery-sent';

function Auth() {
  const { step, isLoading } = useAuthProvider();

  if (isLoading) {
    return <Loader kind="light" />;
  }

  return (
    <>
      {step === STEPS.signIn && <SignIn />}
      {step === STEPS.signUp && <SignUp />}
      {step === STEPS.confirmation && <SignUpConfirmation />}
      {step === STEPS.passwordRecovery && <PasswordRecovery />}
      {step === STEPS.passwordRecoverySent && <PasswordRecoverySent />}
    </>
  );
}

export default function WithProviders(props: AuthProps): JSX.Element {
  const { isLoading } = useLanguage(props.translations);
  const [trackingIsReady, setTrackingIsReady] = useState(false);
  const mixpanel = useMixpanel(props.environment, props.isEnabledTrackEvents);

  useEffect(() => {
    mixpanel.init();
    setTrackingIsReady(true);
  }, []);

  if (isLoading || !trackingIsReady) {
    return <Loader kind="light" />;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <NotificationProvider>
        <AuthProvider {...props}>
          <Auth />
        </AuthProvider>
      </NotificationProvider>
    </I18nextProvider>
  );
}
