import { useState } from 'react';
import {
  Button,
  ArrowLeft,
  Input,
  Typography,
  ButtonLink,
} from '@gbm/starman-next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Fade } from '@gbm/onboarding-sdk-ui-components';
import { translations } from '@gbm/onboarding-sdk-utils';
import { useBreakpoints } from '@gbm/onboarding-sdk-hooks';
import { Layout } from '../../../components/auth';
import { useAuthProvider } from '../../../providers/auth';
import { STEPS } from '../../../providers/auth/types';
import { FormValues, PASSWORD_RECOVERY_FIELDS } from './types';
import formValidations from './validations';
import styles from './password-recovery.module.scss';

const {
  inputs,
  buttons,
  auth: { passwordRecovery },
} = translations;

export default function PasswordRecovery() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      [PASSWORD_RECOVERY_FIELDS.email]: '',
    },
  });
  const { handlePasswordRecovery, setStep, configuration } = useAuthProvider();
  const { isSm } = useBreakpoints();
  const [isLoading, setIsLoading] = useState(false);

  const submitCallback: SubmitHandler<FormValues> = async (formData) => {
    setIsLoading(true);
    const { email } = formData;
    await handlePasswordRecovery(email);
    setIsLoading(false);
  };

  return (
    <Fade>
      <Layout images={configuration.images}>
        <div className={styles.container}>
          <form
            className={styles.form}
            onSubmit={handleSubmit(submitCallback)}
            noValidate
          >
            <div className={styles.title}>
              <Typography variant={isSm ? 'h5' : 'h2'} weight="semibold">
                {t(passwordRecovery.title)}
              </Typography>
            </div>
            <div className={styles.subtitle}>
              <Typography variant={isSm ? 'small' : 'body2'}>
                {t(passwordRecovery.subtitle)}
              </Typography>
            </div>
            <Input
              widthSize="fluid"
              label={t(inputs.email)}
              type="email"
              placeholder=""
              inputMode="email"
              status={formErrors?.email ? 'error' : undefined}
              style={{ textTransform: 'lowercase' }}
              hintText={
                formErrors?.email
                  ? t(formErrors.email.message as string)
                  : undefined
              }
              {...register(
                PASSWORD_RECOVERY_FIELDS.email,
                formValidations.email,
              )}
            />
            <div className={styles.button}>
              <Button disabled={!isValid} loading={isLoading} kind="primary">
                {t(buttons.continue)}
              </Button>
            </div>
            <div className={styles.back}>
              <ButtonLink onClick={() => setStep(STEPS.signIn)}>
                <ArrowLeft
                  color={styles['icon-color']}
                  size={styles['icon-size']}
                />
                <Typography
                  variant={isSm ? 'small' : 'body2'}
                  weight="semibold"
                >
                  {t(buttons.goBack)}
                </Typography>
              </ButtonLink>
            </div>
          </form>
        </div>
      </Layout>
    </Fade>
  );
}
