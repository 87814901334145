import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { UpgradeProviderProps } from '../../providers/upgrade/types';
import { useAppDispatch, useAppSelector } from '../../store';
import { getUdisSummary, selectUdisSummary } from '../../store/upgrade';
import { useParty } from '../useParty';
import { REDUCER_STATUS } from '../../store/types';

type UseUdisSummaryType = {
  depositsAmount: string;
  monthlyLimit: string;
  nextLevelLimit: string;
  hasReachedSoftLimit: boolean;
  hasReachedLimit: boolean;
  isLoading: boolean;
  isRejected: boolean;
  isFulfilled: boolean;
  isEmpty: boolean;
  fetch: () => void;
};

export default function useUdisSummary(
  configuration: UpgradeProviderProps,
): UseUdisSummaryType {
  const dispatch = useAppDispatch();
  const { party } = useParty(configuration);
  const udisSummary = useAppSelector(selectUdisSummary);
  const isLoading = udisSummary.status === REDUCER_STATUS.pending;
  const isRejected = udisSummary.status === REDUCER_STATUS.rejected;
  const isFulfilled = udisSummary.status === REDUCER_STATUS.resolved;
  const isEmptyUdisSummary = isEmpty(udisSummary.data);
  const [depositsAmount, setDepositsAmount] = useState('');
  const [monthlyLimit, setMonthlyLimit] = useState('');
  const [nextLevelLimit, setNextLevelLimit] = useState('');
  const [hasReachedSoftLimit, setHasReachedSoftLimit] = useState(false);
  const [hasReachedLimit, setHasReachedLimit] = useState(false);

  const fetch = () => {
    if (isEmptyUdisSummary && !isLoading) {
      dispatch(
        getUdisSummary({
          partyId: party.data.party_id,
          config: configuration,
        }),
      );
    }
  };

  useEffect(() => {
    if (isFulfilled) {
      setDepositsAmount(
        udisSummary.data.current_month_deposits_amount.toFixed(2),
      );
      setMonthlyLimit(udisSummary.data.monthly_limit_amount);
      setNextLevelLimit(udisSummary.data.next_level_monthly_limit_amount);
      setHasReachedSoftLimit(udisSummary.data.has_reached_monthly_soft_limit);
      setHasReachedLimit(udisSummary.data.has_reached_monthly_limit);
    }
  }, [udisSummary.status]);

  return {
    depositsAmount,
    monthlyLimit,
    nextLevelLimit,
    hasReachedSoftLimit,
    hasReachedLimit,
    isLoading,
    isFulfilled,
    isRejected,
    isEmpty: isEmptyUdisSummary,
    fetch,
  };
}
