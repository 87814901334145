import { Values } from '@gbm/onboarding-sdk-utils';

import setupStore, { store } from './store';

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export const REDUCER_STATUS = {
  idle: 'idle',
  pending: 'pending',
  rejected: 'rejected',
  resolved: 'resolved',
} as const;
export type ReducerStatusType = Values<typeof REDUCER_STATUS>;

export type StateValueType<T = Record<string, any>> = {
  status: ReducerStatusType;
  data: T;
};
