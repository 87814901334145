import { PropsWithChildren, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';

import { useBodyScroll } from '@gbm/onboarding-sdk-hooks';
import styles from './modal.module.scss';

export type ModalProps = PropsWithChildren<{
  show?: boolean;
  fullScreen?: boolean;
  className?: string;
}>;

function Modal({ children, fullScreen, className }: Omit<ModalProps, 'show'>) {
  const { disableBodyScroll, enableBodyScroll } = useBodyScroll();

  useEffect(() => {
    disableBodyScroll();
    return () => enableBodyScroll();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.container}
    >
      <div
        className={clsx(
          styles.modal,
          {
            [styles['modal--full-screen']]: fullScreen,
          },
          className,
        )}
      >
        {children}
      </div>
    </motion.div>
  );
}

export default function ModalWrapper({ show, ...props }: ModalProps) {
  return (
    <AnimatePresence>{show ? <Modal {...props} /> : null}</AnimatePresence>
  );
}
