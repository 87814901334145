/* eslint-disable jsx-a11y/media-has-caption */
import { useState } from 'react';
import { Button, PlayCircle, Typography } from '@gbm/starman-next';
import { useTranslation, Trans } from 'react-i18next';

import { CloseButton, Modal } from '@gbm/onboarding-sdk-ui-components';
import {
  TRACKING_EVENTS,
  TRACKING_NAMES,
  translations,
} from '@gbm/onboarding-sdk-utils';
import { useBreakpoints, useMixpanel } from '@gbm/onboarding-sdk-hooks';
import { useUpgradeProvider } from '../../../providers/upgrade';
import styles from './initial-screen.module.scss';

const {
  buttons,
  upgrade: { upgrade },
} = translations;

export default function Description() {
  const { t } = useTranslation();
  const { isSm } = useBreakpoints();
  const [showVideo, setShowVideo] = useState(false);
  const { configuration } = useUpgradeProvider();
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  const handleShowVideo = () => {
    mixpanel.track(TRACKING_EVENTS.linkClicked, {
      name: TRACKING_NAMES.playVideoUpgrade,
    });
    setShowVideo(true);
  };

  return (
    <div className={styles.description}>
      <Typography variant="h4">{t(upgrade.description)}</Typography>
      <div className={styles['description-text']}>
        <Typography variant="body2">
          <Trans
            i18nKey={upgrade.descriptionText}
            components={[<strong></strong>]}
          />
        </Typography>
      </div>
      <div className={styles['description-question']}>
        <Typography variant="body2">
          {t(upgrade.descriptionQuestion)}
        </Typography>
      </div>
      <div className={styles['description-list-container']}>
        <ol className={styles['description-list']}>
          <li>
            <Typography variant="body2">
              {t(upgrade.blackBenefitsFirst)}
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              <Trans
                i18nKey={upgrade.blackBenefitsSecond}
                components={[<strong></strong>]}
              />
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              {t(upgrade.blackBenefitsThird)}
            </Typography>
          </li>
          <li>
            <Typography variant="body2">
              {t(upgrade.blackBenefitsFourth)}
            </Typography>
          </li>
        </ol>
      </div>
      <div className={styles['description-button']}>
        <Button
          size={isSm ? 'fluid' : 'default'}
          kind="secondary"
          onClick={handleShowVideo}
          icon={<PlayCircle size={styles['icon-size']} />}
        >
          {t(buttons.playTutorial)}
        </Button>
      </div>
      <Modal show={showVideo} className={styles['description-video']}>
        <div className={styles['description-close']}>
          <CloseButton kind="primary" onClick={() => setShowVideo(false)} />
        </div>
        <video
          controls
          autoPlay
          controlsList="nodownload"
          src="https://assets.gbm.com/onboarding/video/gbm_upgrade.mp4"
        />
      </Modal>
    </div>
  );
}
