import { RegisterOptions } from 'react-hook-form';
import { ZIP_CODE_LENGTH, translations } from '@gbm/onboarding-sdk-utils';

import { normalizeText } from '../../../../utils/helpers';

import { OUTSIDE_NUMBER_MAX_LENGTH, STREET_NAME_MAX_LENGTH } from './types';

const zipCodeValidations: RegisterOptions = {
  required: translations.inputs.zipCodeRequired,
  minLength: {
    value: ZIP_CODE_LENGTH,
    message: translations.inputs.zipCodeLength,
  },
  maxLength: {
    value: ZIP_CODE_LENGTH,
    message: translations.inputs.zipCodeLength,
  },
};

const streetNameValidations: RegisterOptions = {
  required: translations.inputs.streetNameRequired,
  maxLength: {
    value: STREET_NAME_MAX_LENGTH,
    message: translations.inputs.maxLengthInvalid,
  },
  setValueAs: (value) => normalizeText(value),
};

const outsideNumberValidations: RegisterOptions = {
  required: translations.inputs.outsideNumberRequired,
  maxLength: {
    value: OUTSIDE_NUMBER_MAX_LENGTH,
    message: translations.inputs.maxLengthInvalid,
  },
};

export default {
  zipCode: zipCodeValidations,
  suburb: {
    required: translations.inputs.suburbRequired,
  },
  streetName: streetNameValidations,
  outsideNumber: outsideNumberValidations,
  insideNumber: {
    ...outsideNumberValidations,
    required: false,
  },
} as const;
