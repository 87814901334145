export const ADDRESS_INFO_FIELDS = {
  zipCode: 'zipCode',
  suburb: 'suburb',
  streetName: 'streetName',
  outsideNumber: 'outsideNumber',
  hasOutsideNumber: 'hasOutsideNumber',
  insideNumber: 'insideNumber',
} as const;

export type FormValues = {
  [ADDRESS_INFO_FIELDS.zipCode]: string;
  [ADDRESS_INFO_FIELDS.suburb]: string;
  [ADDRESS_INFO_FIELDS.streetName]: string;
  [ADDRESS_INFO_FIELDS.outsideNumber]: string;
  [ADDRESS_INFO_FIELDS.hasOutsideNumber]: string;
  [ADDRESS_INFO_FIELDS.insideNumber]: string;
};

export const STREET_NAME_MAX_LENGTH = 250;
export const OUTSIDE_NUMBER_MAX_LENGTH = 10;
export const WITHOUT_OUTSIDE_NUMBER_VALUE = 'S/N';
