import { createSelector } from '@reduxjs/toolkit';

import { DeepLinksProviderType } from '../../../api/upgrade/types';

import { RootState } from '../types';
import { INITIAL_STATE } from './types';

const selectSlice = (state: RootState) => state.upgrade || INITIAL_STATE;

export const selectNationalities = createSelector(
  selectSlice,
  (state) => state.nationalities,
);

export const selectBanks = createSelector(selectSlice, (state) => state.banks);

export const selectBankAccounts = createSelector(
  selectSlice,
  (state) => state.bankAccounts,
);

export const selectRfc = createSelector(selectSlice, (state) => state.rfc);

export const selectFiel = createSelector(selectSlice, (state) => state.fiel);

export const selectTin = createSelector(selectSlice, (state) => state.tin);

export const selectAddBankAccount = createSelector(
  selectSlice,
  (state) => state.addBankAccount,
);

export const selectUpgradeStep = createSelector(
  selectSlice,
  (state) => state.upgradeStep,
);

export const selectUpgradeStatus = createSelector(
  selectSlice,
  (state) => state.upgradeStatus,
);

export const selectUdisSummary = createSelector(
  selectSlice,
  (state) => state.udisSummary,
);

export const selectDocuments = createSelector(
  selectSlice,
  (state) => state.documents,
);

export const selectEaQuestionnaire = createSelector(
  selectSlice,
  (state) => state.eaQuestionnaire,
);

export const selectEaAnswers = createSelector(
  selectSlice,
  (state) => state.eaAnswers,
);

export const selectPepQuestionnaire = createSelector(
  selectSlice,
  (state) => state.pepQuestionnaire,
);

export const selectPepAnswers = createSelector(
  selectSlice,
  (state) => state.pepAnswers,
);

export const selectKycQuestionnaire = createSelector(
  selectSlice,
  (state) => state.kycQuestionnaire,
);

export const selectKycAnswers = createSelector(
  selectSlice,
  (state) => state.kycAnswers,
);

export const selectProofOfAddress = createSelector(
  selectSlice,
  (state) => state.proofOfAddress,
);

export const selectBankAccountDecrypted = createSelector(
  selectSlice,
  (state) => state.bankAccountDecrypted,
);

export const selectDeepLinks = createSelector(
  selectSlice,
  (state) => state.deepLinks,
);

export const selectDeepLinkByProvider = (provider: DeepLinksProviderType) =>
  createSelector(selectDeepLinks, (state) => state.data[provider] || '');

export const selectValidUpgrade = createSelector(
  selectSlice,
  (state) => state.validUpgrade,
);

export const selectErrors = createSelector(
  selectSlice,
  (state) => state.errors,
);
