import { PropsWithChildren } from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';

export type FadeProps = PropsWithChildren<{
  fadeIn?: boolean;
  duration?: number;
}>;

const transitionStatus: Record<TransitionStatus, any> = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 },
};

export default function Fade({
  children,
  fadeIn = true,
  duration = 500,
}: FadeProps) {
  return (
    <Transition in={fadeIn} timeout={duration} appear>
      {(status: TransitionStatus) => (
        <div
          style={{
            transition: `opacity ${duration}ms ease-in-out`,
            opacity: 0,
            ...transitionStatus[status],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
}
