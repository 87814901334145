import { useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import {
  useNotificationProvider,
  NOTIFICATION_TIMEOUT,
} from '@gbm/onboarding-sdk-ui-components';
import { DEFAULT_COUNTRY } from '@gbm/onboarding-sdk-utils';
import { CountryType } from '../../../api/opening-lite/types';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getCountries,
  getStates,
  clearErrors,
  selectCountries,
  selectStates,
  selectErrors,
} from '../../store/opening-lite';
import { OpeningLiteProviderProps } from '../../providers/opening-lite/types';
import { OpeningLiteStateType } from '../../store/opening-lite/types';
import { REDUCER_STATUS } from '../../store/types';

type UseCountriesAndStatesType = Pick<
  OpeningLiteStateType,
  'countries' | 'states'
> & {
  phoneCodes: Array<CountryType>;
  isLoadingCountries: boolean;
  isLoadingStates: boolean;
};

export default function useCountriesAndStates(
  configuration: OpeningLiteProviderProps,
): UseCountriesAndStatesType {
  const dispatch = useAppDispatch();
  const { showNotification } = useNotificationProvider();
  const countries = useAppSelector(selectCountries);
  const states = useAppSelector(selectStates);
  const errors = useAppSelector(selectErrors);
  const isLoadingCountries = countries.status === REDUCER_STATUS.pending;
  const isLoadingStates = states.status === REDUCER_STATUS.pending;

  const phoneCodes = useMemo(() => {
    if (!isEmpty(countries.data)) {
      return uniqBy(
        [...countries.data].sort(
          ({ phoneCode: a }, { phoneCode: b }) =>
            parseInt(a, 10) - parseInt(b, 10),
        ),
        'phoneCode',
      );
    }

    return [];
  }, [countries.data]);

  useEffect(() => {
    if (isEmpty(countries.data) && !isLoadingCountries) {
      dispatch(getCountries({ config: configuration }));
    }

    if (isEmpty(states.data) && !isLoadingStates) {
      dispatch(
        getStates({
          config: configuration,
          countryCode: DEFAULT_COUNTRY.key,
        }),
      );
    }
  }, []);

  useEffect(() => {
    let hasError = false;

    if (!isEmpty(errors.countries)) {
      showNotification({
        description: errors.countries?.message,
        kind: 'error',
      });
      hasError = true;
    }

    if (!isEmpty(errors.states)) {
      showNotification({
        description: errors.states?.message,
        kind: 'error',
      });
      hasError = true;
    }

    if (hasError) {
      setTimeout(() => {
        dispatch(clearErrors());
      }, NOTIFICATION_TIMEOUT);
    }
  }, [errors.countries, errors.states]);

  return { countries, states, phoneCodes, isLoadingCountries, isLoadingStates };
}
