import { useEffect, useState, useLayoutEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import { Button, ButtonLink } from '@gbm/starman-next';
import { Auth, OpeningLite, Upgrade } from '@gbm/onboarding-sdk';
import { InactivityProvider } from '@gbm/onboarding-sdk-ui-components';

import styles from './app.module.scss';
import '@gbm/onboarding-sdk/index.css';

const appName = 'demo-onboarding-sdk';
const clientId = 'xm30kyn4kuzjzd6inl8qgf5i39w6ssyi';
const environment = 'dev';
type SessionType = {
  email: string;
  accessToken?: string;
  refreshToken?: string;
};

function App() {
  const navigation = useNavigate();
  const [session, setSession] = useState<SessionType>({
    email: '',
    accessToken: '',
    refreshToken: '',
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let email = localStorage.getItem('email');
    let accessToken = localStorage.getItem('accessToken');
    let refreshToken = localStorage.getItem('refreshToken');

    // Let redirect the user to the onboarding black flow in mobile
    // browsers to continue with the identity validation process
    if (
      params.has('email') &&
      params.has('access_token') &&
      params.has('refresh_token')
    ) {
      email = params.get('email');
      accessToken = params.get('access_token');
      refreshToken = params.get('refresh_token');
      window.history.replaceState(
        {},
        '',
        `${window.location.origin}${window.location.pathname}`,
      );
    }

    setSession({
      email: email as string,
      accessToken: accessToken as string,
      refreshToken: refreshToken as string,
    });
  }, []);

  useLayoutEffect(() => {
    if (!session.email || !session.accessToken || !session.refreshToken) {
      navigation(`/auth${window.location.search}`);
      return;
    }

    localStorage.setItem('email', session.email);
    localStorage.setItem('accessToken', session.accessToken);
    localStorage.setItem('refreshToken', session.refreshToken);

    if (window.location.pathname === '/auth') {
      navigation('/dashboard');
    }
  }, [session]);

  const onSignedIn = (params: SessionType) => {
    setSession(params);
    navigation('/onboarding-lite');
  };

  const onSignedUp = (email: string) => {
    setSession({ ...session, email });
  };

  const onSignedOut = () => {
    localStorage.clear();
    setSession({
      email: '',
      accessToken: '',
      refreshToken: '',
    });
  };

  const onCompleted = () => {
    navigation('/dashboard');
  };

  const onSessionRefreshed = (accessToken: string) => {
    setSession({ ...session, accessToken });
    window.location.reload();
  };

  return (
    <Routes>
      <Route
        path="/onboarding-lite"
        element={
          <>
            <InactivityProvider onLogout={onSignedOut} />
            <OpeningLite
              environment={environment}
              clientId={clientId}
              appName={appName}
              accessToken={session.accessToken as string}
              refreshToken={session.refreshToken as string}
              email={session.email}
              onSignedOut={onSignedOut}
              onSessionRefreshed={onSessionRefreshed}
              validations={{
                allowInvalidCurps: true,
                validateTaxIdentifier: true,
              }}
              isEnabledTrackEvents={false}
              isEnabledTradingOpening
              onCompleted={onCompleted}
              prepopulatedInfo={{}}
            />
          </>
        }
      />
      <Route
        path="/onboarding-black"
        element={
          <>
            <InactivityProvider onLogout={onSignedOut} />
            <Upgrade
              isEnabledTrackEvents={false}
              environment={environment}
              clientId={clientId}
              appName={appName}
              accessToken={session.accessToken as string}
              refreshToken={session.refreshToken as string}
              email={session.email}
              onSignedOut={onSignedOut}
              onSessionRefreshed={onSessionRefreshed}
              onExit={() => navigation('/dashboard')}
              isEnabledUpgradeValidation
              images={{}}
            />
          </>
        }
      />
      <Route
        path="/dashboard"
        element={
          <div className={styles.dashboard}>
            <InactivityProvider onLogout={onSignedOut} />
            <div>
              <Button onClick={() => navigation('/onboarding-black')}>
                Upgrade account
              </Button>
              <div className={styles.link}>
                <ButtonLink underline="always" onClick={onSignedOut}>
                  Log out
                </ButtonLink>
              </div>
            </div>
          </div>
        }
      />
      <Route
        path="/auth"
        element={
          <Auth
            environment={environment}
            clientId={clientId}
            appName={appName}
            isEnabledTrackEvents={false}
            onSignedIn={onSignedIn}
            onSignedUp={onSignedUp}
            onSignedOut={onSignedOut}
            urls={{
              privacyPolicy: '',
              jurisdiction: '',
            }}
            images={{}}
          />
        }
      />
      <Route
        path="*"
        element={<Navigate to={`/auth${window.location.search}`} replace />}
      />
    </Routes>
  );
}

export default function Router() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
