import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import { ContractStatus } from '@gbm/onboarding-sdk-ui-components';
import {
  LITE_IDS,
  TRACKING_EVENTS,
  TRACKING_NAMES,
  translations,
} from '@gbm/onboarding-sdk-utils';

import { useOpeningLiteProvider } from '../../../providers/opening-lite';

const {
  openingLite: { contractStatus },
} = translations;

export default function Activating() {
  const { t } = useTranslation();
  const { configuration } = useOpeningLiteProvider();
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  useEffect(() => {
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.verifyingInformation,
    });
  }, []);

  return (
    <div id={LITE_IDS.viewActivating}>
      <ContractStatus
        title={t(contractStatus.contractIsActivating)}
        description={t(contractStatus.activatingDescription)}
      />
    </div>
  );
}
