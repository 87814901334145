import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import clsx from 'clsx';

import { ArrowLeft, ButtonFab, Typography } from '@gbm/starman-next';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import { AppLayout, Loader } from '@gbm/onboarding-sdk-ui-components';
import {
  TRACKING_EVENTS,
  TRACKING_NAMES,
  UPGRADE_IDS,
  translations,
} from '@gbm/onboarding-sdk-utils';

import { useUpgradeProvider } from '../../../providers/upgrade';
import {
  STEPS,
  UPGRADE_STATUSES,
  FAILED_UPGRADE_STATUSES,
  UPGRADE_STEPS,
} from '../../../providers/upgrade/types';
import { OPENING_TYPE } from '../../../providers/opening-lite/types';
import {
  useParty,
  useUpgradeStep,
  useUpgradeStatus,
  useUdisSummary,
  useDocuments,
} from '../../../hooks';
import { REDUCER_STATUS } from '../../../store/types';
import DocumentTampered from './document-tampered';
import ReachedAttempts from './reached-attempts';
import Notifications from './notifications';
import Deposits from './deposits';
import Start from './start';
import Description from './description';
import Status from './status';
import InvalidUpgrade from './invalid-upgrade';
import RejectedDocuments from './rejected-documents';

import styles from './initial-screen.module.scss';

const {
  upgrade: { upgrade },
} = translations;

export default function UpgradeScreen() {
  const { t } = useTranslation();
  const { setStep, configuration } = useUpgradeProvider();
  const {
    party,
    isFulfilled: isFulfilledParty,
    fetch: fetchParty,
  } = useParty(configuration);
  const {
    currentStep,
    getUpgradeStep,
    saveUpgradeStep,
    handleUpgradeStep,
    validateUpgrade,
    validUpgrade,
  } = useUpgradeStep(configuration);
  const {
    upgradeStatus,
    fetch: fetchUpgradeStatus,
    isFulfilled: isFulfilledUpgradeStatus,
  } = useUpgradeStatus(configuration);
  const {
    isFulfilled: isFulfilledUdisSummary,
    fetch: fetchUdisSummary,
    monthlyLimit,
    depositsAmount,
  } = useUdisSummary(configuration);
  const {
    loremRejectionReason,
    rejectedMessage: rejectedDocumentsMessage,
    getStepFromDocuments,
  } = useDocuments(upgradeStatus.data?.documents);
  const isAlreadyStarted =
    upgradeStatus.data?.status !== UPGRADE_STATUSES.notStarted ||
    currentStep !== UPGRADE_STEPS.notSet;
  const [isLoadingUpgrade, setIsLoadingUpgrade] = useState(false);
  const [
    isPending,
    isRejected,
    hasDocumentTampered,
    hasRejectedDocuments,
    hasReachedAttempts,
  ] = useMemo(() => {
    const status = upgradeStatus.data?.status;
    return [
      status === UPGRADE_STATUSES.pending,
      FAILED_UPGRADE_STATUSES.includes(status as string),
      status === UPGRADE_STATUSES.documentTampering,
      status === UPGRADE_STATUSES.rejectedDocuments,
      status === UPGRADE_STATUSES.timeoutForAttempts,
    ];
  }, [upgradeStatus]);
  const informationLoaded =
    isFulfilledUdisSummary && isFulfilledUpgradeStatus && currentStep;
  const [showDocumentTamperedAlert, setShowDocumentTamperedAlert] =
    useState(true);
  const [showReachedAttemptsAlert, setShowReachedAttemptsAlert] =
    useState(true);
  const reachedAttemptsTime =
    configuration.reachedAttemptsTime || t(upgrade.reachedAttemptsTime);
  const showStartSection =
    !hasDocumentTampered && !hasReachedAttempts && !isPending && !isRejected;
  const [showInvalidUpgrade, setShowInvalidUpgrade] = useState(false);
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  useEffect(() => {
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.accountLimit,
    });
    fetchParty();
  }, []);

  useEffect(() => {
    if (isFulfilledParty) {
      if (party.data.opening_type === OPENING_TYPE.black) {
        setStep(STEPS.success);
        return;
      }

      fetchUpgradeStatus();
      fetchUdisSummary();
      getUpgradeStep();
    }
  }, [isFulfilledParty]);

  useEffect(() => {
    if (
      informationLoaded &&
      isAlreadyStarted &&
      configuration.skipInitialScreen
    ) {
      handleContinueUpgrade();
    }
  }, [informationLoaded, isAlreadyStarted, configuration.skipInitialScreen]);

  useEffect(() => {
    if (
      hasRejectedDocuments &&
      [
        UPGRADE_STEPS.ine,
        UPGRADE_STEPS.selfie,
        UPGRADE_STEPS.livenessTest,
        UPGRADE_STEPS.proofOfAddress,
        UPGRADE_STEPS.partyReviewData,
        UPGRADE_STEPS.gbmReviewData,
      ].includes(currentStep)
    ) {
      checkStepFromDocuments();
    }
  }, [hasRejectedDocuments, currentStep]);

  useEffect(() => {
    if (validUpgrade.status !== REDUCER_STATUS.resolved) return;

    setShowInvalidUpgrade(!validUpgrade.data.validForUpgrade);
    if (validUpgrade.data.validForUpgrade) {
      startUpgrade();
    } else {
      setIsLoadingUpgrade(false);
    }
  }, [validUpgrade.status]);

  useEffect(() => {
    if (loremRejectionReason) {
      mixpanel.track(TRACKING_EVENTS.screenViewed, {
        name: TRACKING_NAMES.accountLimit,
        status: 'document_rejected',
        rejection_reason: loremRejectionReason,
      });
    }
  }, [loremRejectionReason]);

  const startUpgrade = () =>
    isAlreadyStarted ? handleContinueUpgrade() : handleStartUpgrade();

  const handleSubmit = () => {
    setIsLoadingUpgrade(true);
    if (configuration.isEnabledUpgradeValidation) {
      validateUpgrade();
    } else {
      startUpgrade();
    }
  };

  const checkStepFromDocuments = async () => {
    setIsLoadingUpgrade(true);
    const nextStep = getStepFromDocuments(currentStep);

    if (nextStep !== currentStep) {
      await saveUpgradeStep(nextStep);
    }

    setIsLoadingUpgrade(false);
  };

  const handleStartUpgrade = async () => {
    setIsLoadingUpgrade(true);
    await saveUpgradeStep(UPGRADE_STEPS.nationality);
    setStep(STEPS.nationality);
  };

  const handleContinueUpgrade = () => {
    setIsLoadingUpgrade(true);
    handleUpgradeStep(setStep);
  };

  const handleGoBack = () => {
    if (configuration.onGoBack) {
      configuration.onGoBack();
    } else {
      window.history.go(-1);
    }
  };

  if (!informationLoaded) {
    return <Loader kind="light" />;
  }

  if (loremRejectionReason) {
    return <RejectedDocuments onClose={handleGoBack} />;
  }

  if (hasDocumentTampered && showDocumentTamperedAlert) {
    return (
      <DocumentTampered
        onClose={
          /* istanbul ignore next */ () => setShowDocumentTamperedAlert(false)
        }
      />
    );
  }

  if (hasReachedAttempts && showReachedAttemptsAlert) {
    return (
      <ReachedAttempts
        time={reachedAttemptsTime as string}
        onClose={
          /* istanbul ignore next */ () => setShowReachedAttemptsAlert(false)
        }
      />
    );
  }

  if (showInvalidUpgrade) {
    return <InvalidUpgrade onClose={() => setShowInvalidUpgrade(false)} />;
  }

  return (
    <AppLayout>
      <motion.div
        id={UPGRADE_IDS.viewUpgrade}
        className={styles.container}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <div className={styles.close}>
          <div className={styles['close-button']}>
            <ButtonFab kind="secondary" onClick={handleGoBack}>
              <ArrowLeft size={styles['icon-size']} />
            </ButtonFab>
          </div>
          <Typography variant="small">{t(upgrade.title)}</Typography>
        </div>
        <Notifications
          isPending={isPending}
          isRejected={isRejected}
          rejectedDocumentsMessage={rejectedDocumentsMessage?.text}
          hasDocumentTampered={hasDocumentTampered}
          hasReachedAttempts={hasReachedAttempts}
          reachedAttemptsTime={reachedAttemptsTime}
        />
        <div
          className={clsx(styles.content, {
            [styles['content--status']]: !showStartSection,
          })}
        >
          <Deposits
            amount={depositsAmount}
            accountLimit={Number(monthlyLimit)}
            depositsAmount={Number(depositsAmount)}
            fullRow={!showStartSection}
          />
          {showStartSection ? (
            <>
              <Start
                alreadyStarted={isAlreadyStarted}
                loading={isLoadingUpgrade}
                onSubmit={handleSubmit}
              />
              <Description />
            </>
          ) : (
            <Status
              accountLimit={Number(monthlyLimit)}
              isPending={isPending}
              isRejected={isRejected}
              hasDocumentTampered={hasDocumentTampered}
              hasReachedAttempts={hasReachedAttempts}
            />
          )}
        </div>
      </motion.div>
    </AppLayout>
  );
}
