import { useMemo } from 'react';
import { Mixpanel, Environment } from '@gbm/onboarding-sdk-utils';

export default function useMixpanel(
  environment: Environment,
  isEnabledTrackingEvents: boolean = true,
) {
  return useMemo(
    () => new Mixpanel(environment, isEnabledTrackingEvents),
    [environment, isEnabledTrackingEvents],
  );
}
