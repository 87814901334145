import sortBy from 'lodash/sortBy';

export const financialInfoQuestionsAdapter = (
  response: Record<string, any>,
) => ({
  ...response,
  data: {
    ...response.data,
    id: response.data.id?.toUpperCase(),
    version: response.data.version,
    questionnaireId: response.data.questionnaire_id?.toUpperCase(),
    data: response.data.data?.map((question: Record<string, any>) => ({
      ...question,
      relatedAnswerId: question.related_answer_id?.toUpperCase(),
      answerType: question.answer_type,
      answer: sortBy(
        question.answer?.map((answerProps: Record<string, any>) => ({
          ...answerProps,
          id: answerProps.id?.toUpperCase(),
        })),
        [({ text }) => (/^\d+$/.test(text) ? parseInt(text, 10) : text)],
      ),
      dataType: question.data_type,
      maxLength: question.max_length,
    })),
  },
});

export const financialInfoAnswersAdapter = (response: Record<string, any>) => ({
  ...response,
  data: {
    ...response.data?.[0]?.questionnaire,
    questionnaireType: response.data?.[0]?.questionnaire.questionnaire_type,
    questionnaireAnswers:
      response.data?.[0]?.questionnaire.questionnaire_answers.map(
        (answer: Record<string, any>) => ({
          ...answer,
          questionId: answer.question_id?.toUpperCase(),
          answerId: answer.answer_id?.toUpperCase(),
          answerResponse: answer.answer_response,
        }),
      ),
  },
});
