import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import {
  ArrowCircleRight,
  Button,
  ButtonLink,
  InputCode,
  Typography,
} from '@gbm/starman-next';

import {
  MFA_PREFERENCE,
  MfaPreferenceType,
  i18n,
  translations,
} from '@gbm/onboarding-sdk-utils';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalProps,
} from '../modal';
import { FIELD_NAME, validations } from './types';
import styles from './mfa-challenge.module.scss';

export type MfaChallengeProps = Omit<ModalProps, 'children'> & {
  onClose?: () => void;
  onResend?: () => void;
  onSubmit: (mfaCode: string) => void;
  loading?: boolean;
  mfaPreference?: MfaPreferenceType;
  email: string;
};

export type FormValues = {
  mfaCode: string;
};

const {
  buttons,
  links,
  base: { mfaChallenge },
} = translations;

export default function MfaChallenge({
  onClose,
  onResend,
  onSubmit,
  loading,
  email,
  mfaPreference = MFA_PREFERENCE.disabled,
  ...modalProps
}: MfaChallengeProps) {
  const { t } = useTranslation(undefined, { i18n });
  const {
    register,
    handleSubmit,
    formState: { isValid, errors: formErrors },
  } = useForm<FormValues>({ mode: 'onChange' });
  const isMfaDisabled = mfaPreference === MFA_PREFERENCE.disabled;

  const handleSubmitCallback = (data: FormValues) => {
    onSubmit(data.mfaCode);
  };

  return (
    <Modal {...modalProps}>
      <ModalHeader title={t(mfaChallenge.title)} onClose={onClose} />
      <ModalContent>
        <div className={styles.title}>
          <Typography variant="h5">
            {t(isMfaDisabled ? mfaChallenge.emailTitle : mfaChallenge.mfaTitle)}
          </Typography>
        </div>
        <div className={styles.description}>
          <Typography variant="small">
            {isMfaDisabled ? (
              <Trans
                i18n={i18n}
                i18nKey={mfaChallenge.emailSubtitle}
                components={[
                  <span className={styles['description-text']}></span>,
                ]}
                values={{ email }}
              />
            ) : (
              t(mfaChallenge.mfaSubtitle)
            )}
          </Typography>
        </div>
        <div className={styles.input}>
          <InputCode
            autoFocus
            id={FIELD_NAME}
            label=""
            widthSize="fluid"
            status={formErrors?.mfaCode ? 'error' : undefined}
            hintText={
              formErrors?.mfaCode
                ? t(formErrors.mfaCode.message as string)
                : undefined
            }
            {...register(FIELD_NAME, validations)}
          />
          {isMfaDisabled && onResend ? (
            <div className={styles.link}>
              <Typography variant="xsmall">
                {t(mfaChallenge.didNotReceiveCode)}
              </Typography>
              &nbsp;
              <ButtonLink underline="always" onClick={onResend}>
                <Typography variant="xsmall" weight="semibold">
                  {t(links.resend)}
                </Typography>
              </ButtonLink>
            </div>
          ) : null}
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          testId="mfaSubmit"
          disabled={!isValid}
          loading={loading}
          kind="primary"
          onClick={handleSubmit(handleSubmitCallback)}
          size="fluid"
          icon={<ArrowCircleRight size={styles['icon-size']} weight="fill" />}
        >
          {t(isMfaDisabled ? buttons.continue : buttons.validateCode)}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
