export const TAX_INFO_FIELDS = {
  usaResident: 'usaResident',
  countryResident: 'countryResident',
  rfc: 'rfc',
  homoclave: 'homoclave',
  hasNoRfc: 'hasNoRfc',
  rfcVerified: 'rfcVerified',
  hasFiel: 'hasFiel',
  fielNumber: 'fielNumber',
  clabeBank: 'clabeBank',
  bank: 'bank',
  tinInput: 'tinInput',
} as const;

export type FormValues = {
  [TAX_INFO_FIELDS.usaResident]: boolean;
  [TAX_INFO_FIELDS.rfc]: string;
  [TAX_INFO_FIELDS.homoclave]: string;
  [TAX_INFO_FIELDS.hasNoRfc]: boolean;
  [TAX_INFO_FIELDS.rfcVerified]: boolean;
  [TAX_INFO_FIELDS.hasFiel]: string;
  [TAX_INFO_FIELDS.fielNumber]: string;
  [TAX_INFO_FIELDS.clabeBank]: string;
  [TAX_INFO_FIELDS.bank]: string;
  [TAX_INFO_FIELDS.tinInput]: string;
};

export const CLABE_LENGTH = 18;

export const TIN_LENGTH = 9;

export const DEFAULT_COUNTRY_CODE = 'MEX';
export const DEFAULT_NATIONALITY_LABEL = 'Mexicana';

export const COUNTRY_TAXATION_TYPE = 'Taxation';

export const INITIAL_TAX_COUNTRIES = [
  {
    value: DEFAULT_COUNTRY_CODE,
    is_main_country: false,
    country_code: DEFAULT_COUNTRY_CODE,
    country_type: COUNTRY_TAXATION_TYPE,
    key: 'taxation-0',
  },
];

export const BANK_ACCOUNT_OPENING_TYPES = {
  clabe: 'CLABE',
};

export const BANK_ACCOUNT_OPENING_TYPES_LEN = {
  clabe: 18,
  debit: 16,
};

export const HAS_FIEL_OPTIONS = {
  yesOption: 'yes',
  noOption: 'no',
};
