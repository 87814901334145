import { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import {
  Environment,
  ENVIRONMENTS,
  Values,
  TranslationsType,
  PlatformType,
} from '@gbm/onboarding-sdk-utils';

export const STEPS = {
  unknown: 'unknown',
  upgradeScreen: 'upgradeScreen',
  nationality: 'nationality',
  taxInformation: 'taxInformation',
  financialInformation: 'financialInformation',
  ine: 'ine',
  proofOfAddress: 'proofOfAddress',
  confirmation: 'confirmation',
  pending: 'pending',
  reviewing: 'reviewing',
  success: 'success',
} as const;
export type StepType = Values<typeof STEPS>;

export const UPGRADE_STEPS = {
  economicActivity: 'economic_activity',
  endedProcess: 'ended_process',
  gbmReviewData: 'gbm_review_data',
  gbmReviewDocs: 'gbm_review_docs',
  ine: 'ine',
  livenessTest: 'liveness_test',
  nationality: 'nationality',
  partyReviewData: 'party_review_data',
  proofOfAddress: 'proof_of_address',
  selfie: 'selfie',
  taxInformation: 'tax_information',
  notSet: 'not_set',
};
export type UpgradeStepType = Values<typeof UPGRADE_STEPS>;

export const UPGRADE_STATUSES = {
  notStarted: 'not_started',
  pending: 'pending',
  upgraded: 'upgraded',
  pendingManualValidation: 'pending_manual_validation',
  failed: 'failed',
  documentTampering: 'document_tampering',
  rejectedDocuments: 'rejected_documents',
  timeoutForAttempts: 'timeout_for_attempts',
};
export type UpgradeStatusesType = Values<typeof UPGRADE_STATUSES>;

export const FAILED_UPGRADE_STATUSES = [
  UPGRADE_STATUSES.pendingManualValidation,
  UPGRADE_STATUSES.failed,
];

export const DEPOSITS = [
  { label: 'GBM', amount: 1600 },
  { label: 'Mercado Pago', amount: 2000 },
];

export type UpgradeImages = {
  darkLogo?: string;
};

export type UpgradeProviderProps = PropsWithChildren<{
  environment: Environment;
  email: string;
  clientId: string;
  accessToken: string;
  refreshToken: string;
  appName: string;
  onRefreshSession: () => void;
  onSessionRefreshed: (accessToken: string) => void;
  onSignedOut: () => void;
  onExit?: () => void;
  onCompleted?: (data?: any) => void;
  isEnabledBankAccountSection?: boolean;
  isEnabledTrackEvents?: boolean;
  reachedAttemptsTime?: string;
  isEnabledUpgradeValidation?: boolean;
  translations?: TranslationsType;
  urls?: {
    privacyPolicy?: string;
  };
  images?: UpgradeImages;
  onStartIdentityValidation?: () => void;
  onGoBack?: () => void;
  skipInitialScreen?: boolean;
  platform?: PlatformType;
}>;

export type UpgradeProps = Omit<
  UpgradeProviderProps,
  'onRefreshSession' | 'children'
>;

export type UpgradeProviderType = {
  step: StepType;
  setStep: Dispatch<SetStateAction<StepType>>;
  isLoading: boolean;
  configuration: UpgradeProviderProps;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  isEditing: boolean;
  handleExit: () => void;
  handleSignOut: () => void;
};

export const DOCUMENTS_STATUS = {
  failed: 'failed',
  pendingValidation: 'pending_validation',
  rejected: 'rejected',
  validated: 'validated',
  verifying: 'verifying',
};

export const DOCUMENT_TYPES = {
  proofOfAddress: 'proof_of_address',
  livenessTest: 'liveness_test',
  officialIdentificationBack: 'official_identification_back',
  officialIdentificationFront: 'official_identification_front',
  selfie: 'selfie',
} as const;
export type DocumentTypesType = Values<typeof DOCUMENT_TYPES>;
export type DocumentLoremRequestType = {
  document_type: DocumentTypesType;
  file_content: string;
};

export const MAX_GET_STEP_ATTEMPTS = 3;

export const POOL_UPGRADE_STATUS_TIME = 30000; // 30 seconds
export const POOL_UPGRADE_STEP_TIME = 60000; // 60 seconds

export const ERROR_CODES = {
  addressNotFound: 'ProofOfAddressAddressNotFound',
  invalid: 'ProofOfAddressInvalid',
  noMatchName: 'ProofOfAddressNoMatchName',
  cityInvalid: 'ProofOfAddressCityInvalid',
  stateInvalid: 'ProofOfAddressStateInvalid',
  townshipInvalid: 'ProofOfAddressTownshipInvalid',
  zipCodeMismatch: 'ProofOfAddressZipCodeMismatch',
  expired: 'ProofOfAddressExpired',
};

export const LOREM_VALIDATION_ERROR_CODES = {
  ine_not_current: 'ine_not_current',
  ine_reported_lost: 'ine_reported_lost',
  ine_reported_stolen: 'ine_reported_stolen',
  liveness_failed: 'liveness_failed',
  missing_document_id: 'missing_document_id',
  missing_selfie: 'missing_selfie',
  module_not_supported: 'module_not_supported',
  not_enough_data: 'not_enough_data',
  user_not_found: 'user_not_found',
  user_not_found_in_ine_db: 'user_not_found_in_ine_db',
  validation_error: 'validation_error',
} as const;
export type LoremValidationErrorCodeType = Values<
  typeof LOREM_VALIDATION_ERROR_CODES
>;

export const mockUseUpgradeProvider: UpgradeProviderType = {
  step: STEPS.upgradeScreen,
  setStep: () => null,
  isLoading: false,
  configuration: {
    environment: ENVIRONMENTS.development,
    email: 'fake@email.com',
    clientId: '',
    accessToken: '',
    refreshToken: '',
    appName: '',
    onRefreshSession: () => null,
    onSessionRefreshed: () => null,
    onSignedOut: () => null,
  },
  setIsEditing: () => null,
  isEditing: false,
  handleExit: () => null,
  handleSignOut: () => null,
};
