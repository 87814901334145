/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useContext, useState } from 'react';
import { Notification, NotificationProps } from '@gbm/starman-next';
import { AnimatePresence, motion } from 'framer-motion';

import { useBreakpoints } from '@gbm/onboarding-sdk-hooks';
import {
  NotificationContextType,
  NotificationProviderProps,
  NOTIFICATION_TIMEOUT,
  DEFAULT_NOTIFICATION_PROPS,
} from './types';
import styles from './notification-provider.module.scss';

/* istanbul ignore next */
const NotificationContext = createContext<NotificationContextType>({
  showNotification: () => null,
  hideNotification: () => null,
});
export const useNotificationProvider = () => useContext(NotificationContext);

export default function NotificationProvider({
  children,
}: NotificationProviderProps) {
  const { isSm } = useBreakpoints();
  const [notificationIsVisible, setNotificationIsVisible] = useState(false);
  const [notificationProps, setNotificationProps] = useState<NotificationProps>(
    DEFAULT_NOTIFICATION_PROPS,
  );

  const showNotification = (props: Partial<NotificationProps>) => {
    const onClose =
      props.onClose || hideNotification || notificationProps.onClose;

    setNotificationProps({
      ...notificationProps,
      ...props,
      onClose,
    });
    setNotificationIsVisible(true);
    setTimeout(hideNotification, NOTIFICATION_TIMEOUT);
  };

  const hideNotification = () => {
    setNotificationIsVisible(false);
    setNotificationProps(DEFAULT_NOTIFICATION_PROPS);
  };

  return (
    <NotificationContext.Provider
      value={{
        showNotification,
        hideNotification,
      }}
    >
      <AnimatePresence>
        {notificationIsVisible ? (
          <motion.div
            className={styles.container}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className={styles.notification}>
              <Notification
                widthSize={isSm ? 'small' : 'fluid'}
                {...notificationProps}
              />
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
      {children}
    </NotificationContext.Provider>
  );
}
