import { useEffect } from 'react';
import { Checkbox, Input, SkeletonLoader, Typography } from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import {
  Environment,
  HOMOCLAVE_LENGTH,
  OpeningType,
  RFC_LENGTH,
  TRACKING_EVENTS,
  TRACKING_NAMES,
  i18n,
  translations,
} from '@gbm/onboarding-sdk-utils';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import { FormRow } from '@gbm/onboarding-sdk-ui-components';
import formValidations from './validations';
import styles from './input-rfc.module.scss';

export type InputRfcProps = {
  id: string;
  homoclaveId: string;
  hasNoRfcId: string;
  environment: Environment;
  appName: string;
  openingType: OpeningType;
  isEnabledTrackEvents?: boolean;
  loading?: boolean;
  onHasNoRfcClick?: () => void;
};

const { inputs } = translations;

export default function InputRfc({
  id,
  homoclaveId,
  hasNoRfcId,
  environment,
  isEnabledTrackEvents = false,
  loading,
  onHasNoRfcClick,
}: InputRfcProps) {
  const { t } = useTranslation(undefined, { i18n });
  const {
    register,
    resetField,
    clearErrors: clearFormErrors,
    formState: { errors: formErrors },
    watch,
  } = useFormContext();
  const [value, homoclaveValue, hasNoRfc] = watch([
    id,
    homoclaveId,
    hasNoRfcId,
  ]);
  const mixpanel = useMixpanel(environment, isEnabledTrackEvents);

  useEffect(() => {
    if (value?.length === RFC_LENGTH) {
      mixpanel.track(TRACKING_EVENTS.fieldFilled, {
        name: TRACKING_NAMES.taxId,
      });
    }
  }, [value]);

  useEffect(() => {
    if (homoclaveValue?.length === HOMOCLAVE_LENGTH) {
      mixpanel.track(TRACKING_EVENTS.fieldFilled, {
        name: TRACKING_NAMES.taxKey,
      });
    }
  }, [homoclaveValue]);

  useEffect(() => {
    clearFormErrors([id, homoclaveId]);

    if (hasNoRfc) {
      mixpanel.track(TRACKING_EVENTS.fieldEnabled, {
        name: TRACKING_NAMES.notTaxId,
      });
      resetField(id, { defaultValue: '' });
      resetField(homoclaveId, { defaultValue: '' });
    }

    onHasNoRfcClick?.();
  }, [hasNoRfc]);

  return (
    <>
      <FormRow className={styles.container}>
        {loading ? (
          <SkeletonLoader kind="large" reverse />
        ) : (
          <Input
            widthSize="fluid"
            id={id}
            label={t(inputs.rfc)}
            status={formErrors?.rfc ? 'error' : undefined}
            hintText={
              /* istanbul ignore next */ formErrors?.rfc
                ? t(formErrors.rfc.message as string)
                : undefined
            }
            counterEnabled
            maxLength={RFC_LENGTH}
            value={value}
            disabled={hasNoRfc}
            {...register(id, {
              ...formValidations.rfcValidations,
              required: !hasNoRfc ? inputs.rfcRequired : false,
            })}
          />
        )}
        {loading ? (
          <SkeletonLoader kind="large" reverse />
        ) : (
          <Input
            widthSize="small"
            id={homoclaveId}
            label={t(inputs.homoclave)}
            counterEnabled
            maxLength={HOMOCLAVE_LENGTH}
            status={formErrors?.homoclave ? 'error' : undefined}
            disabled={hasNoRfc}
            value={homoclaveValue}
            hintText={
              /* istanbul ignore next */ formErrors?.homoclave
                ? t(formErrors.homoclave.message as string)
                : undefined
            }
            {...register(homoclaveId, {
              ...formValidations.homoclaveValidations,
              required: !hasNoRfc ? inputs.homoclaveRequired : false,
            })}
          />
        )}
      </FormRow>
      <FormRow className={!loading ? styles['has-no-rfc-checkbox'] : undefined}>
        {loading ? (
          <div className={styles['checkbox-loader']}>
            <SkeletonLoader kind="large" reverse />
          </div>
        ) : (
          <Checkbox {...register(hasNoRfcId)}>
            <Typography variant="small">{t(inputs.hasNoRfc)}</Typography>
          </Checkbox>
        )}
      </FormRow>
    </>
  );
}
