import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { convertLanguageJsonToObject } from './translations/helpers';
import {
  ConvertedToObjectType,
  TranslationJsonTypeValue,
} from './translations/types';
import baseEs from './translations/es/base.json';
import authEs from './translations/es/auth.json';
import buttonsEs from './translations/es/buttons.json';
import inputsEs from './translations/es/inputs.json';
import linksEs from './translations/es/links.json';
import openingLiteEs from './translations/es/opening-lite.json';
import upgradeEs from './translations/es/upgrade.json';

export const translationsJson = {
  es: {
    base: baseEs,
    auth: authEs,
    buttons: buttonsEs,
    inputs: inputsEs,
    links: linksEs,
    openingLite: openingLiteEs,
    upgrade: upgradeEs,
  },
};

convertLanguageJsonToObject(translationsJson.es);

export const i18nInit = i18n.use(initReactI18next).use(LanguageDetector).init({
  lng: 'es',
  fallbackLng: 'es',
  resources: translationsJson,
});

export const translateJsonToObject = (
  ns: string,
  json: ConvertedToObjectType<TranslationJsonTypeValue>,
) => {
  const translatedJson: Record<string, unknown> = {};

  Object.keys(json).forEach((key) => {
    translatedJson[key] = i18n.t(key, { ns, returnObjects: true });
  });

  return translatedJson;
};

export { i18n };
