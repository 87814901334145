import platform from 'platform';
import { v4 as uuidv4 } from 'uuid';

import {
  config,
  ApiType,
  Environment,
  PLATFORM_CAPTCHA,
} from '@gbm/onboarding-sdk-utils';
import request from '../request';
import { HTTP_VERBS } from '../types';
import { defaultSuccessAdapter, completeErrorAdapter } from '../adapters';
import {
  ChallengeInfoType,
  CODE_CHALLENGE_METHOD,
  SIGNIN_RESPONSE_TYPE,
} from '../../app/providers/auth/types';
import { ApiOptions } from '../models';
import { GeolocationType } from '../opening-lite/types';

export default class Auth {
  private apiUrl: ApiType;

  private onboardingApiUrl: ApiType;

  private authRequest(
    url: string,
    options: Partial<ApiOptions>,
    accessToken: string = '',
  ) {
    return request(url, undefined, accessToken, options);
  }

  constructor(environment: Environment) {
    const {
      auth: { apiUrl },
      onboarding: { apiUrl: onboardingApiUrl },
    } = config(environment);
    this.apiUrl = apiUrl;
    this.onboardingApiUrl = onboardingApiUrl;
  }

  async signIn(
    clientId: string,
    email: string,
    password: string,
    challenge: string,
    geolocation: GeolocationType,
  ) {
    const url = `${this.apiUrl.v1}/session/user`;
    const body = {
      clientId,
      user: email,
      password,
      responseType: SIGNIN_RESPONSE_TYPE,
      codeChallenge: challenge,
      codeChallengeMethod: CODE_CHALLENGE_METHOD,
    };
    return this.authRequest(url, {
      body,
      method: HTTP_VERBS.post,
      headers: {
        browser: platform.name,
        device: platform.os,
        'device-latitude': geolocation.latitude,
        'device-longitude': geolocation.longitude,
        'device-mac-address': uuidv4(),
      },
    });
  }

  async signInMfa(
    clientId: string,
    email: string,
    challenge: string,
    mfaCode: string,
    challengeInfo: ChallengeInfoType,
    geolocation: GeolocationType,
  ) {
    const url = `${this.apiUrl.v1}/session/user/challenge`;
    const body = {
      ...challengeInfo,
      clientId,
      user: email,
      code: mfaCode,
      responseType: SIGNIN_RESPONSE_TYPE,
      codeChallenge: challenge,
      codeChallengeMethod: CODE_CHALLENGE_METHOD,
    };
    return this.authRequest(url, {
      body,
      method: HTTP_VERBS.post,
      headers: {
        browser: platform.name,
        device: platform.os,
        'device-latitude': geolocation.latitude,
        'device-longitude': geolocation.longitude,
        'device-mac-address': uuidv4(),
      },
    });
  }

  async sessionToken(clientId: string, code: string, codeVerifier: string) {
    const url = `${this.apiUrl.v1}/session/token`;
    const body = {
      clientId,
      codeVerifier,
      code,
    };
    return this.authRequest(url, {
      body,
      method: HTTP_VERBS.post,
    });
  }

  async postSession(idToken: string, accessToken: string) {
    const url = `${this.onboardingApiUrl.v1}/session`;
    const body = {
      identity_token: idToken,
    };
    return this.authRequest(
      url,
      {
        body,
        method: HTTP_VERBS.post,
      },
      accessToken,
    );
  }

  async signUp(
    clientId: string,
    email: string,
    password: string,
    recaptchaValue: string,
  ) {
    const url = `${this.apiUrl.v2}/signup`;
    const body = {
      clientId,
      password,
      platform: PLATFORM_CAPTCHA.alpha,
      user: email,
      userReCaptchaResponse: recaptchaValue,
    };
    return this.authRequest(url, {
      body,
      method: HTTP_VERBS.post,
    });
  }

  async signUpConfirmation(clientId: string, email: string, code: string) {
    const url = `${this.apiUrl.v1}/signup/confirm`;
    const body = {
      clientId,
      code,
      user: email,
    };
    return this.authRequest(url, {
      body,
      method: HTTP_VERBS.post,
    });
  }

  async resendSignUpCode(
    clientId: string,
    email: string,
    recaptchaValue: string,
  ) {
    const url = `${this.apiUrl.v2}/signup/new-code`;
    const body = {
      clientId,
      user: email,
      userReCaptchaResponse: recaptchaValue,
      platform: PLATFORM_CAPTCHA.alpha,
    };
    return this.authRequest(url, {
      body,
      method: HTTP_VERBS.post,
    });
  }

  async getChallenge(accessToken: string) {
    const url = `${this.apiUrl.v1}/challenge`;
    return request(
      url,
      undefined,
      accessToken,
      {},
      defaultSuccessAdapter,
      completeErrorAdapter,
    );
  }

  async validateChallenge(accessToken: string, mfaCode: string) {
    const url = `${this.apiUrl.v1}/challenge`;
    return request(
      url,
      undefined,
      accessToken,
      {
        method: HTTP_VERBS.post,
        body: { code: mfaCode },
      },
      defaultSuccessAdapter,
      completeErrorAdapter,
    );
  }

  async passwordRecovery(clientId: string, email: string) {
    const url = `${this.apiUrl.v1}/password-recovery`;
    const body = { clientId, user: email };
    return this.authRequest(url, {
      body,
      method: HTTP_VERBS.post,
    });
  }

  async refreshSession(clientId: string, refreshToken: string) {
    const url = `${this.apiUrl.v1}/session/user/refresh`;
    const body = { clientId, refreshToken };
    return this.authRequest(url, {
      body,
      method: HTTP_VERBS.post,
    });
  }
}
