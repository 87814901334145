import { Values } from '../types';

export const AUTH_IDS = {
  signup: 'btnSignup',
  signin: 'btnSigin',
  signinByMfa: 'btnSigninByMfa',
  viewConfirmation: 'viewConfirmation',
  viewSignin: 'viewSignin',
  viewSignup: 'viewSignup',
} as const;

export const LITE_IDS = {
  welcome: 'btnWelcome',
  curpInfo: 'btnCurpInfo',
  personalInfo: 'btnPersonalInfo',
  addressInfo: 'btnAddressInfo',
  confirmation: 'btnConfirmation',
  viewWelcome: 'viewWelcome',
  viewCurpInfo: 'viewCurpInfo',
  viewPersonalInfo: 'viewPersonalInfo',
  viewAddressInfo: 'viewAddressInfo',
  viewConfirmation: 'viewConfirmation',
  viewPending: 'viewPending',
  viewSign: 'viewSign',
  viewActivating: 'viewActivating',
  viewPld: 'viewPld',
  viewAlreadyRegistered: 'viewAlreadyRegistered',
  linkCurpInfo: 'linkCurpInfo',
} as const;

export const UPGRADE_IDS = {
  viewUpgrade: 'viewUpgrade',
  viewNationality: 'viewNationality',
  viewTaxInfo: 'viewTaxInfo',
  viewFinancialInfo: 'viewFinancialInfo',
  viewIdentity: 'viewIdentity',
  viewProofOfAddress: 'viewProofOfAddress',
  viewConfirmation: 'viewConfirmation',
  viewPending: 'viewPending',
  viewReviewing: 'viewReviewing',
  viewSuccess: 'viewSuccess',
  btnNationality: 'btnNationality',
  btnAddCitizenship: 'btnAddCitizenship',
  btnTaxInfo: 'btnTaxInfo',
  btnFinancialInfo: 'btnFinancialInfo',
  btnProofOfAddress: 'btnProofOfAddress',
  btnConfirmation: 'btnConfirmation',
  btnReviewing: 'btnReviewing',
  btnSuccess: 'btnSuccess',
} as const;

export type AuthIdsType = Values<typeof AUTH_IDS>;
export type LiteIdsType = Values<typeof LITE_IDS>;
export type UpgradeIdsType = Values<typeof UPGRADE_IDS>;
