import { Dispatch, PropsWithChildren, SetStateAction } from 'react';

import {
  AuthUserType,
  Environment,
  Values,
  TranslationsType,
} from '@gbm/onboarding-sdk-utils';
import { GeolocationType } from '../../../api/opening-lite/types';

export const STEPS = {
  signIn: 'signIn',
  signedIn: 'signedIn',
  signUp: 'signUp',
  confirmation: 'confirmation',
  passwordRecovery: 'passwordRecovery',
  passwordRecoverySent: 'passwordRecoverySent',
} as const;
export type StepType = Values<typeof STEPS>;

export const AUTH_EVENTS = {
  signUp: 'signup',
  signupConfirm: 'signup_confirm',
  login: 'login',
  forgetPassword: 'forget_password',
  screenLogin: 'screen_login',
  screenSignUp: 'screen_sign_up',
  fillPassword: 'fill_password',
  fillEmail: 'fill_email',
  signUpClick: 'sign_up_click',
  notReceiveCodeClick: 'not_receive_code_click',
} as const;

export type AuthContextValueType = {
  email: string;
  step: StepType;
  setStep: Dispatch<SetStateAction<StepType>>;
  handleSignIn: (
    email: string,
    password: string,
    geolocation: GeolocationType,
  ) => void;
  handleSignUp: (
    email: string,
    password: string,
    recaptchaValue: string,
  ) => void;
  handleSignOut: () => void;
  handlePasswordRecovery: (email: string) => void;
  handleResendCode: (recaptchaValue: string) => void;
  configuration: AuthProviderProps;
  isLoading: boolean;
};

export type AuthImages = {
  lightLogo?: string;
  darkLogo?: string;
  backgroundImages?: Array<string>;
};

export type AuthProviderProps = PropsWithChildren<{
  environment: Environment;
  clientId: string;
  appName: string;
  translations?: TranslationsType;
  urls?: {
    privacyPolicy?: string;
    jurisdiction?: string;
  };
  fetchContractInfo?: boolean;
  isEnabledTrackEvents?: boolean;
  images?: AuthImages;
  onSignedUp?: (email: string) => void;
  onSignedIn?: (data: AuthUserType) => void;
  onSignedOut?: () => void;
}>;

export type AuthProps = Omit<AuthProviderProps, 'children'>;

export const ALLOWED_CLIENT_IDS_TO_FETCH_CONTRACTS: Array<string> = [
  'xm30kyn4kuzjzd6inl8qgf5i39w6ssyi', // GBM+ Demo SDK
  'd8fb00c17bb94f19953756af1ccf4e69', // GBM+ STG
  '7c464570619a417080b300076e163289', // GBM+ PRD
  'ru6qef3dl7eao97qr7rtw0owfi5inwox', // Tauros
  '9poxadlugtf9wnhv3yit6mydtph29kgt', // Pilou STG
];

export type PkceType = {
  verifier: string;
  challenge: string;
};

export const SIGNIN_RESPONSE_TYPE = 'code';

export const CODE_CHALLENGE_METHOD = 'SHA256';

export const PKCE_LENGTH = 43;

export const ACTION_PARAMS = {
  confirmation: 'confirmation',
};

export const CHALLENGE_TYPES = {
  softwareTokenMfa: 'SOFTWARE_TOKEN_MFA',
} as const;
export type ChallengeTypesType = Values<typeof CHALLENGE_TYPES>;

export type ChallengeInfoType = {
  challengeType: ChallengeTypesType;
  destination: string;
  session: string;
  user: string;
};

export type MfaCodeModalDataType = {
  challengeInfo: ChallengeInfoType;
  email: string;
  password: string;
  geolocation: GeolocationType;
};
