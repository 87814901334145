import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

import {
  Loader,
  NotificationProvider,
} from '@gbm/onboarding-sdk-ui-components';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import { i18n } from '@gbm/onboarding-sdk-utils';
import { store } from '../../store';
import {
  OPENING_TYPE,
  OpeningLiteProps,
  STEPS,
} from '../../providers/opening-lite/types';
import { useLanguage } from '../../hooks';
import { Welcome } from './welcome';
import { CurpInfo } from './curp-info';
import { PersonalInfo } from './personal-info';
import { AddressInfo } from './address-info';
import { Resume } from './resume';
import {
  Activating,
  AlreadyRegistered,
  Pending,
  Pld,
  Sign,
} from './contract-creation';
import {
  OpeningLiteProvider,
  useOpeningLiteProvider,
} from '../../providers/opening-lite';

function OpeningLite() {
  const { step, isLoading } = useOpeningLiteProvider();

  if (isLoading || step === STEPS.unknown) {
    return <Loader kind="light" />;
  }

  return (
    <>
      {step === STEPS.welcome && <Welcome />}
      {step === STEPS.curpInfo && <CurpInfo />}
      {step === STEPS.personalInfo && <PersonalInfo />}
      {step === STEPS.addressInfo && <AddressInfo />}
      {step === STEPS.confirmation && <Resume />}
      {step === STEPS.pending && <Pending />}
      {step === STEPS.sign && <Sign />}
      {step === STEPS.activating && <Activating />}
      {step === STEPS.pld && <Pld />}
      {step === STEPS.alreadyRegistered && <AlreadyRegistered />}
    </>
  );
}

export default function WithProviders(props: OpeningLiteProps): JSX.Element {
  const { isLoading } = useLanguage(props.translations);
  const [trackingIsReady, setTrackingIsReady] = useState(false);
  const mixpanel = useMixpanel(props.environment, props.isEnabledTrackEvents);

  useEffect(() => {
    const initTracking = async () => {
      await mixpanel.init();
      mixpanel.identify(props.email);
      mixpanel.peopleSet({
        $email: props.email,
        $opening_type: OPENING_TYPE.lite,
      });
      if (props.platform) {
        mixpanel.registerPlatform(props.platform);
      }
      setTrackingIsReady(true);
    };

    initTracking();
  }, []);

  if (isLoading || !trackingIsReady) {
    return <Loader kind="light" />;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <NotificationProvider>
        <Provider store={store}>
          <OpeningLiteProvider {...props}>
            <OpeningLite />
          </OpeningLiteProvider>
        </Provider>
      </NotificationProvider>
    </I18nextProvider>
  );
}
