import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import {
  Button,
  Input,
  InputPassword,
  Typography,
  ButtonLink,
} from '@gbm/starman-next';

import {
  Fade,
  GeolocationRejectedSteps,
  GeolocationRequired,
} from '@gbm/onboarding-sdk-ui-components';
import {
  GEOLOCATION_PERMISSIONS_STATUSES,
  AUTH_IDS,
  translations,
} from '@gbm/onboarding-sdk-utils';
import { useBreakpoints } from '@gbm/onboarding-sdk-hooks';
import { LogoMarks } from '../../../components';
import { Layout } from '../../../components/auth';
import { useGeolocation } from '../../../hooks';
import { useAuthProvider } from '../../../providers/auth';
import { FormValues, SIGN_IN_FIELDS } from './types';
import formValidations from './validations';
import { STEPS } from '../../../providers/auth/types';
import styles from './sign-in.module.scss';

const { auth, inputs, buttons, links } = translations;

export default function SignIn() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    getValues,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      [SIGN_IN_FIELDS.email]: '',
      [SIGN_IN_FIELDS.password]: '',
    },
  });
  const { handleSignIn, setStep, configuration } = useAuthProvider();
  const { isSm } = useBreakpoints();
  const [isLoading, setIsLoading] = useState(false);

  const {
    data: geolocationData,
    permission,
    checkPermission,
    getGeolocation,
  } = useGeolocation();
  const [displayRequiredGeolocation, setDisplayRequiredGeolocation] =
    useState(false);
  const [displayRejectedStepsGeolocation, setDisplayRejectedStepsGeolocation] =
    useState(false);

  useEffect(() => {
    checkPermission();
  }, []);

  useEffect(() => {
    const login = async () => {
      if (!isEmpty(geolocationData)) {
        const email = getValues(SIGN_IN_FIELDS.email);
        const password = getValues(SIGN_IN_FIELDS.password);

        setDisplayRequiredGeolocation(false);
        await handleSignIn(email, password, geolocationData);
        setIsLoading(false);
      }
    };

    login();
  }, [geolocationData]);

  const submitCallback: SubmitHandler<FormValues> = async () => {
    setIsLoading(true);

    switch (permission) {
      case GEOLOCATION_PERMISSIONS_STATUSES.prompt:
      case GEOLOCATION_PERMISSIONS_STATUSES.denied:
        setDisplayRequiredGeolocation(true);
        break;
      case GEOLOCATION_PERMISSIONS_STATUSES.granted:
      default:
        getGeolocation();
        break;
    }
  };

  const handleOnGeolocationContinue = () => {
    if (permission === GEOLOCATION_PERMISSIONS_STATUSES.denied) {
      setDisplayRejectedStepsGeolocation(true);
      return;
    }

    getGeolocation();
  };

  const handleCloseGeolocationModal = () => {
    setIsLoading(false);
    setDisplayRequiredGeolocation(false);
  };

  return (
    <Fade>
      <Layout images={configuration.images}>
        <div className={styles.container} id={AUTH_IDS.viewSignin}>
          <form
            className={styles.form}
            onSubmit={handleSubmit(submitCallback)}
            noValidate
          >
            <div className={styles.logo}>
              <LogoMarks
                variant="dark"
                darkLogo={configuration.images?.darkLogo}
              />
            </div>
            <div className={styles.title}>
              <Typography variant={isSm ? 'h5' : 'h2'} weight="semibold">
                {t(auth.signIn)}
              </Typography>
            </div>
            <Input
              id="email"
              widthSize="fluid"
              label={t(inputs.email)}
              type="email"
              placeholder=""
              inputMode="email"
              status={formErrors?.email ? 'error' : undefined}
              style={{ textTransform: 'lowercase' }}
              hintText={
                formErrors?.email
                  ? t(formErrors.email.message as string)
                  : undefined
              }
              {...register(SIGN_IN_FIELDS.email, formValidations.email)}
            />
            <div className={styles['password-input']}>
              <InputPassword
                id="password"
                widthSize="fluid"
                label={t(inputs.password)}
                placeholder=""
                status={formErrors?.password ? 'error' : undefined}
                hintText={
                  formErrors?.password
                    ? t(formErrors.password.message as string)
                    : undefined
                }
                {...register(SIGN_IN_FIELDS.password, formValidations.password)}
              />
              <ButtonLink
                type="button"
                underline="always"
                onClick={() => setStep(STEPS.passwordRecovery)}
              >
                <Typography variant="xsmall" weight="semibold">
                  {t(links.forgotPassword)}
                </Typography>
              </ButtonLink>
            </div>
            <div className={styles.button}>
              <Button
                disabled={false}
                loading={isLoading}
                kind="primary"
                id={AUTH_IDS.signin}
              >
                {t(buttons.signIn)}
              </Button>
            </div>
            <div className={styles.register}>
              <Typography variant="small">
                {t(buttons.signUpQuestion)}
              </Typography>
              &nbsp;
              <ButtonLink
                type="button"
                underline="always"
                onClick={() => setStep(STEPS.signUp)}
              >
                <Typography variant="small" weight="semibold">
                  {t(buttons.signUp)}
                </Typography>
              </ButtonLink>
            </div>
          </form>
        </div>
      </Layout>
      <GeolocationRequired
        show={displayRequiredGeolocation}
        onContinue={handleOnGeolocationContinue}
        onExit={handleCloseGeolocationModal}
      />
      <GeolocationRejectedSteps
        show={displayRejectedStepsGeolocation}
        onClose={
          /* istanbul ignore next */ () =>
            setDisplayRejectedStepsGeolocation(false)
        }
      />
    </Fade>
  );
}
