import { Children, HTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './form-layout.module.scss';

export default function FormFooter({
  children,
  className,
}: HTMLAttributes<HTMLDivElement>) {
  const childrenLength = Children.toArray(children).length;

  return (
    <footer
      className={clsx(
        styles.footer,
        {
          [styles['footer--end']]: childrenLength === 1,
        },
        className,
      )}
    >
      {children}
    </footer>
  );
}
