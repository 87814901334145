import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContractStatus } from '@gbm/onboarding-sdk-ui-components';
import {
  LITE_IDS,
  translations,
  TRACKING_EVENTS,
  TRACKING_NAMES,
} from '@gbm/onboarding-sdk-utils';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import Delayed from './delayed';
import { useOpeningLiteProvider } from '../../../providers/opening-lite';

const {
  openingLite: { contractStatus },
} = translations;

export default function Pending() {
  const { t } = useTranslation();
  const { openingStatus, configuration } = useOpeningLiteProvider();
  const [isDelayed, setIsDelayed] = useState(false);
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  useEffect(() => {
    setIsDelayed(!!openingStatus.data.is_contract_creation_time_exceeded);
  }, [openingStatus.data.is_contract_creation_time_exceeded]);

  useEffect(() => {
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.verifyingInformation,
      success: true,
    });
  }, []);

  if (isDelayed) {
    return <Delayed onClick={() => setIsDelayed(false)} />;
  }

  return (
    <div id={LITE_IDS.viewPending}>
      <ContractStatus
        title={t(contractStatus.contractIsPending)}
        description={t(contractStatus.pendingDescription)}
      />
    </div>
  );
}
