import { AnchorHTMLAttributes } from 'react';
import { ButtonLink, ButtonLinkProps } from '@gbm/starman-next';

type ExternalLinkProps = Pick<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  'target' | 'href' | 'children'
> &
  Pick<ButtonLinkProps, 'kind'>;

export default function ExternalLink({
  children,
  target,
  href,
  kind,
}: ExternalLinkProps) {
  const handleClick = () => {
    window.open(href, target);
  };

  return (
    <ButtonLink
      type="button"
      underline="always"
      onClick={handleClick}
      kind={kind}
    >
      {children}
    </ButtonLink>
  );
}
