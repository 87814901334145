import { RegisterOptions } from 'react-hook-form';

import { translations } from '@gbm/onboarding-sdk-utils';

import { CURP_MAX_LENGTH } from './types';

const curpValidations: RegisterOptions = {
  required: translations.inputs.curpRequired,
  maxLength: {
    value: CURP_MAX_LENGTH,
    message: translations.inputs.curpInvalid,
  },
  minLength: {
    value: CURP_MAX_LENGTH,
    message: translations.inputs.curpInvalid,
  },
};

export default {
  curp: curpValidations,
} as const;
