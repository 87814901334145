import { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './icon-text.module.scss';

type IconTextProps = {
  iconPosition: 'left' | 'right';
  text: ReactNode;
  icon: string;
  className?: string;
};

export default function IconText({
  iconPosition,
  text,
  icon,
  className,
}: IconTextProps) {
  return (
    <span className={clsx(styles.container, className)}>
      {iconPosition === 'left' && <img src={icon} alt="" />}
      {text}
      {iconPosition === 'right' && <img src={icon} alt="" />}
    </span>
  );
}
