import { useCallback, useEffect, useState } from 'react';

import {
  LEGAL_DOCUMENTS_PREFIX,
  GENERIC_ERROR,
  LEGAL_DOCUMENT_TYPES,
  LegalDocumentType,
  LegalDocumentTypeValues,
} from '@gbm/onboarding-sdk-utils';
import { useNotificationProvider } from '@gbm/onboarding-sdk-ui-components';
import { useUpgradeApi } from '../../../api/upgrade/use-upgrade-api';
import { HTTP_STATUS_CODES } from '../../../api/types';
import { OpeningLiteProviderProps } from '../../providers/opening-lite/types';
import { useParty } from '../useParty';
import { UseLegalDocumentsType } from './types';

export default function useLegalDocuments(
  config: OpeningLiteProviderProps,
): UseLegalDocumentsType {
  const { showNotification } = useNotificationProvider();
  const [termsAndConditionsDoc, setTermsAndConditionsDoc] =
    useState<LegalDocumentType>();
  const [executionServiceDoc, setExecutionServiceDoc] =
    useState<LegalDocumentType>();
  const { party } = useParty(config);
  const [legalDocs, setLegalDocs] = useState<Array<LegalDocumentType>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const upgradeApi = useUpgradeApi(config);

  useEffect(() => {
    if (error) {
      showNotification({
        description: GENERIC_ERROR,
        kind: 'error',
      });
      setError(null);
    }
  }, [error]);

  const fetch = async () => {
    const { data, err } = await upgradeApi.getLegalDocuments();

    if (data) {
      let docs: LegalDocumentType[] = [];
      const tcDocument = data.find(
        (doc: LegalDocumentType) =>
          doc.type === LEGAL_DOCUMENT_TYPES.termsAndConditions,
      );
      const legalDocuments = data.filter((doc: LegalDocumentType) =>
        doc.type.includes(LEGAL_DOCUMENTS_PREFIX),
      );

      tcDocument && setTermsAndConditionsDoc(tcDocument);

      if (legalDocuments && tcDocument) {
        docs = [...legalDocuments, tcDocument];
      }

      if (config.isEnabledTradingOpening) {
        const executionDoc = data.find(
          (doc: LegalDocumentType) =>
            doc.type === LEGAL_DOCUMENT_TYPES.executionService,
        );
        if (executionDoc) {
          setExecutionServiceDoc(executionDoc);
          docs.push(executionDoc);
        }
      }

      setLegalDocs(docs);

      return data;
    }

    setError(err);
    return [];
  };

  const findDocument = useCallback(
    (legalDocuments: Array<LegalDocumentType>, type: LegalDocumentTypeValues) =>
      legalDocuments.find(
        ({ type: docType }: LegalDocumentType) => docType === type,
      ),
    [],
  );

  const saveDocument = useCallback(async (document: LegalDocumentType) => {
    const { status, err } = await upgradeApi.saveLegalDocument(
      party.data.party_id,
      {
        ...document,
        type: document.type,
        version: document.version,
      },
    );

    if (status !== HTTP_STATUS_CODES.created) {
      setError(err);
      return false;
    }

    return true;
  }, []);

  const signDocument = useCallback(
    async (documentType: LegalDocumentTypeValues) => {
      setIsLoading(true);
      const documents = await fetch();
      const documentToSign = findDocument(documents, documentType);

      if (!documentToSign) {
        setIsLoading(false);
        return true;
      }

      const signed = await saveDocument(documentToSign);
      setIsLoading(false);
      return signed;
    },
    [],
  );

  return {
    fetch,
    termsAndConditionsDoc,
    legalDocs,
    signDocument,
    isLoading,
    executionServiceDoc,
  };
}
