import { RegisterOptions } from 'react-hook-form';

import { REGEX_VALIDATORS, translations } from '@gbm/onboarding-sdk-utils';

const emailValidations: RegisterOptions = {
  required: translations.auth.emailRequired,
  maxLength: { value: 256, message: translations.auth.emailInvalid },
  pattern: {
    value: REGEX_VALIDATORS.email,
    message: translations.auth.emailInvalid,
  },
  setValueAs: (value: string) => value.toLowerCase(),
};

const passwordValidations: RegisterOptions = {
  required: translations.auth.passwordRequired,
};

export default {
  email: emailValidations,
  password: passwordValidations,
} as const;
