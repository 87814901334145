import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GEOLOCATION_PERMISSIONS_STATUSES,
  GeolocationPermissionStatusType,
  translations,
} from '@gbm/onboarding-sdk-utils';
import { useNotificationProvider } from '@gbm/onboarding-sdk-ui-components';
import { getGeolocation } from '../../../utils/helpers';
import { GeolocationType } from '../../../api/opening-lite/types';

const {
  base: { geolocation },
} = translations;

type UseGeolocationType = {
  data?: GeolocationType;
  permission: GeolocationPermissionStatusType;
  checkPermission: () => void;
  getGeolocation: (customOptions?: Partial<PositionOptions>) => void;
};

export default function useGeolocation(): UseGeolocationType {
  const { t } = useTranslation();
  const { showNotification } = useNotificationProvider();
  const [data, setData] = useState<GeolocationType>();
  const [permission, setPermission] = useState<GeolocationPermissionStatusType>(
    GEOLOCATION_PERMISSIONS_STATUSES.prompt,
  );

  const checkPermission = async () => {
    if (navigator.permissions) {
      const { state } = await navigator.permissions.query({
        name: 'geolocation',
      });
      setPermission(state);
      return;
    }

    setPermission(GEOLOCATION_PERMISSIONS_STATUSES.prompt);
  };

  const getGeolocationCallback = (customOptions?: Partial<PositionOptions>) => {
    getGeolocation(customOptions)
      .then((result) => {
        setPermission(GEOLOCATION_PERMISSIONS_STATUSES.granted);
        setData(result);
      })
      .catch(() => {
        setPermission(GEOLOCATION_PERMISSIONS_STATUSES.denied);
        showNotification({
          kind: 'error',
          description: t(geolocation.denied),
        });
        setData(undefined);
      });
  };

  return {
    data,
    permission,
    checkPermission,
    getGeolocation: getGeolocationCallback,
  };
}
