/* eslint-disable react/jsx-no-constructed-context-values */
import Select from 'react-select';
import { X, Typography } from '@gbm/starman-next';
import clsx from 'clsx';

import SelectMultipleControl from './control';
import SelectMultiplePlaceholder from './placeholder';
import SelectMultipleIndicator from './indicator';
import SelectMultipleMenu from './menu';
import SelectMultipleItem from './item';
import { SelectMultipleContext } from './use-select-multiple';
import { SelectMultipleProps } from './types';
import styles from './select-multiple.module.scss';

const CrossIcon = () => <X />;

export default function DropdownMulti({
  className,
  isDisabled,
  id,
  label,
  hasError,
  readOnly,
  errorMessage,
  supportMessage,
  onChange,
  onBlur,
  placeholder,
  value,
  defaultValue,
  autoFocus,
  options,
  labelClassName,
  inputClassName,
}: SelectMultipleProps) {
  return (
    <div className={className}>
      <div className={clsx(styles.label, labelClassName)}>
        <Typography
          className={clsx({ [styles['label--disabled']]: isDisabled })}
          variant="small"
          htmlFor={id}
          component="label"
        >
          {label}
        </Typography>
      </div>
      <SelectMultipleContext.Provider
        value={{
          id,
          value,
          defaultValue,
          options,
          onChange,
          hasError,
          readOnly,
        }}
      >
        <div className={inputClassName}>
          <Select
            isMulti
            isSearchable={false}
            name={label}
            aria-labelledby={id}
            aria-label={label}
            options={options}
            isDisabled={isDisabled || readOnly}
            isOptionDisabled={(option) => option.isDisabled}
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            autoFocus={autoFocus}
            placeholder={placeholder}
            components={{
              CrossIcon,
              Control: SelectMultipleControl,
              Placeholder: SelectMultiplePlaceholder,
              DropdownIndicator: SelectMultipleIndicator,
              IndicatorSeparator: null,
              Menu: SelectMultipleMenu,
              Option: SelectMultipleItem,
            }}
            styles={{
              menuList: (base) => ({
                ...base,
                padding: 0,
                height: 'auto',
                maxHeight: '10rem',
                overflowY: 'scroll',
              }),
              option: (base) => ({
                ...base,
                cursor: 'pointer',
                backgroundColor: 'transparent',
                '&:active': { backgroundColor: 'transparent' },
                '&:focus-visible': { borderColor: 'transparent' },
              }),
            }}
          />
        </div>
      </SelectMultipleContext.Provider>
      {hasError ? (
        <div className={styles.error}>
          <Typography variant="xsmall">
            {errorMessage}
            {supportMessage ? <br /> : null}
          </Typography>
        </div>
      ) : null}
      {supportMessage || null}
    </div>
  );
}
