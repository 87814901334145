import { createContext, useContext } from 'react';

import { SelectMultipleProps } from './types';

export const SelectMultipleContext = createContext<SelectMultipleProps>({
  id: '',
  value: [],
  defaultValue: [],
  onChange: /* istanbul ignore next */ () => null,
  options: [],
});

export default () => useContext(SelectMultipleContext);
