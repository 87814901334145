import { PropsWithChildren } from 'react';
import { Typography, PencilSimple, ButtonFab } from '@gbm/starman-next';

import { useBreakpoints } from '@gbm/onboarding-sdk-hooks';
import { FormSection } from '../form-layout';
import styles from './resume-section.module.scss';

type ResumeSectionProps = PropsWithChildren<{
  title: string;
  onEditClick?: () => void;
  isLoading?: boolean;
}>;

export default function ResumeSection({
  title,
  onEditClick,
  isLoading,
  children,
}: ResumeSectionProps) {
  const { isSm, isMd } = useBreakpoints();

  return (
    <FormSection contentClassName={styles.content}>
      <div className={styles.title}>
        <Typography variant={isSm || isMd ? 'h5' : 'h4'} weight="semibold">
          {title}
        </Typography>
        {onEditClick && !isLoading ? (
          <ButtonFab kind="secondary" onClick={onEditClick}>
            <PencilSimple size={styles['icon-size']} />
          </ButtonFab>
        ) : null}
      </div>
      {children}
    </FormSection>
  );
}
