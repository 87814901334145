import { useEffect } from 'react';

import { TRACKING_EVENTS, TRACKING_NAMES } from '@gbm/onboarding-sdk-utils';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import { OpeningLayout } from '../../../components/opening-lite';
import { AddressForm } from '../../../components';
import { StateValueType } from '../../../store/types';
import { useOpeningLiteProvider } from '../../../providers/opening-lite';
import {
  OPENING_LITE_STEPS,
  STEPS,
} from '../../../providers/opening-lite/types';

export default function AddressInfo() {
  const {
    saveOpeningLiteStep,
    setStep,
    isEditing,
    configuration,
    setIsEditing,
  } = useOpeningLiteProvider();
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 50);
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.address,
    });
  }, []);

  const handleFulfilledParty = () => {
    saveOpeningLiteStep(OPENING_LITE_STEPS.addressInfo);
  };

  const handleContinue = (address: StateValueType<Record<string, any>>) => {
    setStep(STEPS.confirmation);
    configuration.onAddressInfoCompleted?.({
      addressType: address.data.address_type,
      country: address.data.country,
      insideNumber: address.data.inside_number,
      outsideNumber: address.data.outside_number,
      state: address.data.state,
      streetName: address.data.street_name,
      suburb: address.data.suburb,
      township: address.data.township,
      zipCode: address.data.zipCode,
    });
  };

  const handleCloseEdition = () => {
    setIsEditing(false);
    setStep(STEPS.confirmation);
  };

  const handleGoBack = () => {
    setStep(STEPS.personalInfo);
  };

  return (
    <OpeningLayout current={2}>
      <AddressForm
        configuration={configuration}
        onFulfilledParty={handleFulfilledParty}
        onHandleContinue={handleContinue}
        onGoBack={handleGoBack}
        onCloseEdition={handleCloseEdition}
        isEditing={isEditing}
      />
    </OpeningLayout>
  );
}
