import { useEffect, useState } from 'react';
import { ArrowCircleRight, Button } from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';

import { FullAlert, Loader } from '@gbm/onboarding-sdk-ui-components';
import {
  makeTrackingErrorProps,
  LITE_IDS,
  translations,
  TRACKING_EVENTS,
  TRACKING_NAMES,
} from '@gbm/onboarding-sdk-utils';
import { useBreakpoints, useMixpanel } from '@gbm/onboarding-sdk-hooks';
import { useOpeningLiteApi } from '../../../../api/opening-lite/use-opening-lite-api';
import { useContract, useParty } from '../../../hooks';
import { useOpeningLiteProvider } from '../../../providers/opening-lite';

import styles from './contract-creation.module.scss';

const {
  buttons,
  openingLite: { contractStatus },
} = translations;

export default function Sign() {
  const { t } = useTranslation();
  const { isSm } = useBreakpoints();
  const { configuration } = useOpeningLiteProvider();
  const [displayError, setDisplayError] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingSignatureLink, setIsLoadingSignatureLink] = useState(false);
  const {
    party,
    fetch: fetchParty,
    isFulfilled: isFulfilledParty,
  } = useParty(configuration);
  const openingLiteApi = useOpeningLiteApi(configuration);
  const {
    contract,
    fetch: fetchContract,
    isFulfilled: isFulfilledContract,
  } = useContract(configuration);
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  useEffect(() => {
    fetchParty();
    fetchContract();
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.sign,
    });
  }, []);

  useEffect(() => {
    if (isFulfilledParty && isFulfilledContract) {
      getSignContractLink();
    }
  }, [party.status, contract.status]);

  const handleRetryClick = () => {
    if (!isLoadingSignatureLink) {
      setIsLoadingSignatureLink(true);
      getSignContractLink();
    }
  };

  const getSignContractLink = async () => {
    const { data, err, headers } = await openingLiteApi.getSignContractLink(
      party.data.party_id,
      contract.data.contract_id,
    );

    if (err) {
      const trackingErrorProps = makeTrackingErrorProps(err, headers);

      mixpanel.track(TRACKING_EVENTS.contractSigned, {
        ...trackingErrorProps,
        status: 'pending_sign',
      });
      setIsLoadingData(false);
      setIsLoadingSignatureLink(false);
      setDisplayError(true);
    } else {
      mixpanel.track(TRACKING_EVENTS.contractSigned, {
        success: true,
        status: 'pending_sign',
      });

      const redirectUri = new URL(window.location.href);
      redirectUri.searchParams.set('signed', 'true');
      window.location.assign(`${data.uri}&redirect_uri=${redirectUri.href}`);
    }
  };

  if (isLoadingData) {
    return <Loader kind="light" />;
  }

  return (
    <div id={LITE_IDS.viewSign}>
      {displayError && (
        <FullAlert
          actions={
            <Button
              size={isSm ? 'fluid' : 'default'}
              kind="primary"
              onClick={handleRetryClick}
              loading={isLoadingSignatureLink}
              icon={
                <ArrowCircleRight size={styles['icon-size']} weight="fill" />
              }
            >
              {t(buttons.tryAgain)}
            </Button>
          }
          title={t(contractStatus.somethingHappened)}
          description={t(contractStatus.tryAgain)}
          environment={configuration.environment}
        />
      )}
    </div>
  );
}
