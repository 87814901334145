import {
  Loader as LoaderStarman,
  LoaderProps as LoaderStarmanProps,
} from '@gbm/starman-next';
import clsx from 'clsx';

import styles from './loader.module.scss';

export type LoaderProps = LoaderStarmanProps & {
  fullScreen?: boolean;
};

export default function Loader({ fullScreen = true, ...props }: LoaderProps) {
  return (
    <div
      className={clsx(styles.loader, {
        [styles['loader--fullscreen']]: fullScreen,
      })}
    >
      <LoaderStarman {...props} />
    </div>
  );
}
