import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TRACKING_EVENTS,
  TRACKING_NAMES,
  UPGRADE_IDS,
  translations,
} from '@gbm/onboarding-sdk-utils';
import { ContractStatus } from '@gbm/onboarding-sdk-ui-components';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';

import { useUpgradeStatus } from '../../../hooks';
import { useUpgradeProvider } from '../../../providers/upgrade';
import { UPGRADE_STATUSES } from '../../../providers/upgrade/types';

const {
  upgrade: { contractStatus },
} = translations;

export default function Pending() {
  const { t } = useTranslation();
  const { configuration } = useUpgradeProvider();
  const { poolForRequestStatus, stopPooling } = useUpgradeStatus(configuration);
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  useEffect(() => {
    poolForRequestStatus();
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.upgradeStatus,
      status: UPGRADE_STATUSES.pending,
    });
    return () => {
      stopPooling();
    };
  }, []);

  return (
    <div id={UPGRADE_IDS.viewPending}>
      <ContractStatus
        title={t(contractStatus.reviewing)}
        description={t(contractStatus.waitReviewing)}
      />
    </div>
  );
}
