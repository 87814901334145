import { RegisterOptions } from 'react-hook-form';

import {
  RFC_LENGTH,
  HOMOCLAVE_LENGTH,
  REGEX_VALIDATORS,
  translations,
} from '@gbm/onboarding-sdk-utils';

export const rfcValidations: RegisterOptions = {
  required: translations.inputs.rfcRequired,
  pattern: {
    value: REGEX_VALIDATORS.rfcWithoutHomoclave,
    message: translations.inputs.rfcValid,
  },
  minLength: {
    value: RFC_LENGTH,
    message: translations.inputs.rfcLength,
  },
  maxLength: {
    value: RFC_LENGTH,
    message: translations.inputs.rfcLength,
  },
  setValueAs: (value: string) => value.toUpperCase(),
};

export const homoclaveValidations: RegisterOptions = {
  required: translations.inputs.homoclaveRequired,
  pattern: {
    value: REGEX_VALIDATORS.homoclave,
    message: translations.inputs.homoclaveValid,
  },
  minLength: {
    value: HOMOCLAVE_LENGTH,
    message: translations.inputs.homoclaveValid,
  },
  maxLength: {
    value: HOMOCLAVE_LENGTH,
    message: translations.inputs.homoclaveValid,
  },
  setValueAs: (value: string) => value.toUpperCase(),
};

export default {
  homoclaveValidations,
  rfcValidations,
} as const;
