import { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  ArrowCircleRight,
  Typography,
} from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { motion } from 'framer-motion';

import {
  FormContent,
  FormFooter,
  FormSection,
  useNotificationProvider,
} from '@gbm/onboarding-sdk-ui-components';
import {
  GENERIC_ERROR,
  LEGAL_DOCUMENT_TYPES,
  makeTrackingErrorProps,
  UPGRADE_IDS,
  translations,
  TRACKING_EVENTS,
  TRACKING_NAMES,
} from '@gbm/onboarding-sdk-utils';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import {
  UpgradeLayout,
  Nationality,
  TaxInformation,
} from '../../../components/upgrade';
import { FinancialInformationResume } from '../../../components/upgrade/financial-information';
import {
  useContract,
  useEaQuestionnaire,
  useIdentifiers,
  useKycQuestionnaire,
  useLegalDocuments,
  useParty,
  usePartyCountries,
  usePepQuestionnaire,
  useUpgradeStep,
} from '../../../hooks';
import {
  STEPS,
  StepType,
  UPGRADE_STEPS,
} from '../../../providers/upgrade/types';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  getBankAccountDecrypted,
  getBankAccountStatus,
  selectBankAccountDecrypted,
  selectBankAccounts,
} from '../../../store/upgrade';
import { useUpgradeApi } from '../../../../api/upgrade/use-upgrade-api';
import { HTTP_STATUS_CODES } from '../../../../api/types';
import { VALID_BANK_ACCOUNT_STATUSES } from '../../../store/upgrade/types';
import { REDUCER_STATUS } from '../../../store/types';
import { useUpgradeProvider } from '../../../providers/upgrade';
import styles from './resume.module.scss';

const {
  buttons,
  upgrade: { confirmation },
} = translations;

export default function Confirmation() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { configuration, setIsEditing, setStep } = useUpgradeProvider();
  const { party, isFulfilled: isFulfilledParty } = useParty(configuration);
  const {
    fetch: fetchPartyCountries,
    birthCountries,
    citizenshipCountries,
    nationalities,
    taxCountries,
    isLoading: isLoadingCountries,
  } = usePartyCountries(configuration);
  const {
    tin,
    fiel,
    rfc,
    isLoading: isLoadingIds,
    fetch: fetchIds,
  } = useIdentifiers(configuration);
  const {
    contract,
    isFulfilled: isFulfilledContract,
    fetch: fetchContract,
  } = useContract(configuration);
  const {
    fetchQuestionnaire: fetchEaQuestions,
    fetchAnswers: fetchEaAnswers,
    questionsWithAnswers: eaResponses,
    isLoading: isLoadingEaResponses,
  } = useEaQuestionnaire(configuration);
  const {
    fetchQuestionnaire: fetchPepQuestions,
    fetchAnswers: fetchPepAnswers,
    questionsWithAnswers: pepResponses,
    isLoading: isLoadingPepResponses,
  } = usePepQuestionnaire(configuration);
  const {
    fetchQuestionnaire: fetchKycQuestions,
    fetchAnswers: fetchKycAnswers,
    questionsWithAnswers: kycResponses,
    isLoading: isLoadingKycResponses,
  } = useKycQuestionnaire(configuration);
  const { signDocument } = useLegalDocuments(configuration);
  const bankAccounts = useAppSelector(selectBankAccounts);
  const bankAccountDecrypted = useAppSelector(selectBankAccountDecrypted);
  const [noPresentialConfirmation, setNoPresentialConfirmation] =
    useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isLoadingBankAccount, setIsLoadingBankAccount] = useState(true);
  const upgradeApi = useUpgradeApi(configuration);
  const { saveUpgradeStep } = useUpgradeStep(configuration);
  const { showNotification } = useNotificationProvider();
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.confirmationUpgrade,
    });
    fetchContract();
  }, []);

  useEffect(() => {
    /* istanbul ignore else */
    if (isFulfilledParty) {
      fetchPartyCountries(true);
      fetchIds(true);
      fetchEaQuestions(true);
      fetchEaAnswers(true);
      fetchPepQuestions(true);
      fetchPepAnswers(true);
      fetchKycQuestions(true);
      fetchKycAnswers(true);
    }
  }, [party.status]);

  useEffect(() => {
    /* istanbul ignore else */
    if (isFulfilledContract && isEmpty(bankAccounts.data)) {
      dispatch(
        getBankAccountStatus({
          config: configuration,
          contractId: contract.data.legacy_contract_id,
        }),
      );
    }
  }, [contract.status]);

  useEffect(() => {
    if (isEmpty(bankAccounts.data)) {
      setIsLoadingBankAccount(false);
      return;
    }

    const bankAccount = bankAccounts.data.items.find(
      ({ verification_status: status }: { verification_status: string }) =>
        VALID_BANK_ACCOUNT_STATUSES.includes(status),
    );

    if (isEmpty(bankAccount) || !isEmpty(bankAccountDecrypted.data)) {
      setIsLoadingBankAccount(false);
      return;
    }

    dispatch(
      getBankAccountDecrypted({
        config: configuration,
        bankAccountId: bankAccount.id,
      }),
    );
  }, [bankAccounts]);

  useEffect(() => {
    if (bankAccountDecrypted.status === REDUCER_STATUS.resolved) {
      setIsLoadingBankAccount(false);
    }
  }, [bankAccountDecrypted.status]);

  const handleOnEditClick = (step: StepType) => {
    setIsEditing(true);
    setStep(step);
  };

  const handleContinueButtonClick = async () => {
    setIsLoadingButton(true);
    let trackingErrorProps;

    const docSigned = await signDocument(
      LEGAL_DOCUMENT_TYPES.noPresentialOpeningConfirmation,
    );

    if (!docSigned) {
      trackingErrorProps = makeTrackingErrorProps({}, {});
      showNotification({
        kind: 'error',
        description: GENERIC_ERROR,
      });
      setIsLoadingButton(false);
      return;
    }

    const { status, err, headers } = await upgradeApi.contractUpgrade(
      party.data.party_id,
    );

    if (status === HTTP_STATUS_CODES.accepted) {
      saveUpgradeStep(UPGRADE_STEPS.gbmReviewDocs);
      setStep(STEPS.pending);
    } else {
      trackingErrorProps = makeTrackingErrorProps(err, headers);
      showNotification({
        kind: 'error',
        description: trackingErrorProps.message,
      });
      setIsLoadingButton(false);
    }
  };

  return (
    <UpgradeLayout current={5}>
      <motion.div
        className={`${UPGRADE_IDS.viewConfirmation} ${styles.container}`}
        id={UPGRADE_IDS.viewConfirmation}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <FormContent>
          <FormSection
            title={t(confirmation.title)}
            description={t(confirmation.subtitle)}
            contentClassName={styles.content}
          >
            <Nationality
              birthCountries={birthCountries}
              citizenshipCountries={citizenshipCountries}
              nationalityCountries={nationalities}
              isLoading={
                !birthCountries ||
                !citizenshipCountries ||
                !nationalities ||
                isLoadingCountries
              }
              onEditClick={() => handleOnEditClick(STEPS.nationality)}
            />
            <TaxInformation
              taxCountries={taxCountries}
              fiel={fiel.data?.value || ''}
              tin={tin.data?.value || ''}
              rfc={rfc.data?.value || ''}
              clabe={bankAccountDecrypted.data?.bank_account_number || ''}
              isLoading={
                !taxCountries ||
                isLoadingCountries ||
                isLoadingIds ||
                isLoadingBankAccount
              }
              onEditClick={() => handleOnEditClick(STEPS.taxInformation)}
            />
            <FinancialInformationResume
              eaResponses={eaResponses}
              pepResponses={pepResponses}
              kycResponses={kycResponses}
              isLoading={
                isEmpty(eaResponses) ||
                isEmpty(pepResponses) ||
                isEmpty(kycResponses) ||
                isLoadingEaResponses ||
                isLoadingPepResponses ||
                isLoadingKycResponses
              }
              onEditClick={() => handleOnEditClick(STEPS.financialInformation)}
            />
          </FormSection>
          <FormSection>
            <Checkbox
              id="noPresentialConfirmation"
              checked={noPresentialConfirmation}
              onChange={() =>
                setNoPresentialConfirmation(!noPresentialConfirmation)
              }
            >
              <Typography variant="body2">
                {t(confirmation.nonPresentialConfirmation)}
              </Typography>
            </Checkbox>
          </FormSection>
        </FormContent>
        <FormFooter>
          <Button
            id={UPGRADE_IDS.btnConfirmation}
            disabled={!noPresentialConfirmation}
            loading={isLoadingButton}
            kind="primary"
            onClick={handleContinueButtonClick}
            icon={<ArrowCircleRight size={styles['icon-size']} weight="fill" />}
          >
            {t(buttons.continue)}
          </Button>
        </FormFooter>
      </motion.div>
    </UpgradeLayout>
  );
}
