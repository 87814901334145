import { PropsWithChildren, ReactNode } from 'react';
import { Typography } from '@gbm/starman-next';
import clsx from 'clsx';

import { useBreakpoints } from '@gbm/onboarding-sdk-hooks';
import styles from './form-layout.module.scss';

export type FormSectionProps = PropsWithChildren<{
  title?: string;
  description?: string;
  contentClassName?: string;
  headerContent?: ReactNode;
}>;

export default function FormSection({
  children,
  title,
  description,
  headerContent,
  contentClassName,
}: FormSectionProps) {
  const { isSm, isMd } = useBreakpoints();

  return (
    <section className={styles.section}>
      {title ? (
        <Typography variant={isSm || isMd ? 'h4' : 'h3'}>{title}</Typography>
      ) : null}
      {description ? (
        <div className={styles['section-description']}>
          <Typography variant={isSm || isMd ? 'small' : 'body2'}>
            {description}
          </Typography>
        </div>
      ) : null}
      {headerContent ? (
        <div className={styles['section-header']}>{headerContent}</div>
      ) : null}
      <div className={clsx(styles['section-content'], contentClassName)}>
        {children}
      </div>
    </section>
  );
}
