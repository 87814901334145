import { HTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './form-layout.module.scss';

export default function FormRow({
  children,
  className,
}: HTMLAttributes<HTMLDivElement>) {
  return <div className={clsx(styles.row, className)}>{children}</div>;
}
