import { ConvertedToObjectType, TranslationJsonType } from './types';

export const translations: ConvertedToObjectType<TranslationJsonType> =
  {} as any;

export const convertLanguageJsonToObject = (
  json: any,
  // eslint-disable-next-line default-param-last
  objToConvertTo = translations,
  current?: string,
  first?: boolean,
) => {
  Object.keys(json).forEach((key) => {
    const separator = first ? '' : '.';
    const currentLookupKey = current
      ? `${current}${separator}${key}`
      : `${key}:`;
    const newObjToConvertTo = objToConvertTo;
    if (typeof json[key] === 'object') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Here complains that key is string, but it's fine
      newObjToConvertTo[key] = {};
      convertLanguageJsonToObject(
        json[key],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Here complains that key is string, but it's fine
        objToConvertTo[key],
        currentLookupKey,
        !current,
      );
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Here complains that key is string, but it's fine
      newObjToConvertTo[key] = currentLookupKey;
    }
  });
};
