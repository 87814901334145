import { PropsWithChildren } from 'react';
import { Stepper, StepperProps } from '@gbm/starman-next';
import clsx from 'clsx';

import { AppLayout } from '../app-layout';
import { CloseButton } from '../close-button';
import logo from '../../assets/images/logos/GBM-logo-black.svg';
import styles from './form-layout.module.scss';

export type FormLayoutProps = StepperProps &
  PropsWithChildren<{
    onClose: () => void;
    fullStepper?: boolean;
    kind?: 'primary' | 'secondary';
  }>;

export default function FormLayout({
  children,
  steps,
  current,
  onClose,
  fullStepper,
  displayTitles,
  stepWidthSize,
  stepTitlePosition,
  kind = 'primary',
}: FormLayoutProps) {
  return (
    <AppLayout>
      <div
        className={clsx(styles.container, {
          [styles['container--secondary']]: kind === 'secondary',
        })}
      >
        <header className={styles['header-container']}>
          <div className={styles.header}>
            <img src={logo} alt="GBM" className={styles.logo} />
            <CloseButton kind="secondary" onClick={onClose} />
          </div>
          <div
            className={clsx(styles.stepper, {
              [styles['stepper--full']]: fullStepper,
            })}
          >
            <Stepper
              steps={steps}
              current={current}
              displayTitles={displayTitles}
              stepWidthSize={stepWidthSize}
              stepTitlePosition={stepTitlePosition}
            />
          </div>
        </header>
        {children}
      </div>
    </AppLayout>
  );
}
