import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Ujet,
  UJET_URL,
  translations,
  Environment,
  i18n,
} from '@gbm/onboarding-sdk-utils';
import { useScript } from '@gbm/onboarding-sdk-hooks';
import styles from './chat.module.scss';

export type ChatProps = {
  environment: Environment;
  message?: ReactNode;
};

const {
  base: { chat },
} = translations;
const ujetChat = new Ujet();

export default function Chat({ environment, message }: ChatProps) {
  useScript(UJET_URL);
  const { t } = useTranslation(undefined, { i18n });

  return (
    <button
      type="button"
      id="chat"
      aria-label={t(chat.helpMessage)}
      onClick={() => ujetChat.startChat(environment)}
    >
      <div className={styles.container}>
        <div className={styles.text}>{message || t(chat.helpMessage)}</div>
      </div>
    </button>
  );
}
