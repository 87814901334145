import clsx from 'clsx';
import {
  ButtonFab,
  ArrowLeft,
  Typography,
  Chats,
  EnvelopeSimpleOpen,
  Question,
  Button,
} from '@gbm/starman-next';
import { Trans, useTranslation } from 'react-i18next';

import { useBreakpoints } from '@gbm/onboarding-sdk-hooks';
import { ENVIRONMENTS, config, translations } from '@gbm/onboarding-sdk-utils';
import { AppLayout } from '@gbm/onboarding-sdk-ui-components';
import logo from '@gbm/onboarding-sdk-ui-components/src/assets/images/logos/GBM-logo.svg';
import logoBlack from '@gbm/onboarding-sdk-ui-components/src/assets/images/logos/GBM-logo-black.svg';
import { CHAT_URL, FAQS_URL } from './types';

import styles from './curp-info.module.scss';

type HelpProps = {
  onBack: () => void;
};

const { helpEmail } = config(ENVIRONMENTS.development);
const {
  openingLite: { curpInfo: curpInfoMessages },
} = translations;

export default function Help({ onBack }: HelpProps) {
  const { t } = useTranslation();
  const { isSm, isMd } = useBreakpoints();

  const handleOpenChat = () => window.location.assign(CHAT_URL);
  return (
    <AppLayout>
      {(isSm || isMd) && (
        <div className={styles.close}>
          <ButtonFab kind="secondary" onClick={onBack}>
            <ArrowLeft size={styles['icon-size']} />
          </ButtonFab>
        </div>
      )}
      <div className={styles.container}>
        <aside className={styles.image}>
          <div className={styles['help-aside']}>
            <ButtonFab onClick={onBack} kind="primary">
              <ArrowLeft size={styles['icon-size']} />
            </ButtonFab>
            <img src={logo} alt="GBM" className={styles.logo} />
          </div>
        </aside>
        <div className={clsx(styles.content, styles['help-content'])}>
          <div className={styles['help-logo']}>
            <img src={logoBlack} alt="GBM" className={styles.logo} />
          </div>
          <div className={styles['help-title']}>
            <Typography variant={isSm ? 'h3' : 'h2'}>
              <Trans
                i18nKey={curpInfoMessages.help.title}
                components={[<br />]}
              />
            </Typography>
          </div>
          <div className={styles['help-phone']}>
            <Question size={styles['icon-size']} />
            <a href={FAQS_URL} target="_blank">
              <Typography variant={isSm ? 'small' : 'body2'}>
                {t(curpInfoMessages.help.faqs)}
              </Typography>
            </a>
          </div>
          <div className={styles['help-email']}>
            <EnvelopeSimpleOpen size={styles['icon-size']} />
            <a href={`mailto:${helpEmail}`} className={styles.link}>
              <Typography variant={isSm ? 'small' : 'body2'} weight="semibold">
                {helpEmail}
              </Typography>
            </a>
          </div>
          <div className={styles.chat}>
            <Button
              icon={<Chats size={styles['chat-icon']} />}
              iconAlignment="left"
              size={isSm || isMd ? 'fluid' : 'tiny'}
              kind={isSm || isMd ? 'primary' : 'secondary'}
              onClick={handleOpenChat}
            >
              {t('chat')}
            </Button>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
