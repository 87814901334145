import { useState, useEffect, ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { useTranslation, Trans } from 'react-i18next';
import {
  Input,
  Button,
  Typography,
  ButtonLink,
  ArrowCircleRight,
} from '@gbm/starman-next';
import { motion } from 'framer-motion';

import {
  AppLayout,
  useNotificationProvider,
} from '@gbm/onboarding-sdk-ui-components';
import logo from '@gbm/onboarding-sdk-ui-components/src/assets/images/logos/GBM-logo-black.svg';
import {
  LITE_IDS,
  translations,
  GENERIC_ERROR,
  TRACKING_EVENTS,
  TRACKING_NAMES,
} from '@gbm/onboarding-sdk-utils';
import { useBreakpoints, useMixpanel } from '@gbm/onboarding-sdk-hooks';
import { normalizeText } from '../../../../utils/helpers';
import { useAppDispatch, useAppSelector } from '../../../store';
import { useOpeningLiteProvider } from '../../../providers/opening-lite';
import {
  getDataFromCurp,
  clearDataFromCurp,
  clearErrors,
  selectDataFromCurp,
  selectErrors,
} from '../../../store/opening-lite';
import {
  FormValues,
  CURP_INFO_FIELDS,
  INFORMATIVE_CURP_ERRORS,
  CURP_ERRORS,
  CURP_MAX_LENGTH,
} from './types';
import formValidations from './validations';
import { STEPS } from '../../../providers/opening-lite/types';
import { REDUCER_STATUS } from '../../../store/types';
import Help from './help';
import styles from './curp-info.module.scss';

const {
  base,
  buttons,
  inputs,
  openingLite: { curpInfo: curpInfoMessages },
} = translations;

export default function CurpInfo() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isSm } = useBreakpoints();
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid: isValidForm },
    clearErrors: clearFormErrors,
    setValue,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      [CURP_INFO_FIELDS.curp]: '',
    },
    criteriaMode: 'all',
  });
  const { setStep, configuration } = useOpeningLiteProvider();
  const { showNotification, hideNotification } = useNotificationProvider();
  const dataFromCurp = useAppSelector(selectDataFromCurp);
  const errors = useAppSelector(selectErrors);
  const loadingCurpData = dataFromCurp.status === REDUCER_STATUS.pending;
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );
  const prepopulatedValue = configuration.prepopulatedInfo?.personalInfo?.curp;
  const [curpValue, setCurpValue] = useState(prepopulatedValue ?? '');
  const [displayHelp, setDisplayHelp] = useState(false);

  useEffect(() => {
    prepopulatedValue && handleInputChange(prepopulatedValue);
    handleCloseErrorBanner();
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.curp,
    });
  }, []);

  useEffect(() => {
    if (loadingCurpData) {
      clearFormErrors(CURP_INFO_FIELDS.curp);
      setValue(CURP_INFO_FIELDS.curp, curpValue, { shouldValidate: true });
    }
  }, [loadingCurpData]);

  useEffect(() => {
    if (dataFromCurp.status === REDUCER_STATUS.resolved) {
      mixpanel.track(TRACKING_EVENTS.screenFilled, {
        name: TRACKING_NAMES.curp,
        success: true,
      });
    } else if (dataFromCurp.status === REDUCER_STATUS.rejected) {
      if (errors.dataFromCurp?.code === CURP_ERRORS.invalid) {
        showNotification({
          title: t(base.important),
          kind: 'error',
          description: (
            <Typography variant="small">
              <Trans
                i18nKey={curpInfoMessages.invalidCurpContactRenapo}
                components={[<span className={styles['renapo-link']}></span>]}
              />
            </Typography>
          ),
        });
      } else if (
        INFORMATIVE_CURP_ERRORS.includes(errors.dataFromCurp?.code as string)
      ) {
        showNotification({
          title: t(base.contact),
          kind: 'info',
          description: (
            <div className={styles.info}>
              <Typography variant="small">
                {errors.dataFromCurp?.message}&nbsp;
              </Typography>
              <ButtonLink
                underline="always"
                onClick={handleContactUs}
                kind="primary--inverted"
              >
                <Typography variant="small">{t(buttons.contactUs)}</Typography>
              </ButtonLink>
            </div>
          ),
        });
      } else {
        showNotification({
          title: t(base.error),
          kind: 'error',
          description: errors.dataFromCurp?.message ?? GENERIC_ERROR,
        });
      }
    }
  }, [dataFromCurp.status]);

  const handleUnknownCurp = () => {
    mixpanel.track(TRACKING_EVENTS.linkClicked, {
      name: TRACKING_NAMES.curpUnknown,
    });
    dispatch(clearDataFromCurp());
    setStep(STEPS.personalInfo);
  };

  const handleCloseErrorBanner = () => {
    dispatch(clearErrors());
    hideNotification();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement> | string) => {
    const value = typeof e === 'string' ? e : e.target.value;
    const cleanValue = normalizeText(value);
    setCurpValue(cleanValue);

    if (cleanValue.length === CURP_MAX_LENGTH) {
      dispatch(
        getDataFromCurp({
          config: configuration,
          curp: cleanValue,
        }),
      );
    }
  };

  const handleContactUs = () => {
    handleCloseErrorBanner();
    setDisplayHelp(true);
  };

  if (displayHelp) {
    return <Help onBack={() => setDisplayHelp(false)} />;
  }

  return (
    <AppLayout>
      <motion.div
        className={styles.container}
        id={LITE_IDS.viewCurpInfo}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <aside className={styles.image} />
        <div className={styles.content}>
          <div className={styles.above}>
            <img src={logo} alt="GBM" className={styles.logo} />
          </div>
          <div className={styles.below}>
            <div className={styles.text}>
              <Typography variant={isSm ? 'h3' : 'h2'}>
                {t(curpInfoMessages.title)}
              </Typography>
              <div className={styles.subtitle}>
                <Typography variant={isSm ? 'body2' : 'body1'}>
                  {t(curpInfoMessages.description)}
                </Typography>
              </div>
            </div>
            <div className={styles['input-container']}>
              <Input
                id={CURP_INFO_FIELDS.curp}
                widthSize="fluid"
                label={t(inputs.typeYourCurp)}
                type="text"
                placeholder=""
                inputMode="text"
                counterEnabled
                maxLength={CURP_MAX_LENGTH}
                minLength={CURP_MAX_LENGTH}
                status={formErrors?.curp ? 'error' : undefined}
                style={{ textTransform: 'uppercase' }}
                disabled={loadingCurpData}
                value={curpValue}
                hintText={
                  formErrors?.curp
                    ? t(formErrors.curp.message as string)
                    : undefined
                }
                {...register(CURP_INFO_FIELDS.curp, {
                  ...formValidations.curp,
                  onChange: handleInputChange,
                })}
              />
              <div className={styles.link}>
                <ButtonLink
                  onClick={handleUnknownCurp}
                  id={LITE_IDS.linkCurpInfo}
                  disabled={loadingCurpData}
                  underline="always"
                >
                  <Typography variant="xsmall">
                    {t(curpInfoMessages.doNotKnowCurp)}
                  </Typography>
                </ButtonLink>
              </div>
            </div>
            <div className={styles['button-container']}>
              <Button
                size={isSm ? 'fluid' : 'default'}
                disabled={
                  !isEmpty(formErrors) ||
                  dataFromCurp.status === REDUCER_STATUS.rejected ||
                  !isValidForm
                }
                loading={loadingCurpData}
                kind="primary"
                id={LITE_IDS.curpInfo}
                onClick={handleSubmit(() => setStep(STEPS.personalInfo))}
                icon={
                  <ArrowCircleRight size={styles['icon-size']} weight="fill" />
                }
              >
                {t(buttons.continue)}
              </Button>
            </div>
          </div>
        </div>
      </motion.div>
    </AppLayout>
  );
}
