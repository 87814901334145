import mixpanel from 'mixpanel-browser';

import { Environment, ENVIRONMENTS, PlatformType } from '../types';
import { config } from '../config';

export default class Mixpanel {
  private mixpanelApiKey: string;

  private isEnabledTrackingEvents: boolean;

  private debug: boolean;

  constructor(
    environment: Environment,
    isEnabledTrackingEvents: boolean = true,
  ) {
    const { mixpanelApiKey } = config(environment);
    this.mixpanelApiKey = mixpanelApiKey;
    this.isEnabledTrackingEvents = isEnabledTrackingEvents;
    this.debug = environment === ENVIRONMENTS.development;
  }

  init() {
    if (this.isEnabledTrackingEvents) {
      mixpanel.init(this.mixpanelApiKey, {
        loaded: () =>
          mixpanel.set_config({
            debug: this.debug,
          }),
      });
    }
  }

  identify(id: string) {
    if (this.isEnabledTrackingEvents) {
      mixpanel.identify(id);
    }
  }

  alias(id: string) {
    if (this.isEnabledTrackingEvents) {
      mixpanel.alias(id);
    }
  }

  track(name: string, props = {}, cb = () => null) {
    if (this.isEnabledTrackingEvents) {
      mixpanel.track(name, props, cb);
    }
  }

  peopleSet(props: Record<string, unknown>) {
    if (this.isEnabledTrackingEvents) {
      mixpanel.people.set(props);
    }
  }

  peopleUnset(props: string | string[]) {
    if (this.isEnabledTrackingEvents) {
      mixpanel.people.unset(props);
    }
  }

  registerPlatform(platform: PlatformType) {
    if (this.isEnabledTrackingEvents) {
      mixpanel.register({ platform });
    }
  }
}
