import { useState, useEffect } from 'react';
import {
  Input,
  Typography,
  Button,
  Checkbox,
  Tooltip,
  ButtonLink,
} from '@gbm/starman-next';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import ReCAPTCHA from 'react-google-recaptcha';

import { ExternalLink, Fade } from '@gbm/onboarding-sdk-ui-components';
import {
  config,
  ENVIRONMENTS,
  AUTH_IDS,
  translations,
  TRACKING_EVENTS,
  TRACKING_NAMES,
} from '@gbm/onboarding-sdk-utils';
import { useBreakpoints, useMixpanel } from '@gbm/onboarding-sdk-hooks';
import { LogoMarks } from '../../../components';
import {
  Layout,
  InputPassword,
  PasswordPolicies,
} from '../../../components/auth';
import { useAuthProvider } from '../../../providers/auth';

import { SIGN_UP_FIELDS, FormValues } from './types';
import formValidations from './validations';
import styles from './sign-up.module.scss';

const { legalDocsAuthBaseUrl, recaptchaKey } = config(ENVIRONMENTS.production);
const { auth, inputs, links, buttons } = translations;

export default function SignUp() {
  const { t } = useTranslation();
  const formMethods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      [SIGN_UP_FIELDS.email]: '',
      [SIGN_UP_FIELDS.emailConfirmation]: '',
      [SIGN_UP_FIELDS.password]: '',
      [SIGN_UP_FIELDS.privacyPolicy]: false,
      [SIGN_UP_FIELDS.openingAcceptance]: false,
    },
    criteriaMode: 'all',
  });
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
    trigger,
    watch,
  } = formMethods;
  const { handleSignUp, handleSignOut, configuration } = useAuthProvider();
  const { isSm } = useBreakpoints();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState<null | string>(null);
  const emailConfirmationValue = watch(SIGN_UP_FIELDS.emailConfirmation);
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  const submitCallback: SubmitHandler<FormValues> = async (formData) => {
    setIsLoading(true);
    mixpanel.track(TRACKING_EVENTS.signUpClicked, {
      name: TRACKING_NAMES.signUp,
    });
    const { password } = formData;
    await handleSignUp(email, password, recaptchaValue as string);
    setIsLoading(false);
  };

  useEffect(() => {
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.signUp,
    });
  }, []);

  useEffect(() => {
    if (emailConfirmationValue) {
      trigger(SIGN_UP_FIELDS.emailConfirmation);
    }
  }, [email]);

  useEffect(() => {
    const hasError =
      SIGN_UP_FIELDS.email in formErrors ||
      SIGN_UP_FIELDS.emailConfirmation in formErrors;

    if (email && emailConfirmationValue && !hasError) {
      mixpanel.track(TRACKING_EVENTS.fieldFilled, {
        name: TRACKING_NAMES.email,
      });
    }
  }, [email, emailConfirmationValue]);

  return (
    <Fade>
      <Layout
        variant="center"
        onClose={handleSignOut}
        images={configuration.images}
      >
        <div className={styles.container} id={AUTH_IDS.viewSignup}>
          <FormProvider {...formMethods}>
            <form
              className={styles.form}
              onSubmit={handleSubmit(submitCallback)}
              noValidate
            >
              <div className={styles.logo}>
                <LogoMarks
                  variant="dark"
                  darkLogo={configuration.images?.darkLogo}
                />
              </div>
              <div className={styles.title}>
                <Typography
                  variant={/* istanbul ignore next */ isSm ? 'h5' : 'h2'}
                  weight="semibold"
                >
                  {t(auth.signUp)}
                </Typography>
              </div>
              <Input
                id="email"
                widthSize="fluid"
                label={t(inputs.email)}
                type="email"
                placeholder=""
                inputMode="email"
                status={formErrors?.email ? 'error' : undefined}
                style={{ textTransform: 'lowercase' }}
                hintText={
                  formErrors?.email
                    ? t(formErrors.email.message as string)
                    : undefined
                }
                {...register(SIGN_UP_FIELDS.email, {
                  ...formValidations.email,
                  onChange: (e) => setEmail(e.target.value.toLowerCase()),
                })}
              />
              <div className={styles.space} />
              <Input
                id="emailConfirmation"
                widthSize="fluid"
                label={t(inputs.emailConfirmation)}
                type="email"
                placeholder=""
                inputMode="email"
                status={formErrors?.emailConfirmation ? 'error' : undefined}
                style={{ textTransform: 'lowercase' }}
                hintText={
                  formErrors?.emailConfirmation
                    ? t(formErrors.emailConfirmation.message as string)
                    : undefined
                }
                {...register(SIGN_UP_FIELDS.emailConfirmation, {
                  ...formValidations.emailConfirmation,
                  validate: {
                    matchEmail: (value) =>
                      email === value.toLowerCase() ||
                      auth.emailConfirmationRequired,
                  },
                })}
              />
              <InputPassword id="input-password" email={email} />
              <PasswordPolicies />
              <div className={styles.checks}>
                <Checkbox
                  id="acceptPrivacyPolicy"
                  {...register(
                    SIGN_UP_FIELDS.privacyPolicy,
                    formValidations.privacyPolicy,
                  )}
                >
                  <div className={styles['privacy-policy']}>
                    <Typography variant="small">
                      {t(auth.acceptPrivacyPolicy)}
                    </Typography>{' '}
                    <ExternalLink
                      target="_blank"
                      href={
                        configuration?.urls?.privacyPolicy ||
                        `${legalDocsAuthBaseUrl}/aviso-de-privacidad.pdf`
                      }
                    >
                      <Typography variant="small" weight="semibold">
                        {t(links.privacyPolicy)}
                      </Typography>
                    </ExternalLink>{' '}
                    <Typography variant="small">{t(auth.andThe)}</Typography>{' '}
                    <ExternalLink
                      target="_blank"
                      href={
                        configuration?.urls?.jurisdiction ||
                        `${legalDocsAuthBaseUrl}/jurisdiccion-aplicable.pdf`
                      }
                    >
                      <Typography variant="small" weight="semibold">
                        {t(links.jurisdiction)}
                      </Typography>
                    </ExternalLink>
                  </div>
                </Checkbox>
                <Checkbox
                  id="openingAcceptance"
                  {...register(
                    SIGN_UP_FIELDS.openingAcceptance,
                    formValidations.openingAcceptance,
                  )}
                >
                  <div className={styles['opening-acceptance']}>
                    <Typography variant="small">
                      {t(auth.openingAcceptance)}
                    </Typography>{' '}
                    <Tooltip
                      contentChildren={[
                        { content: t(auth.openingAcceptanceDescription) },
                      ]}
                      position="bottom"
                    >
                      <ButtonLink type="button" underline="always">
                        <Typography variant="small" weight="semibold">
                          {t(links.openingAcceptance)}
                        </Typography>
                      </ButtonLink>
                    </Tooltip>
                  </div>
                </Checkbox>
              </div>
              <div className={styles.recaptcha}>
                <ReCAPTCHA
                  onChange={(token) => setRecaptchaValue(token)}
                  onExpired={() => setRecaptchaValue(null)}
                  sitekey={recaptchaKey}
                />
              </div>
              <div className={styles.button}>
                <Button
                  testId="signup-button"
                  disabled={!isEmpty(formErrors) || !recaptchaValue}
                  loading={isLoading}
                  kind="primary"
                  id={AUTH_IDS.signup}
                >
                  {t(buttons.register)}
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>
      </Layout>
    </Fade>
  );
}
