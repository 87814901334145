import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  useNotificationProvider,
  NOTIFICATION_TIMEOUT,
} from '@gbm/onboarding-sdk-ui-components';
import { AddressType } from '../../../api/opening-lite/types';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  getPartyAddress,
  savePartyAddress,
  updatePartyAddress,
  clearErrors,
  selectAddress,
  selectErrors,
} from '../../store/opening-lite';
import { OpeningLiteProviderProps } from '../../providers/opening-lite/types';
import { useParty } from '../useParty';
import { OpeningLiteStateType } from '../../store/opening-lite/types';
import { REDUCER_STATUS } from '../../store/types';

type UseAddressType = Pick<OpeningLiteStateType, 'address'> & {
  isLoading: boolean;
  isRejected: boolean;
  isFulfilled: boolean;
  isEmpty: boolean;
  fetch: () => void;
  save: (data: AddressType) => void;
  update: (data: AddressType) => void;
};

export default function useAddress(
  configuration: OpeningLiteProviderProps,
): UseAddressType {
  const dispatch = useAppDispatch();
  const { showNotification } = useNotificationProvider();
  const address = useAppSelector(selectAddress);
  const errors = useAppSelector(selectErrors);
  const { party } = useParty(configuration);
  const isLoading = address.status === REDUCER_STATUS.pending;
  const isRejected = address.status === REDUCER_STATUS.rejected;
  const isEmptyAddress = isEmpty(address.data);
  const isFulfilled =
    address.status === REDUCER_STATUS.resolved && !isEmptyAddress;

  const fetch = () => {
    if (isEmptyAddress && !isLoading) {
      dispatch(
        getPartyAddress({
          config: configuration,
          partyId: party.data.party_id,
        }),
      );
    }
  };

  const save = (data: AddressType) => {
    dispatch(
      savePartyAddress({
        config: configuration,
        partyId: party.data.party_id,
        address: data,
      }),
    );
  };

  const update = (data: AddressType) => {
    dispatch(
      updatePartyAddress({
        config: configuration,
        partyId: party.data.party_id,
        addressId: data.id as string,
        address: data,
      }),
    );
  };

  useEffect(() => {
    if (!isEmpty(errors.address)) {
      showNotification({
        description: errors.address?.message,
        kind: 'error',
      });
      setTimeout(() => {
        dispatch(clearErrors());
      }, NOTIFICATION_TIMEOUT);
    }
  }, [errors.address]);

  return {
    address,
    fetch,
    save,
    update,
    isLoading,
    isRejected,
    isFulfilled,
    isEmpty: isEmptyAddress,
  };
}
