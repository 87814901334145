/* eslint-disable no-param-reassign */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { ErrorType } from '../../../api/types';
import { REDUCER_STATUS } from '../types';

import {
  getOpeningStatus,
  getDataFromCurp,
  getCurpFromData,
  saveParty,
  getParty,
  getPartyCurp,
  savePartyPhone,
  updatePartyPhone,
  getPartyPhone,
  getCountries,
  getStates,
  savePartyAddress,
  updatePartyAddress,
  getPartyAddress,
  getDataFromZipCode,
  saveContract,
  getContract,
  getChallenge,
  getContractAccount,
} from './actions';
import { INITIAL_STATE, REDUCER_NAME, SavePartyErrorType } from './types';

export const OpeningLiteSlice = createSlice({
  initialState: { ...INITIAL_STATE },
  name: REDUCER_NAME,
  reducers: {
    clearDataFromCurp: (draft) => {
      draft.dataFromCurp.status = REDUCER_STATUS.idle;
      draft.dataFromCurp.data = {};
      draft.errors.dataFromCurp = {};
    },
    clearDataFromZipCode: (draft) => {
      draft.dataFromZipCode.status = REDUCER_STATUS.idle;
      draft.dataFromZipCode.data = {};
      draft.errors.dataFromZipCode = {};
    },
    clearChallenge: (draft) => {
      draft.challenge.status = REDUCER_STATUS.idle;
      draft.challenge.data = {};
      draft.errors.challenge = {};
    },
    openingTypeReceived: (draft, { payload }) => {
      draft.party.data = {
        ...draft.party.data,
        opening_type: payload.openingType,
      };
    },
    clearErrors: (draft) => {
      draft.errors = { ...INITIAL_STATE.errors };
    },
    reset: () => ({ ...INITIAL_STATE }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOpeningStatus.pending, (draft) => {
        draft.openingStatus.status = REDUCER_STATUS.pending;
      })
      .addCase(getOpeningStatus.fulfilled, (draft, { payload }) => {
        draft.openingStatus.status = REDUCER_STATUS.resolved;
        draft.openingStatus.data = payload;
      })
      .addCase(getOpeningStatus.rejected, (draft, { payload }) => {
        draft.openingStatus.status = REDUCER_STATUS.rejected;
        draft.errors.openingStatus = payload as ErrorType;
      })

      .addCase(getDataFromCurp.pending, (draft) => {
        draft.dataFromCurp.status = REDUCER_STATUS.pending;
      })
      .addCase(getDataFromCurp.fulfilled, (draft, { payload }) => {
        draft.dataFromCurp.status = REDUCER_STATUS.resolved;
        draft.dataFromCurp.data = payload;
      })
      .addCase(getDataFromCurp.rejected, (draft, { payload }) => {
        draft.dataFromCurp.status = REDUCER_STATUS.rejected;
        draft.errors.dataFromCurp = payload as ErrorType;
      })

      .addCase(getCountries.pending, (draft) => {
        draft.countries.status = REDUCER_STATUS.pending;
      })
      .addCase(getCountries.fulfilled, (draft, { payload }) => {
        draft.countries.status = REDUCER_STATUS.resolved;
        draft.countries.data = payload;
      })
      .addCase(getCountries.rejected, (draft, { payload }) => {
        draft.countries.status = REDUCER_STATUS.rejected;
        draft.errors.countries = payload as ErrorType;
      })

      .addCase(getStates.pending, (draft) => {
        draft.states.status = REDUCER_STATUS.pending;
      })
      .addCase(getStates.fulfilled, (draft, { payload }) => {
        draft.states.status = REDUCER_STATUS.resolved;
        draft.states.data = payload;
      })
      .addCase(getStates.rejected, (draft, { payload }) => {
        draft.states.status = REDUCER_STATUS.rejected;
        draft.errors.states = payload as ErrorType;
      })

      .addCase(getDataFromZipCode.pending, (draft) => {
        draft.errors.dataFromZipCode = {};
        draft.dataFromZipCode.status = REDUCER_STATUS.pending;
        draft.dataFromZipCode.data = {};
      })
      .addCase(getDataFromZipCode.fulfilled, (draft, { payload }) => {
        draft.dataFromZipCode.status = REDUCER_STATUS.resolved;
        draft.dataFromZipCode.data = payload;
      })
      .addCase(getDataFromZipCode.rejected, (draft, { payload }) => {
        draft.dataFromZipCode.status = REDUCER_STATUS.rejected;
        draft.errors.dataFromZipCode = payload as ErrorType;
        draft.dataFromZipCode.data = {};
      })

      .addCase(getChallenge.pending, (draft) => {
        draft.challenge.status = REDUCER_STATUS.pending;
        draft.challenge.data = {};
      })
      .addCase(getChallenge.fulfilled, (draft, { payload }) => {
        draft.challenge.status = REDUCER_STATUS.resolved;
        draft.challenge.data = payload;
      })
      .addCase(getChallenge.rejected, (draft, { payload }) => {
        draft.challenge.status = REDUCER_STATUS.rejected;
        draft.errors.challenge = payload as ErrorType;
      });

    builder
      .addMatcher(
        isAnyOf(getCurpFromData.pending, getPartyCurp.pending),
        (draft) => {
          draft.curp.status = REDUCER_STATUS.pending;
          draft.curp.data = {};
        },
      )
      .addMatcher(
        isAnyOf(getCurpFromData.fulfilled, getPartyCurp.fulfilled),
        (draft, { payload }) => {
          draft.curp.status = REDUCER_STATUS.resolved;
          draft.curp.data = payload;
        },
      )
      .addMatcher(
        isAnyOf(getCurpFromData.rejected, getPartyCurp.rejected),
        (draft, { payload }) => {
          draft.curp.status = REDUCER_STATUS.rejected;
          draft.errors.curp = payload as ErrorType;
        },
      )

      .addMatcher(isAnyOf(saveParty.pending, getParty.pending), (draft) => {
        draft.party.status = REDUCER_STATUS.pending;
      })
      .addMatcher(
        isAnyOf(saveParty.fulfilled, getParty.fulfilled),
        (draft, { payload }) => {
          draft.party.status = REDUCER_STATUS.resolved;
          draft.party.data = payload;
        },
      )
      .addMatcher(
        isAnyOf(saveParty.rejected, getParty.rejected),
        (draft, { payload }) => {
          draft.party.status = REDUCER_STATUS.rejected;
          const { err, alreadyRegistered } = payload as SavePartyErrorType;

          if (alreadyRegistered) {
            draft.alreadyRegistered = err as ErrorType;
          } else {
            draft.errors.party = err as ErrorType;
          }
        },
      )

      .addMatcher(
        isAnyOf(
          savePartyPhone.pending,
          updatePartyPhone.pending,
          getPartyPhone.pending,
        ),
        (draft) => {
          draft.telephone.status = REDUCER_STATUS.pending;
        },
      )
      .addMatcher(
        isAnyOf(
          savePartyPhone.fulfilled,
          updatePartyPhone.fulfilled,
          getPartyPhone.fulfilled,
        ),
        (draft, { payload }) => {
          draft.telephone.status = REDUCER_STATUS.resolved;
          draft.telephone.data = payload;
        },
      )
      .addMatcher(
        isAnyOf(
          savePartyPhone.rejected,
          updatePartyPhone.rejected,
          getPartyPhone.rejected,
        ),
        (draft, { payload }) => {
          draft.telephone.status = REDUCER_STATUS.rejected;
          draft.errors.telephone = payload as ErrorType;
        },
      )

      .addMatcher(
        isAnyOf(
          savePartyAddress.pending,
          updatePartyAddress.pending,
          getPartyAddress.pending,
        ),
        (draft) => {
          draft.address.status = REDUCER_STATUS.pending;
        },
      )
      .addMatcher(
        isAnyOf(
          savePartyAddress.fulfilled,
          updatePartyAddress.fulfilled,
          getPartyAddress.fulfilled,
        ),
        (draft, { payload }) => {
          draft.address.status = REDUCER_STATUS.resolved;
          draft.address.data = payload;
        },
      )
      .addMatcher(
        isAnyOf(
          savePartyAddress.rejected,
          updatePartyAddress.rejected,
          getPartyAddress.rejected,
        ),
        (draft, { payload }) => {
          draft.address.status = REDUCER_STATUS.rejected;
          draft.errors.address = payload as ErrorType;
        },
      )

      .addMatcher(
        isAnyOf(saveContract.pending, getContract.pending),
        (draft) => {
          draft.contract.status = REDUCER_STATUS.pending;
        },
      )
      .addMatcher(
        isAnyOf(saveContract.fulfilled, getContract.fulfilled),
        (draft, { payload }) => {
          draft.contract.status = REDUCER_STATUS.resolved;
          draft.contract.data = payload;
        },
      )
      .addMatcher(
        isAnyOf(saveContract.rejected, getContract.rejected),
        (draft, { payload }) => {
          draft.contract.status = REDUCER_STATUS.rejected;
          draft.errors.contract = payload as ErrorType;
        },
      )

      .addMatcher(isAnyOf(getContractAccount.pending), (draft) => {
        draft.account.status = REDUCER_STATUS.pending;
      })
      .addMatcher(
        isAnyOf(getContractAccount.fulfilled),
        (draft, { payload }) => {
          draft.account.status = REDUCER_STATUS.resolved;
          draft.account.data = payload;
        },
      )
      .addMatcher(
        isAnyOf(getContractAccount.rejected),
        (draft, { payload }) => {
          draft.account.status = REDUCER_STATUS.rejected;
          draft.errors.account = payload as ErrorType;
        },
      );
  },
});

export const {
  clearDataFromCurp,
  clearDataFromZipCode,
  clearChallenge,
  openingTypeReceived,
  clearErrors,
  reset,
} = OpeningLiteSlice.actions;

export default OpeningLiteSlice.reducer;
