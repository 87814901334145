import { RegisterOptions } from 'react-hook-form';

import { translations } from '@gbm/onboarding-sdk-utils';
import {
  hasUpperCase,
  hasLowerCase,
  hasSpecialSymbol,
  hasNumber,
} from '../../../../utils/helpers';

const passwordValidations: RegisterOptions = {
  required: translations.auth.passwordRequired,
  validate: {
    hasUpperCase,
    hasLowerCase,
    hasSpecialSymbol,
    hasNumber,
    minLength: (value) => value.length >= 12,
  },
};

export default {
  password: passwordValidations,
} as const;
