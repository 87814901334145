import { ArrowCircleRight, Button } from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';

import { FullAlert } from '@gbm/onboarding-sdk-ui-components';
import { LITE_IDS, translations } from '@gbm/onboarding-sdk-utils';
import { useBreakpoints } from '@gbm/onboarding-sdk-hooks';
import { useOpeningLiteProvider } from '../../../providers/opening-lite';
import { useAppSelector } from '../../../store';
import { selectAlreadyRegistered } from '../../../store/opening-lite';
import styles from './contract-creation.module.scss';

const {
  buttons,
  openingLite: { contractStatus },
} = translations;

export default function AlreadyRegistered() {
  const { t } = useTranslation();
  const { isSm } = useBreakpoints();
  const alreadyRegistered = useAppSelector(selectAlreadyRegistered);
  const { handleSignOut, configuration } = useOpeningLiteProvider();

  return (
    <div id={LITE_IDS.viewAlreadyRegistered}>
      <FullAlert
        actions={
          <Button
            size={isSm ? 'fluid' : 'default'}
            kind="primary"
            onClick={handleSignOut}
            icon={<ArrowCircleRight size={styles['icon-size']} weight="fill" />}
          >
            {t(buttons.signIn)}
          </Button>
        }
        title={t(contractStatus.alreadyRegistered)}
        description={
          <>
            {alreadyRegistered.message}.{' '}
            {t(contractStatus.alreadyRegisteredHelp)}
          </>
        }
        environment={configuration.environment}
      />
    </div>
  );
}
