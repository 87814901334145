import { MapPin, Typography, Button, ButtonLink } from '@gbm/starman-next';
import { Trans, useTranslation } from 'react-i18next';

import { ENVIRONMENTS, i18n, translations } from '@gbm/onboarding-sdk-utils';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalProps,
} from '../modal';
import { Chat } from '../chat';
import styles from './geolocation.module.scss';

export type RequiredProps = Omit<ModalProps, 'children' | 'fullScreen'> & {
  onContinue: () => void;
  onExit: () => void;
};

const {
  buttons,
  links,
  base: {
    geolocation: { required },
  },
} = translations;

export default function Required({
  onContinue,
  onExit,
  ...modalProps
}: RequiredProps) {
  const { t } = useTranslation(undefined, { i18n });

  return (
    <Modal fullScreen {...modalProps}>
      <ModalHeader onClose={onExit} />
      <ModalContent contentClassName={styles.content}>
        <div className={styles.required}>
          <MapPin size={40} />
          <div className={styles['required-title']}>
            <Typography variant="h3">{t(required.title)}</Typography>
          </div>
          <div className={styles['required-description']}>
            <Typography variant="body2">
              <Trans
                i18n={i18n}
                i18nKey={required.description}
                components={[<strong></strong>]}
              />
            </Typography>
          </div>
          <div className={styles['required-question']}>
            <Typography variant="body2">
              <Trans
                i18n={i18n}
                i18nKey={required.questions}
                components={[<strong></strong>]}
              />
            </Typography>
          </div>
          <div className={styles['required-buttons']}>
            <Button kind="primary" onClick={onContinue} testId="continueButton">
              {t(buttons.continue)}
            </Button>
          </div>
          <div className={styles['required-buttons--exit']}>
            <ButtonLink
              underline="always"
              onClick={onExit}
              data-testid="closeLink"
            >
              <Typography variant="body1">{t(links.exit)}</Typography>
            </ButtonLink>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div className={styles['required-chat']}>
          <Chat environment={ENVIRONMENTS.production} />
        </div>
      </ModalFooter>
    </Modal>
  );
}
