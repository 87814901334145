import { Button, Typography } from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';

import { i18n, translations } from '@gbm/onboarding-sdk-utils';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalProps,
} from '../modal';
import styles from './geolocation.module.scss';

export type GeolocationEnableStepsProps = Omit<ModalProps, 'children'> & {
  onClick: () => void;
};

const {
  buttons,
  base: { geolocation },
} = translations;

export default function GeolocationEnableSteps({
  onClick,
  ...modalProps
}: GeolocationEnableStepsProps) {
  const { t } = useTranslation(undefined, { i18n });

  return (
    <Modal {...modalProps}>
      <ModalHeader title={t(geolocation.title)} onClose={onClick} />
      <ModalContent>
        <div className={styles.text}>
          <Typography variant="h5">{t(geolocation.promptTitle)}</Typography>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button kind="primary" size="fluid" onClick={onClick}>
          {t(buttons.ready)}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
