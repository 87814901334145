import {
  ArrowCircleRight,
  Button,
  Prohibit,
  Typography,
} from '@gbm/starman-next';
import { Trans, useTranslation } from 'react-i18next';

import { translations } from '@gbm/onboarding-sdk-utils';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@gbm/onboarding-sdk-ui-components';
import styles from './initial-screen.module.scss';

export type ReachedAttemptsProps = {
  onClose: () => void;
  time: string;
};
const {
  buttons,
  upgrade: { contractStatus },
} = translations;

export default function ReachedAttempts({
  onClose,
  time,
}: ReachedAttemptsProps) {
  const { t } = useTranslation();

  return (
    <Modal show>
      <ModalHeader onClose={onClose} />
      <ModalContent>
        <div className={styles['reached-attempts-icon']}>
          <Prohibit
            size={styles['icon-size']}
            color={styles['icon-color--error']}
          />
        </div>
        <div className={styles['reached-attempts-title']}>
          <Typography variant="h5">
            {t(contractStatus.reachedAttemptsTitle)}
          </Typography>
        </div>
        <div className={styles['reached-attempts-description']}>
          <Typography variant="small">
            <Trans
              i18nKey={contractStatus.reachedAttemptsDescription}
              values={{ time }}
              components={[<strong></strong>]}
            />
          </Typography>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          size="fluid"
          kind="primary"
          onClick={onClose}
          icon={<ArrowCircleRight size={styles['icon-size']} weight="fill" />}
        >
          {t(buttons.understood)}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
