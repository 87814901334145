import { useTranslation } from 'react-i18next';

import { FormLayout, FormLayoutProps } from '@gbm/onboarding-sdk-ui-components';
import { translations } from '@gbm/onboarding-sdk-utils';
import { useBreakpoints } from '@gbm/onboarding-sdk-hooks';
import { useOpeningLiteProvider } from '../../../providers/opening-lite';

export type OpeningLayoutProps = Omit<FormLayoutProps, 'steps' | 'onClose'> & {
  onClose?: () => void;
};

const { openingLite } = translations;

export default function OpeningLayout({
  children,
  onClose,
  current,
}: OpeningLayoutProps) {
  const { t } = useTranslation();
  const { isSm, isMd } = useBreakpoints();
  const { handleSignOut } = useOpeningLiteProvider();
  const steps = [
    {
      key: 'step-personal-info',
      title: t(openingLite.steps.personalInfo),
    },
    {
      key: 'step-address',
      title: t(openingLite.steps.address),
    },
    {
      key: 'step-confirmation',
      title: t(openingLite.steps.confirmation),
    },
  ];

  const handleOnClose = () => {
    if (onClose) {
      onClose();
    } else {
      handleSignOut();
    }
  };

  return (
    <FormLayout
      steps={steps}
      current={current}
      onClose={handleOnClose}
      displayTitles={!isSm}
      stepWidthSize={isSm || isMd ? 'small' : 'default'}
    >
      {children}
    </FormLayout>
  );
}
