import { ReactNode } from 'react';

export const ENVIRONMENTS = {
  development: 'dev',
  production: 'prd',
} as const;

export type Environment = Values<typeof ENVIRONMENTS>;

export type Values<T> = T[keyof T];
export type Keys<T> = keyof T;

export type ApiType = {
  v1: string;
  v2?: string;
  v3?: string;
};

type AuthEnvironmentType = {
  apiUrl: ApiType;
};

type OnboardingEnvironmentType = {
  apiUrl: ApiType;
};

type EnvironmentType = {
  auth: AuthEnvironmentType;
  env: Environment;
  legalDocsBaseUrl: string;
  legalDocsAuthBaseUrl: string;
  academyUrl: string;
  blogUrl: string;
  helpEmail: string;
  recaptchaKey: string;
  mixpanelApiKey: string;
  segmentApiKey: string;
  onboarding: OnboardingEnvironmentType;
};

export type ConfigType = {
  dev: EnvironmentType;
  prd: EnvironmentType;
};

export type AuthUserType = {
  accessToken?: string;
  expiresIn?: number;
  identityToken?: string;
  refreshToken?: string;
  tokenType?: string;
  email: string;
  contract?: Record<string, any>;
  account?: Record<string, any>;
};

export const DEFAULT_COUNTRY = {
  key: 'MEX',
  label: 'México',
  value: 'MEX',
} as const;
export const DEFAULT_COUNTRY_PHONE_CODE = '52';
export const PHONE_LENGTH = 10;
export const ZIP_CODE_LENGTH = 5;
export const PHONE_MASK = '99 9999 9999';
export const BIRTH_DATE_MASK = '99 / 99 / 9999';
export const BIRTH_DATE_LENGTH = 10;
export const ZIP_CODE_MASK = '99999';
export const EMPTY_PARAM = 'N/A';
export const EMPTY_FIELD_PLACEHOLDER = '-';

/* eslint-disable no-control-regex */
export const REGEX_VALIDATORS = {
  curp: /^([a-zA-ZñÑ]{4})(\d{2})((0[1-9]{1})|(1[0-2]{1}))(([0-2]{1}\d{1})|(3{1}[0-1]{1}))([hHmM]{1})([a-zA-Z]{5})([a-zA-ZñÑ0-9]{1})(\d{1})$/,
  email:
    /^((([a-z]|\d|[!#$%&'*+-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/,
  rfc: /^[a-zA-Z]{3,4}(\d{2})(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])([0-9A-Z]{3})$/,
  rfcWithoutHomoclave:
    /^[a-zA-Z]{3,4}(\d{2})(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])$/,
  homoclave: /^(?:- ?)?([A-Z\d]{2})([A\d])$/,
  fiel: /^(?![0]{20}).*(\d{20})$/,
  clabe: /^(?![0]{18}).*(\d{18})$/,
  tin: /^(9\d{2})(7\d|8[0-8])(\d{4})$/,
  digit: /\d/,
  numbers: /^\d*$/,
  upperCase: /[A-Z]/,
  lowerCase: /[a-z]/,
  specialChar: /^(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])\S/,
  repeatedElements: /(.)\1{2}/,
  gbm: /GBM/,
  sequentialElements:
    /(012)|(123)|(234)|(345)|(456)|(567)|(678)|(789)|(890)|(098)|(987)|(876)|(765)|(654)|(543)|(432)|(321)|(210)|(ABC)|(BCD)|(CDE)|(DEF)|(EFG)|(FGH)|(GHI)|(HIJ)|(IJK)|(JKL)|(KLM)|(LMN)|(MNO)|(NOP)|(OPQ)|(PQR)|(QRS)|(RST)|(STU)|(TUV)|(UVW)|(VWX)|(WXY)|(XYZ)|(ZYX)|(YXW)|(XWV)|(WVU)|(VUT)|(UTS)|(TSR)|(SRQ)|(RQP)|(QPO)|(PON)|(ONM)|(NML)|(MLK)|(LKJ)|(KJI)|(JIH)|(IHG)|(HGF)|(GFE)|(FED)|(EDC)|(DCB)|(CBA)/,
  passwordPolicy:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])(?!.*[^a-zA-z0-9=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])\S{12,99}$/,
  dateFormat: /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
  replaceLetters: /^\s+|\s+$|[$,a-zA-Z]/g,
  pep: /^[ A-Z0-9\u00E0-\u00FC.¨/´-]*$/i,
};

export const GENERIC_ERROR =
  'Ocurrió un error al procesar la información. Por favor inténtalo más tarde';

export const PLATFORM_CAPTCHA = {
  alpha: 'web',
  numeric: 2,
} as const;

export const DATE_FORMATS = {
  yyyyMMdd: 'yyyy-MM-dd',
  ddMMyyyy: 'dd/MM/yyyy',
  ddMMyyyyWithDash: 'dd-MM-yyyy',
  yyyyMMddWithDash: 'yyyy-MM-dd',
  MMddyyyy: 'MM/dd/yyyy',
  dd: 'dd',
  MM: 'MM',
  yyyy: 'yyyy',
};
export type DateFormatType = Values<typeof DATE_FORMATS>;

export const LEGAL_DOCUMENTS_PREFIX = 'opening_user';
export const LEGAL_DOCUMENT_TYPES = {
  termsAndConditions: 'terms_and_conditions',
  noPresentialOpeningConfirmation: 'no_presential_opening_confirmation',
  personalIdDataVerificationUpgrade:
    'opening_black_personal_id_data_verification',
  executionService: 'execution_service',
};
export type LegalDocumentTypeValues = Values<typeof LEGAL_DOCUMENT_TYPES>;
export type LegalDocumentType = {
  id: string;
  type: LegalDocumentTypeValues;
  version: number;
  uri: string;
  is_latest: boolean;
  file_name?: string;
  data?: ReactNode;
};

export const PRIVACY_POLICY_DOCUMENT_NAME = 'Aviso_de_Privacidad_v1';

export const GEOLOCATION_PERMISSIONS_STATUSES = {
  denied: 'denied',
  granted: 'granted',
  prompt: 'prompt',
};
export type GeolocationPermissionStatusType = Values<
  typeof GEOLOCATION_PERMISSIONS_STATUSES
>;

export const MFA_PREFERENCE = {
  disabled: 'Disabled',
  totp: 'TOTP',
};
export type MfaPreferenceType = Values<typeof MFA_PREFERENCE>;

export const MASK_PATTERNS = {
  clabe: '### ### ########### #',
  rfc: '########## ###',
  phone: '## #### ####',
  date: '## # ## # ####',
};

export const CODES_STATES = {
  Aguascalientes: 'AS',
  'Baja California': 'BC',
  'Baja California Sur': 'BS',
  Campeche: 'CC',
  'Coahuila de Zaragoza': 'CL',
  Colima: 'CM',
  Chiapas: 'CS',
  Chihuahua: 'CH',
  'Ciudad de México': 'DF',
  'Ciudad de Mexico': 'DF',
  Durango: 'DG',
  Guanajuato: 'GT',
  Guerrero: 'GR',
  Hidalgo: 'HG',
  Jalisco: 'JC',
  Mexico: 'MC',
  México: 'MC',
  'Estado de México': 'MC',
  'Estado de Mexico': 'MC',
  'Michoacán de Ocampo': 'MN',
  'Michoacan de Ocampo': 'MN',
  Morelos: 'MS',
  Nayarit: 'NT',
  'Nuevo Leon': 'NL',
  'Nuevo León': 'NL',
  Oaxaca: 'OC',
  Puebla: 'PL',
  Queretaro: 'QT',
  Querétaro: 'QT',
  'Quintana Roo': 'QR',
  'San Luis Potosi': 'SP',
  'San Luis Potosí': 'SP',
  Sinaloa: 'SL',
  Sonora: 'SR',
  Tabasco: 'TC',
  Tamaulipas: 'TS',
  Tlaxcala: 'TL',
  'Veracruz de Ignacio de la Llave': 'VZ',
  Yucatan: 'YN',
  Yucatán: 'YN',
  Zacatecas: 'ZS',
} as const;

export type CodeStatesKey = Keys<typeof CODES_STATES>;

export type MockableFunction = (...args: any[]) => any;

export const formError = 'error';

export const RFC_LENGTH = 10;

export const HOMOCLAVE_LENGTH = 3;

export type DocumentsType = {
  document_type: string;
  document_status: string;
  document_rejection_reason?: string;
  latest: boolean;
  rejection_message?: string;
};

export const IMAGE_MIN_WIDTH_IN_PIXELS = 300;
export const IMAGE_MAX_WIDTH_IN_PIXELS = 1024;
export const IMAGE_MAX_HEIGHT_IN_PIXELS = 1024;
export const IMAGES = 'image/jpeg, image/png, image/jpg';
export const IMAGES_PDF = 'image/jpeg, image/png, image/jpg, application/pdf';
export const IMAGE_QUALITY_COMPRESSION = 70;
export const MAX_FILE_SIZE = 3670016;
export const LOREM_IMAGE_SIZE_IN_PIXELS = 1250;

export const DOCUMENT_UPLOAD_STATUSES = {
  notSet: 'not_set',
  optimizing: 'optimizing',
  uploading: 'uploading',
  uploaded: 'uploaded',
  sizeExceed: 'size_exceed',
  networkError: 'network_error',
} as const;
export type DocumentUploadStatusType = Values<typeof DOCUMENT_UPLOAD_STATUSES>;

export type CompressImageCallbackProps = {
  file: Blob;
  width?: number;
  height?: number;
  extension?: string;
};

export const AGE_RANGE = {
  default: 0,
  min: 18,
  max: 100,
} as const;

export const OPENING_TYPE = {
  lite: 'light',
  liteForTracking: 'lite', // For tracking events
  black: 'black',
} as const;

export type OpeningType = Values<typeof OPENING_TYPE>;

export const PLATFORM = {
  android: 'Android',
  ios: 'iOS',
  web: 'Browser',
} as const;

export type PlatformType = Values<typeof PLATFORM>;
