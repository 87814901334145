import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../types';
import { INITIAL_STATE } from './types';

const selectSlice = (state: RootState) => state.openingLite || INITIAL_STATE;

export const selectOpeningStatus = createSelector(
  selectSlice,
  (state) => state.openingStatus,
);

export const selectCountries = createSelector(
  selectSlice,
  (state) => state.countries,
);

export const selectStates = createSelector(
  selectSlice,
  (state) => state.states,
);

export const selectContract = createSelector(
  selectSlice,
  (state) => state.contract,
);

export const selectCurp = createSelector(selectSlice, (state) => state.curp);

export const selectDataFromCurp = createSelector(
  selectSlice,
  (state) => state.dataFromCurp,
);

export const selectParty = createSelector(selectSlice, (state) => state.party);

export const selectAddress = createSelector(
  selectSlice,
  (state) => state.address,
);

export const selectPhone = createSelector(
  selectSlice,
  (state) => state.telephone,
);

export const selectDataFromZipCode = createSelector(
  selectSlice,
  (state) => state.dataFromZipCode,
);

export const selectChallenge = createSelector(
  selectSlice,
  (state) => state.challenge,
);

export const selectAlreadyRegistered = createSelector(
  selectSlice,
  (state) => state.alreadyRegistered,
);

export const selectAccount = createSelector(
  selectSlice,
  (state) => state.account,
);

export const selectErrors = createSelector(
  selectSlice,
  (state) => state.errors,
);
