import { getErrorMessage } from '../helpers';

import {
  TRACKING_DEFAULT_VALUES,
  TrackingResponse,
  TrackingCode,
} from './types';

export const makeTrackingErrorProps = (
  err: TrackingResponse & TrackingCode,
  headers: Record<string, any>,
) => ({
  success: false,
  code:
    err?.code ||
    err?.response?.data?.error?.code ||
    TRACKING_DEFAULT_VALUES.emptyParam,
  event_id:
    err?.event_id ||
    err?.response?.data?.error?.event_id ||
    TRACKING_DEFAULT_VALUES.emptyParam,
  message: getErrorMessage((err?.message && err) || err?.response),
  request_id:
    headers?.['x-amzn-requestid'] || TRACKING_DEFAULT_VALUES.emptyParam,
  target:
    err?.target ||
    err?.response?.data?.error?.target ||
    TRACKING_DEFAULT_VALUES.emptyParam,
});
