import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, Button } from '@gbm/starman-next';

import { FullAlert } from '@gbm/onboarding-sdk-ui-components';
import {
  LITE_IDS,
  translations,
  TRACKING_EVENTS,
  TRACKING_NAMES,
} from '@gbm/onboarding-sdk-utils';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import { useOpeningLiteProvider } from '../../../providers/opening-lite';
import styles from './contract-creation.module.scss';

const {
  buttons,
  openingLite: { contractStatus },
} = translations;

export default function Pld() {
  const { t } = useTranslation();
  const { configuration, handleSignOut } = useOpeningLiteProvider();
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  useEffect(() => {
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.pldError,
    });
  }, []);

  return (
    <div id={LITE_IDS.viewPld}>
      <FullAlert
        kind="error"
        description={t(contractStatus.informationPldDescriptionError)}
        title={t(contractStatus.informationPldTitleError)}
        environment={configuration.environment}
        actionsEnd={false}
        actions={
          <Button
            size="small"
            kind="secondary"
            icon={<ArrowLeft size={styles['icon-size']} />}
            iconAlignment="left"
            onClick={handleSignOut}
          >
            {t(buttons.goBack)}
          </Button>
        }
      />
    </div>
  );
}
