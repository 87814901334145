export const SIGN_UP_FIELDS = {
  email: 'email',
  emailConfirmation: 'emailConfirmation',
  password: 'password',
  privacyPolicy: 'privacyPolicy',
  openingAcceptance: 'openingAcceptance',
} as const;

export type FormValues = {
  [SIGN_UP_FIELDS.email]: string;
  [SIGN_UP_FIELDS.emailConfirmation]: string;
  [SIGN_UP_FIELDS.password]: string;
  [SIGN_UP_FIELDS.privacyPolicy]: boolean;
  [SIGN_UP_FIELDS.openingAcceptance]: boolean;
};
