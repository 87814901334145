/* eslint-disable no-restricted-globals */
import axios from 'axios';

import { Environment } from '../types';
import { config } from '../config';

import { UJET_MENU_KEYS } from './types';

export default class Ujet {
  info = {
    timeOpened: {},
    timezone: {},
    pageOn: {},
    referrer: {},
    previousSites: {},
    browserName: {},
    browserEngine: {},
    browserVersion1a: {},
    browserVersion1b: {},
    browserLanguage: {},
    browserLanguages: {},
    browserOnline: {},
    browserPlatform: {},
    javaEnabled: {},
    dataCookiesEnabled: {},
    dataCookies1: {},
    dataCookies2: {},
    dataStorage: {},
    sizeScreenW: {},
    sizeScreenH: {},
    sizeInW: {},
    sizeInH: {},
    sizeAvailW: {},
    sizeAvailH: {},
    screenResolution: {},
    scrColorDepth: {},
    scrPixelDepth: {},
    os: {},
    browser: {},
    device: {},
  };

  menuKey = '';

  constructor() {
    const todayDate = new Date();

    this.info.timeOpened = { label: 'timeOpened', value: todayDate };
    this.info.timezone = {
      label: 'timezone',
      value: todayDate.getTimezoneOffset() / 60,
    };
    this.info.pageOn = { label: 'pageOn', value: window.location.pathname };
    this.info.referrer = {
      label: 'referrer',
      value: document.referrer || 'N/A',
    };
    this.info.previousSites = { label: 'previousSites', value: history.length };
    this.info.browserName = { label: 'browserName', value: navigator.appName };
    this.info.browserEngine = {
      label: 'browserEngine',
      value: navigator.product,
    };
    this.info.browserVersion1a = {
      label: 'browserVersion1a',
      value: navigator.appVersion,
    };
    this.info.browserVersion1b = {
      label: 'browserVersion1b',
      value: navigator.userAgent,
    };
    this.info.browserLanguage = {
      label: 'browserLanguage',
      value: navigator.language,
    };
    this.info.browserLanguages = {
      label: 'browserLanguages',
      value: navigator.languages,
    };
    this.info.browserOnline = {
      label: 'browserOnline',
      value: navigator.onLine,
    };
    this.info.browserPlatform = {
      label: 'browserPlatform',
      value: navigator.platform,
    };
    this.info.javaEnabled = {
      label: 'javaEnabled',
      value: navigator.javaEnabled(),
    };
    this.info.dataCookiesEnabled = {
      label: 'dataCookiesEnabled',
      value: navigator.cookieEnabled,
    };
    this.info.dataCookies1 = { label: 'dataCookies1', value: document.cookie };
    this.info.dataCookies2 = {
      label: 'dataCookies2',
      value: decodeURIComponent(document.cookie),
    };
    this.info.dataStorage = { label: 'dataStorage', value: localStorage };
    this.info.sizeScreenW = { label: 'sizeScreenW', value: screen.width };
    this.info.sizeScreenH = { label: 'sizeScreenH', value: screen.height };
    this.info.sizeInW = { label: 'sizeInW', value: innerWidth };
    this.info.sizeInH = { label: 'sizeInH', value: innerHeight };
    this.info.sizeAvailW = { label: 'sizeAvailW', value: screen.availWidth };
    this.info.sizeAvailH = { label: 'sizeAvailH', value: screen.availHeight };
    this.info.screenResolution = {
      label: 'screenResolution',
      value: `${screen.width} x ${screen.height}`,
    };
    this.info.scrColorDepth = {
      label: 'scrColorDepth',
      value: screen.colorDepth,
    };
    this.info.scrPixelDepth = {
      label: 'scrPixelDepth',
      value: screen.pixelDepth,
    };
    this.info.os = { label: 'os', value: this.getOSName() };
    this.info.browser = { label: 'browser', value: this.getBrowserName() };
    this.info.device = {
      label: 'device',
      value: this.isMobile() ? 'Mobile' : 'Desktop',
    };
  }

  getInfo = () => this.info;

  isMobile = () => /Mobi/.test(navigator.userAgent);

  getBrowserName() {
    if (window.chrome) {
      return 'Chrome';
    }

    // Opera 8.0+
    if (
      (window.opr && window.opr.addons) ||
      window.opera ||
      navigator.userAgent.indexOf(' OPR/') >= 0
    ) {
      return 'Opera';
    }

    // Firefox 1.0+
    if ('InstallTrigger' in window) {
      return 'Firefox';
    }

    // Safari 3.0+ "[object HTMLElementConstructor]"
    if (
      /constructor/i.test(window.HTMLElement) ||
      ((p) => p.toString() === '[object SafariRemoteNotification]')(
        !window.safari,
      )
    ) {
      return 'Safari';
    }

    // Internet Explorer 6-11
    if (/* @cc_on!@ */ document.DOCUMENT_NODE) {
      return 'Internet Explorer';
    }

    // Edge 20+
    if (window.styleMedia) {
      return 'Microsoft Edge';
    }

    return undefined;
  }

  iOSVersion() {
    if (/iP(hone|od|ad)/.test(navigator.appVersion)) {
      const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      return [
        parseInt(v?.[1] || '0', 10),
        parseInt(v?.[2] || '0', 10),
        parseInt(v?.[3] || '0', 10),
      ];
    }

    return undefined;
  }

  androidVersion = () =>
    /Android/.test(navigator.appVersion) &&
    navigator.appVersion.match(/Android (\d+).(\d+)/);

  getOSName = () => {
    const { userAgent } = navigator;
    let os;

    if (this.isMobile()) {
      if (/Windows/.test(userAgent)) {
        os = 'Windows';
        if (/Phone 8.0/.test(navigator.userAgent)) {
          os += ' Phone 8.0';
        } else if (/Phone 10.0/.test(userAgent)) {
          os += ' Phone 10.0';
        }
      } else if (/Android/.test(userAgent)) {
        os = this.androidVersion() || 'Android';
      } else if (/iPad;/.test(userAgent) || /iPhone;/.test(userAgent)) {
        const ver = this.iOSVersion();
        os = `iOS ${ver?.[0]}.${ver?.[1]}.${ver?.[2]}`;
      } else if (/BBd*/.test(userAgent)) {
        os = 'BlackBerry';
      }
    } else if (/Windows/.test(userAgent)) {
      os = 'Windows';
      if (/5.1;/.test(userAgent)) {
        os += ' XP';
      } else if (/6.0;/.test(userAgent)) {
        os += ' Vista';
      } else if (/6.1;/.test(userAgent)) {
        os += ' 7';
      } else if (/6.2/.test(userAgent)) {
        os += ' 8';
      } else if (/10.0;/.test(userAgent)) {
        os += ' 10';
      }

      if (/64/.test(userAgent)) {
        os += ' 64-bit';
      } else {
        os += ' 32-bit';
      }
    } else if (/Macintosh/.test(userAgent)) {
      os = 'Macintosh';
      if (/OS X/.test(userAgent)) {
        os += ' OS X';
      }
    }

    return os;
  };

  initializeChat = async (chatContainer: any, environment: Environment) => {
    const {
      onboarding: { apiUrl },
    } = config(environment);
    const { data } = await axios.get(`${apiUrl.v1}/ujet-token`);

    if (data) {
      this.authorizeChat(data, chatContainer);
      chatContainer.classList.add('hidden');
    }
  };

  authorizeChat = (data: any, chatContainer: any) => {
    const ujet = window.UJET;
    const browserDataObj = this.getInfo();

    if (ujet) {
      // eslint-disable-next-line new-cap
      const ujetInstance = new ujet({
        companyId: data.company_id,
        logo: 'https://gbm.com/assets/ujet/gbm-plus-logo-ujet.svg',
        right: '40px',
        bottom: '88px',
        lang: 'es',
        customData: browserDataObj,
        launcher: {
          right: '40px',
          bottom: '32px',
          cssText:
            '.wrap{width:48px!important;height:48px!important}.wrap *{width:48px!important;height:48px!important}.wrap img{top:20%;left:20%;}',
          chatIcon: 'https://gbm.com/assets/ujet/logo-ujet.svg',
          closeIcon: 'https://gbm.com/assets/ujet/logo-ujet.svg',
          style: {
            '--background-color': '#FFFFFF',
            '--icon-color': '#FFFFFF',
            width: '48px!important',
            height: '48px!important',
          },
        },
        style: {
          '--primary-color': '#000000',
          '--primary-font': '"Gilroy", sans-serif',
        },
      });

      ujetInstance.start({
        menuKey: this.menuKey,
      });

      ujetInstance.on('close', () => {
        ujetInstance.destroy();
        this.closeChat(chatContainer);
      });

      ujetInstance.on('created', () => {
        ujetInstance.authenticate(data.token);
      });
    }
  };

  closeChat(chatContainer: any) {
    chatContainer.classList.remove('hidden');
    chatContainer.blur();
  }

  startChat(environment: Environment, menuKey = UJET_MENU_KEYS.legacyMenu) {
    this.menuKey = menuKey;
    const chatContainer = document.getElementById('chat');
    this.initializeChat(chatContainer, environment);
  }
}
