import clsx from 'clsx';
import { ControlProps, GroupBase } from 'react-select';

import useSelectMultiple from './use-select-multiple';
import { SelectMultipleOption } from './types';
import styles from './select-multiple.module.scss';

export default function SelectMultipleControl({
  children,
  ...props
}: ControlProps<SelectMultipleOption, true, GroupBase<SelectMultipleOption>>) {
  const { hasError, readOnly } = useSelectMultiple();

  return (
    <div
      role="button"
      tabIndex={props.selectProps.tabIndex}
      className={clsx(styles.control, {
        [styles['control--error']]: hasError,
        [styles['control--readonly']]: readOnly,
      })}
      onClick={
        props.selectProps.menuIsOpen
          ? props.selectProps.onMenuClose
          : props.selectProps.onMenuOpen
      }
      onKeyDown={props.selectProps.onKeyDown}
      {...props}
    >
      {children}
    </div>
  );
}
