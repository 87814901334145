import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

import {
  Loader,
  NotificationProvider,
} from '@gbm/onboarding-sdk-ui-components';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import { i18n } from '@gbm/onboarding-sdk-utils';
import { store } from '../../store';
import { UpgradeProvider, useUpgradeProvider } from '../../providers/upgrade';
import { STEPS, UpgradeProps } from '../../providers/upgrade/types';
import { useLanguage } from '../../hooks';
import { OPENING_TYPE } from '../../providers/opening-lite/types';
import { InitialScreen } from './initial-screen';
import { Nationality } from './nationality';
import { TaxInformation } from './tax-information';
import { FinancialInformation } from './financial-information';
import { IdentityValidation } from './identity-validation';
import { ProofOfAddress } from './proof-of-address';
import { Resume } from './resume';
import { Pending, Reviewing, Success } from './contract-upgrade';

const stepScreen = {
  [STEPS.upgradeScreen]: <InitialScreen />,
  [STEPS.nationality]: <Nationality />,
  [STEPS.taxInformation]: <TaxInformation />,
  [STEPS.financialInformation]: <FinancialInformation />,
  [STEPS.ine]: <IdentityValidation />,
  [STEPS.proofOfAddress]: <ProofOfAddress />,
  [STEPS.confirmation]: <Resume />,
  [STEPS.pending]: <Pending />,
  [STEPS.reviewing]: <Reviewing />,
  [STEPS.success]: <Success />,
};

function Upgrade() {
  const { step, isLoading } = useUpgradeProvider();

  if (isLoading || step === STEPS.unknown) {
    return <Loader kind="light" />;
  }

  return stepScreen[step];
}

export default function WithProviders(props: UpgradeProps): JSX.Element {
  const { isLoading } = useLanguage(props.translations);
  const [trackingIsReady, setTrackingIsReady] = useState(false);
  const mixpanel = useMixpanel(props.environment, props.isEnabledTrackEvents);

  useEffect(() => {
    const initTracking = async () => {
      await mixpanel.init();
      mixpanel.identify(props.email);
      mixpanel.peopleSet({
        $email: props.email,
        $opening_type: OPENING_TYPE.lite,
      });
      if (props.platform) {
        mixpanel.registerPlatform(props.platform);
      }
      setTrackingIsReady(true);
    };

    initTracking();
  }, []);

  if (isLoading || !trackingIsReady) {
    return <Loader kind="light" />;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <NotificationProvider>
        <Provider store={store}>
          <UpgradeProvider {...props}>
            <Upgrade />
          </UpgradeProvider>
        </Provider>
      </NotificationProvider>
    </I18nextProvider>
  );
}
