import { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  Input,
  Button,
  SkeletonLoader,
  ArrowCircleRight,
  ButtonLink,
  Typography,
  PlusCircle,
} from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import {
  FormContent,
  FormFooter,
  FormRow,
  FormSection,
  SelectItem,
  useNotificationProvider,
} from '@gbm/onboarding-sdk-ui-components';
import {
  UPGRADE_IDS,
  GENERIC_ERROR,
  translations,
  TRACKING_EVENTS,
  TRACKING_NAMES,
} from '@gbm/onboarding-sdk-utils';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';
import { useAppSelector, useAppDispatch } from '../../../store';
import { formatCountriesToSave } from '../../../../utils/helpers';
import {
  updateCountriesBatch,
  selectNationalities,
  selectErrors,
} from '../../../store/upgrade';
import { UpgradeLayout } from '../../../components/upgrade';
import {
  useCountriesAndStates,
  useParty,
  useUpgradeStep,
} from '../../../hooks';
import { PartyCountryType } from '../../../../api/upgrade/types';
import { useUpgradeApi } from '../../../../api/upgrade/use-upgrade-api';
import { STEPS, UPGRADE_STEPS } from '../../../providers/upgrade/types';
import { REDUCER_STATUS } from '../../../store/types';
import {
  COUNTRY_TYPE,
  countryListType,
  CountryName,
} from '../../../store/upgrade/types';
import { useUpgradeProvider } from '../../../providers/upgrade';
import styles from './nationality.module.scss';

const {
  inputs,
  buttons,
  upgrade: { nationality },
} = translations;

const COUNTRY_LIMIT = 2;

export default function Nationality() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { configuration, setStep, isEditing, setIsEditing } =
    useUpgradeProvider();
  const { countries } = useCountriesAndStates(configuration);
  const { showNotification } = useNotificationProvider();
  const nationalitiesStore = useAppSelector(selectNationalities);
  const isFulfilled = nationalitiesStore.status === REDUCER_STATUS.resolved;
  const errors = useAppSelector(selectErrors);
  const upgradeApi = useUpgradeApi(configuration);
  const {
    party,
    isEmpty: isEmptyParty,
    isFulfilled: isFulfilledParty,
  } = useParty(configuration);
  const { saveUpgradeStep } = useUpgradeStep(configuration);
  const [userCountries, setUserCountries] = useState<PartyCountryType[] | []>(
    [],
  );
  const [nationalities, setNationalities] = useState<countryListType[] | []>(
    [],
  );
  const [citizenship, setCitizenship] = useState<countryListType[] | []>([]);
  const [isLoadingParty, setIsLoadingParty] = useState<boolean>(false);
  const [savingOrUpdatingCountries, setSavingOrUpdatingCountries] =
    useState(false);
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  const formatCountriesToList = (
    data: PartyCountryType[],
    list: countryListType[] | [],
    dropdownType: CountryName,
    setList: React.Dispatch<React.SetStateAction<[] | countryListType[]>>,
  ) => {
    setList(
      data.map((userCountryItem, index) => ({
        value: userCountryItem.country_code,
        children: countries.data.find(
          /* istanbul ignore next */
          (countryItem) => countryItem.value === userCountryItem.country_code,
        )?.label,
        key: `${dropdownType}-${index}`,
      })),
    );
  };

  const fetchPartyCountries = async () => {
    setIsLoadingParty(true);
    const response = await upgradeApi.getPartyCountries(party.data.party_id);
    setIsLoadingParty(false);
    /* istanbul ignore else */
    if (response.data) {
      setUserCountries(response.data);
      formatCountriesToList(
        response.data.filter(
          (countryItem: PartyCountryType) =>
            countryItem.country_type === COUNTRY_TYPE.nationality &&
            !countryItem.is_main_country,
        ),
        nationalities,
        COUNTRY_TYPE.nationality,
        setNationalities,
      );
      formatCountriesToList(
        response.data.filter(
          (countryItem: PartyCountryType) =>
            countryItem.country_type === COUNTRY_TYPE.citizenship &&
            !countryItem.is_main_country,
        ),
        citizenship,
        COUNTRY_TYPE.citizenship,
        setCitizenship,
      );
      return;
    }

    showNotification({
      description: GENERIC_ERROR,
      kind: 'error',
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.nationality,
      success: true,
    });
  }, []);

  useEffect(() => {
    /* istanbul ignore else */
    if (!isEmptyParty && isFulfilledParty) {
      fetchPartyCountries();
    }
  }, [isEmptyParty, isFulfilledParty]);

  useEffect(() => {
    /* istanbul ignore else */
    if (isFulfilled && savingOrUpdatingCountries) {
      mixpanel.track(TRACKING_EVENTS.screenFilled, {
        name: TRACKING_NAMES.nationality,
        success: true,
      });
      if (!isEditing) {
        saveUpgradeStep(UPGRADE_STEPS.taxInformation);
        setStep(STEPS.taxInformation);
      } else {
        handleCloseEdition();
      }
    }
  }, [isFulfilled]);

  useEffect(() => {
    /* istanbul ignore else */
    if (!isEmpty(errors.nationalities)) {
      showNotification({
        description: errors.nationalities?.message,
        kind: 'error',
      });
    }
  }, [errors.nationalities]);

  const getCountryValue = (inputType: CountryName) => {
    if (userCountries.length === 0) return '';

    const partyCountry = userCountries.find(
      (countryItem) => countryItem.country_type === inputType,
    );
    /* istanbul ignore else */
    if (partyCountry) {
      return (
        countries.data.find(
          (countryItem) => countryItem.value === partyCountry.country_code,
        )?.label || ''
      );
    }

    return '';
  };

  const countryOptions = (dropdownType: CountryName) => {
    const placeholder =
      dropdownType === COUNTRY_TYPE.nationality
        ? t(nationality.pickNationality)
        : t(nationality.pickCitizenship);

    return [
      {
        value: '',
        children: placeholder,
        hidden: true,
        key: '',
      },
      ...countries.data.map((country) => ({
        value: country.value,
        children: country.label,
        key: country.key,
      })),
    ];
  };

  const handleAddCountry = (dropdownType: CountryName) => {
    if (dropdownType === COUNTRY_TYPE.nationality) {
      setNationalities([
        ...nationalities,
        {
          value: '',
          children: '',
          key: `${dropdownType}-${nationalities.length}`,
        },
      ]);
      return;
    }
    setCitizenship([
      ...citizenship,
      {
        value: '',
        children: '',
        key: `${dropdownType}-${citizenship.length}`,
      },
    ]);
  };

  const handeDeleteCountry = (
    dropdownType: CountryName,
    key: string,
    setList: React.Dispatch<React.SetStateAction<[] | countryListType[]>>,
  ) => {
    const isNationalityType = dropdownType === COUNTRY_TYPE.nationality;
    const list = [...(isNationalityType ? nationalities : citizenship)];
    const newList = [...list]
      .filter((country) => country.key !== key)
      .map(
        /* istanbul ignore next */
        (country, index) => {
          if (index > 0) {
            return {
              ...country,
              id: index,
              key: `${dropdownType}-${index}`,
            };
          }
          return country;
        },
      );
    setList(newList);
  };

  const handleCountryChange = (
    key: string,
    dropdownType: CountryName,
    value: string,
    setList: React.Dispatch<React.SetStateAction<[] | countryListType[]>>,
  ) => {
    const list = [
      ...(dropdownType === COUNTRY_TYPE.nationality
        ? nationalities
        : citizenship),
    ];
    const newList = [...list].map((country) => {
      if (country.key === key) {
        return {
          ...country,
          value,
        };
      }
      /* istanbul ignore next */
      return country;
    });
    setList(newList);
  };

  const isButtonDisabled = () => {
    const emptyNationality = nationalities.filter(
      (nationalityItem) => nationalityItem.value === '',
    );
    const emptyCitizenship = citizenship.filter(
      (citizenshipItem) => citizenshipItem.value === '',
    );
    if (
      emptyNationality.length > 0 ||
      emptyCitizenship.length > 0 ||
      !isFulfilledParty ||
      isLoadingParty
    ) {
      return true;
    }
    return false;
  };

  const handleContinue = () => {
    /* istanbul ignore next */
    if (isButtonDisabled()) return;

    setSavingOrUpdatingCountries(true);
    const userNationality = userCountries.filter(
      (countryItem) =>
        countryItem.country_type === COUNTRY_TYPE.nationality &&
        countryItem.is_main_country,
    );
    const userCitizenship = userCountries.filter(
      (countryItem) =>
        countryItem.country_type === COUNTRY_TYPE.citizenship &&
        countryItem.is_main_country,
    );

    dispatch(
      updateCountriesBatch({
        partyId: party.data.party_id,
        countries: {
          nationality: [
            ...userNationality,
            ...formatCountriesToSave(nationalities, COUNTRY_TYPE.nationality),
          ],
          citizenship: [
            ...userCitizenship,
            ...formatCountriesToSave(citizenship, COUNTRY_TYPE.citizenship),
          ],
        },
        config: configuration,
        isFromNationality: true,
      }),
    );
  };

  const handleCloseEdition = () => {
    setIsEditing(false);
    setStep(STEPS.confirmation);
  };

  return (
    <UpgradeLayout current={1}>
      <motion.div
        className={styles.container}
        id={UPGRADE_IDS.viewNationality}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <FormContent>
          <FormSection
            title={t(nationality.title)}
            description={t(nationality.subtitle)}
          >
            <FormRow>
              {isLoadingParty ? (
                <SkeletonLoader kind="large" reverse />
              ) : (
                <Input
                  id="birthInput"
                  name="birthInput"
                  widthSize="fluid"
                  label={t(inputs.birthCountry)}
                  type="text"
                  placeholder=""
                  inputMode="text"
                  value={getCountryValue('birth')}
                  disabled
                />
              )}
              <div className={styles.empty} />
            </FormRow>
            <FormRow>
              {isLoadingParty ? (
                <SkeletonLoader kind="large" reverse />
              ) : (
                <div className={styles.column}>
                  <Input
                    id="nationalityInput"
                    widthSize="fluid"
                    name="nationalityInput"
                    label={t(inputs.nationality)}
                    type="text"
                    placeholder=""
                    inputMode="text"
                    value={getCountryValue(COUNTRY_TYPE.nationality)}
                    disabled
                  />
                  {nationalities.map((nationalityItem) => (
                    <SelectItem
                      key={`nationality-${nationalityItem.value}`}
                      className={styles.select}
                      options={countryOptions(COUNTRY_TYPE.nationality)}
                      selectProps={{
                        value: nationalityItem.value,
                        onChange: (value) =>
                          handleCountryChange(
                            nationalityItem.key,
                            COUNTRY_TYPE.nationality,
                            value,
                            setNationalities,
                          ),
                      }}
                      onDelete={() =>
                        handeDeleteCountry(
                          COUNTRY_TYPE.nationality,
                          nationalityItem.key,
                          setNationalities,
                        )
                      }
                    />
                  ))}
                  {nationalities.length < COUNTRY_LIMIT && (
                    <div className={styles.button}>
                      <PlusCircle size={styles['icon-size']} weight="fill" />
                      <ButtonLink
                        kind="primary"
                        data-testid="addCountry"
                        onClick={() =>
                          handleAddCountry(COUNTRY_TYPE.nationality)
                        }
                      >
                        <Typography variant="body2" weight="semibold">
                          {t(nationality.addNationality)}
                        </Typography>
                      </ButtonLink>
                    </div>
                  )}
                </div>
              )}
              {isLoadingParty ? (
                <SkeletonLoader kind="large" reverse />
              ) : (
                <div className={styles.column}>
                  <Input
                    id="citizenshipInput"
                    name="citizenshipInput"
                    widthSize="fluid"
                    label={t(inputs.citizenship)}
                    placeholder=""
                    inputMode="text"
                    value={getCountryValue(COUNTRY_TYPE.citizenship)}
                    disabled
                  />
                  {citizenship.map((citizenshipItem) => (
                    <SelectItem
                      key={`citizenship-${citizenshipItem.value}`}
                      className={styles.select}
                      options={countryOptions(COUNTRY_TYPE.citizenship)}
                      selectProps={{
                        value: citizenshipItem.value,
                        onChange: (value) =>
                          handleCountryChange(
                            citizenshipItem.key,
                            COUNTRY_TYPE.citizenship,
                            value,
                            setCitizenship,
                          ),
                      }}
                      onDelete={() =>
                        handeDeleteCountry(
                          COUNTRY_TYPE.citizenship,
                          citizenshipItem.key,
                          setCitizenship,
                        )
                      }
                    />
                  ))}
                  {citizenship.length < COUNTRY_LIMIT && (
                    <div className={styles.button}>
                      <PlusCircle size={styles['icon-size']} weight="fill" />
                      <ButtonLink
                        kind="primary"
                        data-testid="addCitizenship"
                        onClick={() =>
                          handleAddCountry(COUNTRY_TYPE.citizenship)
                        }
                      >
                        <Typography variant="body2" weight="semibold">
                          {t(nationality.addCitizenship)}
                        </Typography>
                      </ButtonLink>
                    </div>
                  )}
                </div>
              )}
            </FormRow>
          </FormSection>
        </FormContent>
        <FormFooter>
          {isEditing ? (
            <ButtonLink onClick={handleCloseEdition} underline="always">
              {t(buttons.cancel)}
            </ButtonLink>
          ) : null}
          <Button
            id={UPGRADE_IDS.btnNationality}
            testId="submitButton"
            kind="primary"
            onClick={handleContinue}
            disabled={isButtonDisabled()}
            loading={savingOrUpdatingCountries}
            icon={<ArrowCircleRight size={styles['icon-size']} weight="fill" />}
          >
            {t(buttons[isEditing ? 'save' : 'continue'])}
          </Button>
        </FormFooter>
      </motion.div>
    </UpgradeLayout>
  );
}
