import { BIRTH_DATE_LENGTH, translations } from '@gbm/onboarding-sdk-utils';

import { isBeforeDate, removeSpaces } from '../../../../../../utils/helpers';

export default {
  required: translations.inputs.genericRequiredField,
  validate: {
    length: (value: string) => {
      const cleanedValue = removeSpaces(value);
      return (
        BIRTH_DATE_LENGTH === cleanedValue.length ||
        translations.inputs.invalidField
      );
    },
    isBefore: (value: string) =>
      isBeforeDate(value, new Date()) || translations.inputs.invalidField,
  },
};
