import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrowCircleRight,
  Button,
  HourglassMedium,
  Typography,
} from '@gbm/starman-next';

import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@gbm/onboarding-sdk-ui-components';
import {
  TRACKING_EVENTS,
  TRACKING_NAMES,
  UPGRADE_IDS,
  translations,
} from '@gbm/onboarding-sdk-utils';
import { useMixpanel } from '@gbm/onboarding-sdk-hooks';

import { STEPS } from '../../../providers/upgrade/types';
import { useUpgradeProvider } from '../../../providers/upgrade';
import styles from './contract-upgrade.module.scss';
import { useUpgradeStatus } from '../../../hooks';

const {
  buttons,
  upgrade: { contractStatus },
} = translations;

export default function Reviewing() {
  const { t } = useTranslation();
  const { setStep, configuration } = useUpgradeProvider();
  const { upgradeStatus } = useUpgradeStatus(configuration);
  const mixpanel = useMixpanel(
    configuration.environment,
    configuration.isEnabledTrackEvents,
  );

  const handleClose = () => {
    if (!configuration.skipInitialScreen) {
      setStep(STEPS.upgradeScreen);
      return;
    }

    if (configuration.onGoBack) {
      configuration.onGoBack();
      return;
    }

    window.history.go(-1);
  };

  useEffect(() => {
    mixpanel.track(TRACKING_EVENTS.screenViewed, {
      name: TRACKING_NAMES.upgradeStatus,
      status: upgradeStatus.data?.status,
    });
  }, []);

  return (
    <div id={UPGRADE_IDS.viewReviewing}>
      <Modal show>
        <ModalHeader onClose={handleClose} />
        <ModalContent>
          <div className={styles['reviewing-icon']}>
            <HourglassMedium
              size={styles['icon-size']}
              color={styles['icon-color--info']}
            />
          </div>
          <div className={styles['reviewing-title']}>
            <Typography variant="h5">
              {t(contractStatus.reviewingData)}
            </Typography>
          </div>
          <div className={styles['reviewing-description']}>
            <Typography variant="small">
              {t(contractStatus.reviewingDataDescription)}
            </Typography>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            size="fluid"
            id={UPGRADE_IDS.btnReviewing}
            kind="primary"
            onClick={handleClose}
            icon={<ArrowCircleRight size={styles['icon-size']} weight="fill" />}
          >
            {t(buttons.understood)}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
