import { Typography } from '@gbm/starman-next';
import clsx from 'clsx';

import { CloseButton } from '../close-button';
import styles from './modal.module.scss';

export type ModalHeaderProps = {
  title?: string;
  className?: string;
  onClose?: () => void;
};

export default function ModalHeader({
  title,
  className,
  onClose,
}: ModalHeaderProps) {
  return (
    <header className={clsx(styles.header, className)}>
      {title ? (
        <div className={styles.title}>
          <Typography variant="small">{title}</Typography>
        </div>
      ) : null}
      <div className={styles.close}>
        <CloseButton kind="secondary" onClick={onClose} />
      </div>
    </header>
  );
}
