import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from '@gbm/onboarding-sdk-utils';
import {
  Typography,
  Alert,
  CircleNotch,
  Accordion,
  Clock,
  Prohibit,
} from '@gbm/starman-next';
import { formatNumber } from '@gbm/utils';
import clsx from 'clsx';

import styles from './initial-screen.module.scss';

type StatusProps = {
  accountLimit: number;
  isPending: boolean;
  isRejected: boolean;
  hasDocumentTampered: boolean;
  hasReachedAttempts: boolean;
};

const {
  upgrade: { upgrade },
} = translations;

export default function Status({
  accountLimit,
  isPending,
  isRejected,
  hasDocumentTampered,
  hasReachedAttempts,
}: StatusProps) {
  const { t } = useTranslation();
  const [message, Icon, feedback] = useMemo(() => {
    if (isRejected || hasReachedAttempts) {
      return [t(upgrade.pending), Clock, 'warning'];
    }

    if (hasDocumentTampered) {
      return [t(upgrade.rejected), Prohibit, 'error'];
    }

    return [t(upgrade.inProcess), CircleNotch, 'info'];
  }, [isPending, isRejected, hasDocumentTampered, hasReachedAttempts]);

  return (
    <div className={styles.status}>
      <Typography variant="h4">{t(upgrade.unlimited)}</Typography>
      <div
        className={clsx(styles['status-alert'], {
          [styles['status-alert--rejected']]: isRejected || hasReachedAttempts,
          [styles['status-alert--error']]: hasDocumentTampered,
        })}
      >
        <Alert
          // @ts-ignore
          feedback={feedback}
          icon={<Icon size={styles['icon-size']} />}
        >
          {`${t(upgrade.increaseLimitAccount)}. ${message}`}
        </Alert>
      </div>
      <div className={styles['status-accordion']}>
        <Accordion
          // @ts-ignore
          title={<Typography variant="h5">{t(upgrade.whatIf)}</Typography>}
        >
          <Typography variant="body2">
            {t(upgrade.whatIfDescription, {
              amount: formatNumber(accountLimit, 'currency'),
            })}
          </Typography>
        </Accordion>
      </div>
    </div>
  );
}
