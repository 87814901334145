import { SelectOptionProps } from '@gbm/starman-next';

import { DocumentsType, Values } from '@gbm/onboarding-sdk-utils';
import {
  PartyCountryType,
  IdentifierType,
  BankAccountType,
  LoremQuestionType,
  DeepLinksActionType,
  DeepLinksParamsType,
  DeepLinksProviderType,
} from '../../../api/upgrade/types';
import { REDUCER_STATUS, StateValueType } from '../types';
import { ErrorType, HttpVerbsTypes } from '../../../api/types';
import {
  UpgradeProviderProps,
  UpgradeStatusesType,
} from '../../providers/upgrade/types';
import {
  AnswersDataType,
  QuestionnaireDataType,
} from '../../components/upgrade/financial-information/types';

export const REDUCER_NAME = 'upgrade';

export const BANK_ACCOUNT_STATUS = {
  deleted: 'deleted',
  failed: 'failed',
  manualVerification: 'required_manual_verification',
  new: 'new',
  pendingVerification: 'pending_verification',
  pending: 'pending',
  registered: 'registered',
  verified: 'verified',
};

export const COUNTRY_TYPE = {
  birth: 'birth',
  residence: 'residence',
  nationality: 'nationality',
  citizenship: 'citizenship',
  taxation: 'taxation',
} as const;

export type CountryName = Values<typeof COUNTRY_TYPE>;

export type countryListType = SelectOptionProps & { key: string };

export const USA_COUNTRY_CODE = 'USA';

export const VALID_BANK_ACCOUNT_STATUSES = [
  BANK_ACCOUNT_STATUS.new,
  BANK_ACCOUNT_STATUS.registered,
  BANK_ACCOUNT_STATUS.pending,
  BANK_ACCOUNT_STATUS.pendingVerification,
  BANK_ACCOUNT_STATUS.verified,
  BANK_ACCOUNT_STATUS.deleted,
  BANK_ACCOUNT_STATUS.manualVerification,
];

export const IDENTIFIERS = {
  curp: 'CURP',
  rfc: 'RFC',
  tin: 'TIN',
  fiel: 'FIEL',
};

export type UpgradeStateErrorsType = {
  nationalities?: ErrorType;
  bankAccounts?: ErrorType;
  rfc?: ErrorType;
  tin?: ErrorType;
  fiel?: ErrorType;
  banks?: ErrorType;
  addBankAccount?: ErrorType;
  upgradeStep?: ErrorType;
  upgradeStatus?: ErrorType;
  udisSummary?: ErrorType;
  documents?: ErrorType;
  eaQuestionnaire?: ErrorType;
  eaAnswers?: ErrorType;
  pepQuestionnaire?: ErrorType;
  pepAnswers?: ErrorType;
  kycQuestionnaire?: ErrorType;
  kycAnswers?: ErrorType;
  proofOfAddress?: ErrorType;
  bankAccountDecrypted?: ErrorType;
  deepLinks?: ErrorType;
  validUpgrade?: ErrorType;
};

export type UpgradeStatusType = {
  attempts: number;
  documents?: DocumentsType[];
  status: UpgradeStatusesType;
};

export type UpgradeStateType = {
  nationalities: StateValueType<Array<any>>;
  banks: StateValueType;
  bankAccounts: StateValueType;
  rfc: StateValueType;
  tin: StateValueType;
  fiel: StateValueType;
  addBankAccount: StateValueType;
  upgradeStep: StateValueType;
  upgradeStatus: StateValueType<UpgradeStatusType | undefined>;
  udisSummary: StateValueType;
  documents: StateValueType;
  eaQuestionnaire: StateValueType<QuestionnaireDataType | {}>;
  eaAnswers: StateValueType<AnswersDataType | {}>;
  pepQuestionnaire: StateValueType<QuestionnaireDataType | {}>;
  pepAnswers: StateValueType<AnswersDataType | {}>;
  kycQuestionnaire: StateValueType<QuestionnaireDataType | {}>;
  kycAnswers: StateValueType<AnswersDataType | {}>;
  proofOfAddress: StateValueType;
  bankAccountDecrypted: StateValueType;
  deepLinks: StateValueType;
  validUpgrade: StateValueType;
  errors: UpgradeStateErrorsType;
};

type countriesRequest = {
  [COUNTRY_TYPE.birth]?: PartyCountryType[];
  [COUNTRY_TYPE.citizenship]?: PartyCountryType[];
  [COUNTRY_TYPE.nationality]?: PartyCountryType[];
  [COUNTRY_TYPE.residence]?: PartyCountryType[];
  [COUNTRY_TYPE.taxation]?: PartyCountryType[];
};

export type InfoRequestType<T> = {
  config: UpgradeProviderProps;
  partyId?: string;
} & T;

export type UpdateCountriesBatchRequestType = InfoRequestType<{
  countries: countriesRequest;
  isFromNationality: boolean;
}>;

export type BankAccountsRequestType = InfoRequestType<{
  contractId: string;
}>;

export type AddBankAccountRequestType = InfoRequestType<{
  bankAccount: BankAccountType;
}>;

export type RfcRequestType = InfoRequestType<{
  rfc: string;
}>;

export type TinRequestType = InfoRequestType<{
  tin: string;
}>;

export type FielRequestType = InfoRequestType<{
  fiel: string;
}>;

export type DeleteIdentifierType = InfoRequestType<{
  identifier: IdentifierType;
}>;

export type SaveAnswersRequestType = InfoRequestType<{
  questions: Array<LoremQuestionType>;
  method: HttpVerbsTypes;
}>;

export type SavePoaRequestType = InfoRequestType<{
  file: Blob;
  width: number;
  height: number;
  extension: string;
}>;

export type VerifyPoaRequestType = InfoRequestType<{
  prefersIne: boolean;
}>;

export type RequestDeepLinkType = InfoRequestType<{
  action: DeepLinksActionType;
  params: DeepLinksParamsType;
  provider: DeepLinksProviderType;
  origin: string;
}>;

export const INITIAL_STATE: UpgradeStateType = {
  nationalities: {
    status: REDUCER_STATUS.idle,
    data: [],
  },
  banks: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  bankAccounts: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  rfc: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  tin: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  fiel: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  addBankAccount: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  upgradeStep: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  upgradeStatus: {
    status: REDUCER_STATUS.idle,
    data: undefined,
  },
  udisSummary: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  documents: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  eaQuestionnaire: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  eaAnswers: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  pepQuestionnaire: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  pepAnswers: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  kycQuestionnaire: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  kycAnswers: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  proofOfAddress: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  bankAccountDecrypted: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  deepLinks: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  validUpgrade: {
    status: REDUCER_STATUS.idle,
    data: {},
  },
  errors: {},
};

export type BanksType = {
  bank_id: number;
  country_code: string;
  gbm_bank_id: number;
  gbm_bank_reference_number: number;
  is_automatic_deposit_enabled: boolean;
  is_schedule_deposits_enabled: boolean;
  name: string;
  reference_number: string;
  routing_number_type: string;
  routing_number_type_id: number;
  trading_name: string;
};

export const QUESTIONNAIRES = {
  economicActivity: {
    id: 'CCB87491-CDD7-4E7C-A4F1-883C61D2944B',
    version: 3,
  },
  pep: {
    id: 'B3457B12-06E2-4A39-8F07-C3480720E8DB',
    version: 2,
  },
  kyc: {
    id: '38FE1616-B71B-4F01-9E38-F053EAD498EC',
    version: 1,
  },
} as const;
