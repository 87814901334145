import { AnalyticsBrowser } from '@segment/analytics-next';
import { getAccessToken, getUserId } from '../helpers';

import { config } from '../config';
import { Environment } from '../types';

import { SEGMENT_TYPES, SegmentType } from './types';

const init = async (environment: Environment) => {
  if (!window.segmentAnalytics) {
    const { segmentApiKey } = config(environment);
    const [Analytics] = await AnalyticsBrowser.load({
      writeKey: segmentApiKey,
    });
    window.segmentAnalytics = Analytics;
  }
};

const Segment = {
  track: async (
    environment: Environment,
    event: string,
    properties: object,
    type: SegmentType = SEGMENT_TYPES.track,
  ) => {
    await init(environment);

    if (event !== SEGMENT_TYPES.anonymousId) {
      const accessToken = getAccessToken() || '';
      const userId = getUserId(accessToken);

      window.segmentAnalytics[type](event, { sub_id: userId, ...properties });
    } else {
      window.segmentAnalytics[type](event, properties);
    }
  },
};

export default Segment;
