import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import flatten from 'lodash/flatten';

import {
  makeTrackingErrorProps,
  Mixpanel,
  TRACKING_EVENTS,
  TRACKING_NAMES,
} from '@gbm/onboarding-sdk-utils';
import { Upgrade } from '../../../api';
import {
  IdentifierType,
  LOREM_QUESTIONNAIRE_TYPE,
} from '../../../api/upgrade/types';
import { formatQuestionnaireToSave, makeError } from '../../../utils/helpers';
import { HTTP_STATUS_CODES } from '../../../api/types';
import {
  DOCUMENT_TYPES,
  DOCUMENTS_STATUS,
} from '../../providers/upgrade/types';
import { StateValueType } from '../types';
import {
  REDUCER_NAME,
  UpdateCountriesBatchRequestType,
  BankAccountsRequestType,
  RfcRequestType,
  TinRequestType,
  FielRequestType,
  DeleteIdentifierType,
  AddBankAccountRequestType,
  BANK_ACCOUNT_STATUS,
  CountryName,
  IDENTIFIERS,
  InfoRequestType,
  QUESTIONNAIRES,
  SaveAnswersRequestType,
  VerifyPoaRequestType,
  SavePoaRequestType,
  RequestDeepLinkType,
} from './types';

const getPartyRfc = createAction<StateValueType>(`${REDUCER_NAME}/getPartyRfc`);
const getPartyFiel = createAction<StateValueType>(
  `${REDUCER_NAME}/getPartyFiel`,
);
const getPartyTin = createAction<StateValueType>(`${REDUCER_NAME}/getPartyTin`);

export const updateCountriesBatch = createAsyncThunk(
  `${REDUCER_NAME}/updateCountriesBatch`,
  async (
    {
      partyId,
      countries,
      config,
      isFromNationality,
    }: UpdateCountriesBatchRequestType,
    { rejectWithValue },
  ) => {
    try {
      const upgradeApi = new Upgrade(config);
      const mixpanel = new Mixpanel(
        config.environment,
        config.isEnabledTrackEvents,
      );
      const requests = Object.keys(countries).map((key) =>
        upgradeApi.updatePartyCountriesBatch(
          partyId as string,
          // @ts-ignore
          countries[key as CountryName]!,
          key,
        ),
      );
      const batchResponse = await Promise.all(requests);

      if (batchResponse.every((response) => response?.data)) {
        return flatten(batchResponse.map((response) => response.data));
      }

      const [error, headers] = batchResponse.find(
        (response) => response.err && [response.err, response.headers],
      );
      const trackingErrorProps = makeTrackingErrorProps(error, headers);
      const name = isFromNationality
        ? TRACKING_NAMES.nationality
        : TRACKING_NAMES.fiscalData;

      mixpanel.track(TRACKING_EVENTS.screenFilled, {
        name,
        ...trackingErrorProps,
      });

      return rejectWithValue(error);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getBankList = createAsyncThunk(
  `${REDUCER_NAME}/getBankList`,
  async ({ config }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const upgradeApi = new Upgrade(config);
      const { data, err } = await upgradeApi.getBankList();
      if (data) {
        return data;
      }
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getPartyCountries = createAsyncThunk(
  `${REDUCER_NAME}/getPartyCountries`,
  async ({ config, partyId }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const upgradeApi = new Upgrade(config);
      const { data, err } = await upgradeApi.getPartyCountries(
        partyId as string,
      );
      if (data) {
        return data;
      }
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getBankAccountStatus = createAsyncThunk(
  `${REDUCER_NAME}/getBankAccountStatus`,
  async (
    { config, contractId }: BankAccountsRequestType,
    { rejectWithValue },
  ) => {
    try {
      const upgradeApi = new Upgrade(config);
      const { data, err } = await upgradeApi.getBankAccountStatus(contractId);
      if (data) {
        const bankAccountsDocuments = data.items.map(
          (bankAccount: any) =>
            bankAccount.verification_status ===
              BANK_ACCOUNT_STATUS.manualVerification &&
            upgradeApi.getWithdrawalDocuments(bankAccount.id),
        );
        const accountsResult = await Promise.all(bankAccountsDocuments);
        accountsResult.forEach((document, index) => {
          document && (data.items[index].documents = document?.data?.items);
        });
        return data;
      }
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getPartyIdentifiers = createAsyncThunk(
  `${REDUCER_NAME}/getPartyIdentifiers`,
  async (
    { partyId, config }: InfoRequestType<{}>,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const upgradeApi = new Upgrade(config);
      const { data, err } = await upgradeApi.getPartyIdentifiers(
        partyId as string,
      );
      if (data) {
        const rfc = data.find(
          (id: IdentifierType) =>
            id.name.toLowerCase() === IDENTIFIERS.rfc.toLocaleLowerCase(),
        );
        const tin = data.find(
          (id: IdentifierType) =>
            id.name.toLowerCase() === IDENTIFIERS.tin.toLocaleLowerCase(),
        );
        const fiel = data.find(
          (id: IdentifierType) =>
            id.name.toLowerCase() === IDENTIFIERS.fiel.toLocaleLowerCase(),
        );
        dispatch(getPartyRfc(rfc));
        dispatch(getPartyFiel(fiel));
        dispatch(getPartyTin(tin));

        return data;
      }
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const deleteIdentifier = createAsyncThunk(
  `${REDUCER_NAME}/deleteIdentifier`,
  async (
    { partyId, config, identifier }: DeleteIdentifierType,
    { rejectWithValue },
  ) => {
    try {
      const upgradeApi = new Upgrade(config);
      const { data, err } = await upgradeApi.deleteIdentifier(
        partyId as string,
        identifier,
      );
      if (data) {
        return data;
      }
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const savePartyRfc = createAsyncThunk(
  `${REDUCER_NAME}/savePartyRfc`,
  async ({ partyId, config, rfc }: RfcRequestType, { rejectWithValue }) => {
    try {
      const upgradeApi = new Upgrade(config);
      const mixpanel = new Mixpanel(
        config.environment,
        config.isEnabledTrackEvents,
      );
      const { data, err, headers } = await upgradeApi.savePartyRfc(
        partyId as string,
        rfc,
      );

      if (data) {
        return data;
      }

      const trackingErrorProps = makeTrackingErrorProps(err, headers);
      mixpanel.track(TRACKING_EVENTS.screenFilled, {
        name: TRACKING_NAMES.fiscalData,
        ...trackingErrorProps,
      });
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const savePartyTin = createAsyncThunk(
  `${REDUCER_NAME}/savePartyTin`,
  async ({ partyId, config, tin }: TinRequestType, { rejectWithValue }) => {
    try {
      const upgradeApi = new Upgrade(config);
      const mixpanel = new Mixpanel(
        config.environment,
        config.isEnabledTrackEvents,
      );
      const { data, err, headers } = await upgradeApi.savePartyTin(
        partyId as string,
        tin,
      );

      if (data) {
        return data;
      }

      const trackingErrorProps = makeTrackingErrorProps(err, headers);
      mixpanel.track(TRACKING_EVENTS.screenFilled, {
        name: TRACKING_NAMES.fiscalData,
        ...trackingErrorProps,
      });
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const savePartyFiel = createAsyncThunk(
  `${REDUCER_NAME}/savePartyFiel`,
  async ({ partyId, config, fiel }: FielRequestType, { rejectWithValue }) => {
    try {
      const upgradeApi = new Upgrade(config);
      const mixpanel = new Mixpanel(
        config.environment,
        config.isEnabledTrackEvents,
      );
      const { data, err, headers } = await upgradeApi.savePartyFiel(
        partyId as string,
        fiel,
      );

      if (data) {
        return data;
      }

      const trackingErrorProps = makeTrackingErrorProps(err, headers);
      mixpanel.track(TRACKING_EVENTS.screenFilled, {
        name: TRACKING_NAMES.fiscalData,
        ...trackingErrorProps,
      });
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const saveBankAccount = createAsyncThunk(
  `${REDUCER_NAME}/saveBankAccount`,
  async (
    { bankAccount, config }: AddBankAccountRequestType,
    { rejectWithValue },
  ) => {
    try {
      const upgradeApi = new Upgrade(config);

      const { data, err } = await upgradeApi.saveBankAccount(bankAccount);

      if (data) {
        return data;
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getUpgradeStep = createAsyncThunk(
  `${REDUCER_NAME}/getUpgradeStep`,
  async ({ partyId, config }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const upgradeApi = new Upgrade(config);
      const { data, err } = await upgradeApi.getUpgradeStep(partyId as string);
      if (data) {
        return data;
      }
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getUpgradeStatus = createAsyncThunk(
  `${REDUCER_NAME}/getUpgradeStatus`,
  async ({ partyId, config }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const upgradeApi = new Upgrade(config);
      const { data, err } = await upgradeApi.getUpgradeStatus(
        partyId as string,
      );
      if (data) {
        return data;
      }
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getUdisSummary = createAsyncThunk(
  `${REDUCER_NAME}/getUdisSummary`,
  async ({ partyId, config }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const upgradeApi = new Upgrade(config);
      const { data, err } = await upgradeApi.getUdisSummary(partyId as string);
      if (data) {
        return data;
      }
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getDocuments = createAsyncThunk(
  `${REDUCER_NAME}/getDocuments`,
  async ({ partyId, config }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const upgradeApi = new Upgrade(config);
      const { data, err } = await upgradeApi.getDocuments(partyId as string);
      if (data) {
        return data;
      }
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getEaQuestionnaire = createAsyncThunk(
  `${REDUCER_NAME}/getEaQuestionnaire`,
  async ({ config }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const upgradeApi = new Upgrade(config);
      const { data, err } = await upgradeApi.getFinancialInfoQuestionnaire(
        QUESTIONNAIRES.economicActivity.id,
        QUESTIONNAIRES.economicActivity.version,
      );

      if (data) {
        return data;
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getEaAnswers = createAsyncThunk(
  `${REDUCER_NAME}/getEaAnswers`,
  async ({ partyId, config }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const upgradeApi = new Upgrade(config);
      const { data, err } =
        await upgradeApi.getFinancialInfoQuestionnaireAnswers(
          partyId as string,
          LOREM_QUESTIONNAIRE_TYPE.clientEconomicActivity,
        );

      if (data) {
        return data;
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const saveEaAnswers = createAsyncThunk(
  `${REDUCER_NAME}/saveEaAnswers`,
  async (
    { partyId, config, questions, method }: SaveAnswersRequestType,
    { rejectWithValue },
  ) => {
    try {
      const upgradeApi = new Upgrade(config);
      const mixpanel = new Mixpanel(
        config.environment,
        config.isEnabledTrackEvents,
      );
      const answers = formatQuestionnaireToSave(
        QUESTIONNAIRES.economicActivity.id,
        QUESTIONNAIRES.economicActivity.version.toString(),
        LOREM_QUESTIONNAIRE_TYPE.clientEconomicActivity,
        questions,
      );
      const { data, err, headers } = await upgradeApi.saveFinancialInfoAnswers(
        partyId as string,
        answers,
        method,
      );

      if (data) {
        return data;
      }

      const trackingErrorProps = makeTrackingErrorProps(err, headers);
      mixpanel.track(TRACKING_EVENTS.screenFilled, {
        name: TRACKING_NAMES.economicActivity,
        ...trackingErrorProps,
      });
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getPepQuestionnaire = createAsyncThunk(
  `${REDUCER_NAME}/getPepQuestionnaire`,
  async ({ config }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const upgradeApi = new Upgrade(config);
      const { data, err } = await upgradeApi.getFinancialInfoQuestionnaire(
        QUESTIONNAIRES.pep.id,
        QUESTIONNAIRES.pep.version,
      );

      if (data) {
        return data;
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getPepAnswers = createAsyncThunk(
  `${REDUCER_NAME}/getPepAnswers`,
  async ({ partyId, config }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const upgradeApi = new Upgrade(config);
      const { data, err } =
        await upgradeApi.getFinancialInfoQuestionnaireAnswers(
          partyId as string,
          LOREM_QUESTIONNAIRE_TYPE.pep,
        );

      if (data) {
        return data;
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const savePepAnswers = createAsyncThunk(
  `${REDUCER_NAME}/savePepAnswers`,
  async (
    { partyId, config, questions, method }: SaveAnswersRequestType,
    { rejectWithValue },
  ) => {
    try {
      const upgradeApi = new Upgrade(config);
      const mixpanel = new Mixpanel(
        config.environment,
        config.isEnabledTrackEvents,
      );
      const answers = formatQuestionnaireToSave(
        QUESTIONNAIRES.pep.id,
        QUESTIONNAIRES.pep.version.toString(),
        LOREM_QUESTIONNAIRE_TYPE.pep,
        questions,
      );
      const { data, err, headers } = await upgradeApi.saveFinancialInfoAnswers(
        partyId as string,
        answers,
        method,
      );

      if (data) {
        return data;
      }

      const trackingErrorProps = makeTrackingErrorProps(err, headers);
      mixpanel.track(TRACKING_EVENTS.screenFilled, {
        name: TRACKING_NAMES.economicActivity,
        ...trackingErrorProps,
      });
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getKycQuestionnaire = createAsyncThunk(
  `${REDUCER_NAME}/getKycQuestionnaire`,
  async ({ config }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const upgradeApi = new Upgrade(config);
      const { data, err } = await upgradeApi.getFinancialInfoQuestionnaire(
        QUESTIONNAIRES.kyc.id,
        QUESTIONNAIRES.kyc.version,
      );

      if (data) {
        return data;
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getKycAnswers = createAsyncThunk(
  `${REDUCER_NAME}/getKycAnswers`,
  async ({ partyId, config }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const upgradeApi = new Upgrade(config);
      const { data, err } =
        await upgradeApi.getFinancialInfoQuestionnaireAnswers(
          partyId as string,
          LOREM_QUESTIONNAIRE_TYPE.kyc,
        );

      if (data) {
        return data;
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const saveKycAnswers = createAsyncThunk(
  `${REDUCER_NAME}/saveKycAnswers`,
  async (
    { partyId, config, questions, method }: SaveAnswersRequestType,
    { rejectWithValue },
  ) => {
    try {
      const upgradeApi = new Upgrade(config);
      const mixpanel = new Mixpanel(
        config.environment,
        config.isEnabledTrackEvents,
      );
      const answers = formatQuestionnaireToSave(
        QUESTIONNAIRES.kyc.id,
        QUESTIONNAIRES.kyc.version.toString(),
        LOREM_QUESTIONNAIRE_TYPE.kyc,
        questions,
      );
      const { data, err, headers } = await upgradeApi.saveFinancialInfoAnswers(
        partyId as string,
        answers,
        method,
      );

      if (data) {
        return data;
      }

      const trackingErrorProps = makeTrackingErrorProps(err, headers);
      mixpanel.track(TRACKING_EVENTS.screenFilled, {
        name: TRACKING_NAMES.economicActivity,
        ...trackingErrorProps,
      });
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const saveProofOfAddress = createAsyncThunk(
  `${REDUCER_NAME}/saveProofOfAddress`,
  async (
    { partyId, config, file }: SavePoaRequestType,
    { rejectWithValue },
  ) => {
    try {
      const upgradeApi = new Upgrade(config);
      const mixpanel = new Mixpanel(
        config.environment,
        config.isEnabledTrackEvents,
      );
      const { data, err, headers } = await upgradeApi.saveDocumentMultipart(
        partyId as string,
        file,
        DOCUMENT_TYPES.proofOfAddress,
      );

      if (data) {
        return data;
      }

      const trackingErrorProps = makeTrackingErrorProps(err, headers);
      mixpanel.track(TRACKING_EVENTS.documentUploaded, {
        name: DOCUMENT_TYPES.proofOfAddress,
        ...trackingErrorProps,
      });
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getBankAccountDecrypted = createAsyncThunk(
  `${REDUCER_NAME}/getBankAccountDecrypted`,
  async (
    { bankAccountId, config }: InfoRequestType<{ bankAccountId: string }>,
    { rejectWithValue },
  ) => {
    try {
      const upgradeApi = new Upgrade(config);
      const { data, err } = await upgradeApi.getBankAccountDecrypted(
        bankAccountId,
      );

      if (data) {
        return data;
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const verifyProofOfAddress = createAsyncThunk(
  `${REDUCER_NAME}/verifyProofOfAddress`,
  async (
    { partyId, config, prefersIne }: VerifyPoaRequestType,
    { rejectWithValue },
  ) => {
    try {
      const upgradeApi = new Upgrade(config);
      const mixpanel = new Mixpanel(
        config.environment,
        config.isEnabledTrackEvents,
      );
      const { status, err, headers } = await upgradeApi.verifyDocument(
        partyId as string,
        DOCUMENT_TYPES.proofOfAddress,
        prefersIne,
      );

      if (status === HTTP_STATUS_CODES.noContent) {
        return DOCUMENTS_STATUS.validated;
      }

      const trackingErrorProps = makeTrackingErrorProps(err, headers);
      mixpanel.track(TRACKING_EVENTS.documentValidated, {
        name: DOCUMENT_TYPES.proofOfAddress,
        ...trackingErrorProps,
      });
      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getDeepLink = createAsyncThunk(
  `${REDUCER_NAME}/getDeepLink`,
  async (
    { config, action, params, provider, origin }: RequestDeepLinkType,
    { rejectWithValue },
  ) => {
    try {
      const upgradeApi = new Upgrade(config);
      const { data, err } = await upgradeApi.getDeepLink(
        action,
        params,
        provider,
        origin,
      );

      if (data) {
        return { provider, link: data.deep_link };
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);

export const getValidUpgrade = createAsyncThunk(
  `${REDUCER_NAME}/getValidUpgrade`,
  async ({ config, partyId }: InfoRequestType<{}>, { rejectWithValue }) => {
    try {
      const onboardingBlackApi = new Upgrade(config);
      const { err, status } = await onboardingBlackApi.getValidUpgrade(
        partyId as string,
      );
      if (status === HTTP_STATUS_CODES.unprocessableEntity) {
        return { validForUpgrade: false };
      }

      if (status === HTTP_STATUS_CODES.noContent) {
        return { validForUpgrade: true };
      }

      return rejectWithValue(err);
    } catch (error) {
      const err = makeError(error);
      return rejectWithValue(err);
    }
  },
);
