import { useMemo } from 'react';
import {
  Typography,
  GraphBar,
  Tooltip,
  ButtonLink,
  Info,
} from '@gbm/starman-next';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '@gbm/utils';
import clsx from 'clsx';

import { translations } from '@gbm/onboarding-sdk-utils';
import styles from './initial-screen.module.scss';

type DepositsProps = {
  accountLimit: number;
  amount: string;
  depositsAmount: number;
  fullRow?: boolean;
};

const {
  upgrade: { upgrade },
} = translations;

export default function Deposits({
  accountLimit,
  amount,
  depositsAmount,
  fullRow,
}: DepositsProps) {
  const { t } = useTranslation();
  const [percentage, remaining] = useMemo(() => {
    const value = Math.floor((depositsAmount * 100) / accountLimit);

    if (value < 0) {
      return [0, 100];
    }

    if (value > 100) {
      return [100, 0];
    }

    return [value, 100 - value];
  }, [accountLimit, depositsAmount]);

  return (
    <div
      className={clsx(styles.deposits, { [styles['deposits--full']]: fullRow })}
    >
      <div className={styles['deposits-title']}>
        <Typography variant="small">{t(upgrade.monthlyDeposits)}</Typography>
      </div>
      <div className={styles['deposits-amount']}>
        <Typography variant="h1">{formatNumber(amount, 'currency')}</Typography>
      </div>
      <div className={styles['deposits-bar']}>
        <GraphBar
          size="small"
          bars={[
            { bgColor: styles['blue-600'], percentage },
            {
              bgColor: styles['stale-50'],
              percentage: remaining,
            },
          ]}
        />
      </div>
      <div className={styles['deposits-limit']}>
        <Tooltip
          contentChildren={[
            {
              title: t(upgrade.monthlyLimit),
              content: t(upgrade.monthlyLimitDescription),
            },
          ]}
          position="bottom"
          size="small"
        >
          <ButtonLink underline="always" kind="accent">
            <Typography variant="small">{t(upgrade.monthlyLimit)}</Typography>
            <Info
              color={styles['blue-600']}
              size={styles['icon-size--small']}
              weight="fill"
            />
          </ButtonLink>
        </Tooltip>
        <Typography variant="small">
          {formatNumber(accountLimit, 'currency')}
        </Typography>
      </div>
    </div>
  );
}
