import { QRCodeSVG } from 'qrcode.react';

import styles from './qr-code.module.scss';

type QrCodeProps = {
  size: number;
  value: string;
  withImage?: boolean;
  imageWidth?: number;
  imageHeight?: number;
  imageAlt?: string;
  imageSrc?: string;
};
const QR_IMAGE_PADDING = 12;

export default function QrCode({
  size,
  value,
  withImage = false,
  imageWidth = 0,
  imageHeight = 0,
  imageAlt = '',
  imageSrc = '',
}: QrCodeProps) {
  const newSize = withImage ? size + QR_IMAGE_PADDING : size;
  const imageSettings = {
    imageSettings: {
      src: '',
      excavate: true,
      height: imageHeight + QR_IMAGE_PADDING,
      width: imageWidth + QR_IMAGE_PADDING,
    },
  };
  const imageSettingsProp = withImage ? imageSettings : {};

  return (
    <div
      className={styles.container}
      style={{
        width: newSize,
        height: newSize,
      }}
    >
      <QRCodeSVG
        value={value}
        size={newSize}
        level="Q"
        {...imageSettingsProp}
      />
      {withImage && (
        <img
          alt={imageAlt}
          src={imageSrc}
          className={styles.image}
          style={{
            height: imageHeight,
            width: imageWidth,
          }}
        />
      )}
    </div>
  );
}
